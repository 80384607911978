import React from 'react'
import { Routes, Route } from 'react-router-dom';

import Home from './../components/Home';
import AuthLayout from './../components/layouts/auth/AuthLayout';
import SignUp from './../components/auth/SignUp';
import SignIn from './../components/auth/SignIn';
import ForgotPassword from './../components/auth/ForgotPassword';
import OtpVerify from './../components/auth/OtpVerify';
import { UserTypeProvider } from '../contexts/UserTypeContext';

const Auth = () => {
    return (
        <>
            <UserTypeProvider>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='' element={<AuthLayout />}>
                        <Route path='/sign-up/:userType' element={<SignUp />} />
                        <Route path='/sign-in/:userType' element={<SignIn />} />
                        <Route path='/otp-verify' element={<OtpVerify />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                    </Route>
                </Routes>
            </UserTypeProvider>
        </>
    )
}

export default Auth