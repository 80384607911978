import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import LogoImage from '../../../src/assets/icons/Logo.png';
import SubmitIcon from '../../../src/assets/icons/submit_button.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Vector1 from '../../../src/assets/icons/big_lines.png';
import Vector2 from '../../../src/assets/icons/small_lines.png';
import CmsHeader
 from '../layouts/base/CmsHeader';
const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'Please complete this required field.';
        if (!formData.lastName) newErrors.lastName = 'Please complete this required field.';
        if (!formData.email) newErrors.email = 'Please complete this required field.';
        if (!formData.role) newErrors.role = 'Please complete this required field.';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            alert('Form submitted successfully!');
            navigate('/');
        }
    };

    const goToHome = () => {
        navigate("/");
    };

    return (
      <>
        <div className="xl:tw-hidden tw-block">
          <CmsHeader />
        </div>
        <div className="tw-flex tw-justify-center xl:tw-px-36 xl:tw-pt-20">
          {/* Sidebar - Fixed position */}
          <div className="tw-w-1/6 tw-bg-[#3748E8] tw-h-full tw-flex-col tw-justify-center tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-10 tw-hidden xl:tw-flex">
            <div className="tw-absolute tw-top-4 tw-left-4">
              <img
                src={LogoImage}
                alt="Re-loan logo"
                className="tw-w-40 tw-cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>

            {/* Vector Decorations */}
            <img
              src={Vector2}
              alt="vector2"
              className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-50 tw-h-1/3"
            />
            <img
              src={Vector1}
              alt="vector1"
              className="tw-absolute tw-top-20 tw-left-0 tw-opacity-50 tw-h-2/3"
            />
          </div>

          {/* Main Content */}
          <div className="tw-w-5/6 tw-bg-white tw-h-full tw-justify-center tw-text-2xl tw-overflow-y-auto tw-scrollbar xl:tw-ml-[22%] tw-pt-12">
            <h1 className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-reloan tw-relative tw-flex">
              <a href="/">
                <ChevronLeftIcon
                  fontSize={"12px"}
                  className="tw-text-gray-800"
                />
              </a>
              Get In Touch
            </h1>
            <p className="tw-text-xl tw-text-gray-600 tw-mb-10 tw-px-16 tw-text-center xl:tw-text-left">
              Leave your details, and we’ll connect you with the right team
              member as soon as possible.
            </p>

            <form className="tw-flex tw-flex-col tw-gap-5 tw-p-5">
              <div className="tw-flex tw-flex-col">
                <label
                  htmlFor="firstName"
                  className="tw-text-gray-700 tw-font-medium tw-mb-2"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-text-gray-900 focus:tw-border-blue-500"
                  placeholder="Enter your first name"
                />
              </div>

              <div className="tw-flex tw-flex-col">
                <label
                  htmlFor="lastName"
                  className="tw-text-gray-700 tw-font-medium tw-mb-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-text-gray-900 focus:tw-border-blue-500"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="tw-flex tw-flex-col">
                <label
                  htmlFor="email"
                  className="tw-text-gray-700 tw-font-medium tw-mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-text-gray-900 focus:tw-border-blue-500"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="tw-row-span-4 tw-flex tw-flex-col tw-justify-end">
                <label
                  htmlFor="message"
                  className="tw-text-gray-700 tw-font-medium tw-mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  rows="16"
                  className="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-text-gray-900 focus:tw-border-blue-500 tw-h-52"
                  placeholder="Type your message here"
                ></textarea>
              </div>

              <div className="tw-flex tw-flex-col">
                <label
                  htmlFor="role"
                  className="tw-text-gray-700 tw-font-medium tw-mb-2"
                >
                  I am a
                </label>
                <select
                  id="role"
                  className="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-text-gray-900 focus:tw-border-blue-500"
                >
                  <option value="">Select Option</option>
                  <option value="borrower">Borrower</option>
                  <option value="lender">Lender</option>
                </select>
              </div>

              <div className="tw-col-span-2 tw-flex tw-justify-end tw-mt-4">
                <Link to={"/"} tabIndex={-1}>
                  <button className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-mt-3 tw-flex tw-gap-x-1 tw-items-center tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl">
                    <img
                      src={SubmitIcon}
                      className="tw-w-133 tw-h-63"
                      alt="submit"
                    />
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </>
    );
};

export default ContactUs;
