import React, { useState, useEffect } from "react";
import Header from "../layouts/homepage/Header";
import { authLocal } from "../../utils/common";
import CmsContent from "./CmsContent";
import { useApiService } from "../../services/apiService";
import PageLoader from "../../contexts/PageLoaderContext";

function CmsPage({ slug }) {
    const { getRequest } = useApiService();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false); // Added for loading state
    const [error, setError] = useState(null); // Added for error state
    const [cmsData, setCmsData] = useState(null); // Added for CMS data

    let title = "";
    if (slug === "website-terms") {
        title = "Website Terms";
    } else if (slug === "privacy-policy") {
        title = "Privacy Policy";
    } else if (slug === "cookies-policy") {
        title = "Cookies Policy";
    } else if (slug === "contact") {
        title = "Contact";
    } else {
        title = "Disclaimer";
    }

    useEffect(() => {
        const userData = authLocal.get();
        setIsUserLoggedIn(userData && userData.email ? true : false);
    }, []);

    useEffect(() => {
        // Fetch CMS content from the API
        const fetchCmsContent = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await getRequest(`/cms/get-with-slug/${slug}`);
                if (response?.success) {
                    const data = response?.data?.cms; // Fetch CMS data
                    setCmsData(data);
                } else {
                    setCmsData([]);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCmsContent();
    }, [slug]);

    const handleUserTypeChange = (type) => {
        console.log("User type changed to:", type);
    };

    return (
        <div className="tw-relative tw-h-screen">
            <div className="tw-flex tw-justify-center">
                {loading && <PageLoader/>}
                {error && <p>Error: {error}</p>}
                {cmsData && <CmsContent title={title} content={cmsData.content} />}
            </div>
        </div>
    );
}

export default CmsPage;
