import React from 'react'
import LogoImage from "../../../assets/icons/Logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Corrected Import
import "swiper/swiper-bundle.css";
import { useNavigate } from 'react-router-dom';



import '../../../auth.css'
const Sidebar = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    }

    const testimonials = [
        {
            paragraph: "This product has really helped our business grow significantly.",
            name: "Jennie Ruby",
            designation: "CEO, TechCorp",
            profileImage: "https://randomuser.me/api/portraits/women/1.jpg"
        },
        {
            paragraph: "Amazing service and great support. Highly recommend!",
            name: "Yoo Jimin",
            designation: "CTO, InnovateX",
            profileImage: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            paragraph: "Our team uses this platform daily and we love its simplicity.",
            name: "Shin Ryujin",
            designation: "COO, WebDyno",
            profileImage: "https://randomuser.me/api/portraits/women/2.jpg"
        },
        {
            paragraph: "A fantastic tool that has made our processes much smoother.",
            name: "Kim Taehyung",
            designation: "Founder, VisionSoft",
            profileImage: "https://randomuser.me/api/portraits/men/2.jpg"
        },
    ];
    return (
        <>
            {/* Left Side - Blue Section (40%) */}
            <div
                className="tw-basis-[40%] tw-text-white tw-px-12 tw-py-16 tw-flex tw-flex-col tw-justify-between tw-overflow-hidden sidebar-background">
                {/* Logo */}
                <div className='tw-px-10'>
                    <img src={LogoImage} alt="Re-loan logo" className="tw-mb-0" onClick={goToHome} />
                    <div className="tw-mt-24">
                        <h1 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-6xl tw-text-[#FFFFFF] tw-my-4 tw-font-semibold tw-text-center">
                            Start your remarkable <div className='tw-mt-3'>journey with us!</div> 
                        </h1>
                        <p className="tw-font-inter tw-leading-10 tw-text-2xl tw-mt-11 tw-text-[#FFFFFFBF]">
                            Our cold email automation helps you send personalized <br />
                            cold emails at scale with high email deliverability.
                        </p>
                    </div>
                </div>

                {/* Main Text Section */}

                <div className="tw-mt-10 tw-pb-10">
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        id="swiper"
                        slidesPerView="auto"
                        spaceBetween={30}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                            renderBullet: (index, className) => {
                                return `<span class="${className} tw-indicator custom-indicator"></span>`;
                            },
                        }}
                        autoplay={{ delay: 3000 }}
                        breakpoints={{ 640: { slidesPerView: 1 }, 1024: { slidesPerView: 2 }, 1280: { slidesPerView: 3 } }}
                        style={{ paddingBottom: "20px" }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={`slide-${index}`} style={{ listStyle: "none" }}>
                                <div className="tw-bg-white tw-text-black tw-rounded-lg tw-p-4 tw-shadow-lg tw-h-full slide-content">
                                    <div className="tw-text-4xl tw-text-blue-400 tw-mb-2">
                                        &ldquo;
                                    </div>
                                    <p className="tw-text-sm tw-opacity-70">{testimonial.paragraph}</p>
                                    <div className="tw-flex tw-items-center tw-mt-4">
                                        <img
                                            src={testimonial.profileImage}
                                            alt={`${testimonial.name}'s profile`}
                                            className="tw-w-10 tw-h-10 tw-rounded-full tw-mr-2"
                                        />
                                        <div>
                                            <div className="tw-font-bold">{testimonial.name}</div>
                                            <div className="tw-text-xs tw-opacity-70">{testimonial.designation}</div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default Sidebar;