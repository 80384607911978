import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import "../../../assets/css/base.css";

const BaseLayout = () => {
  return (
    <div
      className="base-layout"
      style={{
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
      }}
    >
      <Header />
      <main
        className="
          tw-flex
          tw-items-center
          tw-relative
          tw-px-[20px]
          tw-py-[20px]
          tw-h-auto 
          md:tw-px-[20px]
          md:tw-py-[0px]
          tw-w-full
        "
      >
        <Outlet />
      </main>
    </div>
  );
};

export default BaseLayout;
