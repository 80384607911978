import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function StripeSettings({ handleFieldChange, storedData }) {
    const [showPublicKey, setShowPublicKey] = useState(false);
    const [showSecretKey, setShowSecretKey] = useState(false);

    const togglePublicKeyVisibility = () => {
        setShowPublicKey((prev) => !prev);
    };

    const toggleSecretKeyVisibility = () => {
        setShowSecretKey((prev) => !prev);
    };

    // Function to get the value of a specific setting by name
    const getSettingValue = (name) => {
        const setting = storedData.find(item => item.name === name);
        return setting ? setting.value : '';
    };

    return (
        <div className="tw-h-full tw-w-full">
            <form className="tw-space-y-5 tw-p-5">
                {/* Stripe Mode */}
                <div className="tw-flex tw-justify-between tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-[50%] tw-mb-4">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Stripe Mode
                        </label>
                        <select
                            value={getSettingValue('stripe_mode')} // Set value from stored data
                            onChange={(e) => handleFieldChange('stripe_mode', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        >
                            <option value="" disabled>Select Mode</option> 
                            <option value="test">Test</option>
                            <option value="live">Live</option>
                        </select>
                    </div>
                </div>

                <div className="tw-flex tw-gap-4">
                    {/* Stripe Public Key */}
                    <div className="tw-flex tw-flex-col tw-w-[50%] tw-relative">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Stripe Public Key
                        </label>
                        <div className="tw-relative">
                            <input
                                type={showPublicKey ? 'text' : 'password'}
                                placeholder="Enter Stripe Public Key"
                                value={getSettingValue('stripe_public_key')} // Set value from stored data
                                onChange={(e) => handleFieldChange('stripe_public_key', e.target.value)}
                                className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] tw-w-full common-font"
                            />
                            <button
                                type="button"
                                onClick={togglePublicKeyVisibility}
                                className="tw-absolute tw-right-3 tw-top-[50%] tw-transform tw--translate-y-1/2"
                            >
                                {showPublicKey ? (
                                    <VisibilityOff className="tw-text-[#00000066]" />
                                ) : (
                                    <Visibility className="tw-text-[#00000066]" />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Stripe Secret Key */}
                    <div className="tw-flex tw-flex-col tw-w-[50%] tw-relative">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Stripe Secret Key
                        </label>
                        <div className="tw-relative">
                            <input
                                type={showSecretKey ? 'text' : 'password'}
                                placeholder="Enter Stripe Secret Key"
                                value={getSettingValue('stripe_secret_key')} // Set value from stored data
                                onChange={(e) => handleFieldChange('stripe_secret_key', e.target.value)}
                                className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] tw-w-full common-font"
                            />
                            <button
                                type="button"
                                onClick={toggleSecretKeyVisibility}
                                className="tw-absolute tw-right-3 tw-top-[50%] tw-transform tw--translate-y-1/2"
                            >
                                {showSecretKey ? (
                                    <VisibilityOff className="tw-text-[#00000066]" />
                                ) : (
                                    <Visibility className="tw-text-[#00000066]" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default StripeSettings;
