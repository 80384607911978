import React from 'react';
import WebsiteTerms from './WebsiteTerms';
import PrivacyPolicy from './PrivacyPolicy';
import CookiesPolicy from './CookiesPolicy';
import ContactPage from './ContactPage';
import Disclaimer from './DisclaimerPage'

function CmsContent({ title }) {
    let content;

    if (title === "Website Terms") {
        content = (
            <WebsiteTerms />
        );
    } else if (title === "Privacy Policy") {
        content = (
            <PrivacyPolicy />
        );
    } else if (title === "Cookies Policy") {
        content = (
            <CookiesPolicy />
        );
    } else if (title === "Contact") {
        content = (
            <ContactPage />
        );
    } else if (title === "Disclaimer") {
        content = (
            <Disclaimer />
        );
    } else {
        content = (
            <div className="tw-w-[80%] tw-h-[200px]">
                <div className="tw-flex tw-justify-center tw-items-center">
                    <h1 className="tw-font-oswald tw-text-2xl md:tw-text-4xl lg:tw-text-5xl tw-text-black tw-my-4 tw-font-bold tw-text-center">
                        {title}
                    </h1>
                </div>
                <div className="tw-flex tw-justify-center tw-items-center">
                    <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-5xl tw-text-black tw-my-4 tw-font-bold tw-text-center">
                        Coming&nbsp;<span className="tw-text-[#4853e4]">Soon</span>
                    </h2>
                </div>
            </div>
        );
    }

    return (
        <div className="tw-w-full tw-h-screen">
            <div className="tw-text-start tw-font-inter tw-text-black">
                {content} 
            </div>
        </div>
    );
}

export default CmsContent;