import React, { useContext, useEffect, useState } from 'react'
import ApplicationTable from '../applicationTable/ApplicationTable';
import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';
import toast from 'react-hot-toast';
import { formatPrice } from '../../../utils/common';
import { useApiService } from '../../../services/apiService';

function BorrowerTable() {
    const { user } = useContext(UserContext);
    const { getRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [topBorrowerData, setTopBorrowerData] = useState([]);
    

    const fetchTopBorrowerDetail = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await getRequest(`/user/top-borrower`);
            if(response?.success) {
                const topBorrower = response?.data?.userList || [];
                setTopBorrowerData(topBorrower);
            } else {
                setTopBorrowerData([]);
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    useEffect(() => {
        fetchTopBorrowerDetail();
    }, [user]);

    const columns = [
        {
            field: 'borrower_name',
            headerName: 'Name of Borrower',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 200,
            editable: false,
        },
        {
            field: 'last_loan_granted',
            headerName: 'Last Loan Granted',
            width: 200,
            editable: false,
        },
        {
            field: 'total_amount_granted',
            headerName: 'Total Amount Granted ($)',
            width: 200,
            editable: false,
        },
        {
            field: 'total_loan_granted',
            headerName: 'No. Of Loans Granted ($)',
            width: 200,
            editable: false,
        },
    ];
    const rows = topBorrowerData?.length
        ? topBorrowerData?.map((app, index) => ({
            id: app?.email || index, // Use a unique field or fallback to the index
            borrower_name: app?.borrower_name,
            email: app?.email,
            last_loan_granted: app?.last_loan_granted
                ? new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).format(new Date(app?.last_loan_granted))
                : 'N/A',
            total_amount_granted: app?.total_amount_granted ? formatPrice(app?.total_amount_granted) : '',
            total_loan_granted: app?.total_loan_granted,
        }))
        : [];

    const handleRowClick = () => {
        console.log('handleRowClick');
    }
    return (
        <ApplicationTable
            dataTableProps={{
                getRowId: (row) => row?.id, // Ensure the `id` is used for unique row identification
                userStatus: [""],
                dashboardType: 'top borrower',
                title: "Top Borrower",
                getRowClassName: (params) =>
                    params?.row?.id === null ? 'selected-row' : ''
            }}
            rows={rows}
            onRowClick={handleRowClick}
            columns={columns}
            tableHeight="284px"
            maxHeight='60vh'
            minHeight='284px'
        />
    )
}

export default BorrowerTable