// chatUtils.js
import axios from 'axios';
import toast from 'react-hot-toast';

export const conversationListHandler = async (application_id, user, awsContextVal, setConversationList) => {
    try {
        const response = await axios.post(`${awsContextVal?.endpoint}/conversation/list`, {
            "application_id": application_id,
            'user_type': user?.user_type,
            'user_id': user?.id,
        });
        
        if (response?.data?.conversationList) {
            setConversationList(response?.data?.conversationList);
        } else {
            throw new Error("Invalid response format: conversationList is missing.");
        }
    } catch (error) {
        console.error("Error fetching conversation list:", error);
        toast.error(error.message || "Something went wrong. Please try again.");
        setConversationList([]);
    }
};
