import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function UpgradePlanModal({ isOpen, closeModal, hasExpiredMembership, remainingOffers }) {
  if (!isOpen) return null; // If the modal is not open, don't render anything

  // Determine the message to display based on the conditions
  let message;
  if (hasExpiredMembership) {
    message =
      "Your membership has expired. To regain access to features, please renew your subscription.";
  } else {
    message = `You have no remaining offers. To continue sending offers and accessing premium features, consider upgrading your plan.`;
  }

  return (
    <div className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black tw-bg-opacity-70 tw-flex tw-justify-center tw-items-center" style={{zIndex: 101}}>
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-[800px] tw-shadow-lg">
        {/* Header Section */}
        <div className="tw-flex tw-justify-between tw-items-center">
          <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-black common-font">
            Upgrade Your Plan
          </h2>
          <Button
            onClick={closeModal}
            className="tw-bg-transparent tw-border-0 tw-text-gray-500 hover:tw-text-red-600 hover:tw-bg-gray-100 tw-p-2 tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out"
            aria-label="Close Modal"
          >
            X
          </Button>
        </div>

        {/* Message Section */}
        <div className="tw-mb-6">
          <p className="tw-text-lg tw-text-gray-700 common-font">{message}</p>
        </div>

        {/* Upgrade Button Section */}
        <div>
          <Link to="/dashboard/change-subscription">
            <button className="tw-px-4 tw-py-2 tw-bg-[#2A2F70] tw-text-white tw-rounded common-font">
              Upgrade Plan
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UpgradePlanModal;
