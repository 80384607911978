import React, { useState } from "react";
import { getCurrentUser, updatePassword } from "@aws-amplify/auth";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "react-hot-toast";
import EditIcon from "../../assets/icons/editIcon.svg";

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((prev) => !prev);
  const handleClickShowNewPassword = () =>
    setShowNewPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirmation password do not match.");
      return;
    }

    if (currentPassword === newPassword) {
      toast.error("New password cannot be the same as the old password.");
      return;
    }

    try {
      const user = await getCurrentUser();
      await updatePassword({ oldPassword: currentPassword, newPassword });
      toast.success("Password changed successfully!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error(error.message || "Failed to change password.");
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-border-[1px] tw-border-[#0A263F]/14 tw-rounded-lg tw-p-5 tw-my-10 tw-space-y-8">
      <div className="tw-flex tw-justify-between tw-border-b-[1px] tw-pb-4">
        <h2 className="tw-text-xl tw-text-[#202224] tw-font-semibold tw-font-nunito">
          Change Password
        </h2>
        <button
          type="button"
          className="tw-flex tw-space-x-2 tw-items-center tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold hover:-tw-translate-y-1"
          onClick={handleChangePassword}
        >
          <img src={EditIcon} alt="change icon" />
          <span>Change Password</span>
        </button>
      </div>
      <form className="tw-flex tw-space-x-4">
        <div className="tw-flex tw-flex-col tw-w-1/3">
          <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">
            Current Password
          </p>
          <FormControl sx={{ mr: 2, width: "100%" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-currentpassword"
              type={showCurrentPassword ? "text" : "password"}
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              autoComplete="false"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label={
                      showCurrentPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowCurrentPassword}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <div className="tw-flex tw-flex-col tw-w-1/3">
          <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">
            New Password
          </p>
          <FormControl sx={{ mr: 2, width: "100%" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-newpassword"
              type={showNewPassword ? "text" : "password"}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="false"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label={
                      showNewPassword ? "hide the password" : "display the password"
                    }
                    onClick={handleClickShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <div className="tw-flex tw-flex-col tw-w-1/3">
          <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">
            Confirm Password
          </p>
          <FormControl sx={{ mr: 2, width: "100%" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-confirmpassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="false"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label={
                      showConfirmPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
