 import React, { useEffect, useState, useContext, memo } from 'react'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast';
import { Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import StatsDropdown from '../../common/StatsDropdown'
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import UserManagementHeader from './UserManagementHeader'

import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';
import { useModalContext } from "../../../contexts/ModalContexts";
import { userStatus } from '../../../utils/constants/user_status';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ChangePasswordModal from './ChangePasswordModal';
import { useApiService } from '../../../services/apiService';
import Total_borrower from "../../../assets/icons/total_borrower.png";
import Borrower_retention_rate from "../../../assets/icons/borrower_retention_rate.png";
import Total_lender from "../../../assets/icons/total_lender.png";
import Loan_approval_rate from "../../../assets/icons/loan_acceptance_rate.png";
import Avg_loan_value from "../../../assets/icons/avg_loan_value.png";
import StatCards from '../../layouts/homepage/StatCards';

// const client = new CognitoIdentityProviderClient({
//     region: process.env.REACT_APP_AWS_REGION, // e.g., 'us-west-2'
//     credentials: {
//       accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
//       secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
//     },
// });

const ViewMoreModal = memo(({ fetchFunction, ...params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { postRequest, deleteRequest } = useApiService();
    const { showModal } = useModalContext();
    const { showLoader, hideLoader } = useLoader();
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [rowData, setRowData] = useState({});
    const handleCloseModal = () => setOpenChangePasswordModal(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };

    const handleDeleteConfirmation = () => {
        deleteUser(params?.row?.id);
        setOpenConfirm(false); // Close the confirmation dialog
    };
    const deleteUser = async (id, email) => {
        showLoader();
        try {
            const response = await deleteRequest(`/user/delete/${id}`);
            if (response?.success) {
                showModal("User Deleted Successfully!", "success");
            } else {
                showModal("Something Went Wrong.", "fail");
            }
            setAnchorEl(null);
            hideLoader();
            fetchFunction();
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to delete user. Please try again.', "fail");
        } finally {
            hideLoader(false);
        }
    }

    const userStatusFilter = async (id, status) => {
        showLoader();
        handleClose();
        try {
            const response = await postRequest(`/user/update-status`, {
                id: id,
                status: status
            });

            if (response?.success) {
                showModal("User Status filterd Successfully!", "success");
            } else {
                showModal("Something Went Wrong.", "fail");
            }
            hideLoader();
            fetchFunction();
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to status filter. Please try again.', "fail");
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changePassword = (rowData) => {
        setOpenChangePasswordModal(true);
        setRowData(rowData);
        setAnchorEl(null);
    };

    return <>

        <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1"
            onClick={handleClick}
        >
            <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        </button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={handleClose}>View History</MenuItem>
            <MenuItem onClick={() => userStatusFilter(params?.id, '2')}>Disable User</MenuItem>
            <MenuItem onClick={() => userStatusFilter(params?.id, '3')}>Blocked User</MenuItem>
            <MenuItem onClick={handleConfirmOpen}>Delete User</MenuItem>
            <MenuItem onClick={() => changePassword(params?.row)}>Change Password</MenuItem>
            <MenuItem onClick={() => userStatusFilter(params?.id, '1')}>Activate User</MenuItem>
            <MenuItem onClick={() => userStatusFilter(params?.id, '0')}>In-Activate User</MenuItem>
        </Menu>
        <Dialog
            open={openConfirm}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this application?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>


        <ChangePasswordModal 
            open={openChangePasswordModal} 
            handleClose={handleCloseModal} 
            rowData={rowData}
        />
    </>
});

function UserManagement() {
    const { user } = useContext(UserContext);
    const { getRequest, postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [lenderUsers, setLenderUsers] = useState([]);
    const [borrowerUsers, setBorrowerUsers] = useState([]);
    const [borrowerStatus, setBorrowerStatus] = useState('');
    const [lenderStatus, setLenderStatus] = useState('');
    const [userManagementSummary, setUserManagementSummary] = useState([]);
    const [lenderFilter, setLenderFilter] = useState({
        user_type: "lender",
        status: "",
        sort_by: 'u.id',
        sort_order: 'DESC'
    });
    const [borrowerFilter, setBorrowerFilter] = useState({
        user_type: "borrower",
        status: "",
        sort_by: 'u.id',
        sort_order: 'DESC'
    });

    const [stats, setStats] = useState([]);
    //This is to get Lender Users
    const fetchLenderUsers = async () => {
        if (!user) return;

        try {
            showLoader();
            const response = await postRequest(`/user/list`, lenderFilter);
            if (response?.data?.userList?.length > 0) {
                
                const lenderUserList = response.data.userList;
                const lenderFilterUsers = lenderUserList.map((application) => ({
                    
                    "id": application?.user_id,
                    "name": application?.first_name + ' ' + application?.last_name,
                    "email": application?.email,
                    "status": userStatus.find(status => application?.status == status.id)?.status || '',
                    "phone_number":application?.phone_number
                    ? formatPhoneNumberIntl(application.phone_number)  // Format the phone number
                    : 'N/A',
                    "member_since": application?.user_created_at || 'N/A',
                    "no_of_loans": application?.total_loan || 0,
                    "loan_granted": application?.total_loan_granted != null && application?.total_loan_granted !== ''
                        ? `$${parseFloat(application.total_loan_granted.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '$0',
                    "amount_requested": application?.total_amount_requested != null && application?.total_amount_requested !== ''
                        ? `$${parseFloat(application.total_amount_requested.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '$0',
                    "amount_granted": application?.loan_granted_percentage != null && application?.loan_granted_percentage !== ''
                        ? `${parseFloat(application.loan_granted_percentage.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '0',
                    "city": application?.city_id ? application.city_id.toString() : 'N/A', // Added handling for possible null value
                    "state": application?.state_id ? application.state_id.toString() : 'N/A', // Added handling for possible null value
                    "country": application?.country_id ? application.country_id.toString() : 'N/A', // Added handling for possible null value
                    "address": (application?.address_1 || "") + ' ' + (application?.address_2 || ""),
                }));

                setLenderUsers(lenderFilterUsers);
            } else {
                setLenderUsers([]); // Handle the case where no lenders are found
            }
            hideLoader();
        } catch (err) {
            hideLoader(); // Ensure loader is hidden even on error
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching lender users.");
            }
        }
    };

    //This is to get Borrower Users
    const fetchBorrowerUsers = async () => {
        if (!user) return;
        try {
            showLoader();
            const response = await postRequest(`/user/list`, borrowerFilter);

            if (response?.data?.userList?.length > 0) {

                const borrowerUserList = response?.data?.userList;
                const borrowerFilterUsers = borrowerUserList.map((application) => ({
                    "id": application?.user_id,
                    "name": application?.first_name + ' ' + application?.last_name,
                    "email": application?.email,
                    "status": userStatus.find(status => application?.status == status.id)?.status || '',
                    "phone_number":application?.phone_number
                    ? formatPhoneNumberIntl(application.phone_number)  // Format the phone number
                    : 'N/A',
                    "member_since": application?.user_created_at || 'N/A',
                    "no_of_loans": application?.total_loan || 0,
                    "loan_granted": application?.total_loan_granted != null && application?.total_loan_granted !== ''
                        ? `$${parseFloat(application.total_loan_granted.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '$0',
                    "amount_requested": application?.total_amount_requested != null && application?.total_amount_requested !== ''
                        ? `$${parseFloat(application.total_amount_requested.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '$0',
                    "amount_granted": application?.loan_granted_percentage != null && application?.loan_granted_percentage !== ''
                        ? `${parseFloat(application.loan_granted_percentage.toString().replace(/[,$]/g, '')).toLocaleString()}`
                        : '0',
                    "city": application?.city_id,
                    "state": application?.state_id,
                    "country": application?.country_id,
                    "address": (application?.address_1 != null ? application?.address_1 : "") + ' ' + (application?.address_2 != null ? application?.address_2 : "")
                }))

                setBorrowerUsers(borrowerFilterUsers);
            } else {
                setBorrowerUsers([]);
            }
            hideLoader();

        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };

    // const stats = [
    //     { title: 'Total Borrowers', value: '40,689', change: '8.5%' },
    //     { title: 'Borrower Retention Rate', value: '40,689', change: '8.5%' },
    //     { title: 'Total Lenders', value: '40,689', change: '8.5%' },
    //     { title: 'Lender Retention Rate', value: '40,689', change: '8.5%' },
    //     { title: 'Loan Acceptance Rate', value: '40,689', change: '8.5%' },
    //     { title: 'Avg. Loan Value', value: '120%', change: '8.5%' },
    // ];

    const borrowerColumns = [
        // { field: 'id', headerName: 'ID', width: 100, editable: false },
        { field: 'name', headerName: 'Name of Borrower', width: 150, editable: false },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params?.value;
                let badgeColor;
                let badgeChild;
                let statusName = 'Inactive';
                switch (status) {
                    case 'Inactive':
                        statusName = "Inactive";
                        badgeColor = '#00C9A72E';
                        badgeChild = '#00C9A7';
                        break;
                    case 'Active':
                        statusName = "Active";
                        badgeColor = '#377DFF2E';
                        badgeChild = '#377DFF';
                        break;
                    case 'Disabled':
                        statusName = "Disabled";
                        badgeColor = '#ED4C782E';
                        badgeChild = '#ED4C78';
                        break;
                    case 'Blocked':
                        statusName = "Blocked";
                        badgeColor = '#ED4C782E';
                        badgeChild = '#ED4C78';
                        break;
                    default:
                        badgeColor = 'lightgray';
                }

                return (
                    <div
                        className='tw-flex tw-justify-center tw-items-center tw-w-[124px] tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full tw-mt-[12px]'
                        style={{ backgroundColor: badgeColor }}
                    >
                        <span className='tw-ms-[10px] tw-w-[8px] tw-h-[8px] tw-border tw-border-[#DDDDDD] tw-rounded-full' style={{ backgroundColor: badgeChild }}></span>

                        <span className='tw-flex tw-justify-center tw-items-center tw-w-[98px] tw-h-[22px] tw-text-[#070101CC] common-font tw-font-normal tw-text-[16px]'>
                            {status}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            width: 150,
            editable: false,
        },
        {
            field: 'member_since',
            headerName: 'Member Since',
            width: 150,
            editable: false,
            valueFormatter: (params) => {
                const date = new Date(params);
                return date.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
            },
        },
        {
            field: 'no_of_loans',
            headerName: 'No. of loans',
            width: 100,
            editable: false,
        },
        {
            field: 'loan_granted',
            headerName: 'Loan Granted',
            type: 'number',
            width: 150,
            editable: false,
        },
        {
            field: 'amount_requested',
            headerName: 'Amount Requested',
            width: 150,
            editable: false,
        },
        {
            field: 'amount_granted',
            headerName: 'Amount Granted',
            type: 'number',
            width: 150,
            editable: false,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 100,
            editable: false,
            renderCell: (params) => params?.city?.toString(),
        },
        {
            field: 'state',
            headerName: 'State',
            width: 100,
            editable: false,
            renderCell: (params) => params?.state?.toString(),
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 100,
            editable: false,
            renderCell: (params) => params?.country?.toString(),
        },
        {
            field: 'address',
            headerName: 'Address',
            type: 'string',
            width: 150,
            editable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ViewMoreModal {...params} fetchFunction={fetchBorrowerUsers} />
                </>
            ),
        },
    ];

    const lenderColumns = [
        // { field: 'id', headerName: 'ID', width: 100, editable: false },
        { field: 'name', headerName: 'Name of Lenders', width: 150, editable: false },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params?.value;
                let badgeColor;
                let badgeChild;
                let statusName = 'Inactive';
                switch (status) {
                    case 'Inactive':
                        statusName = "Inactive";
                        badgeColor = '#00C9A72E';
                        badgeChild = '#00C9A7';
                        break;
                    case 'Active':
                        statusName = "Active";
                        badgeColor = '#377DFF2E';
                        badgeChild = '#377DFF';
                        break;
                    case 'Disabled':
                        statusName = "Disabled";
                        badgeColor = '#ED4C782E';
                        badgeChild = '#ED4C78';
                        break;
                    case 'Blocked':
                        statusName = "Blocked";
                        badgeColor = '#ED4C782E';
                        badgeChild = '#ED4C78';
                        break;
                    default:
                        badgeColor = 'lightgray';
                }

                return (
                    <div
                        className='tw-flex tw-justify-center tw-items-center tw-w-[124px] tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full'
                        style={{ backgroundColor: badgeColor }}
                    >
                        <span className='tw-ms-[10px] tw-w-[8px] tw-h-[8px] tw-border tw-border-[#DDDDDD] tw-rounded-full' style={{ backgroundColor: badgeChild }}></span>

                        <span className='tw-flex tw-justify-center tw-items-center tw-w-[98px] tw-h-[22px] tw-text-[#070101CC] common-font tw-font-normal tw-text-[16px]'>
                            {status}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            width: 150,
            editable: false,
        },
        {
            field: 'member_since',
            headerName: 'Member Since',
            width: 120,
            editable: false,
            valueFormatter: (params) => {
                const date = new Date(params);
                return date.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
            },
        },
        {
            field: 'no_of_loans',
            headerName: 'Loan Funded',
            width: 100,
            editable: false,
        },
        {
            field: 'loan_granted',
            headerName: 'Total Amount Lent',
            type: 'number',
            width: 150,
            editable: false,
        },
        {
            field: 'amount_requested',
            headerName: 'Avg. Loan Amount',
            width: 150,
            editable: false,
        },
        {
            field: 'amount_granted',
            headerName: 'Lent Rent',
            type: 'number',
            width: 150,
            editable: false,
        },
        {
            field: 'city',
            headerName: 'City',
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'State',
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'country',
            headerName: 'Country',
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'address',
            headerName: 'Address',
            type: 'string',
            width: 150,
            editable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ViewMoreModal {...params} fetchFunction={fetchLenderUsers} />
                </>
            ),
        },
    ];

    const handleRowClick = (params) => {
        // console.log("params:", params)
    };

    const BorrowerUserManagementHeaderProps = {
        title: "Top Borrowers", 
        userStatus:userStatus, 
        handleItemClick: setBorrowerStatus, 
        setFilter: setBorrowerFilter,
        filter: borrowerFilter
    };

    const LenderUserManagementHeaderProps = {
        title: "Top Lenders",
        userStatus:userStatus, 
        handleItemClick: setLenderStatus,
        setFilter: setLenderFilter,
        filter: lenderFilter
    };

    const fetchUserSummary = async () => {
        if (!user) return;
    
        try {
          const response = await getRequest('/admin-user-summary');
          console.log("User testing response :> ", response);
          if (response?.success) {
            setUserManagementSummary(response?.data);
          } else {
            setUserManagementSummary([]);
          }
        } catch (err) {
          if (err.response) {
            toast.error(err.response.data?.message);
          } else {
            toast.error("An error occurred while fetching User Summary.");
          }
        }
    };

    useEffect(() => {
        const stats = [
          {
            title: 'Total Borrowers',
            value:  userManagementSummary?.total_borrowers ? userManagementSummary?.total_borrowers : 0,
            change: userManagementSummary?.total_borrower_growth_percentage ? userManagementSummary?.total_borrower_growth_percentage : 0,
            icon: Total_borrower
          },
          {
            title: 'Borrower Retention Rate',
            value: userManagementSummary?.borrower_retention_rate != null && !isNaN(userManagementSummary?.borrower_retention_rate)
            ? parseFloat(userManagementSummary?.borrower_retention_rate).toFixed(2)
            : "0.00",
            change: null,
            icon: Borrower_retention_rate
          },
          {
            title: 'Total Lenders',
            value: userManagementSummary?.total_lenders ? userManagementSummary?.total_lenders : 0,
            change: userManagementSummary?.total_lender_growth_percentage ? userManagementSummary?.total_lender_growth_percentage : 0,
            icon: Total_lender
          },
          {
            title: 'Lender Retention Rate',
            value: userManagementSummary?.lender_retention_rate != null && !isNaN(userManagementSummary?.lender_retention_rate)
            ? parseFloat(userManagementSummary?.lender_retention_rate).toFixed(2)
            : "0.00",
            change:  null,
            icon: Borrower_retention_rate
          },
          {
            title: 'Loan Acceptance Rate',
            value: userManagementSummary?.loan_acceptance_rate ? `${parseFloat(userManagementSummary?.loan_acceptance_rate).toFixed(2)}%` : 0,
            change: 0,
            icon: Loan_approval_rate
          },
          {
            title: 'Avg. Loan Value',
            value: userManagementSummary?.avg_loan_value ? `${userManagementSummary?.avg_loan_value?.toFixed(2)}` : 0,
            change: userManagementSummary?.total_avg_loan_value_growth_percentage ? userManagementSummary?.total_avg_loan_value_growth_percentage : 0,
            icon: Avg_loan_value
          },
        ];
        setStats(stats)
    }, [userManagementSummary]);
    
    useEffect(() => {
        fetchLenderUsers();
        fetchBorrowerUsers();
        fetchUserSummary();
    }, [user]);

    useEffect(() => {
        fetchBorrowerUsers();
    }, [borrowerFilter]);

    useEffect(() => {
        fetchLenderUsers();
    }, [lenderFilter]);


    return (
        <div className="tw-w-full tw-h-full">
            <div className='tw-w-[377px] tw-h-[60px] tw-flex tw-justify-between tw-items-center'>
                <Link to="/dashboard/admin">
                    <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                </Link>
                <h1 className=' tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                    User Management
                </h1>
            </div>
            <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your notifications.</p>

            <div className="tw-block">
                <StatsDropdown />

                <div className="tw-block tw-w-full tw-bg-white tw-rounded-lg">
                <StatCards statList={stats} />
                </div>
            </div>

            <div className="tw-flex tw-space-x-4 tw-mt-4 hidden tw-w-[100%]">
                <ApplicationTable 
                    dataTableProps={{ 
                        getRowId: (row) => row?.id, 
                        dashboardType:'user_management', 
                        handleItemClick: setLenderStatus,
                        userStatus:userStatus, 
                        title: "Top Lenders",
                        showFilters: true,
                        getRowClassName: (params) =>
                                params?.row?.application_id === null ? 'selected-row' : ''
                    }}
                    rows={lenderUsers}
                    columns={lenderColumns}
                    onRowClick={handleRowClick}
                    customHeaderProps={LenderUserManagementHeaderProps}
                    CustomHeaderComponent={UserManagementHeader} 
                    tableHeight="400px"
                    maxHeight= '140vh'
                    minHeight= '400px'
                />
            </div>

            <div className="tw-flex tw-space-x-4 tw-mt-4 hidden tw-w-[100%] tw-mb-[15px]">
                <ApplicationTable
                    dataTableProps={{ 
                        getRowId: (row) => row?.id, 
                        dashboardType:'user_management', 
                        handleItemClick: setBorrowerStatus, 
                        userStatus:userStatus, 
                        title: "Top Borrowers", 
                        showFilters: true,
                         getRowClassName: (params) =>
                                params?.row?.application_id === null ? 'selected-row' : ''
                    }}
                    rows={borrowerUsers}
                    columns={borrowerColumns} 
                    onRowClick={handleRowClick}
                    customHeaderProps={BorrowerUserManagementHeaderProps} 
                    CustomHeaderComponent={UserManagementHeader} 
                    tableHeight="400px"
                    maxHeight= '140vh'
                    minHeight= '400px'
                />
            </div>
        </div>
    )
}

export default UserManagement