import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Modal, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import toast from 'react-hot-toast';
import { UserContext } from "../../../../contexts/UserContext";
import { useLoader } from '../../../../contexts/LoaderContext';
import OfferLimitReached from './OfferLimitReached';
import { useFetchUserSubscription } from '../../../../hook/useFetchUserSubscription';
import { useApiService } from '../../../../services/apiService';

function SendOffers({ open, handleClose, applicationId, selectedRowBorrowerId, fetchAllUserLoanApplications, setSelectedRowId, setSendOfferSelectedAppId, setChatWindowRender, revisedOfferDetail }) {
    const revisedData = revisedOfferDetail?.data?.application_offer;
    const { user } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();
    const [percentInterest, setPercentInterest] = useState('');
    const [interestUnit, setInterestUnit] = useState('year');
    const [originationFee, setOriginationFee] = useState('');
    const [duration, setDuration] = useState('');
    const [durationFrequency, setDurationFrequency] = useState('monthly');
    const [errors, setErrors] = useState({});
    const [showPopup, setShowPopup] = useState(false); 
    const { postRequest } = useApiService();
    const inputRef = useRef(null);
    const { fetchUserSubscription } = useFetchUserSubscription();

    useEffect(() => {
        if (revisedData) {
            setPercentInterest(revisedData.interest_on_loan || '');
            setInterestUnit(revisedData.interest_type || 'year');
            setOriginationFee(revisedData.fees ? Number(revisedData.fees).toLocaleString() : '');
            setDuration(revisedData.loan_duration || '');
            setDurationFrequency(revisedData.loan_duration_type || 'monthly');
        } else {
            resetForm();
        }
    }, [revisedData]);

    useEffect(() => {
        if (open && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 100)
        }
    }, [open, inputRef.current]);

    const handleChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (value) {
            value = Number(value).toLocaleString(); // Add commas to the number
        }
        setOriginationFee(value);
    };

    const resetForm = () => {
        setPercentInterest('');
        setInterestUnit('year');
        setOriginationFee('');
        setDuration('');
        setDurationFrequency('monthly');
        setErrors({});
    };

    const [isSubmitting, setIsSubmitting] = useState(false); // Prevent duplicate submissions

    const handleSendOffer = async () => {
        if (isSubmitting) return; // Prevent duplicate API calls
        setIsSubmitting(true); // Lock button
    
        showLoader();  // Show loader at the start of the operation
        const newErrors = {};
    
        // Validate form fields
        if (!applicationId) {
            toast.error("Please select a row for the application ID.");
            hideLoader();
            setIsSubmitting(false);
            return;
        }
    
        if (!percentInterest) newErrors.percentInterest = "Percent interest is required.";
        if (!originationFee) newErrors.originationFee = "Origination fee is required.";
        if (!duration) newErrors.duration = "Duration is required.";
    
        setErrors(newErrors);
    
        // Check if there are validation errors
        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill in all required fields.");
            hideLoader();
            setIsSubmitting(false);
            return;
        }
    
        try {
            const requestData = {
                application_id: applicationId,
                lender_id: user?.id,
                borrower_id: selectedRowBorrowerId,
                interest_on_loan: percentInterest,
                interest_type: interestUnit,
                fees: originationFee.replace(/[^0-9.]/g, ''),
                loan_duration: duration,
                loan_duration_type: durationFrequency,
                status_id: "2",
            };
    
            const response = await postRequest(`/application-offer/add`, requestData);
    
            if (response?.success) {
                toast.success(response?.data?.message || "Offer sent successfully");
                fetchAllUserLoanApplications();
                setSelectedRowId(applicationId);
                setSendOfferSelectedAppId(applicationId);
                setChatWindowRender(true);
                fetchUserSubscription();
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            if (error.response) {
                toast.error(`Server Error: ${error.response?.data?.message || "An error occurred on the server."}`);
            } else if (error.request) {
                toast.error("Network Error: No response received from the server.");
            } else {
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            hideLoader();
            setIsSubmitting(false); // Unlock form submission
            resetForm();
            handleClose();
        }
    };
    
    return (
        <>
            <Modal open={open} disablePortal onClose={() => { resetForm(); handleClose(); }} aria-labelledby="modal-title">
                <Box className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                    width: {
                        xs: '90%', // 90% width on small screens
                        md: '40%', // 500px width on medium and large screens
                        lg: '30%',
                    },
                }}>
                    <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                        <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">Send Offer</Typography>
                        <Button onClick={handleClose} className="tw-text-gray-500">X</Button>
                    </div>

                    {/* Percent Interest Field */}
                    <div className="tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Percent Interest on Loan</InputLabel>
                        <div className="tw-flex tw-gap-2">
                            <div className="tw-relative tw-h-[40px] tw-w-1/2">
                                <input
                                    type="number"
                                    ref={inputRef}
                                    value={percentInterest}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Allow only non-negative numbers
                                        if (!isNaN(value) && value >= 0) {
                                            setPercentInterest(value);
                                        }
                                    }}
                                    placeholder="e.g., 5"
                                    id='interest_on_loan'
                                    className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-pr-14 tw-ps-3 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.percentInterest ? 'tw-border-red-500' : ''}`} />
                                <div className="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-r-[5px] tw-inset-y-0 tw-end-0 tw-flex tw-items-center tw-px-4 tw-pointer-events-none">
                                    %
                                </div>
                            </div>
                            <FormControl className="tw-w-1/2">
                                <Select
                                    value={interestUnit}
                                    onChange={(e) => setInterestUnit(e.target.value)}
                                    className="tw-text-sm tw-h-[40px] interest_loan_dropdown"
                                >
                                    <MenuItem value="day">Per Day</MenuItem>
                                    <MenuItem value="week">Per Week</MenuItem>
                                    <MenuItem value="month">Per Month</MenuItem>
                                    <MenuItem value="year">Per Year</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {errors.percentInterest && <p className="tw-text-red-500 tw-text-sm">{errors.percentInterest}</p>}
                    </div>

                    {/* Origination Fee Field */}
                    <div className="tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Origination Fee</InputLabel>
                        <div className="tw-relative tw-h-[40px]">
                            <div className="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[5px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-4 tw-pointer-events-none">
                                $
                            </div>
                            <input
                                type="text"
                                value={originationFee}
                                onChange={handleChange}
                                placeholder="e.g., 1000"
                                id='origination_fee'
                                className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-12 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.originationFee ? 'tw-border-red-500' : ''}`} />
                        </div>
                        {errors.originationFee && <p className="tw-text-red-500 tw-text-sm">{errors.originationFee}</p>}
                    </div>

                    {/* Duration Field */}
                    <div className="tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Loan Duration</InputLabel>
                        <div className="tw-flex tw-gap-2">
                            <div className='tw-h-[45px'>
                                <input
                                    type="number"
                                    value={duration}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Allow only non-negative numbers
                                        if (!isNaN(value) && value >= 0) {
                                            setDuration(value);
                                        }
                                    }}
                                    placeholder="e.g., 12"
                                    id='loan_duration'
                                    className={`tw-ps-4 tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.duration ? 'tw-border-red-500' : ''}`} />
                            </div>
                            <FormControl className="tw-w-1/2">
                                <Select
                                    value={durationFrequency}
                                    onChange={(e) => setDurationFrequency(e.target.value)}
                                    className="tw-text-sm tw-h-[40px] duration_frequency_dropdown"
                                >
                                    <MenuItem value="weekly">Weeks</MenuItem>
                                    <MenuItem value="monthly">Months</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {errors.duration && <p className="tw-text-red-500 tw-text-sm">{errors.duration}</p>}
                    </div>

                    {/* Action Buttons */}
                    <div className="tw-flex tw-justify-end tw-mt-6">
                        <button onClick={handleSendOffer} className="tw-bg-[#7DDE92] hover:tw-bg-[#6fc281] hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-mt-4 tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[5px] tw-px-[18px] tw-gap-[4px] tw-text-[18px] tw-font-inter tw-shadow-md" id='send_offer_btn'>
                        <i className="fas fa-paper-plane tw-mr-2"></i>
                            Send Offer
                        </button>
                    </div>
                </Box>
            </Modal>
            {showPopup &&
                <>
                    <OfferLimitReached showPopup={showPopup} setShowPopup={setShowPopup} />
                </>
            }
        </>
    );
}

export default SendOffers;
