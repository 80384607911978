import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';

function SmtpSettings({ handleFieldChange, storedData }) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const getSettingValue = (name) => {
        const setting = storedData.find((item) => item.name === name);
        return setting ? setting.value : '';
    };

    return (
        <div className="tw-h-full tw-w-full">
            <form className="tw-space-y-5 tw-p-5">
                {/* Email Encryption */}
                <div className="tw-flex tw-justify-between tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-[50%]">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Email Encryption
                        </label>
                        <select
                            value={getSettingValue('email_encryption')}
                            onChange={(e) => handleFieldChange('email_encryption', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        >
                            <option value="" disabled>Select Encryption</option>
                            <option value="none">None</option>
                            <option value="ssl">SSL</option>
                            <option value="tls">TLS</option>
                        </select>
                    </div>
                </div>

                {/* SMTP Host and Port */}
                <div className="tw-flex tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-[50%]">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            SMTP Host
                        </label>
                        <input
                            type="text"
                            placeholder="Enter SMTP Host"
                            value={getSettingValue('smtp_host')}
                            onChange={(e) => handleFieldChange('smtp_host', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-[50%]">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            SMTP Port
                        </label>
                        <input
                            type="text"
                            placeholder="Enter SMTP Port"
                            value={getSettingValue('smtp_port')}
                            onChange={(e) => handleFieldChange('smtp_port', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                </div>

                {/* SMTP Email */}
                <div className="tw-flex tw-justify-between tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-full">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            SMTP Email
                        </label>
                        <input
                            type="email"
                            placeholder="Enter SMTP Email"
                            value={getSettingValue('smtp_email')}
                            onChange={(e) => handleFieldChange('smtp_email', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                </div>

                {/* SMTP Username and Password */}
                <div className="tw-flex tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-[50%]">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            SMTP Username
                        </label>
                        <input
                            type="text"
                            placeholder="Enter SMTP Username"
                            value={getSettingValue('smtp_username')}
                            onChange={(e) => handleFieldChange('smtp_username', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-[50%]">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            SMTP Password
                        </label>
                        <div className="tw-relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter SMTP Password"
                                value={getSettingValue('smtp_password')}
                                onChange={(e) => handleFieldChange('smtp_password', e.target.value)}
                                className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] tw-w-full common-font"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="tw-absolute tw-right-3 tw-top-[50%] tw-transform tw--translate-y-1/2"
                            >
                                {showPassword ? (
                                    <VisibilityOff className="tw-text-[#00000066]" />
                                ) : (
                                    <Visibility className="tw-text-[#00000066]" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Email Charset */}
                <div className="tw-flex tw-justify-between tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-full">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Email Charset
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Email Charset"
                            value={getSettingValue('email_charset')}
                            onChange={(e) => handleFieldChange('email_charset', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SmtpSettings;
