import { useState, useEffect } from "react";
import {
    GetState,
    GetCity,
} from "react-country-state-city";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const AddressForm = ({ setUserInfo, onChange, userInfo, disableForm }) => {
    const handleChange = (e) => {
        const { id, value } = e.target;
        setUserInfo(prev => ({ ...prev, [id]: value }));
        onChange();
    };

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const getCities = (stateId) => {
        GetCity(233, stateId).then((result) => {
            result = result.filter((r) => !r.name.endsWith(" County"))
            setCityList(result);
        });
    }

    const handleStateChange = (e) => {
        const selectedStateIndex = e.target.value; // Get selected state index
        const state = stateList[selectedStateIndex]; // Get full state object
        const stateId = state?.id; // Get state ID

        // Update state and stateId in formState
        setUserInfo(prev => ({ ...prev, state: state?.name, stateId })); 

        // Fetch cities for the selected state
        getCities(stateId);
    };


    const excludedStates = ['AS', 'GU', 'MP', 'UM'];
    useEffect(() => {
        // Fetch states when the component loads
        GetState(233) // Assuming 233 is the country code for the US
            .then((result) => {
                result = result.filter(
                  (i) =>
                    !(
                      excludedStates.includes(i.state_code) ||
                      i.state_code.startsWith("UM-")
                    )
                );
                setStateList(result);
            })
            .catch(err => console.log(err));
    
        // Fetch cities if stateId exists in userInfo
        if (userInfo?.stateId) {
            getCities(userInfo.stateId);
        }
    }, [userInfo.stateId]);
    
    // Add this to ensure the city dropdown is updated if cityId changes
    useEffect(() => {
        if (userInfo?.cityId && cityList.length > 0) {
            const selectedCityIndex = cityList.findIndex(
                (city) => city.id === userInfo.cityId
            );
            if (selectedCityIndex === -1) {
                console.warn("City not found in current city list.");
            }
        }
    }, [userInfo.cityId, cityList]);

    const handleCityChange = (e) => {
        const selectedCityIndex = e.target.value;
        const city = cityList[selectedCityIndex]; 
        const cityId = city?.id;

        // Update city and cityId in formState
        setUserInfo(prev => ({ ...prev, city: city?.name, cityId }));
    };

    return (
        <div className="tw-mt-8">
            <div className="tw-flex tw-space-x-4">
                <div className="tw-flex tw-flex-col">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">State</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <select
                            className="tw-text-black tw-font-inter tw-py-4 tw-border-[0.5px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-[20px]"
                            onChange={handleStateChange}
                            value={stateList.findIndex(item => item?.id === userInfo?.stateId)}
                            disabled={disableForm}

                        >
                            <option value="">Select State</option>
                            {stateList.map((item, index) => (
                                <option key={index} value={index}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </FormControl>
                </div>

                <div className="tw-flex tw-flex-col">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">City</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <select
                            className="tw-text-black tw-font-inter tw-py-4 tw-border-[0.5px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-[20px]"
                            onChange={handleCityChange}
                            value={cityList.findIndex(item => item?.id === userInfo?.cityId)} 
                            disabled={disableForm}

                        >
                            <option value="">Select City</option>
                            {cityList.map((item, index) => (
                                <option key={index} value={index}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </FormControl>
                </div>

                <div className="tw-flex tw-flex-col">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Zip</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="zip"
                            type='text'
                            placeholder="Zip Code"
                            value={userInfo?.zip}
                            onChange={handleChange}
                            disabled={disableForm}

                        />
                    </FormControl>
                </div>
            </div>

            <div className="tw-flex tw-space-x-4 tw-mt-8">
                <div className="tw-flex tw-flex-col tw-w-1/2">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Address Line 1</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="addressLine1"
                            type='text'
                            placeholder="Address Line 1"
                            value={userInfo?.addressLine1}
                            onChange={handleChange}
                            disabled={disableForm}

                        />
                    </FormControl>
                </div>

                <div className="tw-flex tw-flex-col tw-w-1/2">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Address Line 2</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="addressLine2"
                            type='text'
                            placeholder="Address Line 2"
                            value={userInfo?.addressLine2}
                            onChange={handleChange}
                            disabled={disableForm}

                        />
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default AddressForm;
