import React, { useState, useContext, useEffect} from 'react'
import GoogleAddressMarker from '../../common/GoogleAddressMarker';
import { UserContext } from '../../../contexts/UserContext';
import { useApiService } from '../../../services/apiService';

function PropertyDetail(props) {
    const { isOfferSend, user  } = useContext(UserContext);
    const [ isOfferArchived, setIsOfferArchived ] = useState(false);
    const { postRequest } = useApiService();

    // state to open streetview modal
    const [open, setOpen] = useState(false);
    // open street view modal
    const handleOpen = () => setOpen(true);
    // close street view modal
    const handleClose = () => setOpen(false);
    const properyDetails = props?.property;


    const handleSendOffer = () => {
        if (props.disabled) {
            props.setIsModalOpen(true);
            return; 
        }

        if(isOfferSend) {
            props.getRevisedOfferDetails(props?.property?.application_offers_id);
        } else {
            props.getRevisedOfferDetails('');
        }
        props?.setOpenSendOfferModal(true);
    };

    const handleArchiveButton =  async () => {
        await postRequest(`/application/lender/archive/${props.hideArchived ? "add" : "remove"}`, {user_id: user.id, app_id: properyDetails?.application_id})
        await props.refreshApps()
        console.log(props.appsToUse)
         if (props.appsToUse.length === 1) {
            props.setHideArchived(!props.hideArchived)
         } else{
            props.handleRowClick(props.rows[0].application_id === properyDetails?.application_id ? props.rows[1] : props.rows[0])
         }
    };

     useEffect(() => {
        // Notify the parent that the ApplicationTable has completed rendering
        if (props.onReady) {
            props.onReady();
        }
    }, [props.onReady]);

    return (
        <div className='tw-w-[100%] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-[30px] tw-h-full'>
            <div className='tw-w-[100%] tw-py-[22px] tw-border-b-[1px] tw-border-[#DDDDDD] '>
                <h1 className='tw-property-detail-title'>Property Detail</h1>
            </div>

            <div className='tw-property-section'>
                <div className="tw-flex">
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>Property Type</span>
                        <h1 className='tw-property-value'>{properyDetails?.propertyType ? properyDetails?.propertyType : '-'}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Purpose</span>
                        <h1 className='tw-property-value'>{properyDetails?.purpose ? properyDetails?.purpose : '-'}</h1>
                    </div>
                </div>

                <div className='tw-flex !tw-mt-0'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>Property Value</span>
                        <h1 className='tw-property-value'>{properyDetails?.propertyValue}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Loan Amount</span>
                        <h1 className='tw-property-value'>{properyDetails?.loanAmount}</h1>
                    </div>
                </div>

                <div className='tw-flex !tw-mt-0'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>LTV</span>
                        <h1 className='tw-property-value'>{properyDetails?.ltv ? properyDetails?.ltv : '-'}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>City:</span>
                        <h1 className='tw-property-value'>{properyDetails?.city ? properyDetails?.city : '-'}</h1>
                    </div>
                </div>

                <div className='tw-flex !tw-mt-0'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>State:</span>
                        <h1 className='tw-property-value'>{properyDetails?.state ? properyDetails?.state : '-'}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Zip Code:</span>
                        <h1 className='tw-property-value'>{properyDetails?.postal_code ? properyDetails?.postal_code : '-'}</h1>
                    </div>
                </div>

                <div className='tw-flex !tw-mt-0'>
                    <div className='tw-property-address-full'>
                        <span className='tw-property-label'>Address:</span>
                        <p className='tw-property-address-text'>
                            {properyDetails?.streetAddress ? properyDetails?.streetAddress : '-'}
                        </p>
                    </div>
                </div>

                {
                    props.selectedLocation
                        ?
                        <div className='tw-my-5'>
                            <GoogleAddressMarker open={open} handleOpen={handleOpen} handleClose={handleClose} selectedLocation={props.selectedLocation} properyDetails={properyDetails} />
                        </div>
                        : null
                }
                <div className="tw-flex tw-gap-4 tw-justify-evenly !tw-mt-0">
                {props?.rowStatus !== 4 && props?.rowStatus !== 5 && (
                    <button
                        className={`tw-bg-[#7DDE92] hover:tw-bg-[#6fc281] hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[5px] tw-px-[18px] tw-gap-[4px] tw-text-sm md:tw-text-[18px] tw-font-inter tw-shadow-md tw-h-10 tw-w-full ${props.hideArchived ? "tw-block" : "tw-hidden"}`}
                        onClick={handleSendOffer}
                    >
                        <i className="fas fa-paper-plane tw-mr-2"></i>
                        {!isOfferSend ? "Send Offer" : "Revise Offer"}
                    </button>
                )}
                <button
                    className="tw-bg-[#FA8072] hover:tw-bg-[#c26257] tw-w-full hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[5px] tw-px-[18px] tw-gap-[4px] tw-text-sm md:tw-text-[18px] tw-font-inter tw-shadow-md tw-h-10"
                    onClick={handleArchiveButton}
                    >
                    <i className="fas fa-file tw-mr-2"></i>
                    { props.hideArchived ? "Archive" : "Unarchive"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PropertyDetail