import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useUserType } from '../../contexts/UserTypeContext';

function ForgotPassword() {
    const { userType } = useUserType();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(''); // Clear error if validation passes
        // Proceed with form submission logic (e.g., API call)
        console.log('Form submitted with email:', email);
    };

    return (
        <>
            <div className="tw-basis-[60%] tw-bg-white tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-[827px] tw-mx-auto">

                    <h1 className="tw-font-oswald tw-text-4xl tw-text-black tw-font-bold mb-4">Recover Password</h1>
                    <p className="tw-mb-4 tw-text-2xl tw-text-black tw-mt-2">
                        Enter the email that you used when you signed up to recover your password. <br />
                        You will receive a password reset link.
                    </p>
                    <form onSubmit={handleSubmit} className="tw-space-y-10">
                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-w-1/1 tw-mt-5">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${error ? 'tw-border-red-500' : ''}`}
                                />
                                {error && <p className="tw-text-red-500 tw-text-[14px]">{error}</p>}
                            </div>
                            <button className="tw-w-1/5 tw-h-[70px] tw-bg-green-300 tw-border tw-text-blue-900 tw-font-bold tw-py-4 tw-rounded-full tw-mt-10">
                                Send Link
                                <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword