import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import SourceEditing from '@ckeditor/ckeditor5-source-editing';
import { Button } from '@mui/material';

function Modal({ cmsData, closeModal, onSubmit, errors }) {
    const { control, handleSubmit, setValue, watch } = useForm();
    const [isSourceEditing, setIsSourceEditing] = useState(true);

    useEffect(() => {
        if (cmsData?.description) {
            setValue('description', cmsData?.description);
        }

        if (cmsData?.title) {
            setValue('title', cmsData?.title);
        }

        if (cmsData?.slug) {
            setValue('slug', cmsData?.slug);
        }
    }, [cmsData, setValue]);

    const title = watch('title');

    useEffect(() => {
        if (title) {
            setValue('slug', generateSlug(title));
        }
    }, [title, setValue]);

    const generateSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, '') // Remove invalid characters
            .trim()
            .replace(/\s+/g, '-'); // Replace spaces with dashes
    };
    
    // Toggle between WYSIWYG and HTML source editing mode
    const toggleSourceEditing = () => {
        setIsSourceEditing(!isSourceEditing);
    };

    return (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
            <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-[500px] tw-shadow-lg">
                <div className='tw-flex tw-justify-between tw-justify-center tw-items-center'>
                    <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-black">Content Management</h2>
                    <Button
                        onClick={closeModal}
                        className="tw-bg-transparent tw-border-0 tw-text-gray-500 hover:tw-text-red-600 hover:tw-bg-gray-100 tw-p-2 tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out"
                        aria-label="Close Modal"
                    >
                        X
                    </Button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Title</label>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                />
                            )}
                        />
                        {errors?.title && <div className="tw-text-red-500">{errors?.title}</div>}
                    </div>
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Slug</label>
                        <Controller
                            name="slug"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="tw-bg-gray-200 tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                    readOnly
                                />
                            )}
                        />
                        {errors?.slug && <div className="tw-text-red-500">{errors?.slug}</div>}
                    </div>
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Description</label>
                        <div className="tw-h-[250px] tw-overflow-auto">
                            <Button onClick={toggleSourceEditing}>
                                {isSourceEditing ? 'Preview in Ckeditor' : 'View Source'}
                            </Button>
                            
                            {!isSourceEditing ? (
                            <Controller
                                name="description"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={value || ''}
                                        config={{
                                            allowedContent: true, // Allow all elements and attributes
                                            extraAllowedContent: '*[*]{*}', // Allow all elements, attributes, and styles
                                            htmlSupport: {
                                              allow: [
                                                {
                                                  name: /.*/, // Match all element names
                                                  attributes: true, // Allow all attributes
                                                  classes: true, // Allow all classes
                                                  styles: true // Allow all inline styles
                                                }
                                              ]
                                            }
                                          }}
                                        onReady={editor => {
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            onChange(data);
                                        }}
                                        onBlur={onBlur}
                                        ref={ref}
                                    />
                                )}
                            /> 
                            )
                            : 
                            (
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            rows={50}
                                            cols={50}
                                            {...field}
                                            className="tw-bg-gray-200 tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                        />
                                    )}
                                />
                            )}
                            
                        </div>
                        {errors?.description && <div className="tw-text-red-500">{errors?.description}</div>}
                    </div>
                    <div className="tw-flex tw-justify-end tw-gap-4">
                        <button
                            type="button"
                            onClick={closeModal}
                            className="tw-px-4 tw-py-2 tw-border tw-rounded tw-border-gray-400 tw-text-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="tw-px-4 tw-py-2 tw-bg-[#2A2F70] tw-text-white tw-rounded"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Modal;
