import { useState, useEffect, useContext } from "react";
import Header from "./layouts/homepage/Header";
import HomeInfoArea from "./layouts/homepage/HomeInfoArea";
import AdvantagesArea from "./layouts/homepage/AdvantagesArea";
import { UserContext } from "../contexts/UserContext";
import LoginModal from "./modals/LoginModal";
import SignupModal from "./modals/SignupModal";
import ValidationModal from "./modals/ValidationModal";
import ProductArea from "./layouts/homepage/ProductArea";
import TestimonialsArea from "./layouts/homepage/TestimonialsArea";
import LoanCalculatorArea from "./layouts/homepage/LoanCalculatorArea";
import Footer from "./layouts/homepage/Footer";
import TrustedPartnersArea from "./layouts/homepage/TrustedPartnersArea";
import LendersBannerArea from "./layouts/homepage/LendersBannerArea";
import { authLocal } from "../utils/common"; // Ensure this is the correct path
import LogoImage from "../assets/icons/logo.svg"; // Path to your logo
import { PageLoader, usePageLoader } from "../contexts/PageLoaderContext";

function Home() {
    const { user, userType, setUserType } = useContext(UserContext);
    const [showSignupFormModal, setShowSignupFormModal] = useState(false);
    const [showSigninFormModal, setShowSigninFormModal] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // Track if the screen size is mobile

    const closeSigninFormModal = () => setShowSigninFormModal(false);
    const [isPageReady, setIsPageReady] = useState(false);
    const { showPageLoader, hidePageLoader } = usePageLoader();


    const closeSignupFormModal = (success = false) => {
        setShowSignupFormModal(false);

        if (success) {
            setShowValidationModal(true);
        }
    };

    const closeValidationModal = (success = false) => {
        setShowValidationModal(false);
        if (success) {
            setShowSigninFormModal(true);
        }
    };


    // Track screen size to determine if it's mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 960); // Check if screen width is less than 1024px (lg breakpoint)
        };

        // Run the check once on mount
        handleResize();

        // Add event listener to update on window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Check user login status
    useEffect(() => {
        const userData = authLocal.get();
        setIsUserLoggedIn(userData && userData.email ? true : false);
    }, []);

    // Handle loader visibility
    useEffect(() => {
        if (!isPageReady) {
            showPageLoader();
        } else {
            hidePageLoader();
        }
    }, [isPageReady, showPageLoader, hidePageLoader]);

    const handleHomeInfoReady = () => {
        setIsPageReady(true); // Mark the page as ready when HomeInfoArea is ready
    };

    return (
        <div className="tw-relative">
            {!isPageReady && (
                <div style={{ backgroundColor: "#FFFFFF", width: "100vw", height: "100vh" }}>
                {/* This renders a plain white screen */}
              </div>
            )}
            {/* Show Header or Logo */}
            <Header isUserLoggedIn={isUserLoggedIn} />

            <div className="tw-flex tw-mx-7 lg:tw-mx-20 tw-mt-8">
                <HomeInfoArea
                    isUserLoggedIn={isUserLoggedIn}
                    onReady={handleHomeInfoReady}
                />
            </div>

            <div className="tw-flex tw-pt-10">
                <LendersBannerArea />
            </div>

            <div className="tw-flex ">
                <AdvantagesArea openSignupBorrowerModal={setShowSignupFormModal} />
            </div>

            <div className="tw-pb-10">
                <LoanCalculatorArea openSignupBorrowerModal={setShowSignupFormModal} />
            </div>

            <div className="tw-flex ">
                <ProductArea isUserLoggedIn={isUserLoggedIn} />
            </div>

            {/* <div className="tw-flex tw-py-10">
                <TrustedPartnersArea />
            </div>

            <div className="tw-flex tw-mx-5 lg:tw-mx-10">
                <TestimonialsArea />
            </div> */}

            <Footer />

            {/* Render the SignUp Form Modal */}
            <SignupModal
                role={""}
                isOpen={showSignupFormModal}
                onClose={closeSignupFormModal}
            />

            {/* Render the SignIn Form Modal */}
            <LoginModal
                isOpen={showSigninFormModal}
                onClose={closeSigninFormModal}
            />

            {/* Render the Validation Modal */}
            <ValidationModal
                isOpen={showValidationModal}
                onClose={closeValidationModal}
            />
        </div>
    );
}

export default Home;