import { Button, Input } from '@material-tailwind/react';
import React, { useState } from 'react';

function PromoCode({ onApply }) {
    const [localPromoCode, setLocalPromoCode] = useState('');

    const handleApplyPromoCode = () => {
        if (localPromoCode.trim()) {
            onApply(localPromoCode); // Pass the promo code to the parent component
        } else {
            alert('Please enter a valid promo code.');
        }
    };

    const handleInputChange = (e) => {
        const input = e.target.value.toUpperCase().replace(/\s/g, '');
        setLocalPromoCode(input);
    };

    return (
        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0 sm-mt-10">
            <div className="tw-relative tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                <label htmlFor="promoCode" className="form-label">
                    Promo Code <span className="text-red-500"></span>
                </label>
                <Input
                    id="promoCode"
                    type="text"
                    value={localPromoCode}
                    onChange={handleInputChange}
                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald`}
                />
            </div>
            <div className="tw-relative tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-flex tw-items-end">
                <Button
                    type="button"
                    color="green"
                    onClick={handleApplyPromoCode}
                    className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-oswald tw-w-[185px] tw-h-[48px] tw-bg-reloan tw-border-none tw-text-[#2A2F70] tw-rounded-full tw-flex tw-items-center tw-justify-between tw-px-[12px] tw-mt-2 lg:tw-mt-0"
                    id="apply_promo_code"
                >
                    <p className="tw-w-[135px] tw-h-[27px] tw-text-white">Apply Promo Code</p>
                    <div className="tw-bg-white tw-w-[36px] tw-h-[36px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                        ➔
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default PromoCode;
