import React from 'react'
import StatsCards from '../../common/StatsCards'
import Table from '../../common/table/Table';
import GeographicLoanActivity from './GeographicLoanActivity';
import LoanVolByPropType from './LoanVolByPropType';
import LoanAmountDist from './LoanAmountDist';
import { Link } from 'react-router-dom';

function Admin() {
  const stats = [
    { title: 'Total Applications', value: '40,689', change: '8.5%' },
    { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
    { title: 'Pending Applications', value: '40,689', change: '8.5%' },
    { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
    { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
    { title: 'Rejected Applications', value: '120%', change: '8.5%' },
  ];


  return (
    <div className="tw-h-full tw-w-full">
      <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[87px]">
        <div>
          <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">Admin Dashboard</h1>
          <p className='tw-text-[#202224] tw-font-semibold tw-leading-[24.55px] tw-text-[18px] tw-opacity-55 tw-h-[25px]'>
            Here is the information about all your orders
          </p>
        </div>
        <div className='tw-flex tw-space-x-4'>
          <div className="tw-w-[237.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center">
            <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
              <i className="fas fa-user tw-text-[12px] tw-text-[#2A2F70]"></i>
            </span>
            <Link to="/dashboard/view-application">
              <h2 className='tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]'>View All Applications</h2>
            </Link>
          </div>

          <Link to="/dashboard/user-management">
            <div className="tw-w-[193px] tw-h-[49px] tw-bg-[#7DDE92] tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[12px] tw-px-[18px] tw-gap-[4px] tw-text-[18px] tw-leading-[24.55px] tw-flex tw-items-center tw-justify-center">

              <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[1px]">
                <i className="fas fa-user tw-text-[12px] tw-text-[#2A2F70]"></i>
              </span>
              View All Users

            </div>
          </Link>
        </div>
      </header>

      <div className='tw-block '>
        <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg">
          {/* <StatsDropdown /> */}

          {stats?.map((stat, index) => (
            <StatsCards
              key={index}
              title={stat?.title}
              value={stat?.value}
              change={stat?.change}
            />
          ))}
        </div>
      </div>


      <div className='hidden tw-flex'>
        <Table />
        <GeographicLoanActivity />
      </div>

      <div className='hidden tw-flex'>
        <LoanVolByPropType />
        <LoanAmountDist />
      </div>
    </div>
  )
}

export default Admin