import { Link } from "react-router-dom";
import LoginIcon from "../../../assets/icons/borrowerIcon.svg";
import ProductImage1 from "../../../assets/images/product_image_1.png";
import ProductImage2 from "../../../assets/images/product_image_2.png";
import GenericButton from "../../common/buttons/GenericButton";

const ProductArea = ({ isUserLoggedIn, handleUserTypeChange }) => {
    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-flex-col tw-justify-center">
                <div className="tw-flex tw-justify-center tw-items-center">
                    <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                    <span className="tw-font-inter tw-px-2 tw-text-black tw-text-lg tw-font-normal tw-text-center">Product</span>
                    <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                </div>

                <div className="tw-flex tw-flex-col tw-my-4">
                    <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center">Our Platform Provides</h2>
                    <h2 className="tw-font-oswald tw-text-4xl tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-text-[#4853e4]">Seemless Connection</h2>
                </div>

                <div className="tw-flex tw-items-center tw-my-8">
                    <div className="tw-w-1/2 tw-flex-col tw-m-10 tw-space-y-4">
                        <h2 className="tw-font-oswald tw-text-2xl tw-text-[#191919] tw-font-bold">Lenders Love Our Services</h2>
                        <p className="tw-font-inter tw-text-lg tw-text-[#949494]">Re-Loan equips lenders with the essential tools to streamline loan reviews, access
                            comprehensive borrower insights, and engage in seamless, direct communication with clients.
                            Our platform provides lenders with real-time property and market data, allowing them to make
                            faster, more accurate lending decisions. With Re-Loan, lenders can manage multiple
                            applications efficiently and confidently close deals backed by the latest insights.</p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={"/sign-up/lender"}>
                                    <GenericButton icon={LoginIcon} title='Log In As Lender' onClick={() => handleUserTypeChange('lender')} />
                                </Link>
                            </div>
                        )}
                    </div>

                    <img className="tw-w-1/2" src={ProductImage1} alt="product first image" loading="lazy" />
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-justify-center">

                <div className="tw-flex tw-items-center tw-my-8">
                    <img className="tw-w-1/2" src={ProductImage2} alt="product second image" loading="lazy" />

                    <div className="tw-w-1/2 tw-flex-col tw-m-10 tw-space-y-4">
                        <h2 className="tw-font-oswald tw-text-2xl tw-text-[#191919] tw-font-bold">Borrowers Thrive with Our Easy Process</h2>
                        <p className="tw-font-inter tw-text-lg tw-text-[#949494]">At Re-Loan, we prioritize borrower convenience and transparency. Our platform enables you to
                            submit loan applications, track your progress, and directly communicate with multiple
                            lenders—all in one place. With a personalized dashboard, borrowers can access real-time
                            updates, compare offers, and make informed decisions. Re-Loan simplifies the process,
                            ensuring you get the best financing options for your property without unnecessary delays or
                            hidden costs.
                        </p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={"/sign-up/borrower"}>
                                    <GenericButton icon={LoginIcon} title='Log In As Borrower' onClick={() =>       handleUserTypeChange('borrower')} />
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductArea;