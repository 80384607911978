// UserTypeContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the UserType context
const UserTypeContext = createContext();

// Create a custom hook to use the UserType context
export const useUserType = () => {
    return useContext(UserTypeContext);
};

// Create a provider component
export const UserTypeProvider = ({ children }) => {
    const [userType, setUserType] = useState('borrower');

    return (
        <UserTypeContext.Provider value={{ userType, setUserType }}>
            {children}
        </UserTypeContext.Provider>
    );
};
