import { Modal } from "@mui/material";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
export default function PfpCropModal({
  openState,
  closeCb,
  pfpFile,
  uploadCallback,
}) {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  //Convert data URL to a File for Backend
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return (
    <Modal
      open={openState}
      onClose={closeCb}
      className="tw-flex tw-justify-center tw-items-center tw-h-screen"
    >
      <div className="tw-bg-white tw-rounded-xl tw-py-4 tw-px-24">
        <Cropper
          src={pfpFile}
          style={{ width: "450px", height: "450px", borderRadius: "20%" }}
          // Cropper.js options
          onClickCapture={false}
          dragMode="move"
          aspectRatio={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          className="tw-m-0"
        />
        <div className="tw-flex tw-justify-center tw-m-4 tw-gap-3">
          <Button
            variant="contained"
            className="tw-w-1/2"
            onClick={() => {
              uploadCallback(dataURLtoFile(croppedImage, "testfile"));
              closeCb();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="!tw-bg-red-400 tw-w-1/2"
            onClick={closeCb}
          >
            Cancel
          </Button>
        </div>
        <style>
          {`
          .cropper-crop-box {
            border-radius: 50% !important; /* Make the crop box circular */
            overflow: hidden !important;   /* Hide anything outside the circular crop box */
          }
        `}
        </style>
      </div>
    </Modal>
  );
}
