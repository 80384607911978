import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';

function ChatList({
  chatMessages,
  handleViewOfferDetails,
}) {
  const { user } = useContext(UserContext);
  const awsContextVal = useContext(AWSAmplifyConfigContext);

  const groupMessagesByDate = (messages) => {
    const groupedMessages = [];
    let lastDate = null;
    let lastOfferMessageId = null;

    messages.forEach((chat) => {
      const messageDate = new Date(chat.sent_at);
      const messageDateString = messageDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "short",
        day: "2-digit",
        year: "numeric",
      });

      if (messageDateString !== lastDate) {
        groupedMessages.push({
          date: messageDateString,
          messages: [chat],
        });
        lastDate = messageDateString;
      } else {
        groupedMessages[groupedMessages.length - 1].messages.push(chat);
      }
    });

    // Find the last offer message in the entire chat
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].is_offer === 1) {
        lastOfferMessageId = messages[i].message_id;
        break;
      }
    }

    // Filter messages so only the last offer is kept
    groupedMessages.forEach((group) => {
      group.messages = group.messages.filter((m) => m.is_offer === 0 || m.message_id === lastOfferMessageId);
    });

    return groupedMessages;
  };


  const groupedMessages = groupMessagesByDate(chatMessages);
  // console.log("Chat messages:", chatMessages);
  // console.log("AWS Context Value:", awsContextVal);


  const handleDownload = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then(response => response.blob()) // Convert file to blob
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Set the file name
        document.body.appendChild(a);
        a.click(); // Simulate click
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error("Error downloading file:", error));
  };


  return (
    <div className="tw-max-h-[37rem] tw-flex tw-flex-col">
      <div className="tw-flex-1 tw-py-4">
        <ul className="tw-mx-[20px]">
          {groupedMessages.map((group, groupIndex) => {
            // If there are no messages in this group, skip rendering it
            if (group.messages.length === 0) return null;

            return (
              <div key={groupIndex}>
                <div className="tw-mx-[20px] tw-flex tw-items-center">
                  <div className="tw-text-gray-400 tw-flex-shrink-0">
                    {group.date}
                  </div>
                  <div className="tw-border-b tw-border-gray-400 tw-w-[100%] tw-mx-2"></div>
                </div>
                {group.messages.map((chat) => {
                  const attachmentsArray = chat?.attachment
                    ? chat.attachment.split(",").map((item) => item.trim()) // Ensure clean URLs
                    : [];

                  return (
                    <li
                      key={chat?.message_id}
                      className={`tw-flex tw-gap-[10px] tw-my-4 ${chat?.sender_id === user?.id
                        ? "tw-justify-end tw-ml-[40px]"
                        : "tw-justify-start tw-mr-[40px]"
                        }`}
                    >
                      {chat?.is_offer === 1 ? (
                        <div
                          className={`tw-px-4 tw-py-2 tw-rounded-lg tw-bg-blue-100 tw-text-left tw-w-full`}
                        >
                          <div className="tw-flex tw-items-center tw-gap-[10px]">
                            <span className="common-font tw-font-extrabold tw-text-[15px] tw-text-[#444444] tw-capitalize">
                              {chat?.user_name}
                            </span>
                            <span className="time tw-text-gray-500">
                              {new Date(chat?.sent_at).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                          <div className="common-font tw-text-black tw-text-[16px] tw-font-normal tw-mt-2">
                            <p>{chat?.message_text}</p>
                          </div>
                          <div>
                            <button
                              className="tw-mt-2 tw-text-green-600 tw-font-semibold hover:tw-underline"
                              onClick={() =>
                                handleViewOfferDetails(chat?.application_offers_id)
                              }
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`tw-px-4 tw-py-2 tw-rounded-lg tw-max-w-[80%] tw-text-left 
                    ${(user?.user_type === "lender" &&
                              chat?.sender_id === user?.id) ||
                              (user?.user_type === "borrower" &&
                                chat?.sender_id !== user?.id)
                              ? "!tw-bg-blue-100"
                              : "!tw-bg-green-100"
                            } tw-break-words tw-inline-block tw-w-auto tw-overflow-hidden`}
                        >
                          <div className="tw-flex tw-items-center tw-gap-[10px]">
                            <span className="common-font tw-font-extrabold tw-text-[15px] tw-text-[#444444] tw-capitalize">
                              {chat?.user_name}
                            </span>
                            <span className="time tw-text-gray-500">
                              {new Date(chat?.sent_at).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                          <div className="common-font tw-text-black tw-text-[16px] tw-font-normal">

                            {attachmentsArray.length > 0 && (
                              <div className="tw-flex tw-gap-2 tw-flex-wrap tw-mt-2">
                                {attachmentsArray.map((attachment, index) => {
                                  const fileUrl = `https://${awsContextVal.s3BucketName}.s3.${awsContextVal.region}.amazonaws.com/${attachment}`;
                                  const fileExtension = attachment.split(".").pop().toLowerCase();
                                  const isImage = ["png", "jpg", "jpeg", "svg", "gif", "webp"].includes(fileExtension);

                                  return (
                                    <div key={index}>
                                      {isImage ? (
                                        <img
                                          src={fileUrl}
                                          alt="Uploaded file"
                                          className="tw-w-40 tw-h-40 tw-rounded-xl tw-object-cover"
                                          onError={(e) => {
                                            console.error("Image failed to load:", fileUrl);
                                            e.target.src = "/fallback-image.png";
                                          }}
                                        />
                                      ) : (
                                        <button
                                          className="tw-text-blue-500 tw-underline tw-cursor-pointer tw-break-all tw-whitespace-normal tw-max-w-full"
                                          onClick={() => handleDownload(fileUrl, attachment.split("/").pop())}
                                        >
                                          📄 {attachment.split("/").pop()}
                                        </button>
                                      )}
                                    </div>

                                  );
                                })}
                              </div>
                            )}
                            <p>{chat?.message_text}</p>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </div>)
          })}
        </ul>
      </div>
    </div>
  );
}


export default ChatList;
