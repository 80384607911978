import React from 'react';
import TrustedPartnersLine from "../../../assets/icons/trustePartnersLeftLines.svg";
import Adaptavist from "../../../assets/icons/adaptavist.png";
import Mailgun from "../../../assets/icons/mailgun.png";
import Illumos from "../../../assets/icons/illumos.png";
import Kabbage from "../../../assets/icons/kabbage.png";
import Jasmine from "../../../assets/icons/jasmine.png";
import '../../../styles/LendersBannerScroll.css';

const TrustedPartnersArea = () => {
    const logos = [Adaptavist, Mailgun, Illumos, Kabbage, Jasmine];

    return (
        <div className="tw-relative tw-bg-[#4853E4] tw-flex tw-flex-col tw-py-5 tw-w-full">
            <h2 className="tw-font-oswald tw-text-4xl tw-font-bold tw-text-center">
                Our Trusted Partners
            </h2>

            <div className="slider tw-my-0 tw-w-full lg:tw-w-[56rem]">
                <div className="slide-track">
                    {logos.concat(logos).map((logo, index) => (
                        <div className="slide" key={index}>
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TrustedPartnersArea;
