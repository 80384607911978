// FormBox.js
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoxIcon from "../../../assets/icons/Box_icon.png.png";
import {
    GetState,
    GetCity,
} from "react-country-state-city";

const FilterForm = ({ filter, setFilter, filterHandler, resetFilterHandler }) => {
    // console.log("filter:", filter);
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(filter?.date_submitted || null);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const formatPlaceholder = () => {
        if (startDate && endDate) {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            return `${startDate.toLocaleDateString('en-US', options)} - ${endDate.toLocaleDateString('en-US', options)}`;
        }
        return "Dec 01, 2023 - Jan 31, 2024";
    };

    //This is to open filter section
    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        GetState(233)
            .then((result) => {
                // console.log("state: result:", result)
                setStateList(result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getCities = (stateId) => {
        GetCity(233, stateId)
            .then((result) => {
                // console.log("Fetched Cities:", result);
                setCityList(result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleStateChange = (e) => {
        const selectedStateVal = Number(e.target.value);
        setFilter((prevFilter) => ({
            ...prevFilter,
            state_id: selectedStateVal
        }));
        getCities(selectedStateVal);
    };

    const handleCityChange = (e) => {
        const selectedCityIndex = e.target.value;
        const city = cityList[selectedCityIndex];
        const cityId = city.id;
        setFilter((prevFilter) => ({
            ...prevFilter,
            city_id: cityId
        }));
    }

    const handleReset = () => {
        resetFilterHandler();
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <>
            <div className='tw-w-full tw-border tw-border-[#555FE1] tw-rounded-[10px]'>
                <div className="tw-flex tw-justify-between tw-p-4 tw-border-b-0 tw-cursor-pointer" onClick={toggleContainer}>
                    <h2 className='common-font tw-text-[#444444] tw-text-[22px] tw-mt-[5px] tw-ml-[15px]'>Filters</h2>
                    <button
                        className='tw-ml-2 tw-mt-[5px] tw-text-black'

                    >
                        <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
                    </button>
                </div>

                {isOpen && (
                    <div className="tw-w-full tw-h-auto tw-px-[24px] tw-py-[24px]">
                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-pb-[5px]'>Property Details</h2>
                        <div className='tw-flex tw-justify-between tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[35%]'>
                                <div className='tw-label'>
                                    Property Type
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.property_type}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_type: selectedValue
                                            }));
                                        }}>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="all">All</option>
                                        <option value="commercial">Commercial</option>
                                        <option value="residential">Residential</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[35%]'>
                                <div className='tw-label'>
                                    Condition
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.condition}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                condition: selectedValue
                                            }));
                                        }}>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="all">All</option>
                                        <option value="new">New</option>
                                        <option value="old">Old</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Property Size (sqft)
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 450"
                                        className='tw-property-size'
                                        value={filter?.property_size_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_size_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 1000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.property_size_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_size_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='tw-flex tw-space-x-5 tw-mt-5'>
                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Property Value ($)
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 5000"
                                        className='tw-property-size'
                                        value={filter?.property_value_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_value_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 15000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.property_value_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_value_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Appraised Value ($)
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 5000"
                                        className='tw-property-size'
                                        value={filter?.appraised_value_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                appraised_value_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 15000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.appraised_value_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                appraised_value_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Application Status</h2>
                        <div className='tw-flex tw-space-x-5 tw-mt-5'>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Status
                                </div>
                                <div className='tw-h-[42px] tw-flex tw-items-center'>
                                    <input
                                        type="radio"
                                        id="new-application"
                                        name="application-status" // Ensure radios are grouped
                                        className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                        value="new_application"
                                        checked={filter.application_status === 'new_application'}
                                        onClick={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                application_status: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="new-application" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                        New Application
                                    </label>

                                    <input
                                        type="radio"
                                        id="reviewed-application"
                                        name="application-status" // Ensure radios are grouped
                                        className='tw-mr-2 tw-ms-5'
                                        value="reviewed_application"
                                        checked={filter.application_status === 'reviewed_application'}
                                        onClick={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                application_status: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="reviewed-application" className='tw-text-normal common-font tw-text-[#202224]'>
                                        Reviewed Application
                                    </label>
                                </div>
                            </div>

                            {/* Date Picker Section */}
                            <div className='tw-w-[23%]'>
                                <div className='tw-label'>
                                    Date Submitted
                                </div>
                                <div className=' tw-h-[42px] tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-p-2 tw-text-[#202224] tw-bg-[#f9f9ff]'>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {
                                            setEndDate(date);
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                date_submitted: date ? date.toISOString() : '',
                                            }));
                                        }
                                        }
                                        selectsStart
                                        selectsEnd
                                        startDate={startDate}
                                        className='tw-w-full tw-h-full tw-bg-transparent outline-none'
                                        placeholderText={formatPlaceholder()}
                                        dateFormat="MMM dd, yyyy"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Location Details</h2>
                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    State
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                     value={filter?.state_id}
                                        onChange={handleStateChange}
                                    >
                                        <option value="" disabled >- Select -</option>
                                        {stateList.map((item, index) => (
                                            <option key={index} value={item?.id} 
                                           >
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    City
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        onChange={handleCityChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        {cityList.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Zip Code
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="Zip Code"
                                        className='tw-select'
                                        value={filter?.postal_code}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                postal_code: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Proximity
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.proximity}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                proximity: selectedValue
                                            }));
                                        }}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="purchase">10Km</option>
                                        <option value="refinance">50Km</option>
                                        <option value="investment">100Km</option>
                                        <option value="construction">150Km</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Borrower and Loan Details</h2>
                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[23%]'>
                                <div className='tw-label'>
                                    Loan Purpose
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.loan_purpose}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                loan_purpose: selectedValue
                                            }));
                                        }}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="refinance">Refinance</option>
                                        <option value="cashout_refinance">Cash-Out Refinance</option>
                                        <option value="construction_or_rehab">Construction Or Rehab</option>
                                        <option value="purchase">Purchase</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[23%]'>
                                <div className='tw-label'>
                                    Loan Term
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.loan_term}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                loan_term: selectedValue
                                            }));
                                        }}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="5">5 Years</option>
                                        <option value="10">10 Years</option>
                                        <option value="15">15 Years</option>
                                        <option value="20">20 Years</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[23%]'>
                                <div className='tw-label'>
                                    LTV %
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.ltv}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                ltv: selectedValue
                                            }));
                                        }}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="10">10%</option>
                                        <option value="30">30%</option>
                                        <option value="60">60%</option>
                                        <option value="90">90%</option>
                                    </select>
                                </div>
                            </div>

                            <div className='tw-w-[20%]'>
                                <div className='tw-label'>
                                    Property Value ($)
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 5000"
                                        className='tw-property-size'
                                        value={filter?.property_value_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_value_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 15000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.property_value_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                property_value_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>

                            </div>
                            <div className='tw-w-[10%]'>
                                <div className='tw-label'>
                                    Intrest rate preference
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="radio"
                                        id="fixed_pref"
                                        name="intrest_rate_preference"
                                        className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                        value="fixed"
                                        checked={filter.interest_rate_type === 'fixed'}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                interest_rate_type: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="fixed_pref" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                        Fixed
                                    </label>

                                    <input
                                        type="radio"
                                        id="variable_pref"
                                        name="intrest_rate_preference"
                                        className='tw-mr-2 tw-ms-5'
                                        value="variable"
                                        checked={filter.interest_rate_type === 'variable'}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                interest_rate_type: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="variable_pref" className='tw-text-normal common-font tw-text-[#202224]'>
                                        Variable
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[20%]'>
                                <div className='tw-label'>
                                    Status
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="radio"
                                        id="first_time_borrower"
                                        name="application-status-borrower"
                                        className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                        value="first"
                                        checked={filter?.borrower_type === 'first'}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_type: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="first_time_borrower" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                        First Time Borrower
                                    </label>

                                    <input
                                        type="radio"
                                        id="repeat_time_borrower"
                                        name="application-status-borrower"
                                        className='tw-mr-2 tw-ms-5'
                                        value="repeat"
                                        checked={filter?.borrower_type === 'repeat'}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_type: selectedValue
                                            }));
                                        }}
                                    />
                                    <label htmlFor="repeat_time_borrower" className='tw-text-normal common-font tw-text-[#202224]'>
                                        Repeat Borrower
                                    </label>
                                </div>
                            </div>
                            <div className='tw-w-[45%]'>
                                <div className='tw-label'>
                                    Borrower Existing Debt ($)
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 5000"
                                        className='tw-property-size'
                                        value={filter?.borrower_existing_dept_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_existing_dept_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 15000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.borrower_existing_dept_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_existing_dept_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='tw-w-[45%]'>
                                <div className='tw-label'>
                                    Borrower Credit Score
                                </div>
                                <div className='tw-h-[42px]'>
                                    <input
                                        type="text"
                                        placeholder="    Min | 5000"
                                        className='tw-property-size'
                                        value={filter?.borrower_credit_score_min}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_credit_score_min: selectedValue
                                            }));
                                        }}
                                    />

                                    <input
                                        type="text"
                                        placeholder="    Max | 15000"
                                        className='tw-property-size tw-ml-[10px]'
                                        value={filter?.borrower_credit_score_max}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                borrower_credit_score_max: selectedValue
                                            }));
                                        }}
                                    />
                                </div>
                            </div>



                        </div>

                        {/* <div className='tw-h-[49px] tw-my-5'>
                            <button className='tw-w-full tw-h-[49px] tw-border tw-text-[#2A2F70] tw-font-semibold tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#7DDE92] text-center'>Get A Quote</button>
                        </div> */}
                        <div className='tw-flex tw-justify-end'>
                            <div className='tw-w-[362px] tw-h-[58px] tw-mt-[40px] tw-flex'>
                                <div className='tw-w-[138px] tw-h-[49px]  tw-m-1 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-mr-4'
                                    onClick={handleReset}>
                                    <i className="tw-text-white fas fa-redo tw-me-[10px] tw-filter-icons-bg"></i>
                                    <span className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>
                                        Reset All
                                    </span>
                                </div>
                                <div className='tw-w-[200px] tw-h-[58px] tw-rounded-[10px] tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center tw-cursor-pointer'
                                    onClick={filterHandler}>
                                    <div className='tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center'>
                                        <i className="fas fa-chevron-right tw-me-[10px] tw-filter-icons-bg"></i>
                                        <button className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>Apply Filters</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FilterForm;
