import React from "react";
import "../../../styles/AdvantagesArea.css"; // Import the custom CSS
import HealthIconHighBarsIcon from "../../../assets/icons/healthicons_high-bars.svg";
import CommunicationIcon from "../../../assets/icons/communication.svg";
import UilProcessIcon from "../../../assets/icons/uil_process.svg";
import AdvantageCard from "./AdvantageCard";

const AdvantagesArea = ({ openSignupBorrowerModal }) => {
    const advantageCards = [
        {
            count: "01",
            icon: HealthIconHighBarsIcon,
            title: "Transparent and Fair Costs",
            description:
                "Re-Loan is committed to transparency by eliminating fees and middlemen, offering trust and saving time for borrowers and lenders.",
        },
        {
            count: "02",
            icon: UilProcessIcon,
            title: "Seamless Process Automation",
            description:
                "Our platform streamlines the loan journey — from application to funding decision, enhancing efficiency and reducing complexities.",
        },
        {
            count: "03",
            icon: CommunicationIcon,
            title: "Direct Communication",
            description:
                "Re-Loan’s integrated chat allows borrowers and lenders to communicate without delays, fostering clear, real-time collaboration that simplifies the financing process.",
        },
    ];

    return (
        <div className="tw-w-full tw-flex-col tw-justify-center tw-mx-6 lg:tw-mx-5 tw-mt-10 tw-hidden lg:tw-flex">
            <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-mb-7">
                The Re-Loan <span className="tw-text-[#4853e4]">Advantage</span>
            </h2>

            {/* Container for cards with hover effect */}
            <div className="md:tw-justify-between tw-flex-row tw-flex tw-gap-4 tw-m-4 lg:tw-m-12">
            {advantageCards.map((card) => (
            <AdvantageCard
                key={card.count}
                id={card.count}
                count={card.count}
                icon={card.icon}
                title={card.title}
                description={card.description}
                className="advantage-card" // Add hover effect class
        />
        ))}
        </div>

        </div>
    );
};

export default AdvantagesArea;
