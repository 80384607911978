import React, { useState, useContext, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import toast from "react-hot-toast";
import { UserContext } from '../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useNavigate } from "react-router-dom";
import { usePromoCode } from "../../contexts/PromoCodeContext";
import { signUp } from "@aws-amplify/auth";
import { fetchSettings } from "../../utils/services/apiService";
import { getMembershipDates } from '../../utils/common';
import { useApiService } from '../../services/apiService';


const Subscription = () => {
    const [disabledButton, setDisabledButton] = useState(null);
    const navigate = useNavigate();
    const { user, signUpUser, userType, updateMembership } = useContext(UserContext);
    const [plans, setPlans] = useState([]);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const { promoCodeDetail, setPromoCodeDetail } = usePromoCode();
    const [stripePublicKey, setStripePublicKey] = useState('');


    const fetchPlans = async () => {
        showLoader();
        try {
            const response = await postRequest(`/subscription-plan/list`, { status: 1 });
            const data = response.data.subscriptionPlanList;
            const processedPlans = data.map(plan => {
                const originalAmount = parseFloat(plan.amount);
                const discountAmount = promoCodeDetail?.discount_amount ? parseFloat(promoCodeDetail.discount_amount) : 0;
                const discountedAmount = originalAmount - discountAmount;

                return {
                    ...plan,
                    originalAmount,
                    amount: discountedAmount > 0 ? discountedAmount : originalAmount,
                };
            });
            setPlans(processedPlans);
            hideLoader();
        } catch (error) {
            console.error(error);
            hideLoader();
        } finally {
            hideLoader();
        }
    };

    const fetchStripeDetails = async () => {
        showLoader();
        try {
            const setting = await fetchSettings(`/setting/list/`, { "setting_names": ["site_name", "stripe_public_key"] }, postRequest);
            if (setting?.stripe_public_key) {
                setStripePublicKey(setting?.stripe_public_key);
            }
        } catch (error) {
            console.error(error);
            hideLoader();
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        const fetchPromoData = async () => {
            const promoCode = localStorage.getItem('promoCode');

            if (!promoCode) {
                return;
            }

            try {
                const response = await postRequest(`/promo-code/validate`, {
                    code: promoCode,
                });
                if (response?.success) {
                    const data = response?.data?.code;
                    setPromoCodeDetail({
                        id: data?.id,
                        discount_amount: data?.discount_value,
                        code: data?.code,
                    });
                } else {
                    setPromoCodeDetail({});
                    toast.error('Promo code not valid.');
                }
            } catch (error) {
                console.error("Error fetching promo code details:", error);
            }
        };

        if (!promoCodeDetail) {
            fetchPromoData();
        }
    }, [promoCodeDetail, awsContextVal?.endpoint]);

    useEffect(() => {
        fetchPlans();
        fetchStripeDetails();
    }, [awsContextVal?.endpoint, promoCodeDetail]);



    const signUpLender = async (formData, subscription_plan_id) => {
        showLoader();
        try {
            const user_details = await postRequest(`/check-email`, { email: formData.email });
            if (user_details?.data?.is_exist) {
                toast.error("Email Already Exist.");
                hideLoader();
            } else {
                const { userId } = await signUp({
                    username: formData.email,
                    password: formData.password,
                    options: {
                        userAttributes: {
                            email: formData.email,
                            phone_number: formData.phone,
                            name: `${formData.firstName} ${formData.lastName}`,
                            'custom:user_type': userType,
                            'custom:FirstName': formData.firstName,
                            'custom:LastName': formData.lastName,
                        },
                    },
                });

                if (userId) {
                    let user_response = await postRequest(`/create-user`,
                        {
                            email: formData.email,
                            phone_number: formData.phone,
                            name: formData.firstName + " " + formData.lastName,
                            'custom:user_type': userType,
                            'custom:FirstName': formData.firstName,
                            'custom:LastName': formData.lastName
                        }
                    );

                    if (user_response?.data?.id) {
                        //This is to get plan from plan id
                        const selectedPlan = plans.find(plan => plan.id === subscription_plan_id);

                        const membershipDates = getMembershipDates(selectedPlan?.type);

                        await postRequest(`/user/update-membership`, { //guest user restriction
                            subscription_plan_id: selectedPlan?.id,
                            available_offer: selectedPlan?.offer,
                            membership_start: membershipDates?.startDate,
                            membership_end: membershipDates?.endDate,
                            id: user_response?.data?.id,
                        });
                    } 

                    hideLoader();
                    navigate(`/otp-verify`);
                    toast.success("User registered successfully! Please validate and log in.");
                }
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            hideLoader();  // Hide the loader in case of error
        } finally {
            hideLoader();
        }
    }
    const handleCheckoutStripe = async (plan_name, subscription_plan_id, price_id, amount, original_amount, type, available_offer) => {
        const stripePromise = loadStripe(stripePublicKey);
        const stripe = await stripePromise;


        if (amount === 0) {
            signUpLender(signUpUser, subscription_plan_id);
            // navigate(`/otp-verify`);
            return;
        }

        if (!stripe) {
            toast.error('Stripe initialization failed');
            return;
        }

        if (!signUpUser?.email) {
            // If email does not exist, show error message
            toast.error('Email is required. Please sign up before subscribing.');
            return;
        }

        // setDisabledButton(index);
        showLoader();

        //This is to get plan from plan id
        const selectedPlan = plans.find(plan => plan.id === subscription_plan_id);
        // console.log("selectedPlan:", selectedPlan);
        // return false;
        const membershipDates = getMembershipDates(selectedPlan?.type);
        const response = await postRequest(`/payment/create-checkout-session`, { //guest user restriction
            amount: amount,
            price_id: price_id,
            plan_name: plan_name,
            success_url: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
            cancel_url: window.location.href,
            customer_email: signUpUser.email,
            subscription_plan_id: subscription_plan_id,
            original_amount: original_amount,
            promo_code_id: promoCodeDetail?.id,
            discount_amount: promoCodeDetail?.discount_amount,
            discount_code: promoCodeDetail?.code,
            offer: available_offer,
            membership_start: membershipDates?.startDate,
            membership_end: membershipDates?.endDate,
            type: type,
        });
        
        hideLoader();

        const session = await response?.data;
        const { error } = await stripe.redirectToCheckout({
            sessionId: session.id,  // Use session ID returned from the backend
        });
         // Membership Update Flow
         showLoader();
         const membershipResponse = await postRequest(`/user/update-membership`, {
             subscription_plan_id: subscription_plan_id,
             available_offer: available_offer,
             membership_start: membershipDates?.startDate,
             membership_end: membershipDates?.endDate,
             id: user?.id,
         });
         hideLoader();

         const data = membershipResponse?.data;
         if (data?.is_success) {
             // Update user context
             updateMembership({
                 subscription_plan_id: subscription_plan_id,
                 membership_start: membershipDates?.startDate,
                 membership_end: membershipDates?.endDate,
                 available_offer: available_offer,
                 plan: plans.find(plan => plan.id === subscription_plan_id),
             });
             toast.success(data?.message);

             navigate(`/dashboard/payment-list`);
         } else {
             toast.error(data?.message || 'Failed to update subscription.');
         }



        // const { error } = await stripe.redirectToCheckout({
        //     lineItems: [{
        //         price_data: {
        //             currency: 'usd', 
        //             product_data: {
        //                 name: 'Product Name',  // Specify the product name
        //             },
        //             unit_amount: amount *  100,
        //         },
        //         quantity: 1,
        //     }], // Use dynamic price ID
        //     mode: 'payment',
        //     successUrl: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
        //     cancelUrl: window.location.href,
        //     customerEmail: signUpUser.email,
        // });

        if (error) {
            console.error('Stripe Checkout Error:', error.message);
            toast.error('Stripe Checkout Error:', error.message);
        }

        
    };
    return (
        <div className="tw-bg-gray-100 tw-w-full tw-min-h-screen">
            <div className="tw-text-[#4853E4] tw-text-center tw-font-inter tw-pt-[95px]">
                Pricing Plans
            </div>
            <div className="tw-text-black tw-text-center tw-text-[48px] tw-font-[700] tw-font-oswald">
                Pricing That Suits Your Needs.
            </div>
            <div className="tw-font-inter tw-mt-4 tw-text-[#4D4D4D] tw-text-center tw-text-[18px] tw-font-[400]">
                Whether you’re exploring your first deal or managing a high-volume portfolio, our plans <br className="tw-hidden md:tw-inline" /> offer the tools and flexibility you need to succeed.
            </div>

            <section className="lg:tw-mx-[8%] md:tw-mx-[4%] sm:tw-mx-[4%] tw-mx-[4%] tw-mt-8 tw-pb-5">
                <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-center tw-gap-8 tw-items-center xl:tw-items-start">
                    {plans?.map((plan, index) => (
                        <div key={index} className="tw-w-full tw-h-[36rem] advantage-card tw-max-w-sm tw-px-[48px] tw-py-[40px] tw-bg-white tw-border tw-border-gray-200 tw-rounded-[16px] tw-shadow-[0px_7px_15px_0px_rgba(0,0,0,0.15)] dark:tw-bg-gray-800 dark:tw-border-gray-700">
                            <div>
                                <div className="tw-font-inter tw-text-[#4853E4] tw-text-[27px] tw-font-[700]">{plan?.plan}</div>
                                <div className="tw-font-inter tw-text-[#252422] tw-text-[13px] tw-font-[400] tw-mt-3 tw-h-9">{plan?.description}</div>
                                <div className="tw-flex tw-justify-center tw-mt-[30px] tw-mb-[24px]">
                                    <div className="tw-flex tw-items-baseline tw-text-gray-900 dark:tw-text-white tw-font-inter">
                                        <div className="tw-text-[49px] tw-font-extrabold tw-tracking-tight">
                                            <sup className="tw-text-[13px] tw-text-[#252422] tw-font-[400] tw-align-super">$</sup>{plan?.amount}
                                        </div>
                                        <span className="tw-ms-1 tw-text-[13px] tw-font-normal tw-text-[#252422] dark:tw-text-gray-400">{plan?.pricePeriod || '/month'}</span>
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleCheckoutStripe(plan?.plan, plan?.id, plan?.price_id, plan?.amount, plan?.amount, plan?.type, plan?.offer)}
                                    type="button"
                                    className={`tw-font-inter hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-text-white tw-uppercase tw-bg-[#4853E4] focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-200 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-900 tw-font-medium tw-rounded-lg tw-text-[16px] tw-px-5 tw-py-3 tw-inline-flex tw-justify-center tw-w-full tw-text-center ${disabledButton === plan?.id ? 'tw-bg-gray-200 tw-cursor-not-allowed' : ''}`}
                                    disabled={disabledButton === plan?.id}
                                >
                                    Subscribe
                                </button>
                                <div className="tw-mt-[24px]">
                                    {plan?.facility?.map((feature, featureIndex) => (
                                        <div key={featureIndex} className="tw-flex tw-mt-4">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" fill="#4853E4" />
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M22 4L12 14.01L9 11.01" fill="#4853E4" />
                                                    <path d="M22 4L12 14.01L9 11.01" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div
                                                className={`tw-ml-4 tw-font-inter tw-text-[13px] tw-text-[#252422] ${featureIndex === 0 ? "tw-font-[700]" : "tw-font-[400]"
                                                    }`}
                                            >
                                                {feature}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </section >
            <div className="tw-pt-5 tw-pb-[100px] tw-text-[#252422] tw-text-13px] tw-font-[400] tw-text-center tw-font-inter">
                For institutional inquiries, please reach out to us at admin@re-loan.com.
            </div>
        </div >
    )
}
export default Subscription;