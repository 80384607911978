// Function to convert a string to camel case
export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
};
  
// Function to format a date to a readable string
export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const authLocal = {
    get:()=>localStorage.getItem('user_auth')?JSON.parse(localStorage.getItem('user_auth')):null,
    set:(data)=>localStorage.setItem('user_auth',JSON.stringify(data)),
    remove:()=>localStorage.removeItem('user_auth')
}