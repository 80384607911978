import React, { useState, useContext, useReducer, useEffect } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../contexts/UserContext';
import { Input } from '@material-tailwind/react';
import { authLocal } from '../../../../utils/common';
import { useLoader } from '../../../../contexts/LoaderContext';
import { GoogleMapsContext } from '../../../../contexts/GoogleMapsContext';
import { useApiService } from '../../../../services/apiService';

const initialState = {
    loanPurpose: '',
    address: '',
    propertyValue: '',
    loanToValue: ''
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD_VALUE':
            return { ...state, [action.field]: action.value };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
}

function NewApplicationModal({ open, handleClose, onNewApplication }) {
    const { isLoaded, autocomplete } = useContext(GoogleMapsContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const { loanPurpose, address, propertyValue, loanToValue } = state;
    const { showLoader, hideLoader } = useLoader();
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();

    const handleChange = (e) => {
        dispatch({ type: 'SET_FIELD_VALUE', field: e.target.name, value: e.target.value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!loanPurpose) newErrors.loanPurpose = "Loan Purpose is required.";
        if (!address) newErrors.address = "Property Address is required.";
        if (!propertyValue) newErrors.propertyValue = "Property Value is required.";
        if (!loanToValue) newErrors.loanToValue = "Desired Loan to Value is required.";
        
        setErrors(newErrors);
        
        // If there are no errors, return true
        return Object.keys(newErrors).length === 0;
    };

    const createBorrowerLoanApplication = async () => {
        showLoader();
        try {
            const response = await postRequest(`/application/create`, {
                userId: user.id,
                property_address: address,
                property_value: propertyValue,
                loan_purpose: loanPurpose,
                desired_loan_to_value: loanToValue
            });

            if (response.success) {
                toast.success("Application Submitted Successfully.");
                onNewApplication();
                handleModalClose();
            } else {
                toast.error("Application Submission Failed.");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error Submitting the Loan Application.';
            toast.error(errorMessage);
            console.error('Error:', errorMessage);
        } finally {
            hideLoader();
        }
    };

    const getAQuote = async () => {
        setFormSubmitted(true);
        if (!validateForm()) return;

        const loggedIn = authLocal.get();
        if (loggedIn.user_type === 'borrower') {
            await createBorrowerLoanApplication();
        } else {
            toast.error("Unauthorized user type.");
        }
    };

    const handleModalClose = () => {
        dispatch({ type: 'RESET' });
        setFormSubmitted(false);
        setErrors({});
        handleClose();
    };

    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    // useEffect(() => {
    //     // If autocomplete is available, set place changed listener
    //     if (autocomplete) {
    //         const listener = autocomplete.addListener('place_changed', () => {
    //             const place = autocomplete.getPlace();
    //             if (place && place.formatted_address) {
    //                 dispatch({ type: 'SET_FIELD_VALUE', field: 'address', value: place.formatted_address });
    //             }
    //         });

    //         // Cleanup listener on unmount
    //         return () => {
    //             window.google.maps.event.removeListener(listener);
    //         };
    //     }
    // }, [autocomplete]);

    return (
        <Modal open={open} onClose={handleModalClose} aria-labelledby="modal-title">
            <Box className="tw-modal-container">
                <div className='tw-flex tw-justify-end'>
                    <Typography id="modal-title" className="tw-modal-title">New Application</Typography>
                </div>
                <div className='tw-mt-[20px]'>

                    <label htmlFor="loanPurpose" className="form-label">
                        Loan Purpose <span className="text-red-500">*</span>
                    </label>
                    <select className={` ${formSubmitted && !loanPurpose ? 'tw-border tw-px-3 tw-py-2 tw-border-gray-400 tw-w-full tw-h-full tw-rounded-16 tw-text-black tw-border-red-500' : 'tw-select'}`} name="loanPurpose" value={loanPurpose} onChange={handleChange}>
                        <option value="" disabled>- Select -</option>
                        <option value="PURCHASE">PURCHASE</option>
                        <option value="REFINANCE">REFINANCE</option>
                        <option value="CASH-OUT REFINANCE">CASH-OUT REFINANCE</option>
                        <option value="REHAB">REHAB</option>
                        <option value="CONSTRUCTION">CONSTRUCTION</option>
                    </select>
                    {errors.loanPurpose && <p className="tw-text-red-500 tw-text-xs">{errors.loanPurpose}</p>} {/* Show error */}

                    <label htmlFor="address" className="form-label ">
                        Property Address <span className="text-red-500">*</span>
                    </label>
                    <Input
                        id="address"
                        name="address"
                        type="text"
                        value={address}
                        onChange={handleChange}
                        className={`tw-border tw-p-3 tw-border-gray-400 tw-w-full tw-h-full tw-rounded-16 tw-text-black ${formSubmitted && !address ? 'tw-border-red-500' : ''}`}
                        disabled={!isLoaded}
                    />
                    {errors.address && <p className="tw-text-red-500 tw-text-xs">{errors.address}</p>} {/* Show error */}

                    <label htmlFor="propertyValue" className="form-label ">
                        Property Value <span className="text-red-500">*</span>
                    </label>
                    <Input
                        id="propertyValue"
                        name="propertyValue"
                        type="number"
                        value={propertyValue}
                        onChange={handleChange}
                        className={`tw-border tw-p-3 tw-border-gray-400 tw-w-full tw-h-full tw-rounded-16 tw-text-black ${formSubmitted && !propertyValue ? 'tw-border-red-500' : ''}`}
                    />
                    {errors.propertyValue && <p className="tw-text-red-500 tw-text-xs">{errors.propertyValue}</p>} {/* Show error */}

                    <label htmlFor="loanToValue" className="form-label ">
                        Desired Loan to Value <span className="text-red-500">*</span>
                    </label>
                    <Input
                        id="loanToValue"
                        name="loanToValue"
                        type="number"
                        value={loanToValue}
                        onChange={handleChange}
                        className={`tw-border tw-p-3 tw-border-gray-400 tw-w-full tw-h-full tw-rounded-16 tw-text-black ${formSubmitted && !loanToValue ? 'tw-border-red-500' : ''}`}
                    />
                    {errors.loanToValue && <p className="tw-text-red-500 tw-text-xs">{errors.loanToValue}</p>} {/* Show error */}

                    <div className="results tw-font-oswald tw-flex tw-flex-col tw-space-y-0">
                        <h3 className='tw-text-[#000000] tw-text-xl'>
                            Loan Amount: ${formatNumber(Math.round((propertyValue * loanToValue) / 1000))}
                        </h3>
                        <p className='tw-text-[#000000]'>Potential Programs Available: 0</p>
                        <p className='tw-text-[#000000]'>Estimated Rate: 0%</p>
                        <p className='tw-text-[#000000]'>Potential Lenders: 0</p>
                    </div>

                    <div className="loan-amount" style={{ marginTop: '20px' }}>
                        <Button variant="contained" onClick={getAQuote} className="tw-modal-button">
                            GET A QUOTE
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default NewApplicationModal;
