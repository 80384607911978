import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import ApplicationTableHeader from './ApplicationTableHeader';
import { Typography } from '@mui/material';

function ApplicationTable({
    columns,
    rows,
    selectedRow,
    dataTableProps = {},
    selectedRowId,
    onRowClick,
    tableHeight = "100%",
    useAutoHeight = false,
    CustomHeaderComponent,
    customHeaderProps,
    onReady, // Callback to signal the parent when the component is ready
}) {
    const NoRowsOverlay = () => (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                overflow: 'auto',
                height: tableHeight,
            }}
        >
            <Typography variant="body1" color="textSecondary">
                No records found
            </Typography>
        </Box>
    );

    useEffect(() => {
        // Notify the parent that the ApplicationTable has completed rendering
        if (onReady) {
            onReady();
        }
    }, [onReady]);

    return (
        <div className="tw-h-full tw-w-full tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-flex tw-flex-col">
            <style>
                {`
                    .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon {
                        display: none;
                    }
                `}
            </style>
            {CustomHeaderComponent ? (
                <CustomHeaderComponent {...customHeaderProps} />
            ) : (
                <ApplicationTableHeader
                    dataHeaderProps={{
                        title: dataTableProps?.title,
                        filter: dataTableProps?.userStatus,
                        dashboardType: dataTableProps?.dashboardType,
                        handleSortChange: dataTableProps?.handleSortChange,
                        handleItemClick: dataTableProps?.handleItemClick || '',
                        setFilters: dataTableProps?.setFilters,
                    }}
                />
            )}
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    overflow: 'auto',
                    height: tableHeight,
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight={useAutoHeight}
                    getRowHeight={() => 'auto'}
                    onRowClick={(row) => onRowClick(row?.row)}
                    getRowId={(row) => row?.id}
                    hideSortIcons={false}
                    pagination // Enable default pagination
                    pageSizeOptions={[10, 20, 50, 100]} // Default page size options
                    rowSelection={false}
                    hideFooterPagination={true}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 20 },
                        },
                        sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
                    }}
                    components={{
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderRight: '1px solid #DDDDDD',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: "10px 5px",
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        boxShadow: 2,
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: '1px solid #DDDDDD', // Add top border
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                        },
                        '.MuiDataGrid-sortIcon': {
                            opacity: 'inherit !important',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.row.application_id === selectedRowId ? 'selected-row' : ''
                    }
                    rowSelectionModel={[selectedRow]}
                    disableRowSelectionOnClick
                    {...dataTableProps}
                />
            </Box>
        </div>
    );
}

export default ApplicationTable;
