import React, { useContext, useEffect, useState } from "react";
import ApplicationTable from "../../common/applicationTable/ApplicationTable";
import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';
import { Link } from "react-router-dom";
import axios from 'axios';
import { formatPrice, capitalizeWords } from '../../../utils/common';
import { useApiService } from "../../../services/apiService";
import PageLoader, { usePageLoader } from "../../../contexts/PageLoaderContext";
import toast from "react-hot-toast";


const PaymentList = () => {
  const { user } = useContext(UserContext);
  const [paymentList, setPaymentList] = useState([]);
  const { showLoader, hideLoader } = useLoader();
  const { getRequest, postRequest } = useApiService();
  const { showPageLoader, hidePageLoader } = usePageLoader();
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    const initializePage = async () => {
      showPageLoader(); // Show the loader
      try {
        await fetchPayments();
      } catch (error) {
        toast.error("Failed to initialize the page.");
      } finally {
        setIsPageReady(true); // Set page ready
      }
    };

    initializePage();
  }, []);


  const fetchPayments = async () => {
    try {
      const response = await postRequest(`/payment/list`, { user_id: user?.id }); // Replace with your API endpoint
      const data = response.data;
      setPaymentList(data.payments);

    } catch (error) {
      console.error('Error fetching payment data:', error);
    } 
  };

  useEffect(() => {
    fetchPayments();
  }, []);

   useEffect(() => {
          if (isPageReady) {
              setTimeout(() => {
                  hidePageLoader();
              }, 500); // Add a small delay before hiding the page loader
          }
      }, [isPageReady]);



  const columns = [
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "user_name",
      headerName: "Lender Name",
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    // { field: 'customer_id', headerName: 'Customer ID', width: 200, editable: false, headerAlign: 'center' },
    // { field: 'invoice_id', headerName: 'Invoice', width: 200, editable: false, headerAlign: 'center' },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "amount_total",
      headerName: "Total Amount ($)",
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    // { field: 'discount_amount', headerName: 'Discount Amount', width: 150, editable: false, headerAlign: 'center' },
    {
      field: "discount_code",
      headerName: "Discount Code",
      width: 150,
      editable: false,
      headerAlign: "center",
    },

    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 200,
      editable: false,
      headerAlign: "center",
    },
  ];
  const rows = paymentList?.length
    ? paymentList?.map((app) => ({
        id: app?.id,
        created_at: new Date(app?.created_at).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit", 
          minute: "2-digit",
        }),
        user_name: app?.user_name,
        // customer_id: app?.customer_id,
        // invoice_id: app?.invoice_id,
        product_name: app.product_name,
        amount_total: app?.amount_total
          ? formatPrice(app?.amount_total / 100)
          : 0,
        currency: app.currency ? capitalizeWords(app.currency) : "",
        payment_status: app.payment_status
          ? capitalizeWords(app.payment_status)
          : "",
        // discount_amount: app?.discount_amount ? app?.discount_amount : '',
        discount_code: app?.discount_code ? app?.discount_code : "",
      }))
    : [];

  return (
    <div className="tw-w-full tw-h-full tw-my-[12px]">
      {!isPageReady && <PageLoader />}
      <div className="tw-h-[60px] tw-flex tw-items-center tw-gap-2">
        <Link to="/dashboard/lender">
          <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
        </Link>
        <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">
          {" "}
          Payment List
        </h1>
      </div>
      <div className="tw-w-full tw-h-full tw-mt-[20px]">
        <ApplicationTable
          dataTableProps={{
            hideFooterPagination: false,
            getRowId: (row) => row.id, // Use the unique id from your data
            userStatus: [""],
            getRowClassName: (params) =>
              params?.row?.id === null ? "selected-row" : "",
          }}
          rows={rows}
          columns={columns}
          onRowClick={() => { }}
          height="400px"
        />
      </div>
    </div>
  );
}
export default PaymentList;