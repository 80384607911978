import React from 'react'
import { Chart } from 'react-charts';

function AssetGenerated() {
    // Data for the line chart (first section)
    const lineChartData = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: [
                    [100, 0],
                    [200, 50],
                    [300, 100],
                    [400, 150],
                ],
            },
        ],
        []
    );

    const lineChartAxes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom', label: 'Price ($)', hardMin: 100, hardMax: 400 },
            { type: 'linear', position: 'left', label: 'Percentage (%)', hardMin: 0, hardMax: 150 },
        ],
        []
    );

    // Data for the bar chart (second section)
    const barChartData = [
        {
            label: 'Offers',
            data: [
                { x: 2000, y: 2 }, // 2 offers for $2,000
                { x: 3000, y: 0 }, // 0 offers for $3,000
                { x: 4000, y: 3 }, // 3 offers for $4,000
                { x: 5000, y: 5 }, // 3 offers for $5,000
                { x: 6000, y: 4 },
            ]
        }
    ];

    const barChartAxes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom', label: 'Loan Type' }, // X-axis with categories
            { type: 'linear', position: 'left', label: 'Amount (k)', hardMin: 0, hardMax: 4000 }, // Y-axis with values
        ],
        []
    );

    const organizationData = [
        {
            label: 'Organization',
            data: [
                { x: 2000, y: 5 },
                { x: 3000, y: 3 },
                { x: 4000, y: 8 },
                { x: 5000, y: 6.5 },
                { x: 6000, y: 5 },
                { x: 7000, y: 2 },
                { x: 8000, y: 9.5 },
                { x: 9000, y: 7 },
                { x: 10000, y: 6 },
                { x: 11000, y: 5.5 },
                { x: 12000, y: 4.5 },
            ]
        }
    ];

    const annualInterestData = [
        {
            label: 'Annual Interest',
            data: [
                { x: 2000, y: 2.5 },
                { x: 3000, y: 1.4 },
                { x: 4000, y: 3.5 },
                { x: 5000, y: 6.5 },
                { x: 6000, y: 5 },
                { x: 7000, y: 2 },
                { x: 8000, y: 9.5 },
            ]
        }
    ];


    const lengthOfLoanData = [
        {
            label: 'Length of Loan',
            data: [
                { x: 2000, y: 4 },
                { x: 3000, y: 7 },
                { x: 4000, y: 5 },
                { x: 5000, y: 4.5 },
                { x: 6000, y: 8 },
                { x: 7000, y: 6 },
                { x: 8000, y: 3.5 },
            ]
        }
    ];


    return (
        <div className='tw-flex tw-flex-col tw-space-y-3'>
            {/* First Chart Section (Bar Chart) */}
            <div className='tw-h-[300px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-6 tw-py-2 tw-mr-4'>
                {/* Title and Value */}
                <div className='tw-w-full tw-flex tw-justify-between tw-items-center'>
                    <h2 className='tw-text-[#202224] tw-text-[22px] tw-font-semibold tw-common-font'>
                        Origination Fee
                    </h2>
                    <h1 className='tw-text-[#242424] tw-text-[36px] tw-font-semibold'>
                        {/* 240% */}
                    </h1>
                </div>
                {/* Organization Line Chart */}
                <div className='tw-h-[225px] tw-mt-[20px]'>
                    <Chart
                        data={organizationData}
                        axes={[
                            {
                                primary: true,
                                type: 'ordinal', // Ensures all data points are treated as categories
                                position: 'bottom',
                                label: 'Amount ($)',
                                showGrid: false,
                                innerPadding: 0.7,
                                format: d => `$${d.toLocaleString()}`,
                            },
                            {
                                type: 'linear',
                                position: 'left',
                                label: '# of Offers',
                                showGrid: true,
                                hardMin: 0, // Ensures Y-axis starts at 0
                            }
                        ]}
                        series={{ type: 'bar' }}
                        getSeriesStyle={() => ({
                            color: '#555FE1',
                            width: 30, // Adjust width as needed
                        })}
                        getDatumStyle={() => ({
                            color: '#555FE1',
                        })}
                    />
                </div>
            </div>

            {/* Second Section (Bar Chart) */}
            <div className='tw-h-[300px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-6 tw-py-2 tw-mr-4'>
                {/* Title and Chart Container */}
                <div className='tw-w-full tw-flex tw-justify-between tw-items-center'>
                    <h2 className='tw-text-[#202224] tw-text-[22px] tw-font-semibold tw-common-font'>
                        Annual Interest
                    </h2>
                    <h1 className='tw-text-[#242424] tw-text-[36px] tw-font-semibold'>
                        {/* 240% */}
                    </h1>
                </div>
                    {/* Annual interest Bar Chart Container */}
                    <div className='tw-h-[225px] tw-mt-[20px]'>
                        <Chart
                            data={annualInterestData}
                            axes={[
                                {
                                    primary: true,
                                    type: 'ordinal',
                                    position: 'bottom',
                                    label: 'Amount ($)',
                                    showGrid: false,
                                    innerPadding: 0.6,
                                    format: d => `$${d.toLocaleString()}`,
                                },
                                {
                                    type: 'linear',
                                    position: 'left',
                                    label: '# of Offers',
                                    showGrid: true,
                                    hardMin: 0, // Ensures Y-axis starts at 0
                                }
                            ]}
                            series={{ type: 'bar' }} // Specify bar chart
                            getSeriesStyle={() => ({
                                color: '#555FE1', // Set your bar color here
                                width: 30, // You can adjust this value for bar width
                            })}
                            getDatumStyle={() => ({
                                color: '#555FE1', // Set color for individual bars (optional)
                            })}
                            options={{
                                backgroundColor: '#f0f4f8',
                                grid: {
                                    show: false, // Hide grid lines
                                },
                                series: {
                                    showPoints: false, // No points for bar chart
                                },
                            }}
                        />
                    </div>
                </div>

            {/* Third Section (Bar Chart) */}
            <div className='tw-h-[300px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-6 tw-py-2 tw-mr-4'>
                {/* Title and Chart Container */}
                {/* Title at the top */}
                <div className='tw-w-full tw-flex tw-justify-between tw-items-center'>
                    <h2 className='tw-text-[#202224] tw-text-[22px] tw-font-semibold tw-common-font'>
                        Length Of Loan
                    </h2>
                </div>
                {/* Length of loan Bar Chart Container */}
                <div className='tw-h-[225px] tw-mt-[20px]'>
                    <Chart
                        data={lengthOfLoanData}
                        axes={[
                            {
                                primary: true,
                                type: 'ordinal',
                                position: 'bottom',
                                label: 'Amount ($)',
                                showGrid: false,
                                innerPadding: 0.6,
                                format: d => `$${d.toLocaleString()}`,
                            },
                            {
                                type: 'linear',
                                position: 'left',
                                label: '# of Offers',
                                showGrid: true,
                                hardMin: 0,
                            }
                        ]}
                        series={{ type: 'bar' }} // Specify bar chart
                        getSeriesStyle={() => ({
                            color: '#555FE1', // Set your bar color here
                            width: 30, // You can adjust this value for bar width
                        })}
                        getDatumStyle={() => ({
                            color: '#555FE1', // Set color for individual bars (optional)
                        })}
                        options={{
                            backgroundColor: '#f0f4f8',
                            grid: {
                                show: false, // Hide grid lines
                            },
                            series: {
                                showPoints: false, // No points for bar chart
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default AssetGenerated;
