import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import AdminSettings from "./AdminSettings";
import BorrowerSettings from "./BorrowerSettings";
import LenderSettings from "./LenderSettings";
import { UserContext } from "../../contexts/UserContext";
import { useUserType } from "../../contexts/UserTypeContext";

const Settings = () => {
    // Get the userType from the URL
    const { user } = useContext(UserContext);
    switch (user?.user_type) {
        case 'admin':
            return <AdminSettings />;
        case 'borrower':
            return <BorrowerSettings />;
        case 'lender':
            return <LenderSettings />;
        default:
            return <div>404 - Page Not Found</div>;
    }
}

export default Settings;