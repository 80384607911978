import React from 'react';
import { Chart } from 'react-charts';

function AreaInterest() {
    // Sample data for the linear chart
    const data = React.useMemo(
        () => [
            {
                label: 'Area Interest Rate',
                data: [
                    [0, 5],
                    [1, 10],
                    [2, 15],
                    [3, 20],
                    [4, 25],
                    [5, 15],
                    [6, 10],
                    [7, 20],
                    [8, 30],
                    [9, 25],
                ],
            },
        ],
        []
    );

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'left' },
            { type: 'linear', position: 'bottom' },
        ],
        []
    );

    return (
        <div className='tw-w-full tw-h-[606px] tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-flex tw-justify-center tw-items-center'>
            <div className='tw-w-full tw-rounded-[10px] tw-px-[20px]'>
                <div className='tw-w-full tw-h-[119px]'>
                    <h1 className='common-font tw-font-bold tw-text-[28px] tw-text-[#202224]'>Area Interest Rate</h1>
                    <div className='tw-h-[69px] tw-mt-[10px]'>
                        <div className='tw-flex tw-space-x-4'>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>City</div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="purchase">Columbus</option>
                                        <option value="refinance">Cleveland</option>
                                        <option value="investment">Dayton</option>
                                        <option value="construction">Lorain</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>State</div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="purchase">Ohio</option>
                                        <option value="refinance">North Carolina</option>
                                        <option value="investment">Arizona</option>
                                        <option value="construction">New York</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>Area</div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="purchase">Ohio</option>
                                        <option value="refinance">North Carolina</option>
                                        <option value="investment">Arizona</option>
                                        <option value="construction">New York</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>Date</div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'>
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="purchase">10Km</option>
                                        <option value="refinance">50Km</option>
                                        <option value="investment">100Km</option>
                                        <option value="construction">150Km</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tw-mt-[22px] tw-border tw-rounded-[10px]'>
                    <div className='tw-px-[20px]'>
                        <div className=' tw-rounded-[10px]  tw-flex tw-justify-between tw-items-center'>
                            <h1 className='tw-text-[#444444] tw-font-bold tw-text-[22px]'>AIR %</h1>
                            <h1 className='tw-text-[#444444] tw-font-semibold tw-text-[36px]'>13%</h1>
                        </div>
                        <div className='tw-w-full tw-h-[289px] tw-mt-[22px]'>
                            {/* Linear Chart */}
                            <div className='tw-h-full'>
                                <Chart data={data} axes={axes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaInterest;
