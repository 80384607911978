import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import COMREPUSdata from "./jsonData/COMREPUS.json";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: "#fff", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }}>
        <p className="label" style={{ margin: 0}}>{new Date(label).toLocaleDateString("en-US", { month: "short", year: "numeric" })}</p>
        <p className="value" style={{ margin: 0, color: "#555FE1" }}>{`${parseFloat(payload[0].value).toFixed(4)}%`}</p>
      </div>
    );
  }
  return null;
};


export default function COMREPUS() {
  return (
    <ResponsiveContainer width="100%" height={267}>
      <AreaChart
        width={500}
        height={267}
        data={COMREPUSdata}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="observation_date"
          interval={13}
          tickMargin={12}
          tick={{ fontSize: "12px" }}
          tickFormatter={(tick) =>
            new Date(tick).toLocaleDateString("en-US", { year: "numeric" })
          }
        />
        <YAxis 
        tick={{ fontSize: "12px" }}
        tickFormatter={(tick) => `${tick}`} 
        label={{
          value: "Percent Change From Year Ago", // Label text
          angle: -90, // Rotates it vertically
          position: "insideLeft", // Positions it inside the chart
          style: { textAnchor: "middle", fontSize: "12px", fill: "#666" }, // Style adjustments
        }}
        />
        
        <Tooltip content={<CustomTooltip />} />


        <Area
          type="monotone"
          dataKey="COMREPUS"
          stroke="#555FE1"
          strokeWidth={4}
          fill="blue"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
