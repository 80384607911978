// Function to convert a string to camel case
export const capitalizeWords = (str) => {
    return str
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to format a date to a readable string
export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const authLocal = {
    get: () => localStorage.getItem('user_auth') ? JSON.parse(localStorage.getItem('user_auth')) : null,
    set: (data) => localStorage.setItem('user_auth', JSON.stringify(data)),
    remove: () => localStorage.removeItem('user_auth')
}

// function to get age of application
export const formatTimeDifference = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);

    if (diffInSeconds < 60) {
        return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
};

export function formatPrice(price) {
    // Ensure the price is a number
    const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(parseFloat(price));

    return formattedPrice;
}

//for Borrower Dashboard Status
export function getApplicationStatus(statusId, userType) {
    let badgeColor = '#E0E0E0'; // Default color
    let badgeChild = '#BDBDBD'; // Default child color
    let statusText = 'Unknown'; // Default text

    // Determine status details based on statusId and userType
    switch (statusId) {
        case 1: // No Offers
            badgeColor = '#E0E0E0';
            badgeChild = '#BDBDBD';
            userType === "borrower" ? statusText = 'No Offers' : statusText = 'No Offers';
            break;
        case 2: // Unread Offers / Offer Sent
            badgeColor = '#FFD7002E';
            badgeChild = '#FFD700';
            userType === "borrower" ? statusText = 'Unread Offers' : statusText = 'Offer Sent';
            break;
        case 3: // Offers / Offer Sent
            badgeColor = '#00C9A72E';
            badgeChild = '#00C9A7';
            userType === "borrower" ? statusText = 'Offers' : statusText = 'Offer Sent';
            break;
        case 4: // Pending / Offer Accepted
            badgeColor = '#4CAF502E';
            badgeChild = '#4CAF50';
            userType === "borrower" ? statusText = 'Pending' : statusText = 'Offer Accepted';
            break;
        case 5: // Settled
            badgeColor = '#9E9E9E2E';
            badgeChild = '#9E9E9E';
            statusText = 'Settled';
            break;
        case 6: // Archived
            badgeColor = '#9E9E9E2E';
            badgeChild = '#9E9E9E';
            statusText = 'Archived';
            break;
        default: // Unknown Status
            statusText = 'Unknown';
    }

    return {
        badgeColor,
        badgeChild,
        statusText,
        render: () => (
            <div
                className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full"
                style={{ backgroundColor: badgeColor }}
            >
                <span
                    className="tw-w-[10px] tw-h-[10px] tw-border tw-border-[#DDDDDD] tw-rounded-full"
                    style={{ backgroundColor: badgeChild }}
                ></span>
                <span className="common-font tw-font-normal tw-text-[14px] tw-text-[#070101CC] tw-ml-2">
                    {statusText}
                </span>
            </div>
        ),
    };
}


//for Lender Dashboard Status
export function getApplicationOfferStatus(statusText) {
    let badgeColor = '#E0E0E0'; // Default color
    let badgeChild = '#BDBDBD'; // Default child color


    switch (statusText) {
        case "No Offer": // No Offers
            badgeColor = '#E0E0E0';
            badgeChild = '#BDBDBD';
            break;
        case "Offer Sent": // Unread Offers / Offer Sent
            badgeColor = '#FFD7002E';
            badgeChild = '#FFD700';
            break;
        case "Other Offer Accepted": // Offers / Offer Sent
            badgeColor = '#f7b2b3';
            badgeChild = '#f04648';
            break;
        case "Offer Accepted": // Pending / Offer Accepted
            badgeColor = '#4CAF502E';
            badgeChild = '#4CAF50';
            break;
        case "Archived": // Archived
            badgeColor = '#9E9E9E2E';
            badgeChild = '#9E9E9E';
            break;
        default: // Unknown Status
            statusText = 'No Offer';
    }

    return {
        badgeColor,
        badgeChild,
        statusText,
        render: () => (
            <div
                className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full"
                style={{ backgroundColor: badgeColor }}
            >
                <span
                    className="tw-w-[10px] tw-h-[10px] tw-border tw-border-[#DDDDDD] tw-rounded-full"
                    style={{ backgroundColor: badgeChild }}
                ></span>
                <span className="common-font tw-font-normal tw-text-nowrap tw-text-[14px] tw-text-[#070101CC] tw-ml-2">
                    {statusText === "Other Offer Accepted" ? "Other Offer Accepted" : statusText}
                </span>
            </div>
        ),
    };

}


export const initialUser = JSON.parse(localStorage.getItem("signUpUser")) || null;

export const saveUserToLocalStorage = (userData) => {
    localStorage.setItem("signUpUser", JSON.stringify(userData));
};

export const removeUserFromLocalStorage = () => {
    localStorage.removeItem("signUpUser");
};


export const savedUserType = localStorage.getItem('userType') || "borrower";

export const saveUserTypeToLocalStorage = (newUserType) => {
    localStorage.setItem('userType', newUserType);
};

export const removeUserTypeToLocalStorage = () => {
    localStorage.removeItem("userType");
};

export const getMembershipDates = (type) => {
    const startDate = new Date(); // Current date
    const endDate = new Date(startDate); // Clone startDate

    if (type === "monthly") {
        endDate.setMonth(endDate.getMonth() + 1); // Add 1 month
    } else if (type === "yearly") {
        endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year
    } else if (type === "weekly") {
        endDate.setDate(endDate.getDate() + 7); // Add 7 days
    } else {
        endDate.setMonth(endDate.getMonth() + 1); // Add 1 month
    }

    return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
    };
};