// src/components/common/StatsBoxes.js
import React from 'react';
import IcTrendingUp from "../../assets/icons/ic-trending-up.png.png";

const StatsCards = ({ title, value, change, icon }) => {
    const parseValue = (value) => {
        if (typeof value === "string") {
            // Remove $ and commas, then parse as a float
            const numericValue = parseFloat(value.replace(/[$,]/g, ""));
            return isNaN(numericValue) ? 0 : numericValue;
        }
        return value; // Return as-is if it's already a number
    };
    return (
        <>
            <div className="tw-flex tw-flex-col tw-items-center tw-p-[12px] tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-gap-[8px] tw-w-[25%]">
                <div className='tw-flex tw-justify-between tw-items-center'>
                    <div className="tw-flex tw-items-start tw-w-[60px]">
                        <img src={icon} alt="State-icon" className="tw-w-[36.67px] tw-h-[36.67px] tw-border-solid tw-border-[#555FE1]" />
                    </div>
                    <div className='tw-gap-[16px] tw-flex'>
                        <div className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224] tw-text-nowrap tw-flex tw-items-center"><span>{title}</span></div>
                        <div
                            className={`common-font tw-font-semibold tw-leading-[38.19px] tw-tracking-[1px] tw-text-[#202224]   
                            tx-text-[16px]`}
                        >
                            {value}
                        </div>
                    </div>
                </div>
                {change !== null && (
                    <div className='tw-flex tw-items-center tw-space-x-2 common-font tw-font-semibold tw-text-[16px] tw-leading-[21.82px]'>
                        <img src={IcTrendingUp} alt="trending-up-icon" className="tw-w-auto tw-h-auto" />

                        <p className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224]">
                            <span className='tw-text-[#00B69B] tw-me-[5px]'>{change}%</span>
                            {change >= 0 ? 'Up from last week' : 'Down from last week'}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default StatsCards;
