import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext';
import toast from 'react-hot-toast';
import { useLoader } from '../../../contexts/LoaderContext';
import OfferDetailModal from './OfferDetailModal';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import ChatFooter from './ChatFooter';
import { useApiService } from '../../../services/apiService';
import { uploadData } from 'aws-amplify/storage';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';


function ChatWindow({ chatUserName, chatMessages, getConversationList, fetchChatMessages, showTypingIndicator, setShowTypingIndicator, setOpenSendOfferModal, fetchUserLoanApplications, setSelectedAppId, application_id, rowStatus }) {
    const { user } = useContext(UserContext);
    const [message, setMessage] = useState("");
    const [offerDetails, setOfferDetails] = useState(null); // State to store offer details
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusUpadateConverstationId, setStatusUpadateConverstationId] = useState('');
    const { getRequest, postRequest, putRequest } = useApiService();
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    // ? State for attachments for ChatFooter
    const [attachList, setAttachList] = useState([]);
    const imageFileUpload = useRef(null);
    const docFileUpload = useRef(null);


    // State to track loading or error
    const [isSending, setIsSending] = useState(false);
    const { showLoader, hideLoader } = useLoader();
    const chatContainerRef = useRef(null);
    // API call to send the message
    const scrollToBottom = () => {
        if (!chatContainerRef.current) return; // Exit if the ref is null
        setTimeout(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTo({
                    top: chatContainerRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };

      

    const sendMessage = async () => {
        if (message.trim() === "" && attachList.length < 1) return;
        
        if (user?.user_type === 'lender' && chatMessages.every(msg => msg.is_offer !== 1)) {
            toast.error("Please send an offer before messaging.");
            return;
          }

        const attachmentPaths = [];
    
        // Upload all attachments before sending the message
        if (attachList.length >= 1) {
            for (const file of attachList) {
                const fileBaseName = file.name.split(".").slice(0, -1).join(".").replace(/\s+/g, "_");
                const fileExtension = file.name.split(".").pop();
                const timestamp = Date.now();
                const uniqueFileName = `chat-attachments/${fileBaseName}_${timestamp}.${fileExtension}`;
    
                try {
                    const result = await uploadData({
                        path: uniqueFileName,
                        data: file,
                        options: {
                            level: "public",
                            contentType: file.type,
                        },
                    }).result;
    
                    if (result) {
                        attachmentPaths.push(uniqueFileName); // Collect all uploaded images
                    }
                } catch (error) {
                    console.error("Error uploading file:", error);
                }
            }
        }
    
        console.log("Final attachment paths:", attachmentPaths); // Debugging output
    
        // Send all uploaded image paths in a single message
        const newMessage = {
            application_id: application_id || chatMessages[0]?.application_id,
            conversation_id: chatMessages[0]?.conversation_id,
            sender_id: user?.id,
            message_text: message,
            attachments: attachmentPaths, //  Ensure all attachments are included
        };
    
        console.log("Message payload before sending:", newMessage); // Debugging output
    

        const updatedMessages = [
            ...chatMessages,
            {
                ...newMessage,
                id: `temp-${Date.now()}`,
                createdAt: new Date().toISOString(),
            },
        ];
    
        setMessage(""); //  Clear input
        setAttachList([]); //  Clear attachments
        if (imageFileUpload.current) imageFileUpload.current.value = ""; //  Reset image input
        if (docFileUpload.current) docFileUpload.current.value = ""; //  Reset document input
    
        setShowTypingIndicator(true);
        setIsSending(true);

        getConversationList();
    
        // Update UI immediately
        fetchChatMessages(chatMessages[0]?.conversation_id, updatedMessages);
        scrollToBottom();
    
        try {
            const response = await postRequest(`/message/send`, newMessage);
            if (response?.success) {
                getConversationList();
                fetchChatMessages(chatMessages[0]?.conversation_id);
                scrollToBottom();
            } else {
                toast.error("Failed to send message");
            }
        } catch (error) {
            toast.error("Error sending message");
        } finally {
            setIsSending(false);
            setShowTypingIndicator(false);
        }
    };
    

    // Scroll to bottom when chatMessages or typing indicator changes
    useEffect(() => {
        scrollToBottom();
    }, [chatMessages, showTypingIndicator]);

    const handleViewOfferDetails = async (offerId, conversation_id) => {
        setStatusUpadateConverstationId(conversation_id);
        showLoader();
        try {
            const response = await getRequest(`/application-offer/get/${offerId}`
            );
            if (response?.success) {
                setOfferDetails(response?.data);
                if (user?.user_type == "borrower") {
                    await putRequest(`/application-offer/mark-read/${offerId}`);
                    fetchUserLoanApplications();
                    setSelectedAppId(application_id);
                }
                setIsModalOpen(true);
            } else {
                setOfferDetails([]);
                toast.error('Something went wrong.');
            }
        } catch (err) {
            console.log("err:", err)
            toast.error(err?.response?.data?.error);
        } finally {
            hideLoader();
        }
    };

    const offerAcceptStatusHandler = async (application_offer_id, userType) => {
        showLoader();
        try {
            if (userType == 'borrower') {
                const response = await putRequest(`/application-offer/borrower-accept/${application_offer_id}`,
                    {
                        user_id: user?.id,
                        conversation_id: statusUpadateConverstationId,
                    }
                );
                if (response.success) {
                    fetchUserLoanApplications();
                    setSelectedAppId(application_id);
                    toast.success(response?.data?.message, 'success');
                } else {
                    toast.error('Something went wrong.');
                    setSelectedAppId('');
                }
            } else if (userType == "lender") {
                await putRequest(`/application-offer/lender-accept/${application_offer_id}`,
                    {
                        user_id: user?.id,
                        conversation_id: statusUpadateConverstationId,
                    }
                );
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
            setTimeout(() => {
                setIsModalOpen(false);
            }, 500);
        }
    };

    const offerRejectStatusHandler = async (application_offer_id, userType) => {
        showLoader();
        try {
            if (userType == 'borrower') {
                const response = await putRequest(`/application-offer/borrower-reject/${application_offer_id}`,
                    {
                        user_id: user?.id,
                        conversation_id: statusUpadateConverstationId,
                    }
                );
                if (response.success) {
                    fetchUserLoanApplications();
                    setSelectedAppId(application_id);
                    toast.success(response?.data?.message, 'success');
                } else {
                    toast.error('Something went wrong.');
                    setSelectedAppId('');
                }
            } else if (userType == "lender") {
                const response = await putRequest(`/application-offer/lender-reject/${application_offer_id}`,
                    {
                        user_id: user?.id,
                        conversation_id: statusUpadateConverstationId, // 45 
                    }
                );
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
            setTimeout(() => {
                setIsModalOpen(false);
            }, 500);
        }
    }

      

    return (
        <>
            <div style={{
                height: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
                zIndex: 0
            }} className={`${user.user_type === 'borrower' ? 'tw-chat-window' : 'tw-w-full'} tw-h-max`}>
                {/* Chat header for chat window */}
                <ChatHeader chatUserName={chatUserName} />
                <div style={{
                    height: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    overflow: "hidden"
                }} className='tw-chat-section tw-bg-[#FAFAFA] tw-rounded-b-[10px]'>
                    <div style={{
                        display: 'flex',
                        height: "100%",
                        flexDirection: "column",
                        overflow: "hidden"

                    }} className='tw-chat-container tw-h-[613px] tw-rounded-[10px]'>
                        <div ref={chatContainerRef} style={{
                            width: "100%",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto"
                        }}>
                            <ChatList
                                chatMessages={chatMessages}
                                handleViewOfferDetails={handleViewOfferDetails}
                                showTypingIndicator={showTypingIndicator}
                            />
                        </div>
                        {(user?.user_type == 'lender' || chatMessages.length > 0) && (
                            <ChatFooter
                                sendMessage={sendMessage}
                                message={message}
                                setMessage={setMessage}
                                isSending={isSending}
                                setShowTypingIndicator={setShowTypingIndicator}
                                setOpenSendOfferModal={setOpenSendOfferModal}
                                rowStatus={rowStatus}
                                attachList={attachList}
                                setAttachList={setAttachList}
                                imageFileUpload={imageFileUpload}
                                docFileUpload={docFileUpload}
                            />
                        )}
                    </div>
                </div>
            </div>
            <OfferDetailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                details={offerDetails}
                offerAcceptStatusHandler={offerAcceptStatusHandler}
                offerRejectStatusHandler={offerRejectStatusHandler}
            />
        </>
    )
}

export default ChatWindow