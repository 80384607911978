import HealthIconHighBarsIcon from "../../../assets/icons/healthicons_high-bars.svg";
import CommunicationIcon from "../../../assets/icons/communication.svg";
import UilProcessIcon from "../../../assets/icons/uil_process.svg";
import BorrowerSignupIcon from "../../../assets/icons/borrowerIcon.svg";
import AdvantageCard from "./AdvantageCard";
import GenericButton from "../../common/buttons/GenericButton";
import { Link } from "react-router-dom";
import { useUserType } from "../../../contexts/UserTypeContext";

const AdvantagesArea = ({ openSignupBorrowerModal, handleUserTypeChange }) => {
    const advantageCards = [{
        count: '01',
        icon: HealthIconHighBarsIcon,
        title: 'Transparent and Fair Costs',
        description: 'Re-Loan is committed to transparency by eliminating hidden fees and middlemen. Borrowers and lenders benefit from straightforward, flat-rate pricing that enhances trust and saves time.'
    },
    {
        count: '02',
        icon: UilProcessIcon,
        title: 'Seamless Process Automation',
        description: 'Our platform leverages AI to automate the loan journey — from application to funding decisions — enhancing efficiency and reducing complexities.'
    },
    {
        count: '03',
        icon: CommunicationIcon,
        title: 'Direct Communication',
        description: 'Re-Loan’s integrated chat allows borrowers and lenders to communicate without delays, fostering clear, real-time collaboration that simplifies the financing process.'
    }];

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-mx-20">
            <div className="tw-flex tw-justify-center tw-items-center">
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                <span className="tw-font-inter tw-px-2 tw-text-black tw-text-lg tw-font-normal tw-text-center">Features</span>
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
            </div>

            <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-my-4">The Re-Loan <span className="tw-text-[#4853e4]">Advantage</span></h2>

            <p className="tw-font-inter tw-text-[#404040] tw-text-lg tw-text-center tw-font-normal">Built to Streamline Real Estate Financing</p>

            <div className="tw-flex tw-my-10 tw-space-x-2">
                {
                    advantageCards.map(card => <AdvantageCard
                        key={card.count}
                        id={card.count}
                        count={card.count}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                    />)
                }
            </div>
            
            <div className="tw-flex tw-justify-center">
                <Link to={"/sign-up/borrower"}>
                    <GenericButton icon={BorrowerSignupIcon} title='Get A Quote'
                        onClick={() => handleUserTypeChange('borrower')}
                    />
                </Link>
            </div>
        </div>
    )
}

export default AdvantagesArea;
