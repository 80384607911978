import React, { useState } from 'react';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
    Input,
    List,
    ListItem
} from "@material-tailwind/react";

function ApplicationTableHeader({ dataHeaderProps }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenStatus, setIsOpenStatus] = useState(false);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

    const getDropdownVisibility = () => {
        switch (dataHeaderProps?.dashboardType) {
            case 'borrower':
                return [1, 2, 3];
            case 'lender':
                return [1];
            case 'application_management':
                return [1, 2];
            case 'user_management':
                return [1, 2];
            default:
                return [];
        }
    };
    const dropdownVisibility = getDropdownVisibility();

    const toggleDropdownStatus = () => {
        setIsOpenStatus(!isOpenStatus);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectChange = (e) => {
        // Prevent the default behavior of closing the menu
        e.stopPropagation();
        // Handle select option here
    };

    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            <>
                {dataHeaderProps?.title && dataHeaderProps?.title != '' ?
                    <>
                        <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'> {dataHeaderProps?.title}</Typography>
                    </>
                    :

                    <div className='tw-flex-grow-[4] tw-border-b-[1px] tw-border-[#DDDDDD] tw-items-center tw-pl-3'>
                        <i className="fas fa-search tw-text-[#4F4F4F] tw-mr-2 tw-opacity-70"></i>
                        <input
                            type="text"
                            placeholder="Search application here..."
                            className="tw-text-[#4F4F4F] common-font tw-text-[14px] tw-font-normal tw-italic tw-bg-transparent tw-border-none focus:tw-outline-none"
                        />
                    </div>
                }
            </>

            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>

                    {/* Sort dropdown */}
                    {dropdownVisibility.includes(1) && (
                        <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                            <div
                                className='tw-w-full tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2'
                                onClick={toggleDropdown}
                            >
                                <i className="fas fa-sort-alpha-down tw-text-[#4F4F4F]"></i>
                                <span className='tw-text-[#4F4F4F]'>Sort</span>
                            </div>

                            {/* Dropdown Options */}
                            {isOpen && (
                                <div className='tw-absolute tw-w-full tw-mt-28 tw-border tw-border-[#DDDDDD] tw-rounded-b-[5px] tw-bg-[#FFFFFF] tw-z-10 fadeIn'>
                                    {/* Old First */}
                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100 tw-border-b-[2px]'>
                                        <i className="fas fa-sort-down tw-text-[#4F4F4F]"></i>
                                        <span className='tw-text-[#4F4F4F]'>Old First</span>
                                    </div>

                                    {/* New First */}
                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100'>
                                        <i className="fas fa-sort-up tw-text-[#4F4F4F]"></i>
                                        <span className='tw-text-[#4F4F4F]'>New First</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {dropdownVisibility.includes(2) && (
                        // <div className='tw-relative tw-flex tw-px-4 tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF] tw-items-center'>
                        //     <div
                        //         className='tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-items-center'
                        //         onClick={toggleDropdownStatus}
                        //     >
                        //         <div className="tw-w-4 tw-h-4 tw-border-2 tw-border-dashed tw-border-[#4F4F4F] tw-rounded-full"></div> {/* Dashed circle */}
                        //         <span className='tw-text-[#4F4F4F]'>All Statuses</span>
                        //     </div>

                        //     {isOpenStatus && (
                        //         <>
                        //             <div className='borrower-common-menu'>
                        //                 <>
                        //                     {Object.entries(dataHeaderProps.filter).map(([index, status]) => (
                        //                         <div key={index} className="statues-menu-options" onClick={() => {
                        //                             toggleDropdownStatus();
                        //                             dataHeaderProps?.setFilter(index);
                        //                         }}>
                        //                             <i className="statuses-menu-icon fa-solid fa-xmark"></i> {/* Cross icon */}
                        //                             <span className="tw-text-[#4F4F4F]">{status}</span>
                        //                         </div>
                        //                     ))}
                        //                 </>
                        //             </div>
                        //         </>
                        //     )}
                        // </div>
                        <div className='tw-relative tw-flex tw-px-4 tw-h-full tw-border tw-border-[#DDDDDD]     tw-rounded-[5px] tw-bg-[#FFFFFF] tw-items-center'>
                            <Popover placement="bottom">
                                <PopoverHandler>
                                    <Button className='tw-text-[#4F4F4F]'>All Statuses</Button>
                                </PopoverHandler>
                                <PopoverContent className="tw-w-[8%]  tw-border tw-border-[#DDDDDD]">
                                    <List className="p-0 tw-bg-white">
                                        {Object.entries(dataHeaderProps.filter).map(([index, status]) => (
                                            <ListItem className='tw-text-[#4F4F4F] common-font tw-text-[16px]   tw-my-[10px] tw-flex tw-justify-center tw-border-b-[1px]'>
                                                {status}
                                            </ListItem>
                                        ))}
                                    </List>
                                </PopoverContent>
                            </Popover>
                        </div>

                    )}

                    {dropdownVisibility.includes(3) && (
                        <div className='tw-h-full tw-px-2 tw-flex tw-items-center tw-rounded-[5px] tw-border tw-border-[#DDDDDD]'>
                            <Menu placement="bottom-end" open={isFilterMenuOpen} handler={setIsFilterMenuOpen} dismiss={{
                                itemPress: false,
                            }}>
                                <MenuHandler>
                                    <div className='tw-relative tw-cursor-pointer tw-flex tw-space-x-2 tw-mx-[8px] tw-my-[8px]'>
                                        <i className="fas fa-sort-alpha-down tw-text-[#4F4F4F]"></i>
                                        <h1 className='tw-text-[#4F4F4F] tw-font-semibold common-font tw-text-[14px]'>Filters</h1>
                                        <i className="fas fa-sort-alpha-down tw-text-[#4F4F4F]"></i>
                                    </div>
                                </MenuHandler>
                                {
                                    isFilterMenuOpen && (
                                        <MenuList className="tw-w-[315px] tw-h-[515px] tw-bg-white tw-z-10 tw-mt-[10px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px]">
                                            <MenuItem className='tw-w-[265px] tw-h-[415px] tw-ms-[20px] tw-mt-[20px]'>
                                                <div className='borrower-menu-items-container'>
                                                    <label className='borrower-app-table-dropdown-label'>Loan Purpose</label>
                                                    <select className='borrower-app-table-select' name="loanPurpose" onChange={handleSelectChange}>
                                                        <option value="" disabled>- Select -</option>
                                                        <option value="REFINANCE">Refinance</option>
                                                        <option value="CASH-OUT REFINANCE">Cash-Out Refinance</option>
                                                        <option value="CONSTRUCTION_OR_REHAB">CONSTRUCTION Or Rehab</option>
                                                        <option value="PURCHASE">PURCHASE</option>
                                                    </select>
                                                </div>

                                                <div className='borrower-menu-items-container'>
                                                    <label className='borrower-app-table-dropdown-label'>Property Type</label>
                                                    <select className='borrower-app-table-select' name="propertyType" onChange={handleSelectChange}>
                                                        <option value="" disabled>- Select -</option>
                                                        <option value="PURCHASE">PURCHASE</option>
                                                        <option value="REFINANCE">REFINANCE</option>
                                                        <option value="CASH-OUT REFINANCE">CASH-OUT REFINANCE</option>
                                                        <option value="REHAB">REHAB</option>
                                                        <option value="CONSTRUCTION">CONSTRUCTION</option>
                                                    </select>
                                                </div>

                                                <div className='borrower-menu-items-container'>
                                                    <label className='borrower-app-table-dropdown-label'>State</label>
                                                    <select className='borrower-app-table-select' name="state" onChange={handleSelectChange}>
                                                        <option value="" disabled>- Select -</option>
                                                        <option value="PURCHASE">PURCHASE</option>
                                                        <option value="REFINANCE">REFINANCE</option>
                                                        <option value="CASH-OUT REFINANCE">CASH-OUT REFINANCE</option>
                                                        <option value="REHAB">REHAB</option>
                                                        <option value="CONSTRUCTION">CONSTRUCTION</option>
                                                    </select>
                                                </div>

                                                <div className='borrower-menu-items-container'>
                                                    <label className='borrower-app-table-dropdown-label'>City</label>
                                                    <select className='borrower-app-table-select' name="city" onChange={handleSelectChange}>
                                                        <option value="" disabled>- Select -</option>
                                                        <option value="REFINANCE">Refinance</option>
                                                        <option value="CASH-OUT REFINANCE">Cash-Out Refinance</option>
                                                        <option value="CONSTRUCTION_OR_REHAB">CONSTRUCTION Or Rehab</option>
                                                        <option value="PURCHASE">PURCHASE</option>
                                                    </select>
                                                </div>

                                                <div className='tw-w-[265px] tw-h-[69px]'>
                                                    <label className='borrower-app-table-dropdown-label'>Property Value ($)</label>
                                                    <div className='tw-mt-1 tw-flex tw-justify-between'>
                                                        <input
                                                            type="text"
                                                            className='tw-w-[48%] tw-h-[40px] tw-border tw-border-[#DDDDDD] tw-rounded-md tw-px-2'
                                                            placeholder="Min | 5000"
                                                        />
                                                        <input
                                                            type="text"
                                                            className='tw-w-[48%] tw-h-[40px] tw-border tw-border-[#DDDDDD] tw-rounded-md tw-px-2'
                                                            placeholder="Max | 15000"
                                                        />
                                                    </div>
                                                </div>
                                            </MenuItem>

                                            <MenuItem className='tw-w-[265px] tw-h-[48px] tw-ms-[20px] tw-mt-[20px] tw-flex'>
                                                <div className='tw-w-[132.5px] tw-h-[48px] tw-flex tw-justify-center tw-items-center'>
                                                    <i className="tw-text-white fas fa-redo tw-me-[10px] tw-filter-icons-bg"></i>
                                                    <span className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>
                                                        Reset
                                                    </span>
                                                </div>
                                                <div className='tw-w-[132.5px] tw-h-[48px] tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center tw-rounded-[10px]'>
                                                    <i className="fas fa-chevron-right tw-me-[10px] tw-filter-icons-bg"></i>
                                                    <span className='tw-text-[#2A2F70] tw-text-[15px] tw-font-semibold common-font'>Apply</span>
                                                </div>
                                            </MenuItem>
                                        </MenuList>
                                    )
                                }
                            </Menu>

                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default ApplicationTableHeader