import React, { useContext } from 'react'
import { Modal, Box, Typography, Button, Input, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import toast from 'react-hot-toast';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';

function OfferDetailModal({ isOpen, onClose, details }) {
    const { showLoader, hideLoader } = useLoader();
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    if (!isOpen || !details?.application_offer) return null;
    const offer = details.application_offer;
    
    const formattedFees = `$${offer.fees.toLocaleString()}`;
    const formattedInterest = `${offer.interest_on_loan}%`;
    console.log("formattedFees",formattedFees)

    const offerStatusHandler = async (application_offer_id, status) => {
        showLoader();
        try {
            const response = await axios.put(
                `${awsContextVal?.endpoint}/application-offer/update-status/${application_offer_id}`,
                { 
                    status_id: status,
                    user_id: user?.id
                }
            );
            // console.log("response:", response)
            if (response.status == 200) {
                toast.success(response?.data?.message, 'success');
            } else {
                toast.error('Something went wrong.');
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
            setTimeout(() => {
                onClose();
            }, 500);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title">
            <Box className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-lg tw-mx-auto" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                width: {
                    xs: '90%', // 90% width on small screens
                    md: '40%', // 500px width on medium and large screens
                    lg: '30%',
                },
            }}>
                {/* Modal Header */}
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">
                        Offer Details
                    </Typography>
                    <Button onClick={onClose} className="tw-text-gray-500">X</Button>
                </div>

                {/* Percent Interest Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Percent Interest on Loan</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <Input
                            type="text"
                            value={formattedInterest}
                            disabled
                            className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px]"
                        />
                        <FormControl className="tw-w-1/2">
                            <Select value={offer.interest_type} disabled className="tw-text-sm tw-h-[40px]">
                                <MenuItem value="day">Per Day</MenuItem>
                                <MenuItem value="week">Per Week</MenuItem>
                                <MenuItem value="month">Per Month</MenuItem>
                                <MenuItem value="year">Per Year</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Origination Fee Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Origination Fee</InputLabel>
                    <Input
                        type="text"
                        value={formattedFees}
                        disabled
                        className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px]"
                    />
                </div>

                {/* Duration Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Loan Duration</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <Input
                            type="number"
                            value={offer.loan_duration}
                            disabled
                            className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px]"
                        />
                        <FormControl className="tw-w-1/2">
                            <Select value={offer.loan_duration_type} disabled className="tw-text-sm tw-h-[40px]">
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Action Buttons */}
                {user?.user_type == 'borrower' && (offer?.status_id == 1 || offer?.status_id == null) ? (
                    <>
                        <div className="tw-flex tw-justify-between tw-gap-4 tw-mt-6">
                            <Button
                                variant="contained"
                                color="success"
                                className="tw-bg-green-500 tw-text-white"
                                onClick={() => offerStatusHandler(offer?.id, '2')}
                            >
                                Accept
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'red',
                                    borderColor: 'red',
                                    '&:hover': {
                                        borderColor: 'darkred',
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    },
                                }}
                                onClick={() => offerStatusHandler(offer?.id, '3')}
                            >
                                Reject
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                     <div className="tw-mt-6 tw-text-center tw-text-gray-600">
                     {offer?.status_id === 2 ? (
                            <p className="tw-text-green-600">This offer has been accepted.</p>
                        ) : offer?.status_id === 3 ? (
                            <p className="tw-text-red-600">This offer has been rejected.</p>
                        ) : (
                            <p>Lenders do not have access to offer actions.</p>
                        )}
                    </div>
                    </>
                )}


                {/* {user?.user_type == 'borrower' ? (
                    <></>
                ) : (
                    <></>
                )} */}
            </Box>
        </Modal>
    )
}

export default OfferDetailModal
