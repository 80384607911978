// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper{
    overflow: unset !important;
}
.swiper-slide-active .slide-content {
    transform: scale(1.1); 
    transition: transform 0.4s ease-in-out;
}

.swiper-slide {
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
}

.swiper-slide-active {
    opacity: 1;
}


.swiper-pagination {
    bottom: 20px;
    display: flex;
    justify-content: center;
    bottom:var(--swiper-pagination-bottom, -10px) !important;
}

.custom-indicator-swipe {
    margin-top: 10px !important;
    width: 30px !important;
    border-radius: 4px !important;
    height: 5px !important;
    background-color: #4853E4 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/TestimonialSwiper.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,sCAAsC;AAC1C;;AAEA;IACI,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IAIvB,wDAAwD;AAH5D;;AAMA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,6BAA6B;IAC7B,sBAAsB;IACtB,oCAAoC;AACxC","sourcesContent":[".swiper{\n    overflow: unset !important;\n}\n.swiper-slide-active .slide-content {\n    transform: scale(1.1); \n    transition: transform 0.4s ease-in-out;\n}\n\n.swiper-slide {\n    opacity: 0.5;\n    transition: opacity 0.4s ease-in-out;\n}\n\n.swiper-slide-active {\n    opacity: 1;\n}\n\n\n.swiper-pagination {\n    bottom: 20px;\n    display: flex;\n    justify-content: center;\n}\n\n.swiper-pagination{\n    bottom:var(--swiper-pagination-bottom, -10px) !important;\n}\n\n.custom-indicator-swipe {\n    margin-top: 10px !important;\n    width: 30px !important;\n    border-radius: 4px !important;\n    height: 5px !important;\n    background-color: #4853E4 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
