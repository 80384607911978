import LogoImage from "../../../assets/icons/Logo.png";
import { useNavigate } from "react-router-dom";


export default function CmsHeader(){
    const navigate = useNavigate();
    return(
        <header className="tw-w-full tw-bg-gradient-to-b tw-from-reloan tw-to-[#3944d5] tw-h-24 tw-rounded-b-lg tw-flex tw-p-6">
            <img src={LogoImage} onClick={() => {
              navigate("/")
            }} />
        </header>
    )
}