import React, { useEffect, useState, useContext } from 'react';
import FormBox from './FormBox';
import StatsCards from '../../common/StatsCards';
import NewApplicationModal from './modal/NewApplicationModal';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import StatsDropdown from '../../common/StatsDropdown';
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import { GoogleMapsProvider } from '../../../contexts/GoogleMapsContext';
import AssetGenerated from '../lender/AssetGenerated';
import GoogleAddressMarker from '../../common/GoogleAddressMarker';
import Chat from '../../common/chat-new/Chat';
import { conversationListHandler } from '../../common/chat-new/ChatUtils';
import { useLoader } from '../../../contexts/LoaderContext';

function Borrower() {
    const [openModal, setOpenModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(Math.random())
    const [loanApplications, setLoanApplications] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [borrowerApplicationsSummary, setBorrowerApplicationsSummary] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [formattedTimes, setFormattedTimes] = useState([]);
    const { setCurrentOfferPropertyDetails } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();

    const [stats, setStats] = useState([
        { title: 'Total Applications', value: '40,689', change: '8.5%' },
        { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
        { title: 'Pending Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Total Amount Received', value: '40,689', change: '8.5%' },
        { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
    ]);

    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // const stats = [
    //     { title: 'Total Applications', value: '40,689', change: '8.5%' },
    //     { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
    //     { title: 'Pending Applications', value: '40,689', change: '8.5%' },
    //     { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
    //     { title: 'Total Amount Received', value: '40,689', change: '8.5%' },
    //     { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
    // ];

    //This is to get Borrower Dashboard Summary

    const fetchBorrowerLoanSummary = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/getLenderDashboardSummary`, { userId: user?.id });
            setBorrowerApplicationsSummary(response?.data);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };

    const fetchUserLoanApplications = async (sortBy = "a.id", sortOrder = "DESC") => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/borrower/list`,
                { 
                    userId: user.id,
                    sort_by: sortBy,
                    sort_order: sortOrder,
                });
            setLoanApplications(response?.data?.applications);
        } catch (err) {
            // adding a defualt location for demo purposes
            setLoanApplications([{

            }])
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchUserLoanApplications();
        fetchBorrowerLoanSummary();
    }, [refreshKey]);


    useEffect(() => {
        const stats = [
            { title: 'Total Applications', value: borrowerApplicationsSummary?.total_applications, change: '8.5%' },
            { title: 'Accepted Applications', value: borrowerApplicationsSummary?.accepted_applications, change: '8.5%' },
            { title: 'Pending Applications', value: borrowerApplicationsSummary?.pending_applications, change: '8.5%' },
            { title: 'Rejected Applications', value: borrowerApplicationsSummary?.rejected_applications, change: '8.5%' },
            { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
            { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
        ];
        setStats(stats)
    }, [borrowerApplicationsSummary]);

    const handleNewApplication = async () => {
        await fetchUserLoanApplications();
        handleCloseModal();
    };


    // function to get age of application
    const formatTimeDifference = (date) => {
        const now = new Date();
        const createdAt = new Date(date);
        const diffInSeconds = Math.floor((now - createdAt) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    };

    // Update every 60 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setFormattedTimes((prevTimes) => {
                return loanApplications.map((app) => ({
                    application_id: app.application_id,
                    formattedTime: formatTimeDifference(app.application_created_at),
                }));
            });
        }, 60000); // Update every 60 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [loanApplications]);


    const columns = [
        { field: 'application_id', headerName: 'ID', width: 70, editable: false },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            editable: true,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'property_address',
            headerName: 'Property Address',
            width: 170,
            editable: false,
        },
        {
            field: 'loan_purpose',
            headerName: 'Loan Purpose',
            width: 150,
            editable: false,
        },
        {
            field: 'property_value',
            headerName: 'Property Value',
            type: 'number',
            width: 140,
            editable: false,
        },
        {
            field: 'loan_amount',
            headerName: 'Loan Amount',
            width: 110,
            editable: false,
        },
        {
            field: 'desired_loan_to_value',
            headerName: 'Desired LTV',
            type: 'number',
            width: 100,
            editable: false,
        },
        {
            field: 'potential_lenders',
            headerName: 'Potential Lenders',
            type: 'number',
            width: 130,
            editable: false,
        },
        {
            field: 'property_status',
            headerName: 'Status',
            width: 145,
            renderCell: (params) => {
                const status = params?.value;
                let badgeColor;
                let badgeChild;

                switch (status) {
                    case 'Pending':
                        badgeColor = '#377DFF2E';
                        badgeChild = '#377DFF';
                        break;
                    case 'Unread Offers':
                        badgeColor = '#FFD7002E';
                        badgeChild = '#FFD700';
                        break;
                    case 'Offers':
                        badgeColor = '#00C9A72E';
                        badgeChild = '#00C9A7';
                        break;
                    case 'Settled':
                        badgeColor = '#4CAF502E';
                        badgeChild = '#4CAF50';
                        break;
                    case 'Archived':
                        badgeColor = '#9E9E9E2E';
                        badgeChild = '#9E9E9E';
                        break;
                    default:
                        badgeColor = '#E0E0E0';
                        badgeChild = '#BDBDBD';
                }

                return (
                    <div
                        className='tw-flex tw-justify-center tw-items-center tw-w-[124px] tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full tw-mt-[12px]'
                        style={{ backgroundColor: badgeColor }}
                    >
                        <span className='tw-ms-[10px] tw-w-[8px] tw-h-[8px] tw-border tw-border-[#DDDDDD] tw-rounded-full' style={{ backgroundColor: badgeChild }}></span>

                        <span className='tw-flex tw-justify-start tw-items-center tw-w-[98px] tw-h-[22px] tw-text-[#070101CC] common-font tw-font-normal tw-text-[16px] tw-ml-1'>
                            {status}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'estimated_rate',
            headerName: 'Estimated Rate',
            width: 100,
            editable: false,
        },
    ];
    
    const rows = loanApplications?.length ? loanApplications?.map(app => ({
        application_id: app?.application_id ? app?.application_id : '123',
        property_address: app?.property_address ? app?.property_address : 'Demo Address 1',
        loan_purpose: app?.loan_purpose ? app?.loan_purpose : 'Refinance',
        property_value: app?.property_value
            ? app.property_value.startsWith('$')
                ? app.property_value
                : '$' + new Intl.NumberFormat().format(Number(app.property_value.replace(/,/g, '')))
            : "$150,000",
        loan_amount: app?.loan_amount
            ? app.loan_amount.startsWith('$')
                ? app.loan_amount
                : '$' + new Intl.NumberFormat().format(Number(app.loan_amount.replace(/,/g, '')))
            : "$150,000",
        desired_loan_to_value: app?.desired_loan_to_value
            ? app.desired_loan_to_value.startsWith('$')
                ? app.desired_loan_to_value
                : '$' + new Intl.NumberFormat().format(Number(app.desired_loan_to_value.replace(/,/g, '')))
            : "$150,000",
        potential_lenders: app?.potential_lenders ? app?.potential_lenders : "0",
        property_status: app?.application_status || 'Pending',
        estimated_rate: app?.estimated_rate ? app?.estimated_rate : '10%',
        lat: app?.lat ? app?.lat : '40.758896',
        lng: app?.lng ? app?.lng : '-73.985130',
        borrower: app?.first_name + ' ' + app?.last_name,
        streetAddress: app.property_address,
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    const handleRowClick = (params) => {
        // Show the loader
        showLoader();
    
        const location = rows.find((loc) => loc.application_id === params.application_id);
        setSelectedLocation({ lat: location.lat, lng: location.lng }); // Update selected location
        setSelectedRowId(params.application_id);
        setCurrentOfferPropertyDetails(params);
    
        setTimeout(() => {
            hideLoader();
        }, 1000);
    };

    useEffect(() => {
        if (rows.length > 0) {
            const firstRow = rows[0]; 
            const lastRow = rows[rows.length - 1];
            setSelectedRowId(firstRow.application_id);
            setCurrentOfferPropertyDetails(firstRow);
            setSelectedLocation({ lat: firstRow.lat, lng: firstRow.lng }); // Set the default location
        }
    }, [loanApplications]);


    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if(setSelectedRowId) {
    //             conversationListHandler(setSelectedRowId, user, awsContextVal, setConversationList);
    //         }
    //     }, 20000); // Update every 60 seconds

    //     return () => clearInterval(intervalId); // Clean up the interval on component unmount
    // }, [selectedRowId]);

    return (
        <GoogleMapsProvider>
            <div className="tw-h-full tw-w-full">
                <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[87px]">
                    <div>
                        <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">Borrower Dashboard</h1>
                        {/* <p className='tw-text-[#202224] tw-font-semibold tw-leading-[24.55px] tw-text-[18px] tw-opacity-55 tw-h-[25px]'>
                            Here is the information about all your orders
                        </p> */}
                    </div>
                    {/* <button
                        className="tw-w-[193px] tw-h-[49px] tw-bg-[#7DDE92] tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[12px] tw-px-[18px] tw-gap-[4px] tw-text-[18px] tw-leading-[24.55px]"
                        onClick={handleOpenModal}
                    >
                        New Application +
                    </button> */}
                </header>
                {/* <div className="tw-block">
                    <StatsDropdown />

                    <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg">
                        {stats?.map((stat, index) => (
                            <StatsCards
                                key={index}
                                title={stat?.title}
                                value={stat?.value}
                                change={stat?.change}
                            />
                        ))}
                    </div>
                </div> */}

                <div className="tw-flex tw-mt-4 hidden tw-justify-between tw-space-x-4">
                    <div className="tw-w-2/3">
                        <ApplicationTable
                            dataTableProps={{
                                getRowId: (row, index) => `${index}-${row?.application_id}`,
                                userStatus: ['Rejected', "Loan Granted", "Pending"],
                                dashboardType: 'borrower',
                                getRowClassName: (params) =>
                                    params.row.application_id === selectedRowId ? 'selected-row' : ''
                            }}
                            rows={rows}
                            selectedRow={selectedLocation || rows[rows.length - 1]}
                            columns={columns}
                            onRowClick={handleRowClick}
                            height="592px" // Set height to 650px for the borrower dashboard
                        />
                    </div>
                    <div className='tw-w-1/3'>
                        <FormBox callBack={() => setRefreshKey(Math.random())} selectedLocation={selectedLocation} />
                    </div>
                </div>

                <div className='tw-flex tw-space-x-4 tw-mt-10'>
                    <div className='tw-w-2/3'>
                        <Chat application_id={selectedRowId} />
                    </div>

                    <div className='tw-w-1/3'>
                        {/* {
                            selectedLocation
                                ?
                                <div className='tw-mr-3'><GoogleAddressMarker selectedLocation={selectedLocation} /></div>
                                : null
                        } */}
                        <AssetGenerated />
                    </div>
                </div>
                <NewApplicationModal open={openModal} handleClose={handleCloseModal} onNewApplication={handleNewApplication} />
            </div>
        </GoogleMapsProvider>

    );
}

export default Borrower;
