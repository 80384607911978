import React from 'react'
import { Routes, Route } from 'react-router-dom';

import Home from './../components/Home';
import AuthLayout from './../components/layouts/auth/AuthLayout';
import SignUp from './../components/auth/SignUp';
import SignIn from './../components/auth/SignIn';
import ForgotPassword from './../components/auth/ForgotPassword';
import OtpVerify from './../components/auth/OtpVerify';
import Subscription from './../components/auth/Subscription';
import PackageSuccessPage from "./../components/auth/Success";
import { UserTypeProvider } from '../contexts/UserContext';
import CmsPage from '../components/auth/CmsPage';
import { PromoCodeProvider } from '../contexts/PromoCodeContext';

const Auth = () => {
    return (
        <>
            <PromoCodeProvider>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/subscription' element={<Subscription />} />
                    <Route path='/subscription/success' element={<PackageSuccessPage />} />
                    <Route path='' element={<AuthLayout />}>
                        <Route path='/sign-up/:userType' element={<SignUp />} />
                        <Route path='/sign-in' element={<SignIn />} />
                        <Route path='/otp-verify' element={<OtpVerify />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                    </Route>
                    {/* <Route path="/:slug" element={<CmsPage />} /> */}
                    <Route path='/website-terms' element={<CmsPage slug={'website-terms'} />} />
                <Route path='/privacy-policy' element={<CmsPage slug={'privacy-policy'} />} />
                <Route path='/cookies-policy' element={<CmsPage slug={'cookies-policy'} />} />
                <Route path='/contact' element={<CmsPage slug={'contact'} />} />
                <Route path='/disclaimer' element={<CmsPage slug={'disclaimer'} /> } /> 

                </Routes>
            </PromoCodeProvider>

        </>
    )
}

export default Auth