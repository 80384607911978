import React from "react";
import LogoImage from "../../../src/assets/icons/Logo.png";
import { useNavigate, Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Vector1 from "../../../src/assets/icons/big_lines.png";
import Vector2 from "../../../src/assets/icons/small_lines.png";
import CmsHeader from "../layouts/base/CmsHeader";

const Disclaimer = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };
  return (
       <>
      <div className="xl:tw-hidden tw-block">
        <CmsHeader />
      </div>
      <div className="tw-flex tw-justify-center">
        {/* Sidebar - Fixed position */}
        <div className="tw-w-1/6 tw-bg-[#3748E8] tw-h-full tw-flex-col tw-justify-center tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-10 tw-hidden xl:tw-flex">
          <div className="tw-absolute tw-top-4 tw-left-4">
            <img
              src={LogoImage}
              alt="Re-loan logo"
              className="tw-w-40 tw-cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>

          {/* Vector Decorations */}
          <img
            src={Vector2}
            alt="vector2"
            className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-50 tw-h-1/3"
          />
          <img
            src={Vector1}
            alt="vector1"
            className="tw-absolute tw-top-20 tw-left-0 tw-opacity-50 tw-h-2/3"
          />
        </div>

      {/* Main Content */}
      <div className="tw-w-5/6 tw-bg-white tw-h-full tw-justify-center tw-text-2xl tw-overflow-y-auto tw-scrollbar xl:tw-ml-[22%] tw-pt-12">
        <div className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-reloan tw-relative tw-flex">
          <a href="/">
            <ChevronLeftIcon fontSize={"12px"} className="tw-text-gray-800" />
          </a>
          DISCLAIMER
        </div>
        <div className="tw-text-gray-600 tw-flex tw-flex-col tw-gap-6 lg:tw-p-6">
          <p className="tw-mb-6">
            Re-Loan Corp is a neutral platform designed solely to connect
            borrowers and lenders. We are not, and do not claim to be, a
            mortgage broker, lender, or financial advisor. Re-Loan Corp does not
            participate in loan transactions, negotiate loan terms, or provide
            any guarantees regarding the outcomes of introductions made through
            our platform.
          </p>
          <p className="tw-mb-6">
            All loan agreements, terms, and conditions are exclusively between
            borrowers and lenders and are conducted independently of Re-Loan
            Corp. Users of the platform are responsible for conducting their own
            due diligence, verifying all information, and complying with
            applicable laws and regulations.
          </p>
          <p className="tw-mb-6">
            Re-Loan Corp assumes no responsibility or liability for any
            disputes, financial losses, or legal issues arising from the use of
            the platform or agreements made between parties. For any specific
            legal, financial, or regulatory advice, please consult a qualified
            professional.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Disclaimer;
