import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import ApplicationTableHeader from './ApplicationTableHeader';
import { Typography } from '@mui/material';

function ApplicationTable({
    columns,
    rows,
    selectedRow,
    dataTableProps = {},
    selectedRowId,
    onRowClick,
    height = "100%",
    useAutoHeight = false
}) {
    // Custom "No Rows" component for DataGrid
    const NoRowsOverlay = () => (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Typography variant="body1" color="textSecondary">
                No records found
            </Typography>
        </Box>
    );

    return (
        <div className="tw-h-full tw-w-full tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-flex tw-flex-col">
            <ApplicationTableHeader 
                dataHeaderProps={{
                    title: dataTableProps?.title, 
                    filter: dataTableProps?.userStatus,
                    setFilter: dataTableProps?.setBorrowerStatus || dataTableProps?.setLenderStatus || (() => {}),
                    dashboardType: dataTableProps?.dashboardType
                }} 
            />
            <Box 
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    overflow: 'auto',
                    height: height,
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight={useAutoHeight} // Toggle auto-height dynamically
                    onRowClick={(row) => onRowClick(row?.row)}
                    getRowId={(row) => row?.id}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 20 },
                        },
                        sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
                    }}
                    components={{
                        NoRowsOverlay: NoRowsOverlay, // Use custom No Rows message
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderRight: '1px solid #DDDDDD',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        boxShadow: 2,
                        '& .MuiDataGrid-columnHeaders *': {
                            backgroundColor: "#DDE1EE",
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: '1px solid #DDDDDD', // Add top border
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.row.application_id === selectedRowId ? 'selected-row' : ''
                    }
                    pageSizeOptions={[10, 20, 50, 100]}
                    rowSelectionModel={[selectedRow]}
                    disableRowSelectionOnClick
                    {...dataTableProps}
                />
            </Box>
        </div>
    );
}

export default ApplicationTable;
