import React, { useState, useContext, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import toast from "react-hot-toast";
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import { useLoader } from '../../../contexts/LoaderContext';
import { Link, useNavigate } from 'react-router-dom';
import { getMembershipDates, authLocal } from '../../../utils/common';
import { fetchSettings } from "../../../utils/services/apiService"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useFetchUserSubscription } from "../../../hook/useFetchUserSubscription";
import { useApiService } from "../../../services/apiService";
import PageLoader, { usePageLoader } from "../../../contexts/PageLoaderContext";


const ChangeSubscription = () => {
    const navigate = useNavigate();
    const [disabledButton, setDisabledButton] = useState(null);
    const { user, updateMembership, setUser } = useContext(UserContext);
    const [plans, setPlans] = useState([]);
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [stripePublicKey, setStripePublicKey] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { showPageLoader, hidePageLoader } = usePageLoader();
    const [isPageReady, setIsPageReady] = useState(false);

    const { fetchUserSubscription } = useFetchUserSubscription();


    // console.log("user:::", user);

    useEffect(() => {
        const initializePage = async () => {
            showPageLoader(); // Show the loader
            try {
                await fetchPlans();
                await fetchStripeDetails();
                await fetchUserSubscription();
            } catch (error) {
                toast.error("Failed to initialize the page.");
            } finally {
                setIsPageReady(true); // Set page ready
                hidePageLoader(); // Hide the loader
            }
        };

        initializePage();
    }, []);

    const handleCancelClick = () => {
        setIsDialogOpen(true); // Open the dialog
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false); // Close the dialog
    };

    const handleConfirmCancel = async () => {
        try {
            showLoader();
            console.log(user);
            const userDetailsResponse = await postRequest(`/fetch-user`, { email: user.email });
            const updatedUser = userDetailsResponse?.data?.user;
            setUser(updatedUser);

            if (updatedUser?.subscription_plan_id != 35) {
            console.log("Canceling Stripe Subscription:", updatedUser?.stripe_subscription_id);

            const stripeCancelResponse = await postRequest(`/payment/cancel-subscription`, {
                stripe_subscription_id: updatedUser?.stripe_subscription_id,
            });

            if (!stripeCancelResponse?.data?.is_success) {
                throw new Error(stripeCancelResponse?.data?.message || "Failed to cancel Stripe subscription.");
            }
        }

            const response = await postRequest(`/user/cancel-membership`, {
                id: updatedUser?.id,
                stripe_subscription_id: updatedUser?.stripe_subscription_id,
            });
            if (response?.data?.is_success) {
                fetchUserSubscription();
                setIsDialogOpen(false);
                toast.success(response?.data?.message);
            } else {
                toast.error(response?.data?.message);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || "An error occurred while canceling the membership.";
            toast.error(errorMessage);
        } finally {
            hideLoader();
        }
    };


    const fetchStripeDetails = async () => {
        showLoader();
        try {
            console.log(postRequest);
            const setting = await fetchSettings(`/setting/list/`, { "setting_names": ["site_name", "stripe_public_key"] }, postRequest);
            if (setting?.stripe_public_key) {
                setStripePublicKey(setting?.stripe_public_key);
                // console.log(setting?.stripe_public_key);
            }
        } catch (error) {
            console.error(error);
            hideLoader();
        } finally {
            hideLoader();
        }
    };

    const fetchPlans = async () => {
        try {
            showLoader();
            const response = await postRequest(`/subscription-plan/list`, { status: 1 });
            console.log("Plans fetched:", response.data.subscriptionPlanList);
            setPlans(response.data.subscriptionPlanList);
        } catch (error) {
            console.error("Error fetching plans:", error);
        } finally {
            hideLoader();
        }
    };


    useEffect(() => {
        fetchPlans();
        fetchStripeDetails();
    }, []);

    useEffect(() => {
        setSelectedPlanId(user?.subscription_plan_id);
        setDisabledButton(user?.subscription_plan_id);
    }, [user])



    const handleCheckoutStripe = async (
        plan_name,
        subscription_plan_id,
        price_id,
        amount,
        original_amount,
        type,
        available_offer
    ) => {
        const stripePromise = loadStripe(stripePublicKey);

        try {
            // Fetch user's subscription history from the database
            showLoader();

            // **Cancel Existing Subscription if any**
            if (user?.stripe_subscription_id) {
                console.log("Canceling existing Stripe Subscription:", user?.stripe_subscription_id);

                const cancelResponse = await postRequest(`/payment/cancel-subscription`, {
                    stripe_subscription_id: user?.stripe_subscription_id,
                });

                if (!cancelResponse?.data?.is_success) {
                    throw new Error("Failed to cancel previous subscription. Try again.");
                }

                console.log("Previous subscription canceled successfully.");
            }

            const response = await postRequest(`/user-membership/list`,
                { user_id: user?.id }
            );
            hideLoader();

            const membershipHistory = response?.data.userMembershipList || [];
            console.log("Membership History:", membershipHistory);

            // Check if the user has already subscribed to the same plan (excluding Standard)
            const duplicatePlanCount = membershipHistory.filter(
                (membership) =>
                    membership.subscription_plan_id === subscription_plan_id &&
                    subscription_plan_id === 35
            ).length;

            if (duplicatePlanCount > 0) {
                toast.error(
                    "You cannot subscribe to the Trial plan more than once. Please upgrade your subscription."
                );
                return false;
            }
            // Fetch new and existing subscriptions for comparison
            const existing_subscription = plans.find(
                (plan) => plan.id === user?.subscription_plan_id
            );
            const new_subscription = plans.find(
                (plan) => plan.id === subscription_plan_id
            );

            console.log("Existing Subscription:", existing_subscription);
            console.log("New Subscription:", new_subscription);


            // Prevent downgrade
            if (new_subscription?.amount < existing_subscription?.amount) {
                toast.error("You are not able to downgrade your membership.");
                return false;
            }
            const membershipDates = getMembershipDates(type);
            console.log("Membership Dates:", membershipDates);


            if (amount > 0) {
                // Stripe Checkout Flow
                const stripe = await stripePromise;
                if (!stripe) {
                    toast.error("Stripe initialization failed");
                    return;
                }

                showLoader();
                const paymentResponse = await postRequest(
                    `/payment/create-checkout-session`,
                    {
                        amount: amount,
                        price_id: price_id,
                        plan_name: plan_name,
                        success_url: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
                        cancel_url: window.location.href,
                        customer_email: user.email,
                        subscription_plan_id: subscription_plan_id,
                        original_amount: original_amount,
                        offer: available_offer,
                        membership_start: membershipDates?.startDate,
                        membership_end: membershipDates?.endDate,
                        type: type,
                    }
                );
                hideLoader();

                const session = paymentResponse?.data;
                await stripe.redirectToCheckout({ sessionId: session.id });
                return;
            } else {

                // Membership Update Flow
                showLoader();
                const membershipResponse = await postRequest(`/user/update-membership`, {
                    subscription_plan_id: subscription_plan_id,
                    available_offer: available_offer,
                    membership_start: membershipDates?.startDate,
                    membership_end: membershipDates?.endDate,
                    id: user?.id,
                });
                hideLoader();

                const data = membershipResponse?.data;
                if (data?.is_success) {
                    // Update user context
                    updateMembership({
                        subscription_plan_id: subscription_plan_id,
                        membership_start: membershipDates?.startDate,
                        membership_end: membershipDates?.endDate,
                        available_offer: available_offer,
                        plan: plans.find((plan) => plan.id === subscription_plan_id),
                    });
                    toast.success(data?.message);

                    navigate(`/dashboard/payment-list`);
                } else {
                    toast.error(data?.message || "Failed to update subscription.");
                }
            }
        } catch (error) {
            hideLoader();
            console.error("Error during subscription handling:", error);
            toast.error("Error updating subscription.");
        }

    };



    return (
        <div className="tw-w-full tw-min-h-screen">
            {!isPageReady && (
                <PageLoader />
            )}
            <div className="tw-flex tw-justify-center">
                <div className="tw-w-[50%] tw-p-[10px] tw-border tw-mt-[20px] tw-flex tw-flex-col">
                    <div className="tw-flex tw-justify-between tw-items-center">
                        <label className="tw-font-semibold tw-text-[#242424] common-font">Membership Plan:</label>
                        <span className="tw-text-[#242424] common-font">{user?.plan?.plan || "N/A"}</span>
                    </div>
                    <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                        <label className="tw-font-semibold tw-text-[#242424] common-font">Start Membership:</label>
                        <span className="tw-text-[#242424] common-font">
                            {user?.membership_start
                                ? new Date(user?.membership_start).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                })
                                : "N/A"}
                        </span>
                    </div>
                    <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                        <label className="tw-font-semibold tw-text-[#242424] common-font">{user?.subscription_plan_id === 35 ? "End Membership:" : "Membership Renews:"}</label>
                        <span className="tw-text-[#242424] common-font">
                            {user?.membership_end
                                ? new Date(user?.membership_end).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                })
                                : "N/A"}
                        </span>
                    </div>
                    {user?.available_offer >= 0 && (
                        <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                            <label className="tw-font-semibold tw-text-[#242424] common-font">Available Offer:</label>
                            <span className="tw-text-[#242424] common-font">{user?.available_offer || "0"}</span>
                        </div>
                    )}

                    {/* Cancel Membership Button */}
                    {user?.subscription_plan_id <= 0 ? <>
                    </> :
                        <>
                            <div className="tw-mt-4 tw-flex tw-justify-center">
                                <button
                                    className="tw-px-6 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded tw-font-semibold hover:tw-bg-red-600 common-font hover:-tw-translate-y-1 tw-transition-all tw-duration-300"
                                    onClick={handleCancelClick}
                                >
                                    Cancel Subscription
                                </button>
                            </div>
                        </>}
                </div>

                <Dialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Cancellation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to cancel your membership?
                            <br />
                            {/* Your membership is now canceled */}
                            {/* {" "}
                            <span className="tw-font-semibold">
                                {user?.membership_end
                                    ? new Date(user.membership_end).toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                    })
                                    : "N/A"}
                            </span>  */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirmCancel} color="secondary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className="tw-text-[#4853E4] tw-text-center tw-font-inter tw-pt-[35px]">
                Pricing Plans
            </div>
            <div className="tw-text-black tw-text-center tw-text-[48px] tw-font-[700] tw-font-oswald">
                Pricing That Suits Your Needs.
            </div>
            <div className="tw-font-inter tw-mt-4 tw-text-[#4D4D4D] tw-text-center tw-text-[18px] tw-font-[400]">
                Whether you’re exploring your first deal or managing a high-volume portfolio, our plans <br className="tw-hidden md:tw-inline" /> offer the tools and flexibility you need to succeed.
            </div>

            <section className="lg:tw-mx-[8%] md:tw-mx-[4%] sm:tw-mx-[4%] tw-mx-[4%] tw-mt-8 tw-pb-5">
                <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-center tw-gap-8 sm:tw-items-center xl:tw-items-start">
                    {plans?.map((plan, index) => (
                        <div key={index} className="tw-w-full tw-h-[36rem] advantage-card tw-max-w-sm tw-px-[48px] tw-py-[40px] tw-bg-white tw-border tw-border-gray-200 tw-rounded-[16px] tw-shadow-[0px_7px_15px_0px_rgba(0,0,0,0.15)] dark:tw-bg-gray-800 dark:tw-border-gray-700">
                            <div>
                                <div className="tw-font-inter tw-text-[#4853E4] tw-text-[27px] tw-font-[700]">{plan?.plan}</div>
                                <div className="tw-font-inter tw-text-[#252422] tw-text-[13px] tw-font-[400] tw-mt-3 tw-h-9">{plan?.description}</div>
                                <div className="tw-flex tw-justify-center tw-mt-[30px] tw-mb-[24px]">
                                    <div className="tw-flex tw-items-baseline tw-text-gray-900 dark:tw-text-white tw-font-inter">
                                        <div className="tw-text-[49px] tw-font-extrabold tw-tracking-tight">
                                            <sup className="tw-text-[13px] tw-text-[#252422] tw-font-[400] tw-align-super">$</sup>{plan?.amount}
                                        </div>
                                        <span className="tw-ms-1 tw-text-[13px] tw-font-normal tw-text-[#252422] dark:tw-text-gray-400">{plan?.pricePeriod || '/month'}</span>
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleCheckoutStripe(plan?.plan, plan?.id, plan?.price_id, plan?.amount, plan?.amount, plan?.type, plan?.offer)}
                                    type="button"
                                    className={`tw-font-inter hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-text-white tw-uppercase tw-bg-[#4853E4] focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-200 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-900 tw-font-medium tw-rounded-lg tw-text-[16px] tw-px-5 tw-py-3 tw-inline-flex tw-justify-center tw-w-full tw-text-center ${disabledButton === plan?.id ? 'tw-bg-gray-200 tw-cursor-not-allowed' : ''}`}
                                    disabled={disabledButton === plan?.id}
                                >
                                    Subscribe
                                </button>
                                <div className="tw-mt-[24px]">
                                    {plan?.facility?.map((feature, featureIndex) => (
                                        <div key={featureIndex} className="tw-flex tw-mt-4">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" fill="#4853E4" />
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M22 4L12 14.01L9 11.01" fill="#4853E4" />
                                                    <path d="M22 4L12 14.01L9 11.01" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div
                                                className={`tw-ml-4 tw-font-inter tw-text-[13px] tw-text-[#252422] ${featureIndex === 0 ? "tw-font-[700]" : "tw-font-[400]"
                                                    }`}
                                            >
                                                {feature}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </section >
            <div className="tw-pt-5 tw-pb-[100px] tw-text-[#252422] tw-text-13px tw-font-[400] tw-text-center tw-font-inter">
                For institutional inquiries, please reach out to us at admin@re-loan.com.
            </div>
        </div >
    )
}
export default ChangeSubscription;