import React, { useContext, useState } from 'react'
import { Box, Button, Typography, Modal, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useLoader } from '../../../contexts/LoaderContext';
import toast from 'react-hot-toast';
import { useApiService } from '../../../services/apiService';

function ChangePasswordModal({ open, handleClose, rowData }) {
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        let value = e.target.value;
        setPassword(value);
    };


    const handleChangePassword = async () => {
        showLoader();
        const newErrors = {};

        if (!password) {
            newErrors.password = "Password is required.";
            setErrors(newErrors);
            toast.error("Please fill in all required fields.");
            hideLoader();
            return;
        }

        try {
            const requestData = {
                email: rowData?.email,
                new_password: password,
            };
            const response = await postRequest(`/user/change-password`, requestData);
            if (response?.success) {
                toast.success("Password changed successfully!");
                console.log("Response:", response.data);
            } else {
                throw new Error(response?.data?.message || "Unexpected response from the server.");
            }
        } catch (error) {
            if (error.response) {
                console.error("Server error:", error.response.data);
                toast.error(error.response.data?.message || "An error occurred while changing the password.");
            } else if (error.request) {
                console.error("No response received:", error.request);
                toast.error("Unable to reach the server. Please try again later.");
            } else {
                console.error("Error:", error.message);
                toast.error(error.message || "An unexpected error occurred.");
            }
        } finally {
            hideLoader();
            handleClose();
        }
    };

    return (
        <Modal open={open} onClose={() => { handleClose(); }} aria-labelledby="modal-title">
            <Box className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                width: {
                    xs: '90%', // 90% width on small screens
                    md: '40%', // 500px width on medium and large screens
                    lg: '30%',
                },
            }}>
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">Chane Password</Typography>
                    <Button onClick={handleClose} className="tw-text-gray-500">X</Button>
                </div>

                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Password</InputLabel>
                    <div className="tw-relative tw-h-[40px]">
                        <input
                            type="password"
                            onChange={handleChange}
                            placeholder="Password"
                            className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.password ? 'tw-border-red-500' : ''}`} />
                    </div>
                    {errors.password && <p className="tw-text-red-500 tw-text-sm">{errors.password}</p>}
                </div>

                <div className="tw-flex tw-justify-end tw-mt-6">
                    <Button onClick={handleChangePassword} variant="contained" color="primary" className="tw-bg-blue-500 tw-text-white">
                        Save
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ChangePasswordModal
