// FormBox.js
import React, { useContext, useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import {
    GetState,
    GetCity,
} from "react-country-state-city";
import { UserContext } from '../../../contexts/UserContext';
import { useApiService } from '../../../services/apiService';
import { getApplicationOfferStatus } from '../../../utils/common'

const FilterForm = ({ filter, setFilter, filterHandler, resetFilterHandler }) => {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(filter?.date_submitted || null);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [loanPurposeList, setLoanPurposeList] = useState([]);
    const excludedStates = ['AS', 'GU', 'MP', 'UM'];



    const formatPlaceholder = () => {
        if (startDate && endDate) {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            return `${startDate.toLocaleDateString('en-US', options)} - ${endDate.toLocaleDateString('en-US', options)}`;
        }
        return "Dec 01, 2023 - Jan 31, 2024";
    };

    //This is to open filter section
    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Fetch states when the component loads
        GetState(233) // Assuming 233 is the country code for the US
            .then((result) => {
                result = result.filter(
                    (i) =>
                        !(
                            excludedStates.includes(i.state_code) ||
                            i.state_code.startsWith("UM-")
                        )
                );
                setStateList(result);
            })
            .catch(err => console.log(err));

    }, []);

    const getCities = (stateId) => {
        GetCity(233, stateId)
            .then((result) => {
                result = result.filter((r) => !r.name.endsWith(" County"))
                setCityList(result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleStateChange = (e) => {
        const state_code = e.target.value;
        const state_id = e.target.options[e.target.selectedIndex].getAttribute('data-stateid')
        handleFilterChange({
            target: {
                name: 'state_id',
                value: state_code
            }
        });
        getCities(Number(state_id))
    };

    const handleCityChange = (e) => {
        const selectedCityId = e.target.value; // Use the city ID directly
        setFilter((prevFilter) => ({
            ...prevFilter,
            city_id: selectedCityId, // Set the city_id to the selected value
        }));
    };


    const handleFilterChange = useCallback((e) => {
        const { name, value } = e.target;  // Destructure the name and value from the event target
        console.log("Changing filter:", { name, value });
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value // Dynamically set the key based on the name attribute
        }));
        filterHandler()
    }, [setFilter, filterHandler]);

    const handleReset = () => {
        resetFilterHandler();
        setStartDate(null);
        setEndDate(null);

        // Reset city-related state
        setCityList([]); // Clear city list
        setFilter((prevFilter) => ({
            ...prevFilter,
            city_id: '', // Reset city_id
            status_id: ''
        }));
    };

    const fetchPropertyTypeList = async () => {
        try {
            const response = await postRequest(`/property-type/list`, {
                status: '1',
                sort_by: "type",
                sort_order: "ASC"
            });
            if (response?.data?.propertyTypeList) {
                setPropertyTypeList(response?.data?.propertyTypeList);
            }
        } catch (error) {
            console.error("Error fetching property types:", error);
        }
    };

    const fetchLoanPurposeList = async () => {
        try {
            const response = await postRequest(`/loan-purpose/list`, {
                status: '1',
                sort_by: "name",
                sort_order: "ASC"
            });
            if (response?.data?.loadPurposeList) {
                setLoanPurposeList(response?.data?.loadPurposeList);
            }
        } catch (error) {
            console.error("Error fetching Loan Purpose types:", error);
        }
    };

    // Function to format the input as currency
    const formatCurrency = (value) => {
        if (!value) return ''; // If no value, return empty
        const numericValue = value.toString().replace(/[^\d]/g, ''); // Remove non-numeric characters
        return `$${Number(numericValue).toLocaleString()}`; // Add dollar sign and commas
    };

    // Function to handle input changes and maintain numeric values
    const handleCurrencyChange = (e, fieldName) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^\d]/g, ''); // Remove non-numeric characters
        handleFilterChange({
            target: {
                name: fieldName,
                value: numericValue, // Keep the raw numeric value for calculations
            },
        });
    };




    useEffect(() => {
        fetchPropertyTypeList();
        fetchLoanPurposeList();
    }, [user]);

    return (
        <div className="tw-w-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px]">
            <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-pt-4">
                {/* Header Title */}
                <h2 className="common-font tw-text-[#444444] tw-text-[20px] tw-ml-2">
                    Filters
                </h2>

                {/* Buttons Section */}
                <div className="tw-flex tw-items-center tw-space-x-4">
                    {/* Reset Button */}
                    <div
                        tabIndex={0}
                        className="tw-w-[120px] tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-bg-[#FFFFFF] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-cursor-pointer hover:-tw-translate-y-1 tw-transition-all tw-duration-300"
                        onClick={handleReset}
                    >
                        <i className="fas fa-redo tw-text-[#888888] tw-me-[10px]"></i>
                        <button className="tw-text-[#2A2F70] tw-text-[16px] tw-font-semibold">
                            Reset All
                        </button>
                    </div>

                    {/* Apply Filters Button
                    <div
                        tabIndex={0}
                        className="tw-w-[140px] tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-bg-[#7DDE92] tw-rounded-[10px] tw-cursor-pointer hover:-tw-translate-y-1 tw-transition-all tw-duration-300"
                        onClick={filterHandler}
                    >
                        <i className="fas fa-chevron-right tw-text-[#2A2F70] tw-me-[10px]"></i>
                        <button className="tw-text-[#2A2F70] tw-text-[16px] tw-font-semibold">
                            Apply Filters
                        </button>
                    </div> */}
                </div>
            </div>


            {/* Filter Content */}

            <div className="tw-w-full tw-h-auto tw-px-[24px] tw-pb-[24px]">
                <div className="tw-flex tw-justify-between tw-space-x-4 tw-mt-2">
                    {/* Property Type Dropdown */}
                    <div className="tw-w-[35%]">
                        <div className="tw-label">Property Type</div>
                        <div className="tw-w-full tw-h-[42px]">
                            <select
                                className="tw-select property_type_dropdown"
                                name="property_type_id"
                                value={filter?.property_type_id}
                                onChange={handleFilterChange}
                            >
                                <option value="">
                                    Any
                                </option>
                                {propertyTypeList?.map((item) => (
                                    <option key={item?.id} value={item?.id}>
                                        {item?.type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Offer Status Dropdown */}
                    <div className="tw-w-[35%]">
                        <div className="tw-label">Offer Status</div>
                        <div className="tw-w-full tw-h-[42px]">
                            <select
                                className="tw-select application_offer_status_dropdown"
                                name="status_id"
                                value={filter?.status_id || ""}
                                onChange={handleFilterChange}
                            >
                                <option value="">Any</option>
                                <option value="1">No Offer</option>
                                <option value="2">Offer Sent</option>
                                <option value="4">Offer Accepted</option>

                            </select>

                        </div>
                    </div>

                    {/* Property Value Range */}
                    <div className="tw-w-[30%]">
                        <div className="tw-label">Property Value</div>
                        <div className="tw-h-[42px] tw-flex tw-mr-0">
                            <div className="tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2">
                                <span className="tw-flex tw-items-center">
                                    <span className="tw-text-[#888888]">Min</span>
                                    <span className="tw-text-[#242424] tw-mx-2">|</span>
                                </span>
                                <input
                                    type="text"
                                    name="property_value_min"
                                    className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] property_value_min"
                                    value={formatCurrency(filter?.property_value_min || '')}
                                    onChange={(e) => handleCurrencyChange(e, 'property_value_min')}
                                />
                            </div>

                            <div className="tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]">
                                <span className="tw-flex tw-items-center">
                                    <span className="tw-text-[#888888]">Max</span>
                                    <span className="tw-text-[#242424] tw-mx-2">|</span>
                                </span>
                                <input
                                    type="text"
                                    name="property_value_max"
                                    className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] property_value_max"
                                    value={formatCurrency(filter?.property_value_max || '')}
                                    onChange={(e) => handleCurrencyChange(e, 'property_value_max')}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {/* Additional Filters */}
                <div className="tw-flex tw-space-x-4 tw-mt-4">
                    <div className="tw-w-[35%]">
                        <div className="tw-label">Loan Purpose</div>
                        <div className="tw-w-full tw-h-[42px]">
                            <select
                                className="tw-select"
                                value={filter?.loan_purpose_id}
                                name="loan_purpose_id"
                                onChange={handleFilterChange}
                                id="loan_purpose_dropdown"
                            >
                                <option value="">
                                    Any
                                </option>
                                {loanPurposeList?.map((item) => (
                                    <option key={item?.id} value={item?.id}>
                                        {item?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="tw-w-[35%]">
                        <div className="tw-label">Max LTV</div>
                        <div className="tw-w-full tw-h-[42px] tw-flex tw-items-center">
                            <Box sx={{ width: '80%' }} className="tw-h-full tw-flex tw-items-center tw-pr-2">
                                <Slider
                                    value={filter?.ltv || 0}
                                    onChange={(event, newValue) => {
                                        handleFilterChange({
                                            target: { name: 'ltv', value: newValue },
                                        });
                                    }}
                                    aria-label="LTV Slider"
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                    step={1}
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                            <div className="tw-w-[20%] tw-h-[42px] tw-flex tw-items-center tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-overflow-hidden">
                                <input
                                    type="number"
                                    className="tw-w-[80%] tw-text-center tw-h-full tw-border-none tw-text-[#242424] ltv_counting"
                                    value={filter?.ltv || 0}
                                    onChange={(event) => {
                                        const inputValue = event.target.value === '' ? '' : Number(event.target.value);
                                        if (inputValue >= 0 && inputValue <= 100) {
                                            handleFilterChange({ target: { name: 'ltv', value: inputValue } });
                                        }
                                    }}
                                    placeholder="LTV"
                                />
                                <span className="tw-w-[20%] tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-[#f9f9ff] tw-text-[#888888]">%</span>
                            </div>
                        </div>
                    </div>


                    <div className='tw-w-[30%]'>
                        <div className='tw-label'>
                            State
                        </div>
                        <div className='tw-w-full tw-h-[42px]'>
                            <select className='tw-select state_dropdown'
                                value={filter?.state_id}
                                onChange={handleStateChange}
                            >
                                <option value="" >Any</option>
                                {stateList.map((item, index) => (
                                    <option key={index} value={item?.state_code} data-stateid={item?.id}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
};

export default FilterForm;
