import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';


const MarkerInfoModal = ({ open, handleClose, properyDetails, mapCenter }) => {

    const streetViewRef = useRef(null); // Reference for the street view container

    const [isModalOpened, setIsModalOpened] = useState(false);

    // When modal opens, initialize the Street View
    useEffect(() => {
        if (open) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    }, [open]);

    useEffect(() => {
        if (isModalOpened && mapCenter && streetViewRef.current) {
            const streetView = new window.google.maps.StreetViewPanorama(streetViewRef.current, {
                position: mapCenter,
                pov: { heading: 34, pitch: 10 },
                zoom: 1,
            });

            const map = new window.google.maps.Map(streetViewRef.current, {
                center: mapCenter,
                zoom: 12,
                streetViewControl: false,
            });

            map.setStreetView(streetView);
        }
    }, [isModalOpened, mapCenter]); // Reinitialize when mapCenter or modal open state changes


    if (!open) return null;

    return (
        <div className="tw-flex tw-items-center tw-justify-center">

            <Modal open={open} onClose={handleClose} ariaHideApp={false}>
                <Box
                    className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        outline: 'none',
                        width: {
                            xs: '90%', // 90% width on small screens
                            md: '50%', // 500px width on medium and large screens
                            lg: '40%',
                        },
                    }}
                >
                    <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                        <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">Property Info</Typography>
                        <Button onClick={handleClose} className="tw-text-gray-500">X</Button>
                    </div>
                    <Box>
                        <div className="tw-mb-4">
                            <div className='tw-property-section'>

                                <div className='tw-property-detail-row'>
                                    <div className='tw-property-left-partition'>
                                        <span className='tw-property-label'>Property Type</span>
                                        <h1 className='tw-property-value'>{properyDetails?.propertyType ? properyDetails?.propertyType : '-'}</h1>
                                    </div>
                                    <div className='tw-property-right-partition'>
                                        <span className='tw-property-label'>Purpose</span>
                                        <h1 className='tw-property-value'>{properyDetails?.purpose ? properyDetails?.purpose : '-'}</h1>
                                    </div>
                                </div>

                                <div className='tw-property-detail-row'>
                                    <div className='tw-property-left-partition'>
                                        <span className='tw-property-label'>Property Value</span>
                                        <h1 className='tw-property-value'>{properyDetails?.propertyValue}</h1>
                                    </div>
                                    <div className='tw-property-right-partition'>
                                        <span className='tw-property-label'>Loan Amount</span>
                                        <h1 className='tw-property-value'>{properyDetails?.loanAmount}</h1>
                                    </div>
                                </div>

                                <div className='tw-property-detail-row'>
                                    <div className='tw-property-left-partition'>
                                        <span className='tw-property-label'>LTV</span>
                                        <h1 className='tw-property-value'>{properyDetails?.ltv ? properyDetails?.ltv : '-'}</h1>
                                    </div>
                                    <div className='tw-property-right-partition'>
                                        <span className='tw-property-label'>Borrower</span>
                                        <h1 className='tw-property-value'>{properyDetails.borrower ? properyDetails.borrower : '-'} </h1>
                                    </div>
                                </div>
                                <div className='tw-property-detail-row'>
                                    <div className='tw-property-right-partition'>
                                        <span className='tw-property-label'>property address</span>
                                        <h1 className='tw-property-value'>{properyDetails.streetAddress ? properyDetails.streetAddress : '-'} </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Street View at the Bottom */}
                        <Box sx={{ marginTop: '20px' }}>
                        <div ref={streetViewRef} style={{ height: '300px', width: '100%' }} />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default MarkerInfoModal;
