import React from 'react'
import BorrowerTable from './BorrowerTable'
import LenderTable from './LenderTable'

function Table() {
  return (
    <div className='tw-w-[66.5%] tw-h-full tw-my-10'>
        <div className=''>
            <BorrowerTable/>
        </div>
        <div className='tw-mt-[35px]'>
            <LenderTable/>
        </div>

    </div>
  )
}

export default Table