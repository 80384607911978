import React, { createContext, useState, useContext } from 'react';
import { authLocal } from '../utils/common';

// Create a context
export const UserContext = createContext();

// Create a provider component
export const UserDataProvider = ({ children }) => {
  const [user, setUser] = useState(authLocal.get());
  const [currentOfferPropertyDetails, setCurrentOfferPropertyDetails] = useState(null);

  // Function to reset the context
  const resetUser = () => {
    setUser(null); // Reset the data to null or initial state
  };

  return (
    <UserContext.Provider value={{ user, setUser, resetUser, currentOfferPropertyDetails, setCurrentOfferPropertyDetails }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access user data
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserDataProvider');
  }
  return context?.user ?? ""; // Return boolean value indicating if user is authenticated
};
