import React, { useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import LoginBorrowerIcon from "../../../assets/icons/borrowerIcon.svg";
import LoginLenderIcon from "../../../assets/icons/lenderIcon.svg"
import ProductImage1 from "../../../assets/images/product_image_1.png";
import ProductImage2 from "../../../assets/images/product_image_2.png";
import GenericButton from "../../common/buttons/GenericButton";
import { UserContext } from "../../../contexts/UserContext";
import "../../../styles/ProductArea.css";

const ProductArea = ({ isUserLoggedIn }) => {
    const { setUserType } = useContext(UserContext);

    // Refs for sections to observe
    const text1Ref = useRef(null);
    const image1Ref = useRef(null);
    const text2Ref = useRef(null);
    const image2Ref = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // Trigger when 10% of the element is visible
        };

        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("active"); // Add the active class to trigger the animation
                    observer.unobserve(entry.target); // Stop observing once the animation is triggered
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Add elements to be observed
        const elements = [text1Ref.current, image1Ref.current, text2Ref.current, image2Ref.current];
        elements.forEach((el) => {
            if (el) observer.observe(el);
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-flex-col tw-justify-center">
                <div className="tw-flex tw-flex-col">
                    <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center">Our Platform Provides</h2>
                    <h2 className="tw-font-oswald tw-text-4xl tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-text-[#4853e4]">Seamless Connection</h2>
                </div>

                <div className="tw-flex tw-gap-8 lg:tw-gap-0 lg:tw-flex-row tw-flex-col-reverse tw-items-center tw-my-8 tw-overflow-hidden">
                    <div
                        ref={text1Ref}
                        className="lg:tw-w-1/2 tw-w-5/6 tw-flex-col lg:tw-m-10 tw-space-y-4 fade-in slide-in-left-txt"
                    >
                        <h2 className="tw-font-oswald tw-text-5xl tw-text-[#191919] tw-font-bold">Lenders Love Re-Loan</h2>
                        <p className="tw-font-inter tw-text-2xl lg:tw-text-3xl tw-text-[#949494]">
                            Re-Loan provides lenders with powerful tools to review applications,
                            analyze borrower details, and manage communications seamlessly. Our
                            platform enables lenders to make faster, data-driven decisions while
                            reducing risks and increasing their efficiency, creating opportunities
                            to connect with more borrowers and close deals profitably.
                        </p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={"/sign-in"} tabIndex={-1}>
                                    <button
                                        onClick= {() => setUserType("lender")}
                                        className='tw-hidden xl:tw-flex hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-reloan hover:tw-drop-shadow-xl'
                                    >
                                        <img src={LoginLenderIcon} className="tw-w-10 tw-h-10" alt="lender" />
                                        <p className="tw-text-md tw-text-black tw-font-oswald">Log In As Lender</p>
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>

                    <img
                        ref={image1Ref}
                        className="tw-w-full lg:tw-w-1/2 slide-in-right"
                        src={ProductImage1}
                        alt="Lenders Love Our Services"
                        loading="lazy"
                    />
                </div>
            </div>

            <div className="tw-flex tw-flex-col tw-justify-center tw-overflow-hidden">
                <div className="tw-flex tw-gap-8 lg:tw-gap-0 lg:tw-flex-row tw-flex-col tw-items-center tw-my-8">
                    <img
                        ref={image2Ref}
                        className="tw-w-full lg:tw-w-1/2 slide-in-left"
                        src={ProductImage2}
                        alt="Borrowers Thrive with Our Easy Process"
                        loading="lazy"
                    />

                    <div
                        ref={text2Ref}
                        className="tw-w-5/6 lg:tw-w-1/2 tw-flex-col lg:tw-m-10 fade-in slide-in-right-txt"
                    >
                        <h2 className="tw-font-oswald tw-text-5xl tw-text-[#191919] tw-font-bold">Borrowers Thrive on Re-Loan</h2>
                        <p className="tw-font-inter tw-text-2xl lg:tw-text-3xl tw-text-[#949494]">
                            At Re-Loan, we simplify the borrowing experience by helping borrowers
                            submit applications, monitor progress, and connect with a network of
                            lenders in one streamlined platform. Through a user-friendly dashboard,
                            borrowers gain access to real-time updates, compare offers efficiently,
                            and make informed decisions — all without unnecessary costs or complexities.
                        </p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={"/sign-in"} tabIndex={-1}>
                                    <button
                                        onClick= {() => setUserType("borrower")}
                                        className='tw-hidden xl:tw-flex hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                                    >
                                        <img src={LoginBorrowerIcon} className="tw-w-10 tw-h-10" alt="borrower" />
                                        <p className="tw-text-md tw-text-black tw-font-oswald">Log In As Borrower</p>
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductArea;
