import React, { createContext, useContext, useState } from 'react';

// Create the PromoCodeContext
const PromoCodeContext = createContext();

// Create a provider component
export const PromoCodeProvider = ({ children }) => {
    const [promoCodeDetail, setPromoCodeDetail] = useState(null); // Initial state for promo code detail

    return (
        <PromoCodeContext.Provider value={{ promoCodeDetail, setPromoCodeDetail }}>
            {children}
        </PromoCodeContext.Provider>
    );
};

// Custom hook for using the PromoCodeContext
export const usePromoCode = () => {
    const context = useContext(PromoCodeContext);
    if (!context) {
        throw new Error('usePromoCode must be used within a PromoCodeProvider');
    }
    return context;
};
