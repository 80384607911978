// footer for home page
import React, { useState } from "react";
import SubmitIcon from "../../../assets/icons/borrowerIcon.svg";
import ReloanIconWhite from "../../../assets/icons/logo-white.svg";
import FbIcon from "../../../assets/icons/fbIcon.svg";
import InstaIcon from "../../../assets/icons/instaIcon.svg";
import LinkedInIcon from "../../../assets/icons/linkedInIcon.svg";

const Footer = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setError('Please enter a valid email.');
            return;
        }

        setError(''); // Clear error if validation passes
        // Proceed with form submission logic (e.g., API call)
        console.log('Form submitted with email:', email);
    };

    return (
        <div className="tw-w-full tw-bg-[#4853E4] tw-p-2 lg:tw-p-10 tw-rounded-tl-3xl tw-rounded-tr-3xl">
            <div className='tw-flex tw-justify-between tw-items-center tw-mb-4'>
                <h1 className='tw-font-oswald tw-text-[#FFFFFF] tw-font-semibold tw-text-2xl lg:tw-text-4xl'>Get in touch for latest news!</h1>

                <div className='tw-flex tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-space-x-2 lg:tw-space-y-0'>
                    <div>
                    <input type='email' placeholder='Enter your Email' value={email}
                        onChange={(e) => setEmail(e.target.value)} className={`tw-rounded-full tw-font-inter tw-p-2.5 tw-text-[#000000] tw-border-[1px] tw-border-[#FAFAFB] focus:tw-ring-[1px] focus:tw-ring-[#FAFAFB] tw-font-inter ${error ? 'tw-border-red-500' : ''}`} />
                    {error && <p className="tw-text-red-600 tw-text-[14px] tw-mx-2">{error}</p>}
                    </div>
                    <button
                        onClick={handleSubmit}
                        className='tw-h-[45px] tw-flex tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl tw-justify-center'
                    >
                        <img src={SubmitIcon} className='tw-w-6 tw-h-6' alt='submit email icon' />
                        <p className='tw-text-md tw-font-light tw-text-[#FFFFFF] tw-font-oswald tw-px-2'>Submit</p>
                    </button>
                </div>
            </div>

            <div className="tw-flex tw-justify-between tw-items-center tw-border-y-[1px] tw-border-[#FFFFFF]/25 tw-py-4">
                <img src={ReloanIconWhite} alt="reloan white icon" className="tw-object-cover" />

                <div className="tw-flex tw-space-x-8 tw-me-[130px]">
                    <a href="#" className="tw-font-oswald tw-text-[#FFFFFF] tw-text-sm tw-font-light tw-underline">Website Terms</a>
                    <a href="#" className="tw-font-oswald tw-text-[#FFFFFF] tw-text-sm tw-font-light tw-underline">Privacy Policy</a>
                    <a href="#" className="tw-font-oswald tw-text-[#FFFFFF] tw-text-sm tw-font-light tw-underline">Cookies Policy</a>
                </div>

                <div className="tw-flex tw-space-x-4">
                    <a href="#">
                        <img src={LinkedInIcon} alt="linkedin icon" />
                    </a>
                </div>
            </div>

            <div className="tw-flex tw-justify-center tw-items-center tw-mt-4">
                <p className="tw-font-inter tw-text-[#FFFFFF] tw-font-light tw-text-sm">Copyright © 2024 by Re-loan. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer;
