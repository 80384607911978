import React from 'react';
import '../../../styles/LendersBannerScroll.css';

const lenderTypes = [
    "Direct Lenders",
    "Regional Banks",
    "Hedge Funds",
    "Family Offices",
    "HNWI's"
];

const LendersBannerArea = () => {
    return (
        <div className="tw-relative tw-bg-[#4853E4] tw-flex tw-flex-col tw-py-4 tw-w-full">
            <h2 style={{ fontSize: '32px', fontStyle: 'italic' }} className="tw-font-oswald tw-text-4xl tw-font-bold tw-text-center">
                Join Our Nationwide Network Of Lenders Today
            </h2>

            <div className="slider tw-my-0 tw-w-full lg:tw-w-[62rem]">
                <div className="slide-track">
                    {lenderTypes.concat(lenderTypes).map((type, index) => (
                        <div className="slide" key={index}>
                            <span className="tw-text-gray-300 tw-text-2xl tw-italic tw-font-semibold">{type}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LendersBannerArea;
