// src/routes/PrivateRoutes.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  return user ? (
    children ? children : <Outlet />
  ) : <Navigate to="/" />;
};

export default PrivateRoute;