import React from 'react';

const ActiveSubscription = ({ plan, offer }) => {
    return (
        <div>
            {plan?.plan && (
                <div className="tw-flex tw-gap-1">
                    {/* Plan Badge */}
                    <div
                        className="tw-px-4 tw-py-3.5 tw-rounded-[60px] tw-flex tw-justify-center tw-items-center tw-h-[20px]"
                        style={{ backgroundColor: plan?.bgcolor || '#ccc' }} // Use inline style for dynamic background color
                    >
                        {plan && (
                            <img
                                src={plan?.icon_image}
                                alt={plan?.plan}
                                className="tw-rounded-full tw-w-[16px] tw-h-[16px] tw-object-cover"
                            />
                        )}
                        <div
                            className="tw-font-inter tw-text-[14px] tw-font-[700] tw-px-1 tw-text-white"
                        >
                            {plan?.plan}
                        </div>
                    </div>

                    {/* Offer Badge */}
                    {plan?.plan != 'Standard' && (
                        <div
                            className="tw-font-inter tw-text-[14px] tw-font-[700] tw-px-4 tw-min-w-[35px] tw-py-3.5 tw-rounded-[60px] tw-flex tw-justify-center tw-items-center tw-h-[20px]"
                            style={{ backgroundColor: plan?.bgcolor || '#ccc' }} // Use inline style for dynamic background color
                        >
                            {offer > 0 ? offer : 0}
                        </div>
                    )
                    }
                </div>
            )
            }
        </div>
    );
};

export default ActiveSubscription;
