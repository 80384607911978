// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    justify-content: center; /* Center the header text */
    align-items: center;
    padding: 20px;
    background: transparent;
    position: relative; /* Add this to position the buttons */
  }
  
  .header h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 2rem;
    color: #ffffff;
  }
  
  .auth-buttons {
    display: flex;
    gap: 10px;
    position: absolute;
  }
  
  .login-button,
  .signup-button {
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Droid Sans', sans-serif;
  }
  
  .login-button:hover,
  .signup-button:hover {
    background-color: #ffffff;
    color: black;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,2BAA2B;IACpD,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,kBAAkB,EAAE,qCAAqC;EAC3D;;EAEA;IACE,uCAAuC;IACvC,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,kBAAkB;EACpB;;EAEA;;IAEE,6BAA6B;IAC7B,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,qCAAqC;EACvC;;EAEA;;IAEE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".header {\n    justify-content: center; /* Center the header text */\n    align-items: center;\n    padding: 20px;\n    background: transparent;\n    position: relative; /* Add this to position the buttons */\n  }\n  \n  .header h1 {\n    font-family: 'Libre Baskerville', serif;\n    font-size: 2rem;\n    color: #ffffff;\n  }\n  \n  .auth-buttons {\n    display: flex;\n    gap: 10px;\n    position: absolute;\n  }\n  \n  .login-button,\n  .signup-button {\n    background-color: transparent;\n    padding: 10px 20px;\n    border-radius: 5px;\n    font-size: 1rem;\n    cursor: pointer;\n    font-family: 'Droid Sans', sans-serif;\n  }\n  \n  .login-button:hover,\n  .signup-button:hover {\n    background-color: #ffffff;\n    color: black;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
