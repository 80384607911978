import React, { useState, useContext, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';

import Modal from './Modal';
import { useLoader } from '../../../contexts/LoaderContext';
import { UserContext } from '../../../contexts/UserContext';
import toast from 'react-hot-toast';
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import { Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useModalContext } from '../../../contexts/ModalContexts';
import { formatTimeDifference } from '../../../utils/common';
import { useApiService } from '../../../services/apiService';


const ActionModal = memo(({ setHidden, setpromoCodeData, setIsModalOpen, fetchPromoCodeDetail, ...params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { showLoader, hideLoader } = useLoader();
    const { showModal } = useModalContext();
    const { getRequest, deleteRequest } = useApiService();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };

    const deletePromoCodeDetailHandler = async (params) => {
        showLoader();
        try {
            const response = await deleteRequest(
                `/promo-code/delete/${params?.id}`
            );
            if (response?.success) {
                showModal(response?.data?.message, "success");
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchPromoCodeDetail();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to delete Promo Code. Please try again.', "fail");
        } finally {
            hideLoader();
        }
    };

    // // Confirm deletion function
    const handleDeleteConfirmation = () => {
        deletePromoCodeDetailHandler(params?.row);
        setOpenConfirm(false);
    };

    const handleEditPromoCodeData = async (params) => {
        showLoader();
        try {
            const response = await getRequest(`/promo-code/${params?.id}`);
            if (response?.success) {
                const data = response?.data?.promoCode;
                setpromoCodeData({
                    promoCode: data?.code || '',
                    usageLimit: data?.usage_limit || '',
                    discountValue: data?.discount_value || '',
                    status: data?.status || '1',
                });
                setHidden(data?.id);
                setIsModalOpen(true);
            } else {
                showModal("Something Went Wrong.", "fail");
            }
            fetchPromoCodeDetail();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
        } finally {
            hideLoader();
        }
    }

    return <>
        <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1"
            onClick={handleClick}
        >
            <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        </button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={handleConfirmOpen}>Delete</MenuItem>
            <MenuItem onClick={() => handleEditPromoCodeData(params?.row)}>Edit</MenuItem>
        </Menu>
        <Dialog
            open={openConfirm}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Promo Code?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>
});

function Promo_code() {
    const { user } = useContext(UserContext);
    const { postRequest, putRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [hidden, setHidden] = useState(null);
    const [promoCodeData, setpromoCodeData] = useState({
        promoCode: '',
        usageLimit: 0,
        discountValue: 0,
        status: '1',
    });
    const [fetchPromoCodeData, setFetchPromoCodeData] = useState([]);
    const openModal = () => {
        setpromoCodeData({
            promoCode: '',
            usageLimit: 0,
            discountValue: 0,
            status: '1',
        });
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const validate = (data) => {
        const newErrors = {};
        if (!data.promoCode || !data.promoCode.trim()) newErrors.promoCode = 'Promo Code is required.';
        if (data.usageLimit <= 0) newErrors.usageLimit = 'Usage Limit must be greater than 0.';
        if (data.discountValue < 0) newErrors.discountValue = 'Discount Value must be greater than 0.';
        if (!['1', '0'].includes(data.status)) newErrors.status = 'Invalid status selected.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmit = async (data) => {
        // Validate input data before proceeding
        if (!validate(data)) {
            toast.error('Please correct the highlighted errors.');
            return;
        }

        showLoader();

        const payload = {
            code: data?.promoCode,
            discount_value: data?.discountValue,
            usage_limit: data?.usageLimit,
            status: data?.status,
        };

        // Determine API endpoint and HTTP method based on hidden
        const endpoint = hidden
            ? `/promo-code/update/${hidden}`
            : `/promo-code/create`;

        const method = hidden ? putRequest : postRequest;

        try {
            const response = await method(endpoint, payload);
            const message = response?.data?.message || 'Operation successful';
            toast.success(message);

            // Additional actions after success
            fetchPromoCodeDetail();
            closeModal();
        } catch (err) {
            console.error('Error during Promo Code operation:', err);
            const errorMessage = err.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            hideLoader();
        }
    };
    const fetchPromoCodeDetail = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await postRequest(`/promo-code/list`);
            if(response?.success) {
                const promoData = response?.data?.promoCodeList || [];
                setFetchPromoCodeData(promoData);
            } else {
                setFetchPromoCodeData([]);
            }

        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchPromoCodeDetail();
    }, [user]);

    const columns = [
        {
            field: 'code',
            headerName: 'Code',
            width: 180,
            editable: false,
        },
        {
            field: 'discount_value',
            headerName: 'Discount Value',
            width: 350,
            editable: false,
        },
        {
            field: 'usage_limit',
            headerName: 'Usage Limit',
            width: 250,
            editable: false,
        },
        {
            field: 'times_used',
            headerName: 'No. of Used',
            width: 250,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 195,
            editable: false,
            renderCell: (params) => {
                const formattedTimes = [];
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ActionModal
                        setIsModalOpen={setIsModalOpen}
                        fetchPromoCodeDetail={fetchPromoCodeDetail}
                        setpromoCodeData={setpromoCodeData}
                        setHidden={setHidden}
                        {...params}
                    />
                </>
            ),
        },
    ];

    const rows = fetchPromoCodeData?.length ? fetchPromoCodeData?.map((app) => ({
        id: app?.id,
        code: app?.code,
        discount_value: app?.discount_value,
        usage_limit: app?.usage_limit,
        times_used: app?.times_used,
        status: app?.status,
        times_used: app?.times_used,
        createdAt: app.created_at || 'Just now',
    })) : [];

    const handleRowClick = () => {
        console.log('test');
    };

    return (
        <div className="tw-w-full tw-h-full">
            <header className="tw-flex tw-justify-between tw-items-center tw-py-4">
                <main>
                    <div className="tw-flex tw-gap-4 tw-items-center">
                        <Link to={`/dashboard/admin`}>
                            <i className="fas fa-chevron-left tw-w-[18.41px] tw-h-[15.41px] tw-text-[#2A2F70]"></i>
                        </Link>
                        <h1 className="tw-text-[#000000] tw-font-bold tw-text-[40px] common-font">
                            Promo Code Management
                        </h1>
                    </div>
                    <p className="tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70">
                        Here is the information about all your Promo Codes.
                    </p>
                </main>

                <button
                    tabIndex={0}
                    className="tw-w-[200.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center"
                    onClick={openModal}
                >
                    <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
                        <i className="fas fa-plus-circle tw-text-[16px] tw-text-[#2A2F70]"></i>
                    </span>
                    <h2 className="tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]">
                        Create Promo Code
                    </h2>
                </button>
            </header>

            <div className='tw-w-full tw-h-[500px] tw-my-[20px]'>
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row) => row.id, // Use the unique id from your data
                        userStatus: [""],
                        dashboardType: 'promo-code',
                        title: "Promo Code Management",
                        getRowClassName: (params) =>
                            params?.row?.id === null ? 'selected-row' : ''
                    }}
                    rows={rows}
                    onRowClick={handleRowClick}
                    columns={columns}
                    tableHeight="200px"
                    maxHeight='140vh'
                    minHeight='400px'
                />
            </div>

            {isModalOpen && (
                <Modal
                    promoCodeData={promoCodeData}
                    closeModal={closeModal}
                    onSubmit={handleFormSubmit}
                    errors={errors}
                />
            )}
        </div>
    );
}

export default Promo_code;
