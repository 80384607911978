import React, { useEffect, useState, useContext, memo, useRef } from 'react'
import StatsDropdown from '../../common/StatsDropdown'
import StatsCards from '../../common/StatsCards'
import FilterForm from './FilterForm';
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLoader } from '../../../contexts/LoaderContext';
import { useModalContext } from '../../../contexts/ModalContexts';

const ActionModal = memo(({ fetchAllUserLoanApplications, ...params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    const { showModal } = useModalContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };

    const deleteApplicationHandler = async (params) => {
        showLoader();
        try {
            const response = await axios.delete(
                `${awsContextVal?.endpoint}/application/delete/${params?.application_id}`
            );

            if (response?.status === 200) {
                showModal(response?.data?.message, "success");
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }

            fetchAllUserLoanApplications();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to delete Application. Please try again.', "fail");
        } finally {
            hideLoader();
        }
    };

    // Confirm deletion function
    const handleDeleteConfirmation = () => {
        deleteApplicationHandler(params?.row);
        setOpenConfirm(false); // Close the confirmation dialog
    };

    const handleArchiveApplication = async (params, status) => {
        showLoader();
        try {
            const response = await axios.put(
                `${awsContextVal?.endpoint}/application/update-status/${params?.application_id}`, {
                status_id: status
            }
            );
            if (response?.status === 200) {
                showModal(response?.data?.message, "success");
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchAllUserLoanApplications();
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to Archive Application. Please try again.', "fail");
        } finally {
            hideLoader();
            handleClose();
        }

    }

    return <>
        <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1"
            onClick={handleClick}
        >
            <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        </button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={handleClose}>View History</MenuItem>
            <MenuItem onClick={handleConfirmOpen}>Delete Application</MenuItem>
            <MenuItem onClick={() => handleArchiveApplication(params?.row, "5")}>Archive Application</MenuItem>
        </Menu>
        <Dialog
            open={openConfirm}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this application?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>
});

const initialFilterState = {
    user_id: '',
    loan_purpose: '',
    condition: '',
    property_type: '',
    property_size_min: '',
    property_size_max: '',
    property_value_min: '',
    property_value_max: '',
    appraised_value_min: '',
    appraised_value_max: '',
    application_status: '',
    date_submitted: '',
    city_id: '',
    state_id: '',
    postal_code: '',
    proximity: '',
    loan_purpose: '',
    loan_term: '',
    ltv: '',
    interest_rate_type: '',
    borrower_type: '',
    borrower_existing_dept_min: '',
    borrower_existing_dept_max: '',
    borrower_credit_score_min: '',
    borrower_credit_score_max: '',
    sort_by: 'a.id',
    sort_order: 'DESC',
};

function ViewAllApplication() {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const [userAllloanApplications, setUserAllloanApplications] = useState([]);
    const [filter, setFilter] = useState(initialFilterState);
    const initialFilterRef = useRef(initialFilterState);
    const { showLoader, hideLoader } = useLoader();

    const [formattedTimes, setFormattedTimes] = useState([]);

    const fetchAllUserLoanApplications = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/list`, filter);
            const applications = response?.data?.applications || [];
            setUserAllloanApplications(applications);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    const filterHandler = () => {
        fetchAllUserLoanApplications();
    };
    const resetFilterHandler = async () => {
        // Reset the filter to its initial state
        const updatedFilter = initialFilterRef.current;
        setFilter({ ...updatedFilter, resetKey: Math.random() });
    }

    useEffect(() => {
        fetchAllUserLoanApplications(filter);
    }, [filter.resetKey]);

    const stats = [
        { title: 'Total Applications', value: '40,689', change: '8.5%' },
        { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
        { title: 'Pending Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '120%', change: '8.5%' },
    ];

    // function to get age of application
    const formatTimeDifference = (date) => {
        const now = new Date();
        const createdAt = new Date(date);
        const diffInSeconds = Math.floor((now - createdAt) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    };

    // Update every 60 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setFormattedTimes((prevTimes) => {
                return userAllloanApplications.map((app) => ({
                    application_id: app.application_id,
                    formattedTime: formatTimeDifference(app.application_created_at),
                }));
            });
        }, 60000); // Update every 60 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [userAllloanApplications]);

    const columns = [
        { field: 'application_id', headerName: 'ID', width: 70, editable: false },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            editable: true,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'property_address',
            headerName: 'Property Address',
            width: 170,
            editable: false,
        },
        {
            field: 'loan_purpose',
            headerName: 'Loan Purpose',
            width: 150,
            editable: false,
        },
        {
            field: 'property_value',
            headerName: 'Property Value',
            type: 'number',
            width: 140,
            editable: false,
        },
        {
            field: 'loan_amount',
            headerName: 'Loan Amount',
            width: 140,
            editable: false,
        },
        {
            field: 'ltv',
            headerName: 'LTV',
            type: 'number',
            width: 150,
            editable: false,
        },
        {
            field: 'borrower',
            headerName: 'Borrower',
            width: 130,
            editable: false,
        },
        {
            field: 'lender',
            headerName: 'Lender',
            type: 'number',
            width: 130,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 155,
            renderCell: (params) => {
                const status = params?.value;
                let badgeColor;
                let badgeChild;

                switch (status) {
                    case 'Pending':
                        badgeColor = '#377DFF2E';
                        badgeChild = '#377DFF';
                        break;
                    case 'Unread Offers':
                        badgeColor = '#FFD7002E';
                        badgeChild = '#FFD700';
                        break;
                    case 'Offers':
                        badgeColor = '#00C9A72E';
                        badgeChild = '#00C9A7';
                        break;
                    case 'Settled':
                        badgeColor = '#4CAF502E';
                        badgeChild = '#4CAF50';
                        break;
                    case 'Archived':
                        badgeColor = '#9E9E9E2E';
                        badgeChild = '#9E9E9E';
                        break;
                    default:
                        badgeColor = '#E0E0E0';
                        badgeChild = '#BDBDBD';
                }

                return (
                    <div
                        className='tw-flex tw-justify-center tw-items-center tw-w-[140px] tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full tw-mt-[12px] tw-space-x-2'
                        style={{ backgroundColor: badgeColor }}
                    >
                        <span
                            className='tw-w-[10px] tw-h-[10px] tw-border tw-border-[#DDDDDD] tw-rounded-full'
                            style={{ backgroundColor: badgeChild }}
                        ></span>

                        <span className='common-font tw-font-normal tw-text-[16px] tw-text-[#070101CC]'>
                            {status}
                        </span>
                    </div>
                );
            },
        },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     width: 60,
        //     renderCell: (params) => (
        //         <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1">
        //             <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        //         </button>
        //     ),
        // },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ActionModal fetchAllUserLoanApplications={fetchAllUserLoanApplications} {...params} />
                </>
            ),
        },
    ];

    const rows = userAllloanApplications?.length ? userAllloanApplications?.map((app) => ({
        application_id: app?.application_id,
        property_address: app?.property_address,
        loan_purpose: app?.loan_purpose,
        property_value: app?.property_value
            ? app.property_value.startsWith('$')
                ? app.property_value
                : '$' + new Intl.NumberFormat().format(Number(app.property_value.replace(/,/g, '')))
            : "$150,000",
        loan_amount: app?.loan_amount
            ? app.loan_amount.startsWith('$')
                ? app.loan_amount
                : '$' + new Intl.NumberFormat().format(Number(app.loan_amount.replace(/,/g, '')))
            : "$250,000",
        ltv: app?.estimated_rate,
        borrower: app?.first_name + ' ' + app?.last_name,
        lender: app?.potential_lenders + '+',
        status: app?.application_status || 'Pending',
        lat: app?.lat ? app?.lat : '40.758896',
        lng: app?.lng ? app?.lng : '-73.985130',
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    const handleRowClick = (params) => {
        // console.log("params:", params)
    };

    useEffect(() => {
        fetchAllUserLoanApplications();
    }, [user]);
    return (
        <div className="tw-w-full tw-h-full tw-my-[12px]">
            <div className='tw-w-[504px] tw-h-[60px] tw-flex tw-justify-between tw-items-center'>
                <Link to="/dashboard/admin">
                    <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                </Link>
                <h1 className=' tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                    Application Management
                </h1>
            </div>
            <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your notifications.</p>


            <div className="tw-block">
                {/* Dropdown at the top */}
                <StatsDropdown />

                {/* Cards */}
                <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg">
                    {stats?.map((stat, index) => (
                        <StatsCards
                            key={index}
                            title={stat?.title}
                            value={stat?.value}
                            change={stat?.change}
                        />
                    ))}
                </div>
            </div>

            <div className="tw-flex tw-space-x-4 tw-mt-4 hidden">
                <FilterForm
                    filter={filter}
                    setFilter={setFilter}
                    filterHandler={filterHandler}
                    resetFilterHandler={resetFilterHandler}
                />
            </div>

            <div className="tw-flex tw-space-x-4 tw-mt-4 hidden tw-w-[100%]">
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row, index) => `${index}-${row?.application_id}`,
                        userStatus: ["Rejected", 'Loan Granted', "Pending", 'New'],
                        dashboardType: 'application_management'
                    }}
                    rows={rows}
                    columns={columns}
                    onRowClick={handleRowClick}
                    height="400px"
                />
            </div>

        </div>
    )
}

export default ViewAllApplication