import React, { useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Input, Button } from '@material-tailwind/react';
import axios from 'axios';
import { signIn, signOut } from "aws-amplify/auth";
import toast from "react-hot-toast";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BorrowerSignupIcon from "../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../assets/icons/lenderIcon.svg";
import { authLocal, capitalizeWords } from '../../utils/common';
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';
import { UserContext } from '../../contexts/UserContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useUserType } from '../../contexts/UserTypeContext';
import ApplicationTable from '../common/applicationTable/ApplicationTable';

function SignIn() {
    // signOut()
    const { setUser } = useContext(UserContext)
    const { userType } = useUserType();

    const navigate = useNavigate();
    const awsContextVal = useContext(AWSAmplifyConfigContext)
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = useState(false);
    const [isPendingDataSubmitted, setIsPendingDataSubmitted] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // State for form data and errors
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({});

    // Function to switch to the login form
    const handleSignUpClick = () => {
        navigate(`/sign-up`);
    };

    const handleForgotPassword = () => {
        navigate(`/forgot-password`);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        // Validate required fields
        if (!formData.email) {
            validationErrors.email = 'Email Address is required';
        }
        if (!formData.password) {
            validationErrors.password = 'Password is required';
        }

        // If there are errors, set the errors state
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            showLoader();
            const { nextStep } = await signIn({ username: formData?.email, password: formData?.password });

            if (nextStep.signInStep === "DONE") {
                toast.success("Signin Successful!");

                // Fetch user details
                const user_details = await axios.post(`${awsContextVal?.endpoint}/fetch-user`, { email: formData.email });
                if (user_details?.data?.user) {
                    authLocal.set(user_details?.data?.user);
                    setUser(user_details?.data?.user);
                    hideLoader();

                    // Redirect based on user type
                    const userType = user_details?.data?.user?.user_type;
                    if (userType === 'borrower') {
                        navigate("/dashboard/borrower");
                    } else if (userType === 'lender') {
                        navigate("/dashboard/lender");
                    } else if (userType === 'admin') {
                        navigate("/dashboard/admin");
                    }

                    // Check for pending application data
                    const pendingData = localStorage.getItem("pendingApplicationData");
                    if (pendingData && userType === 'borrower') {
                        const { loanPurpose, address, propertyValue, loanToValue, lat, lng } = JSON.parse(pendingData);

                        try {
                            // Submit the pending loan application
                            const response = await axios.post(`${awsContextVal.endpoint}/application/create`, {
                                userId: user_details.data.user.id,
                                property_address: address,
                                property_value: propertyValue,
                                loan_purpose: loanPurpose,
                                desired_loan_to_value: loanToValue,
                                lat: lat,
                                lng: lng,
                                property_status: 1
                            });

                            if (response.status === 200) {
                                toast.success("Pending application submitted successfully.");
                                localStorage.removeItem("pendingApplicationData"); // Clear saved data after submission
                                setIsPendingDataSubmitted(true);
                            } else {
                                toast.error("Failed to submit pending application.");
                            }
                        } catch (error) {
                            console.error("Error submitting pending application:", error);
                            toast.error("Error submitting pending application.");
                        }
                    }
                    else if (pendingData) {
                        localStorage.removeItem("pendingApplicationData");
                    }
                } else {
                    hideLoader();
                    toast.error("User not found.");
                }
            } else {
                hideLoader();
                toast.error("User is not available.");
            }
        } catch (error) {
            hideLoader();
            const errorMessage = error?.message || "OOPS! Something went wrong.";
            toast.error(errorMessage);
        }
    };
    
    
    const { setUserType } = useUserType();

    const handleUserTypeChange = (type) => {
      setUserType(type);
    };

    
    
    return (
        <>
            <div className="tw-basis-[60%] tw-bg-white tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-[827px] tw-mx-auto">
                    {/* <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block tw-text-sm">
                        {capitalizeWords(userType)} Profile
                    </div> */}
                    <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-3xl tw-text-[#333333] tw-my-4 tw-font-medium tw-text-center">Sign In to Re-Loan as a {capitalizeWords(userType)}</h2>
                    <div className="tw-font-inter tw-text-[#666666] tw-text-xl">
                        {/* Don't have an account? <button onClick={handleSignUpClick} className="tw-text-black tw-underline tw-text-[#666666]">Sign up</button> */}
                        <div className='tw-flex tw-gap-x-2 tw-items-center'>
                            <div>Don't have an account?</div>
                            <Link to={"/sign-up/borrower"} >
                                <button
                                     onClick={() => handleUserTypeChange('borrower')}
                                    className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                                >
                                    <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='borrower' />
                                    <p className='tw-text-md tw-text-black tw-font-oswald'>Borrower Signup</p>
                                </button>
                            </Link>
                            <Link to={"/sign-up/lender"}>
                                <button
                                   onClick={() => handleUserTypeChange('lender')}
                                    className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl'
                                >
                                    <img src={LenderSignupIcon} className='tw-w-6 tw-h-6' alt='lender' />
                                    <p className='tw-text-md tw-text-black tw-font-oswald'>Lender Signup</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <form className="tw-space-y-10 tw-mt-9" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-w-1/2">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px]">{errors.email}</p>}
                            </div>
                            <div className="tw-relative tw-w-1/2">
                                <label htmlFor="password" className="form-label">Password</label>
                                <Input
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.password ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.password && <p className="tw-text-red-500 tw-text-[14px]">{errors.password}</p>}
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                            <button className="tw-w-1/6 tw-bg-green-300 tw-border tw-text-[#2A2F70] tw-font-bold tw-py-4 tw-rounded-full">
                                Sign In
                                <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                            </button>
                            <p className="tw-mt-3 tw-text-black tw-text-lg">
                                <button onClick={handleForgotPassword} className="tw-font-oswald tw-text-[#2A2F70]">Forgot Password?</button>
                            </p>
                        </div>
                    </form>
                </div>
                {isPendingDataSubmitted && <ApplicationTable />}
            </div>
        </>
    )
}

export default SignIn