import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Modal, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { Input } from '@material-tailwind/react';
import toast from 'react-hot-toast';
import { UserContext } from "../../../../contexts/UserContext";
import { AWSAmplifyConfigContext } from '../../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import { useLoader } from '../../../../contexts/LoaderContext';
import { conversationListHandler } from '../../../common/chat-new/ChatUtils';

function SendOffers({ open, handleClose, applicationId, selectedRowBorrowerId, setConversationList }) {
    const { user } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();
    const [percentInterest, setPercentInterest] = useState('');
    const [interestUnit, setInterestUnit] = useState('year');
    const [originationFee, setOriginationFee] = useState('');
    const [duration, setDuration] = useState('');
    const [durationFrequency, setDurationFrequency] = useState('monthly');
    const [errors, setErrors] = useState({});
    const awsContextVal = useContext(AWSAmplifyConfigContext);

    const handleChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (value) {
            value = Number(value).toLocaleString(); // Add commas to the number
        }
        setOriginationFee(value);
    };

    const navigate = useNavigate();

    const resetForm = () => {
        setPercentInterest('');
        setInterestUnit('year');
        setOriginationFee('');
        setDuration('');
        setDurationFrequency('monthly');
        setErrors({});
    };

    const handleSendOffer = async () => {
        showLoader();  // Show loader at the start of the operation
        const newErrors = {};

        // Validate form fields
        if (!applicationId) {
            toast.error("Please select a row for the application ID.");
            hideLoader();
            return;
        }

        if (!percentInterest) newErrors.percentInterest = "Percent interest is required.";
        if (!originationFee) newErrors.originationFee = "Origination fee is required.";
        if (!duration) newErrors.duration = "Duration is required.";

        setErrors(newErrors);

        // Check if there are validation errors
        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill in all required fields.");
            hideLoader();
            return;
        }

        try {
            const requestData = {
                application_id: applicationId,
                lender_id: user?.id,
                borrower_id: selectedRowBorrowerId,
                interest_on_loan: percentInterest,
                interest_type: interestUnit,
                fees: originationFee.replace(/[^0-9.]/g, ''),
                loan_duration: duration,
                loan_duration_type: durationFrequency,
                status_id: "1",
            };

            const response = await axios.post(`${awsContextVal?.endpoint}/application-offer/add`, requestData);

            if (response.status === 200) {
                toast.success(response?.data?.message || "Offer sent successfully");
                conversationListHandler(applicationId, user, awsContextVal, setConversationList);

                // navigate(`/dashboard/chat`);
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            if (error.response) {
                toast.error(`Server Error: ${error.response?.data?.message || "An error occurred on the server."}`);
                console.error("Server Error:", error.response.data);
            } else if (error.request) {
                toast.error("Network Error: No response received from the server.");
                console.error("Network Error:", error.request);
            } else {
                toast.error(`Error: ${error.message}`);
                console.error("Error:", error.message);
            }
        } finally {
            hideLoader();
            resetForm();
            handleClose();
        }
        console.log("Sent Data:", { percentInterest, interestUnit, originationFee, duration, durationFrequency, applicationId });
    };
    return (
        <Modal open={open} onClose={() => { resetForm(); handleClose(); }} aria-labelledby="modal-title">
            <Box className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                width: {
                    xs: '90%', // 90% width on small screens
                    md: '40%', // 500px width on medium and large screens
                    lg: '30%',
                },
            }}>
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">Send Offer</Typography>
                    <Button onClick={handleClose} className="tw-text-gray-500">X</Button>
                </div>

                {/* Percent Interest Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Percent Interest on Loan</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <div class="tw-relative tw-h-[40px] tw-w-1/2">
                            <input
                                type="number"
                                value={percentInterest}
                                onChange={(e) => setPercentInterest(e.target.value)}
                                placeholder="e.g., 5"
                                className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-pr-14 tw-ps-3 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.percentInterest ? 'tw-border-red-500' : ''}`} />
                            <div class="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-r-[5px] tw-inset-y-0 tw-end-0 tw-flex tw-items-center tw-px-4 tw-pointer-events-none">
                                %
                            </div>
                        </div>
                        <FormControl className="tw-w-1/2">
                            <Select
                                value={interestUnit}
                                onChange={(e) => setInterestUnit(e.target.value)}
                                className="tw-text-sm tw-h-[40px]"
                            >
                                <MenuItem value="day">Per Day</MenuItem>
                                <MenuItem value="week">Per Week</MenuItem>
                                <MenuItem value="month">Per Month</MenuItem>
                                <MenuItem value="year">Per Year</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {errors.percentInterest && <p className="tw-text-red-500 tw-text-sm">{errors.percentInterest}</p>}
                </div>

                {/* Origination Fee Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Origination Fee</InputLabel>
                    <div class="tw-relative tw-h-[40px]">
                        <div class="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[5px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-4 tw-pointer-events-none">
                            $
                        </div>
                        <input
                            type="text"
                            value={originationFee}
                            onChange={handleChange}
                            placeholder="e.g., 1000"
                            className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-12 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.originationFee ? 'tw-border-red-500' : ''}`} />
                    </div>
                    {errors.originationFee && <p className="tw-text-red-500 tw-text-sm">{errors.originationFee}</p>}
                </div>

                {/* Duration Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Loan Duration</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <div className='tw-h-[45px'>
                            <input
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                placeholder="e.g., 12"
                                className={`tw-ps-4 tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.duration ? 'tw-border-red-500' : ''}`} />
                        </div>
                        <FormControl className="tw-w-1/2">
                            <Select
                                value={durationFrequency}
                                onChange={(e) => setDurationFrequency(e.target.value)}
                                className="tw-text-sm tw-h-[40px]"
                            >
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="monthly">Months</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {errors.duration && <p className="tw-text-red-500 tw-text-sm">{errors.duration}</p>}
                </div>

                {/* Action Buttons */}
                <div className="tw-flex tw-justify-end tw-mt-6">
                    <Button onClick={handleSendOffer} variant="contained" color="primary" className="tw-bg-blue-500 tw-text-white">
                        Send Offer
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default SendOffers;
