import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

function LoanAmountDist() {
    const data = [
        { name: 'Purchase', value: 400 },
        { name: 'Refinance', value: 300 },
        { name: 'Cash-out refinance', value: 300 },
        { name: 'Construction or Rehab', value: 200 },
    ];

    const COLORS = ['#727AE61A', '#555FE1', '#727AE6B2', '#727AE666'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className='tw-w-[600px] tw-h-[596px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-ms-[30px]'>
            <div className='tw-w-[554px] tw-h-[76px] tw-mx-[20px] tw-my-[20px] tw-flex tw-flex-col'>
                <div className='tw-flex tw-justify-between'>
                    <h1 className='tw-text-[#202224] tw-text-[20px] tw-font-semibold common-font'>Loan Amount Distribution</h1>
                    <select className='tw-bg-white tw-text-[#202224] tw-font-medium'>
                        <option value="lastMonth">Last Month</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastYear">Last Year</option>
                        <option value="customDate">Custom Date</option>
                    </select>
                </div>

                {/* Container for horizontal labels */}
                <div className='tw-flex tw-gap-[15px] tw-mt-[10px]'>
                    {data.map((entry, index) => (
                        <div key={index} className='tw-flex'>
                            <span className='tw-w-[25px] tw-h-[25px] tw-rounded' style={{ backgroundColor: COLORS[index % COLORS.length] }}></span>
                            <span className='tw-ml-[5px] tw-text-[#202224] tw-font-medium'>{entry.name}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className='tw-flex tw-justify-center tw-items-center'>
                <div className='tw-w-[380px] tw-h-[380px] tw-mx-[20px] tw-my-[20px]'>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius="80%"
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

export default LoanAmountDist;
