import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';

function Modal({ promoCodeData, closeModal, onSubmit, errors }) {
    const { control, handleSubmit, setValue, watch } = useForm();

    useEffect(() => {
        if (promoCodeData) {
            setValue('promoCode', promoCodeData?.promoCode || '');
            setValue('usageLimit', promoCodeData?.usageLimit || 0);
            setValue('discountValue', promoCodeData?.discountValue || 0);
            setValue('status', promoCodeData?.status || 'active');
        }
    }, [promoCodeData, setValue]);

    const handleFormSubmit = (data) => {
        console.log('Form Data:', data);
        onSubmit?.(data); // Call the parent-provided `onSubmit` if available
    };

    return (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
            <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-[500px] tw-shadow-lg">
                <div className='tw-flex tw-justify-between tw-items-center'>
                    <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-black">Promo Code Management</h2>
                    <Button
                        onClick={closeModal}
                        className="tw-bg-transparent tw-border-0 tw-text-gray-500 hover:tw-text-red-600 hover:tw-bg-gray-100 tw-p-2 tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out"
                        aria-label="Close Modal"
                    >
                        X
                    </Button>
                </div>
                <form onSubmit={handleSubmit(handleFormSubmit)} className="tw-flex tw-flex-col tw-gap-4">
                    {/* Promo Code */}
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Promo Code</label>
                        <Controller
                            name="promoCode"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className="tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                    onChange={(e) => {
                                        const formattedValue = e.target.value.toUpperCase().replace(/\s/g, ''); 
                                        field.onChange(formattedValue);
                                    }}
                                />
                            )}
                        />
                        {errors?.promoCode && <div className="tw-text-red-500">{errors?.promoCode}</div>}
                    </div>

                    {/* Usage Limit */}
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Usage Limit</label>
                        <Controller
                            name="usageLimit"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="number"
                                    className="tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                />
                            )}
                        />
                        {errors?.usageLimit && <div className="tw-text-red-500">{errors?.usageLimit}</div>}
                    </div>

                    {/* Discount Value */}
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Discount Value</label>
                        <Controller
                            name="discountValue"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="number"
                                    className="tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                />
                            )}
                        />
                        {errors?.discountValue && <div className="tw-text-red-500">{errors?.discountValue}</div>}
                    </div>

                    {/* Status */}
                    <div>
                        <label className="tw-block tw-font-semibold tw-text-black">Status</label>
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="tw-w-full tw-border tw-rounded tw-p-2 tw-border-gray-300 tw-text-[#242424]"
                                >
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                            )}
                        />
                        {errors?.status && <div className="tw-text-red-500">{errors?.status}</div>}
                    </div>

                    {/* Form Actions */}
                    <div className="tw-flex tw-justify-end tw-gap-4">
                        <button
                            type="button"
                            onClick={closeModal}
                            className="tw-px-4 tw-py-2 tw-border tw-rounded tw-border-gray-400 tw-text-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="tw-px-4 tw-py-2 tw-bg-[#2A2F70] tw-text-white tw-rounded"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Modal;
