import ProfileIcon from "../../assets/icons/profileIcon.png";
import ChangePasswordForm from "./ChangePasswordForm";

const AdminSettings = () => {
    return (
        <div className="tw-flex tw-flex-col tw-w-full">
            <div className="tw-flex tw-flex-col tw-space-y-2">
                <h2 className="tw-text-[#000000] tw-font-nunito tw-font-bold tw-text-4xl">Profile Settings</h2>

                <p className="tw-text-lg tw-text-[#202224] tw-font-nunito tw-font-light">Here is the information about all your orders</p>

                <div className="tw-flex tw-space-x-4 tw-items-center">
                    <img src={ProfileIcon} alt="profile icon" className="tw-rounded-full tw-w-28 tw-h-28 tw-object-cover" />

                    <button className="tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-px-2 hover:tw-shadow-xl">Upload Image</button>
                </div>
            </div>

            <ChangePasswordForm />            
        </div>
    )
};

export default AdminSettings;