import React, { useEffect, useState, useContext, useRef } from 'react';
import ApplicationTable from '../../common/applicationTable/ApplicationTable'
import FilterForm from './FilterForm'
import StatsCards from '../../common/StatsCards';
import Chat from '../../common/chat-new/Chat';
import AssetGenerated from './AssetGenerated';
import AreaInterest from './AreaInterest';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import PropertyDetail from './PropertyDetail';
// import StatsDropdown from '../../common/StatsDropdown';
import SendOffers from './modal/SendOffers';
import GoogleAddressMarker from '../../common/GoogleAddressMarker';
import { conversationListHandler } from '../../common/chat-new/ChatUtils';
import { useLoader } from '../../../contexts/LoaderContext';
const initialFilterState = {
    user_id: '',
    loan_purpose: '',
    condition: '',
    property_type: '',
    property_size_min: '',
    property_size_max: '',
    property_value_min: '',
    property_value_max: '',
    appraised_value_min: '',
    appraised_value_max: '',
    application_status: '',
    date_submitted: '',
    city_id: '',
    state_id: '',
    postal_code: '',
    proximity: '',
    loan_purpose: '',
    loan_term: '',
    ltv: '',
    interest_rate_type: '',
    borrower_type: '',
    borrower_existing_dept_min: '',
    borrower_existing_dept_max: '',
    borrower_credit_score_min: '',
    borrower_credit_score_max: '',
    sort_by: 'a.id',
    sort_order: 'DESC',
};

function Lender() {
    const [openSendOfferModal, setOpenSendOfferModal] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [userAllloanApplications, setUserAllloanApplications] = useState([]);
    const [lenderApplicationsSummary, setLenderApplicationsSummary] = useState([]);
    const [propertyDetails, setPropertyDetails] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRowBorrowerId, setSelectedBorrowerId] = useState(0);
    const [conversationList, setConversationList] = useState([]);
    const [formattedTimes, setFormattedTimes] = useState([]);
    const [filter, setFilter] = useState(initialFilterState);
    const initialFilterRef = useRef(initialFilterState);
    const { showLoader, hideLoader } = useLoader();
    
    const [stats, setStats] = useState([
        { title: 'Total Applications', value: '40,689', change: '8.5%' },
        { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
        { title: 'Pending Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
        { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
    ]);
    const { user } = useContext(UserContext);
    const { setCurrentOfferPropertyDetails } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const handleCloseModal = () => setOpenSendOfferModal(false);

    //This is to get Borrower Dashboard Summary
    const fetchLenderLoanSummary = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/getLenderDashboardSummary`, { userId: user?.id });
            setLenderApplicationsSummary(response?.data);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };

    const updatePropertyDetails = (property) => {
        // console.log("property:", property)
        setCurrentOfferPropertyDetails(property);
        setPropertyDetails(property);
        setApplicationId(property?.application_id);
        // handleRowClick(property);
    }

    const fetchAllUserLoanApplications = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/lender/list`, filter);
            // console.log("response:", response.data?.applications)
            const applications = response?.data?.applications || [];
            setUserAllloanApplications(applications);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    const filterHandler = () => {
        fetchAllUserLoanApplications();
    };
    const resetFilterHandler = () => {
        const updatedFilter = initialFilterRef.current;
        setFilter({...updatedFilter, resetKey: Math.random()});
    }
    useEffect(() => {
        fetchAllUserLoanApplications(filter);
    }, [filter.resetKey]);
    
    useEffect(() => {
        fetchAllUserLoanApplications();
        fetchLenderLoanSummary()
    }, [user]);

    useEffect(() => {
        const stats = [
            { title: 'Total Applications', value: lenderApplicationsSummary?.total_applications, change: '8.5%' },
            { title: 'Accepted Applications', value: lenderApplicationsSummary?.accepted_applications, change: '8.5%' },
            { title: 'Pending Applications', value: lenderApplicationsSummary?.pending_applications, change: '8.5%' },
            { title: 'Rejected Applications', value: lenderApplicationsSummary?.rejected_applications, change: '8.5%' },
            { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
            { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
        ];
        setStats(stats)
    }, [lenderApplicationsSummary]);

    const handleNewApplication = async () => {
        await fetchAllUserLoanApplications();
    };

    // function to get age of application
    const formatTimeDifference = (date) => {
        const now = new Date();
        const createdAt = new Date(date);
        const diffInSeconds = Math.floor((now - createdAt) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    };

    // Update every 60 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setFormattedTimes((prevTimes) => {
                return userAllloanApplications.map((app) => ({
                    application_id: app.application_id,
                    formattedTime: formatTimeDifference(app.application_created_at),
                }));
            });
        }, 20000); // Update every 60 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [userAllloanApplications]);


    const columns = [
        { field: 'application_id', headerName: 'ID', width: 90, editable: true },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            editable: true,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'propertyType',
            headerName: 'Property Type',
            width: 110,
            editable: true,
        },
        {
            field: 'purpose',
            headerName: 'Purpose',
            width: 110,
            editable: true,
        },
        {
            field: 'propertyValue',
            headerName: 'Property Value',
            type: 'number',
            width: 110,
            editable: true,
        },
        {
            field: 'loanAmount',
            headerName: 'Loan Amount',
            type: 'number',
            width: 110,
            editable: true,
        },
        {
            field: 'ltv',
            headerName: 'LTV',
            type: 'number',
            width: 90,
            editable: true,
        },
        {
            field: 'borrower',
            headerName: 'Borrower',
            width: 150,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 145,
            renderCell: (params) => {
                const status = params?.value;
                let badgeColor;
                let badgeChild;

                switch (status) {
                    case 'Pending':
                        badgeColor = '#377DFF2E';
                        badgeChild = '#377DFF';
                        break;
                    case 'Unread Offers':
                        badgeColor = '#FFD7002E';
                        badgeChild = '#FFD700';
                        break;
                    case 'Offers':
                        badgeColor = '#00C9A72E';
                        badgeChild = '#00C9A7';
                        break;
                    case 'Settled':
                        badgeColor = '#4CAF502E';
                        badgeChild = '#4CAF50';
                        break;
                    case 'Archived':
                        badgeColor = '#9E9E9E2E';
                        badgeChild = '#9E9E9E';
                        break;
                    default:
                        badgeColor = '#E0E0E0';
                        badgeChild = '#BDBDBD';
                }

                return (
                    <div
                        className='tw-flex tw-justify-center tw-items-center tw-w-[124px] tw-h-[26px] tw-border tw-border-[#DDDDDD] tw-rounded-full tw-mt-[12px]'
                        style={{ backgroundColor: badgeColor }}
                    >
                        <span className='tw-ms-[10px] tw-w-[8px] tw-h-[8px] tw-border tw-border-[#DDDDDD] tw-rounded-full' style={{ backgroundColor: badgeChild }}></span>

                        <span className='tw-flex tw-justify-start tw-items-center tw-w-[98px] tw-h-[22px] tw-text-[#070101CC] common-font tw-font-normal tw-text-[16px] tw-ml-1'>
                            {status}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'streetAddress',
            headerName: 'Street Address',
            width: 200,
            editable: true,
        },


    ];
    
    const rows = userAllloanApplications?.length ? userAllloanApplications?.map((app) => ({
        application_id: app?.application_id,
        propertyType: app?.loan_purpose,
        purpose: app?.loan_purpose,
        propertyValue: app?.property_value
            ? app.property_value.startsWith('$')
                ? app.property_value
                : '$' + new Intl.NumberFormat().format(Number(app.property_value.replace(/,/g, '')))
            : "$150,000",
        loanAmount: app?.loanAmount
            ? app.loanAmount.startsWith('$')
                ? app.loanAmount
                : '$' + new Intl.NumberFormat().format(Number(app.loanAmount.replace(/,/g, '')))
            : "$250,000",
        ltv: app?.estimated_rate,
        borrower: app?.first_name + ' ' + app?.last_name,
        status: app?.application_status || 'Pending',
        streetAddress: app.property_address,
        lat: app?.lat ? app?.lat : '40.712776',
        lng: app?.lng ? app?.lng : '-74.005974',
        borrower_id: app?.user_id,
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    // const converstationListHandler = async (application_id) => {
    //     try {
    //         const response = await axios.post(`${awsContextVal?.endpoint}/conversation/list`, {
    //             "application_id": application_id,
    //             'user_type': user?.user_type,
    //             'user_id': user?.id
    //         });
    //         if (response?.data?.conversationList) {
    //             setConversationList(response?.data?.conversationList);
    //         } else {
    //             throw new Error("Invalid response format: conversationList is missing.");
    //         }
    //     } catch (error) {
    //         if (error.response) {
    //             toast.error("Error fetching data. Please try again later.");
    //         } else if (error.request) {
    //             toast.error("No response from the server. Please check your network connection.");
    //         } else {
    //             toast.error("An unknown error occurred. Please try again later.");
    //         }
    //         setConversationList([]);
    //     }
    // };

    const handleRowClick = async (params) => {
        try {
            // Show the loader
            showLoader();
    
            if (params !== '') {
                // Update property details and fetch conversation list
                updatePropertyDetails(params);
                await conversationListHandler(params?.application_id, user, awsContextVal, setConversationList);
    
                const location = rows.find((loc) => loc.application_id === params.application_id);
                setSelectedLocation({ lat: location?.lat, lng: location?.lng });
                setSelectedRowId(params?.application_id);
                setSelectedBorrowerId(params?.borrower_id);
            } else {
                setConversationList([]);
            }
    
            setTimeout(() => {
                hideLoader();
            }, 1000);
    
        } catch (error) {
            console.error("Error in handleRowClick:", error);
            toast.error(error.message || "Something went wrong. Please try again.");
            
            // Ensure the loader is hidden in case of an error
            hideLoader();
        }
    };
    useEffect(() => {
        if (rows.length > 0) {
            const firstRow = rows[0];
            const lastRow = rows[rows.length - 1];
            setSelectedRowId(firstRow?.application_id);
            setSelectedLocation({ lat: firstRow?.lat, lng: firstRow?.lng }); 
            updatePropertyDetails(firstRow);
            conversationListHandler(firstRow?.application_id, user, awsContextVal, setConversationList);
        } else {
            updatePropertyDetails([]);
            handleRowClick([]);
        }
    }, [userAllloanApplications]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(selectedRowId) {
                conversationListHandler(selectedRowId, user, awsContextVal, setConversationList);
            }
        }, 20000); // Update every 20 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [selectedRowId]);


    return (
        <div className="tw-h-full tw-w-full">
            <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[87px]">
                <div>
                    <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">Lender Dashboard</h1>
                    {/* <p className='tw-hidden tw-text-[#202224] tw-font-semibold tw-leading-[24.55px] tw-text-[18px] tw-opacity-55 tw-h-[25px]'>
                        Here is the information about all your orders
                    </p> */}
                </div>
            </header>
            {/* <div className='tw-block '>
                <StatsDropdown />

                <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg tw-hidden">
                    {stats?.map((stat, index) => (
                        <StatsCards
                            key={index}
                            title={stat?.title}
                            value={stat?.value}
                            change={stat?.change}
                        />
                    ))}
                </div>
            </div>*/}
            <div className="tw-flex tw-space-x-4 tw-mt-4 hidden">
                <FilterForm
                    filter={filter}
                    setFilter={setFilter}
                    filterHandler={filterHandler}
                    resetFilterHandler={resetFilterHandler}
                />
            </div>
            <div className="tw-flex tw-space-x-4 tw-mt-10">
                <div className="tw-w-2/3">
                    <ApplicationTable
                        dataTableProps={{
                            getRowId: (row, index) => `${index}-${row?.application_id}`,
                            userStatus: ['Offer Sent', "Rejected", "Pending"],
                            dashboardType: 'lender',
                            getRowClassName: (params) =>
                                params?.row?.application_id === selectedRowId ? 'selected-row' : ''
                        }}
                        onRowClick={handleRowClick}
                        rows={rows}
                        selectedRow={rows.find(row => row.application_id === selectedRowId) || rows[rows.length - 1]}
                        columns={columns}
                        height="400px"
                    />
                </div>
                <div className="tw-w-1/3 tw-h-full">
                    <PropertyDetail property={propertyDetails} setOpenSendOfferModal={setOpenSendOfferModal} selectedLocation={selectedLocation} />
                </div>
            </div>

            {/* <div className='hidden'>
                <Chat />
            </div> */}
            <div className='tw-flex tw-space-x-4 tw-mt-10'>
                <div className='tw-w-2/3'>
                    <Chat application_id={selectedRowId} />
                </div>

                <div className='tw-w-1/3'>
                    {/* {
                        selectedLocation
                            ?
                            <div className='tw-mr-3'><GoogleAddressMarker selectedLocation={selectedLocation} /></div>
                            : null
                    } */}
                    <AssetGenerated />
                </div>
            </div>

            {/* 
            <div className='hidden tw-flex tw-mt-10'>
                <div className='tw-w-[67%]'>
                    <AreaInterest />
                </div>
            </div>
	    */ }


            {/* <SendOffer open={openSendOfferModal} handleClose={handleCloseModal} /> */}
            <SendOffers open={openSendOfferModal} handleClose={handleCloseModal} applicationId={applicationId} selectedRowBorrowerId={selectedRowBorrowerId} setConversationList={setConversationList}/>



            {/* <div className="tw-flex tw-space-x-4 tw-mt-4">
                <div className="tw-flex-1">
                    <ApplicationTable />
                </div>
                <div className="tw-w-[300px]">
                    <FormBox />
                </div>
            </div> */}
        </div>
    )
}

export default Lender
