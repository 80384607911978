// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TestimonialsArea-specific styles */

/* Swiper Container */
.testimonials-area #testimonials-swiper {
    padding-bottom: 50px;
}

/* Swiper Slides */
.testimonials-area .testimonial-slide {
    background-color: white;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    max-width: 90%;
}

/* Swiper Pagination */
.testimonials-area .testimonials-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.testimonials-area .swiper-pagination-bullet {
    background-color: #ddd;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    opacity: 0.7;
    transition: all 0.3s ease;
}

.testimonials-area .swiper-pagination-bullet-active {
    background-color: #4853E4;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TestimonialSwiper.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC,qBAAqB;AACrB;IACI,oBAAoB;AACxB;;AAEA,kBAAkB;AAClB;IACI,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,cAAc;AAClB;;AAEA,sBAAsB;AACtB;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,UAAU;AACd","sourcesContent":["/* TestimonialsArea-specific styles */\n\n/* Swiper Container */\n.testimonials-area #testimonials-swiper {\n    padding-bottom: 50px;\n}\n\n/* Swiper Slides */\n.testimonials-area .testimonial-slide {\n    background-color: white;\n    text-align: left;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    padding: 1rem;\n    max-width: 90%;\n}\n\n/* Swiper Pagination */\n.testimonials-area .testimonials-pagination {\n    display: flex;\n    justify-content: center;\n    margin-top: 1rem;\n}\n\n.testimonials-area .swiper-pagination-bullet {\n    background-color: #ddd;\n    width: 12px;\n    height: 12px;\n    margin: 0 5px;\n    border-radius: 50%;\n    opacity: 0.7;\n    transition: all 0.3s ease;\n}\n\n.testimonials-area .swiper-pagination-bullet-active {\n    background-color: #4853E4;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
