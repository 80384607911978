import React, { useContext } from "react";
import LogoImage from "../../../assets/icons/Logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Corrected Import
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";

import "../../auth.css";
import { LocalPostOfficeSharp } from "@mui/icons-material";
import { UserContext } from "../../../contexts/UserContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const userType = useContext(UserContext);

  const goToHome = () => {
    navigate("/");
  };

  const testimonials = [
    {
      paragraph:
        "This product has really helped our business grow significantly.",
      name: "Jennie Ruby",
      designation: "CEO, TechCorp",
      profileImage: "https://randomuser.me/api/portraits/women/1.jpg",
    },
    {
      paragraph: "Amazing service and great support. Highly recommend!",
      name: "Yoo Jimin",
      designation: "CTO, InnovateX",
      profileImage: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    {
      paragraph:
        "Our team uses this platform daily and we love its simplicity.",
      name: "Shin Ryujin",
      designation: "COO, WebDyno",
      profileImage: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    {
      paragraph: "A fantastic tool that has made our processes much smoother.",
      name: "Kim Taehyung",
      designation: "Founder, VisionSoft",
      profileImage: "https://randomuser.me/api/portraits/men/2.jpg",
    },
  ];

  const signUpMessage = 
    userType.userType == "borrower" 
    ? "Sign up today to experience a faster, smarter way to connect, apply, and secure commercial real estate financing."
    : "Sign up today to access qualified CRE borrowers and streamline your direct lending process—no brokers, just deals."
  return (
    <>
      {/* Left Side - Blue Section (40%) */}
      <div className="tw-h-screen tw-text-white tw-px-4 sm:tw-px-4 md:tw-px-8 lg:tw-px-10 tw-py-[3vh] tw-flex tw-flex-col tw-justify-between tw-overflow-hidden sidebar-background">
        {/* Logo */}
        <div className="tw-h-[50vh]">
          <div className="tw-h-[10vh] sm:tw-h-[10vh] md:tw-h-[15vh] lg:tw-h-[8vh] ">
            <img
              src={LogoImage}
              alt="Re-loan logo"
              className="tw-mb-0"
              onClick={goToHome}
            />
          </div>
          <div className="tw-h-[50vh] tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div>
              {document.location.pathname.endsWith("sign-in") ? (
                <>
                <h1 className="tw-font-oswald tw-leading-1 sm:tw-leading-1 md:tw-leading-12 lg:tw-leading-14 tw-text-start tw-text-6xl lg:tw-text-7xl tw-text-[#FFFFFF] tw-my-7 tw-font-semibold tw-text-center">
                  Welcome Back to Re-Loan
                </h1>
                <p className="tw-font-inter tw-mt-5 tw-text-3xl md:tw-text-3xl tw-text-gray-200 tw-leading-relaxed">
                  Sign in to access your personalized dashboard. Track updates, manage deals, communicate with lenders or borrowers, and stay informed with real-time insights.
                </p>
              </>
              ) : (
                <>
                  {" "}
                  <h1 className="tw-font-oswald tw-leading-1 sm:tw-leading-1 md:tw-leading-12 lg:tw-leading-14 tw-text-start tw-text-6xl lg:tw-text-7xl tw-text-[#FFFFFF] tw-my-7 tw-font-semibold tw-text-center">
                    Start Your Remarkable{" "}
                    <div className="tw-mt-0 sm:tw-mt-0 md:tw-mt-1 lg:tw-mt-1">
                      Journey With Us
                    </div>
                  </h1>
                  <p className="tw-font-inter tw-mt-5 tw-text-3xl md:tw-text-3xl tw-text-gray-200 tw-leading-relaxed">
                    {signUpMessage}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Main Text Section */}

        <div className="tw-h-[50vh]">
          <div className="tw-mt-10 tw-pb-10 tw-hidden lg:tw-block">
            {/* <Swiper
              modules={[Pagination, Autoplay]}
              id="swiper"
              slidesPerView="auto"
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return `<span className="${className} tw-indicator custom-indicator"></span>`;
                },
              }}
              autoplay={{ delay: 3000 }}
              breakpoints={{
                640: { slidesPerView: 1 },
                1024: { slidesPerView: 1 },
                1280: { slidesPerView: 2 },
              }}
              style={{ paddingBottom: "20px" }}
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide
                  key={`slide-${index}`}
                  style={{ listStyle: "none" }}
                  className="!tw-h-44"
                >
                  <div className="tw-bg-white tw-text-black tw-rounded-lg tw-p-1 sm:tw-p-1 md:tw-p-3 lg:tw-p-3 tw-shadow-lg tw-h-full slide-content">
                    <div className="tw-text-4xl tw-text-blue-400 tw-mb-0">
                      &ldquo;
                    </div>
                    <p className="tw-text-sm tw-opacity-70">
                      {testimonial.paragraph}
                    </p>
                    <div className="tw-flex tw-items-center tw-my-4">
                      <img
                        src={testimonial.profileImage}
                        alt={`${testimonial.name}'s profile`}
                        className="tw-w-10 tw-h-10 tw-rounded-full tw-mr-2"
                      />
                      <div>
                        <div className="tw-text-xs sm:tw-text-xs md:tw-text-[16px] lg:tw-text-[12px] tw-font-bold">
                          {testimonial.name}
                        </div>
                        <div className="tw-text-xs tw-opacity-70">
                          {testimonial.designation}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

