import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext';
function ChatList({ chatMessages, handleViewOfferDetails, showTypingIndicator }) {
    const chatContainerRef = useRef(null);
    const { user } = useContext(UserContext);

    return (
        <div className='tw-h-full'>
            <div className='tw-h-full tw-overflow-y-auto ' ref={chatContainerRef}>
                <div className='tw-mx-[20px] tw-flex tw-items-center'>
                    <div className='tw-text-gray-400 tw-flex-shrink-0'>Thursday, Aug 04, 2022</div>
                    <div className='tw-border-b tw-border-gray-400 tw-w-[100%] tw-mx-2'></div>
                </div>
                <ul className='tw-mx-[20px]'>
                    {chatMessages.map((chat) => (
                        <li
                            key={chat?.message_id}
                            className={`tw-flex tw-gap-[10px] tw-my-4 
${chat?.sender_id === user?.id ? 'tw-justify-end tw-ml-[40px]' : 'tw-justify-start tw-mr-[40px]'}
`}
                        >
                            {/* Conditionally render offer message design */}
                            {chat?.is_offer === 1 ? (
                                <div className={`tw-px-4 tw-py-2 tw-rounded-lg tw-bg-green-100 tw-text-left tw-w-full`}>
                                    {/* Header with User and Time */}
                                    <div className="tw-flex tw-items-center tw-gap-[10px]">
                                        <span className='common-font tw-font-extrabold tw-text-[15px] tw-text-[#444444] tw-capitalize'>
                                            {chat?.user_name}
                                        </span>
                                        <span className='time tw-text-gray-500'>
                                            {new Date(chat?.sent_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </span>
                                    </div>

                                    {/* Offer Message Content */}
                                    <div className='common-font tw-text-black tw-text-[16px] tw-font-normal'>
                                        <p>{chat?.message_text}</p>
                                    </div>

                                    {/* Due Date and Button */}
                                    <div className='tw-mt-2'>
                                        {/* Show due date if applicable */}
                                        <span className='tw-block tw-text-gray-600'>
                                            Due: {new Date(chat?.sent_at).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })}
                                        </span>

                                        {/* View Details Button */}
                                        <button
                                            className='tw-mt-2 tw-text-green-600 tw-font-semibold hover:tw-underline'
                                            onClick={() => handleViewOfferDetails(chat?.application_offers_id)}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                // Default chat message design
                                <div className={`tw-px-4 tw-py-2 tw-rounded-lg ${chat?.sender_id === user?.id ? 'tw-bg-blue-100 tw-text-left' : 'tw-bg-green-100 tw-text-left'}`}>
                                    <div className="tw-flex tw-items-center tw-gap-[10px]">
                                        <span className='common-font tw-font-extrabold tw-text-[15px] tw-text-[#444444] tw-capitalize'>
                                            {chat?.user_name}
                                        </span>
                                        <span className='time tw-text-gray-500'>
                                            {new Date(chat?.sent_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </span>
                                    </div>
                                    <div className='common-font tw-text-black tw-text-[16px] tw-font-normal'>
                                        <p>{chat?.message_text}</p>
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}

                    {/* Typing Indicator */}
                    {showTypingIndicator && (
                        <li className='tw-flex tw-justify-start tw-ml-[40px] tw-my-4'>
                            <div className='typing-bubble'>
                                <span className='tw-flex tw-items-center tw-gap-2'>
                                    <span className='tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-pulse'></span>
                                    <span className='tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-pulse tw-delay-75'></span>
                                    <span className='tw-w-2 tw-h-2 tw-bg-gray-500 tw-rounded-full tw-animate-pulse tw-delay-150'></span>
                                </span>
                            </div>
                        </li>
                    )}
                </ul>
            </div>

        </div>
    )
}

export default ChatList
