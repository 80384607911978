import React, { useContext, useEffect, useState } from 'react'
import ApplicationTable from '../applicationTable/ApplicationTable';
import { useLoader } from '../../../contexts/LoaderContext';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import { formatPrice } from '../../../utils/common';
import { useApiService } from '../../../services/apiService';

function LenderTable() {
    const { user } = useContext(UserContext);
    const { getRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [topLenderData, setTopLenderData] = useState([]);
    
    const fetchTopLenderDetail = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await getRequest(`/user/top-lender`);
            if(response?.success) {
                const topLender = response?.data?.userList || [];
                setTopLenderData(topLender);
            } else {
                toast.error('Something went wrong');
                setTopLenderData([]);
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    
    useEffect(() => {
        fetchTopLenderDetail();
    }, [user]);
    
    const columns = [
        {
            field: 'lender_name',
            headerName: 'Name of Lenders',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 200,
            editable: false,
        },
        {
            field: 'last_loan_granted',
            headerName: 'Last Loan Granted',
            width: 200,
            editable: false,
        },
        {
            field: 'total_amount_granted',
            headerName: 'Total Amount Granted ($)',
            width: 200,
            editable: false,
        },
        {
            field: 'total_loan_granted',
            headerName: 'No. Of Loans Granted ($)',
            width: 200,
            editable: false,
        },
    ];
    const rows = topLenderData?.length
        ? topLenderData?.map((app, index) => ({
            id: app?.email || index, // Use a unique field or fallback to the index
            lender_name: app?.lender_name,
            email: app?.email,
            last_loan_granted: app?.last_loan_granted
                ? new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).format(new Date(app?.last_loan_granted))
                : 'N/A',
            total_amount_granted: app?.total_amount_granted ? formatPrice(app?.total_amount_granted) : '',
            total_loan_granted: app?.total_loan_granted,
        }))
        : [];


    const handleRowClick = () =>{
        console.log('handleRowClick');
    };
    
    return (
        <ApplicationTable
            dataTableProps={{
                getRowId: (row) => row?.id, 
                userStatus: [""],
                dashboardType: 'top Lenders',
                title: "Top Lenders",
                getRowClassName: (params) =>
                    params?.row?.id === null ? 'selected-row' : ''
            }}
            rows={rows}
            onRowClick={handleRowClick}
            columns={columns}
            tableHeight="284px"
            maxHeight='62vh'
            minHeight='284px'
        />
    )
}

export default LenderTable