import React from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    ButtonGroup
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useRef } from 'react';

function SubscriptionModal({ open, handleClose, formData, setFormData, handleSubmit, errors, setErrors }) {
    const fileInputRef = useRef(null);

    const addFacilityField = () => {
        setFormData((prev) => ({
            ...prev,
            facilities: [...prev.facilities, ''],
        }));
    };

    const removeFacilityField = (index) => {
        const updatedFacilities = formData.facilities.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, facilities: updatedFacilities }));
    };

    const handleFacilityChange = (index, value) => {
        const updatedFacilities = [...formData.facilities];
        updatedFacilities[index] = value;
        setFormData((prev) => ({ ...prev, facilities: updatedFacilities }));
    };

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: '' })); // Clear any existing error
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = file;
            setFormData({ ...formData, image: previewUrl, imageFile: file }); // Save both URL and file
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
            <Box
                className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-xl"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none',
                    width: {
                        xs: '90%',
                        sm: '70%',
                        md: '50%',
                        lg: '40%',
                        xl: '30%',
                    },
                    maxHeight: '90vh', // Restrict modal height
                    overflowY: 'auto', // Enable scrolling if content exceeds
                }}
            >
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">
                        Create Subscription
                    </Typography>
                    <Button onClick={handleClose} className="tw-text-gray-500">
                        X
                    </Button>
                </div>
                <form className="tw-space-y-5 tw-mt-10" onSubmit={handleSubmit}>
                    {/* Plan and Description */}
                    <div className="tw-flex tw-gap-x-4">
                        <div className="tw-w-1/2 tw-mb-4">
                            <InputLabel className="tw-font-semibold tw-mb-1">Plan</InputLabel>
                            <input
                                type="text"
                                placeholder="Plan"
                                value={formData.plan}
                                onChange={(e) => handleChange('plan', e.target.value)}
                                className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                            />
                            {errors.plan && <Typography className="tw-text-red-500">{errors.plan}</Typography>}
                        </div>
                        <div className="tw-w-1/2 tw-mb-4">
                            <InputLabel className="tw-font-semibold tw-mb-1">Description</InputLabel>
                            <input
                                type="text"
                                placeholder="Description"
                                value={formData.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                            />
                            {errors.description && (
                                <Typography className="tw-text-red-500">{errors.description}</Typography>
                            )}
                        </div>
                    </div>

                    {/* Amount and Offer */}
                    <div className="tw-flex tw-gap-x-4">
                        <div className="tw-w-1/2 tw-mb-4">
                            <InputLabel className="tw-font-semibold tw-mb-1">Amount</InputLabel>
                            <input
                                type="number"
                                placeholder="Amount"
                                value={formData.amount}
                                onChange={(e) => handleChange('amount', e.target.value)}
                                className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                            />
                            {errors.amount && <Typography className="tw-text-red-500">{errors.amount}</Typography>}
                        </div>
                        <div className="tw-w-1/2 tw-mb-4">
                            <InputLabel className="tw-font-semibold tw-mb-1">Offer</InputLabel>
                            <input
                                type="number"
                                placeholder="Offer"
                                value={formData.offer}
                                onChange={(e) => handleChange('offer', e.target.value)}
                                className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                            />
                            {errors.offer && <Typography className="tw-text-red-500">{errors.offer}</Typography>}
                        </div>
                    </div>

                    <div className="tw-flex tw-flex-col tw-gap-x-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Subscription Type</InputLabel>
                        <div className='tw-flex tw-justify-center'>
                            
                            <ButtonGroup disableElevation aria-label="button group">
                                <Button 
                                onClick={(e) => handleChange('type', "weekly")}
                                variant= {formData?.type === 'weekly' ? "contained" : 'outlined'}
                                >
                                    Weekly
                                </Button>
                                <Button 
                                onClick={(e) => handleChange('type', "monthly")}
                                variant= {formData?.type === 'monthly' ? "contained" : 'outlined'}
                                >
                                    Monthly
                                </Button>
                                <Button 
                                onClick={(e) => handleChange('type', "yearly")}
                                variant= {formData?.type === 'yearly' ? "contained" : 'outlined'}
                                >
                                    Yearly
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>

                    {/* Facilities */}
                    <div className="tw-space-y-4">
                        <div className="tw-flex tw-items-center tw-justify-between">
                            <InputLabel className="tw-font-semibold tw-mb-1">Facilities</InputLabel>
                            <Button
                                onClick={addFacilityField}
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="tw-bg-blue-500 tw-text-white"
                            >
                                Add Facility
                            </Button>
                        </div>
                        {formData.facilities.map((facility, index) => (
                            <div className="tw-flex tw-items-center tw-gap-x-2" key={index}>
                                <div className="tw-w-full">
                                    <input
                                        type="text"
                                        placeholder={`Facility ${index + 1}`}
                                        value={facility}
                                        onChange={(e) => handleFacilityChange(index, e.target.value)}
                                        className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                                    />
                                </div>
                                <IconButton
                                    onClick={() => removeFacilityField(index)}
                                    color="error"
                                    disabled={formData.facilities.length === 1}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </div>
                        ))}
                        {errors.facilities && <Typography className="tw-text-red-500">{errors.facilities}</Typography>}
                    </div>

                    {/* Status */}
                    <div className="tw-w-full tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Status</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                value={formData?.status || ""}
                                onChange={(e) => handleChange('status', e.target.value)}
                                className="tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#242424]"
                                displayEmpty
                                inputProps={{ "aria-label": "Status" }}
                            >
                                <MenuItem value="" disabled>
                                    Select Status
                                </MenuItem>
                                <MenuItem value="1">Active</MenuItem>
                                <MenuItem value="0">Inactive</MenuItem>
                            </Select>
                        </FormControl>
                        {errors.status && <Typography className="tw-text-red-500">{errors.status}</Typography>}
                    </div>

                    <div className="tw-w-full tw-mb-4">
                            <InputLabel className="tw-font-semibold tw-mb-1">Price ID</InputLabel>
                            <input
                                type="text"
                                placeholder="Price ID"
                                value={formData.price_id}
                                onChange={(e) => handleChange('price_id', e.target.value)}
                                className="tw-p-[10px] tw-w-full tw-h-[40px] tw-text-[#242424] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px]"
                            />
                            {errors.plan && <Typography className="tw-text-red-500">{errors.plan}</Typography>}
                        </div>

                    {/* Color Picker */}
                    <div className="tw-w-full tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Choose Color</InputLabel>
                        <input
                            type="color"
                            value={formData.color || '#000000'}
                            onChange={(e) => handleChange('color', e.target.value)}
                            className="tw-w-full tw-h-[40px] tw-rounded-md"
                        />
                        {errors.color && <Typography className="tw-text-red-500">{errors.color}</Typography>}
                    </div>

                    {/* Image Upload */}
                    <div className="tw-w-full tw-mb-4">
                        <InputLabel className="tw-font-semibold tw-mb-1">Upload Image</InputLabel>

                        {/* Hidden File Input */}
                        <input
                            type="file"
                            ref={fileInputRef} // Reference to trigger input click
                            onChange={handleFileChange}
                            accept="image/*"
                            style={{ display: 'none' }} // Hide the file input
                        />

                        {/* Button to trigger file input */}
                        <button
                            type="button"
                            onClick={() => fileInputRef.current.click()} // Trigger file input click
                            className="tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-w-full tw-px-2 hover:tw-shadow-xl"
                        >
                            Upload Image
                        </button>

                        {/* Image Preview */}
                        {formData.image && (
                            <div className="tw-mt-4 tw-text-center">
                                <img
                                    src={formData?.image}
                                    alt="Preview"
                                    className="tw-h-24 tw-w-24 tw-object-cover tw-rounded-md tw-border tw-bg-gray-200"
                                />
                            </div>
                        )}

                        {/* Optional Validation Error */}
                        {errors.image && <Typography className="tw-text-red-500">{errors.image}</Typography>}
                    </div>

                    {/* Submit Button */}
                    <div className="tw-flex tw-justify-end tw-mt-6">
                        <Button type="submit" variant="contained" className="tw-bg-blue-500 tw-text-white">
                            Save
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

export default SubscriptionModal;
