import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import ImageIcon from "@mui/icons-material/Image";
import toast from "react-hot-toast";
import ArticleIcon from "@mui/icons-material/Article";
import { Badge } from "@mui/material";
import DocumentAttachmentIcon from '../../../assets/icons/document_attachment.png';

function ChatFooter({
  sendMessage,
  message,
  setMessage,
  isSending,
  setShowTypingIndicator,
  setOpenSendOfferModal,
  rowStatus,
  attachList,
  setAttachList,
  imageFileUpload,
  docFileUpload
}) {
  const { user } = useContext(UserContext);
  const fileTypesAllowed = ["docx", "doc", "pdf", "txt", "csv", "xls", "xlsx", "md"];

  let typingTimeout;

  useEffect(() => {
    console.log(attachList)
  }, [attachList])

  const handleButtonClick = (ref) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;

    files.forEach((file) => {
      if (file.type.startsWith("image")) {
        attachmentHandler(file, "add");
      }
    });

    e.target.value = "";
  };


  const handleDocChange = (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;

    files.forEach((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      if (fileTypesAllowed.includes(extension)) {
        attachmentHandler(file, "add");
      } else {
        toast.error("Only valid documents (DOCX, TXT, etc.) can be uploaded.");
      }
    });

    e.target.value = ""; // Reset input
  };

  const attachmentHandler = (file, operation) => {
    if (operation === "add") {
      setAttachList((prevAttachList) => {
        // Prevent duplicates in the same message
        const isDuplicate = prevAttachList.some(
          (existingFile) => existingFile.name === file.name
        );

        if (!isDuplicate) {
          return [...prevAttachList, file];
        }
        return prevAttachList;
      });
    } else if (operation === "remove") {
      setAttachList((prevAttachList) =>
        prevAttachList.filter((item) => item !== file)
      );
    }
  };


  return (
    <>
      <div
        className={`tw-h-20 tw-px-9 tw-flex tw-gap-4 tw-items-center ${attachList.length > 0 ? "tw-flex" : "tw-hidden"}`}
      >
        {attachList.length > 0
          ? attachList.map((i, index) => {
            return (
              <div className="tw-flex tw-flex-row" key={index}>
                <div className="tw-rounded-2xl tw-border-2 tw-border-blue-gray-100">
                  <img
                    src={i.type?.startsWith("image") ? URL.createObjectURL(i) : DocumentAttachmentIcon}
                    className="tw-h-[4rem] tw-w-[4rem] tw-rounded-xl"
                  />
                </div>
                <Badge
                  color="error"
                  overlap="circular"
                  badgeContent="x"
                  variant="standard"
                  className="hover:tw-cursor-pointer -tw-translate-x-1 -tw-translate-y-1"
                  onClick={() => {
                    attachmentHandler(i, "remove");
                  }}
                ></Badge>
              </div>
            );
          })
          : null}
      </div>
      <div className="tw-footer tw-justify-center tw-font-inter">
        <input
          type="file"
          ref={imageFileUpload}
          onChange={handleImageChange}
          accept="image/*"
          className="tw-hidden"
        />
        <input
          type="file"
          ref={docFileUpload}
          onChange={handleDocChange}
          accept=".pdf,.doc,.docx,.txt,.csv,.xls,.xlsx,.md"
          className="tw-hidden"
        />
        <div className="tw-my-[40px] tw-mr-[10px] tw-flex tw-items-center tw-justify-center">
          {user.user_type === "lender" ? (<Button
            className="tw-bg-[#7DDE92] hover:tw-bg-[#6fc281] hover:-tw-translate-y-1 
                 tw-transition-all tw-duration-300 tw-font-semibold tw-text-[#2A2F70] 
                 tw-rounded-lg tw-py-[5px] tw-px-[18px] tw-gap-[4px] tw-text-sm 
                 md:tw-text-[18px] tw-font-inter tw-shadow-md tw-h-10 
                 tw-min-w-[120px] tw-whitespace-nowrap tw-mx-2"
            onClick={() => { setOpenSendOfferModal(true) }}>
            Send Offer
          </Button>) : <div></div>}
          <Menu placement="top">
            <MenuHandler>
              <Button
                className="tw-w-[30px] tw-min-h-[30px] tw-min-w-[30px] tw-text-center tw-justify-center tw-border-[2px] tw-border-[#646464] tw-rounded-[10px] tw-pt-[2px] tw-text-gray-600 add_chat_btn hover:-tw-translate-y-1 tw-duration-200 tw-transition-all"
                name="add_chat_btn"
              >
                <i className=" fas fa-plus"></i>
              </Button>
            </MenuHandler>
            <MenuList className="tw-bg-white tw-text-black tw-flex tw-flex-col tw-font-inter tw-rounded-xl tw-border tw-border-gray-400">
              <MenuItem
                className="hover:tw-bg-gray-200 tw-p-3 tw-transition-colors tw-duration-200 tw-rounded-t-xl tw-flex tw-justify-start"
                onClick={() => (handleButtonClick(imageFileUpload))}
              >
                <ImageIcon className="tw-mr-1 tw-w-2 tw-h-2 tw-text-gray-600" />
                Image
              </MenuItem>
              <MenuItem className="hover:tw-bg-gray-200 tw-p-3 tw-transition-colors tw-duration-200 tw-rounded-b-xl" onClick={() => (handleButtonClick(docFileUpload))}>
                <ArticleIcon className="tw-mr-1 tw-w-2 tw-h-2 tw-text-gray-600" />
                Document
              </MenuItem>
            </MenuList>
          </Menu>

        </div>
        <div className="tw-w-10/12 tw-h-[54px]">
          {/* <img src='https://acniowa.com/wp-content/uploads/2016/03/test-image.png' width={45} height={45} className='' /> */}
          <input
            type="text"
            placeholder="Message... "
            className="tw-p-5 tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-text-[#202224]"
            id="chatRoomMessageInput"
            name="chatMessage"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setShowTypingIndicator(true);

              // Clear the previous timeout
              if (typingTimeout) clearTimeout(typingTimeout);

              // Hide typing indicator after 2 seconds of inactivity
              typingTimeout = setTimeout(() => {
                setShowTypingIndicator(false);
              }, 3000);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isSending) {
                sendMessage();
              }
            }}
          />
        </div>

        <div
          className="tw-w-[54px] tw-h-[54px] hover:tw-h-[58px] hover:-tw-translate-y-1 hover:tw-shadow-xl hover:tw-w-[58px] tw-transition-all tw-duration-300 tw-px-[15px] tw-rounded-[10px] tw-ml-[5px] tw-my-[40px] tw-bg-[#7DDE92] tw-border-[1px] tw-border-[#DDDDDD] tw-flex tw-items-center tw-justify-center"
          style={{ cursor: isSending ? "not-allowed" : "pointer" }}
          onClick={isSending ? null : sendMessage}
        >
          <button
            className="fas fa-paper-plane tw-text-[#2A2F70] tw-text-[20px] focus:tw-outline-none focus:tw-border-[#2A2F70] send_msg_btn"
            id="send_msg_btn"
            disabled={isSending}
            style={{
              background: "none",
              border: "none",
              outline: "none",
              cursor: "inherit",
            }}
            onClick={(e) => e.preventDefault()}
          ></button>
        </div>
      </div>
    </>
  );
}

export default ChatFooter;
