// src/components/common/StatsBoxes.js
import React from 'react';
import BoxIcon from "../../assets/icons/Box_icon.png.png";
import IcTrendingUp from "../../assets/icons/ic-trending-up.png.png";



const StatsCards = ({ title, value, change }) => {
    return (
        <>
            <div className="tw-flex tw-flex-col tw-items-center tw-p-[16px] tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-gap-[12px] tw-w-[16%]">
                <div className='tw-flex tw-justify-between tw-items-center'>
                    <div className='tw-gap-[16px]'>
                        <div className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224]">{title}</div>
                        <div className="common-font tw-font-semibold tw-text-[28px] tw-leading-[38.19px]  tw-tracking-[1px] tw-text-[#202224] tw-pt-4">{value}</div>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-[60px] tw-h-[60px] tw-bg-[rgba(130,128,255,0.21)] tw-rounded-3xl tw-left-[197px]">
                        <img src={BoxIcon} alt="State-icon" className="tw-w-[36.67px] tw-h-[36.67px] tw-border-solid tw-border-[#555FE1]" />
                    </div>

                </div>
                <div className='tw-flex tw-items-center tw-space-x-2 common-font tw-font-semibold tw-text-[16px] tw-leading-[21.82px]'>
                    <img src={IcTrendingUp} alt="trending-up-icon" className="tw-w-auto tw-h-auto" />

                    <p className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224]">
                        <span className='tw-text-[#00B69B]'>{change}</span>
                        Up from last week
                    </p>
                </div>
            </div>
        </>
    );
};

export default StatsCards;
