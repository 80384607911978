import React, { useContext, useEffect, useState } from 'react';
import ChatWindow from './ChatWindow';
import ChatProfile from "../../../assets/icons/Chat_profile-img.png";
import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';
import { conversationListHandler } from '../../common/chat-new/ChatUtils';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import ChatUserList from './ChatUserList';

function Chat({ application_id }) {
  // console.log("conversationList:", conversationList);
  
  const { user } = useContext(UserContext);
  const { showLoader, hideLoader } = useLoader();
  const awsContextVal = useContext(AWSAmplifyConfigContext);

  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [chatUserName, setChatUserName] = useState('');
  console.log("conversationList:", conversationList);
  
  //This is to get chat conversation list
  const getConversationList = async () => {
      if (!user) return;
      if (!application_id) return;

      try {
          const response = await axios.post(`${awsContextVal?.endpoint}/conversation/list`, {
              "application_id": application_id,
              'user_type': user?.user_type,
              'user_id': user?.id,
          });
          setConversationList(response?.data?.conversationList);
      } catch (err) {
          if (err.response) {
              toast.error(err.response.data?.message);
          } else {
              toast.error("An error occurred while fetching loan applications.");
          }
      }
  };

  // Generate the chat list from conversationList
  const generateChatList = (conversationList) => {
    return conversationList.map((conversation) => {
      const isBorrower = user?.user_type === 'borrower';
      return {
        id: conversation?.conversation_id,
        name: isBorrower ? conversation?.lender_name : conversation?.borrower_name,
        message: conversation?.last_message, // Placeholder message
        date: new Date(conversation?.created_at).toLocaleDateString(),
        profileImg: ChatProfile,
        isOnline: true,
      };
    });
  };

  const chatList = (() => {
    if (conversationList && conversationList.length > 0) {
      // If there are conversations, generate the chat list
      return generateChatList(conversationList);
    } else {
      // If the selected row has no chat data, show a custom message
      return [
        {
          id: 'no-chat',
          name: "No Conversations",
          message: "No chat available for the selected application.",
          date: "",
          profileImg: null,
          isOnline: false,
        },
      ];
    }

    return [];
  })();

  // Fetch chat messages based on conversation_id
  const fetchChatMessages = async (conversation_id) => {
    try {
      setShowTypingIndicator(true); // Show typing indicator when fetch starts
      if (conversationList.length > 0) {
        const response = await axios.post(`${awsContextVal?.endpoint}/conversation/message`, { conversation_id });
        setChatMessages(response?.data?.messageList || []);
      } else {
        setChatMessages([]);
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally{
      setShowTypingIndicator(false); // Hide typing indicator after 3 seconds
    }
  };

  useEffect(() => {
      if (conversationList.length > 0) {
        if(selectedConversationId == ''){
          const firstConversationId = conversationList[0]?.conversation_id;
          const currentUserName = user?.user_type == "borrower" ?  conversationList[0]?.lender_name : conversationList[0]?.borrower_name;
          setSelectedConversationId(firstConversationId);
          setChatUserName(currentUserName);
        }
      } else {
        setChatUserName('');
        setSelectedConversationId('');
        setChatMessages([]);
      }
  }, [conversationList]);

  // Separate useEffect for interval to fetch messages
  useEffect(() => {
    let interval;
    // Set up interval only if there's a selectedConversationId
    if (selectedConversationId) {

      fetchChatMessages(selectedConversationId);

      // if(conversationList.length > 0) {
      //   conversationList?.map((list) => {
      //     if (selectedConversationId == list?.conversation_id) {
      //       if (user?.user_type == "borrower") {
      //         setChatUserName(list?.lender_name);
      //       } else {
      //         setChatUserName(list?.borrower_name);
      //       }
      //     }
      //   });
      // }

      interval = setInterval(async () => {
        setShowTypingIndicator(true); // Show typing indicator when fetch starts

        try {
          // Trigger the fetch operation
          await fetchChatMessages(selectedConversationId);
        } catch (error) {
          console.error('Error fetching chat messages:', error);
        }

        // Add a delay to hide the typing indicator after at least 3 seconds
        setTimeout(() => {
          setShowTypingIndicator(false); // Hide typing indicator after 3 seconds
        }, 3000); // 3 seconds delay
      }, 30000); // 30 seconds for the interval to fetch new messages

      // Cleanup function to clear interval when component unmounts or selectedConversationId changes
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [selectedConversationId]);

  useEffect(() => {

    setSelectedConversationId('');
    setChatMessages([]);
    getConversationList();

    const intervalId = setInterval(() => {
        if(application_id) {
          getConversationList();
        }
    }, 20000); // Update every 60 seconds

    return () => clearInterval(intervalId);
  },[application_id])

  return (
    <div className='tw-custom-container'>
      <div className="tw-flex">
        <ChatUserList
          chatList={chatList}
          setSelectedConversationId={setSelectedConversationId}
          selectedConversationId={selectedConversationId}
          setChatUserName={setChatUserName}
        />
        <ChatWindow
          chatUserName={chatUserName}
          chatMessages={chatMessages}
          conversationId={selectedConversationId}
          fetchChatMessages={fetchChatMessages}
          showTypingIndicator={showTypingIndicator}
          setShowTypingIndicator={setShowTypingIndicator}
        />
      </div>
    </div>
  );
}

export default Chat;
