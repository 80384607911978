import React from "react";

function AnimatePulse () {
    return (
        <div className="tw-animate-pulse tw-flex">
            <div className="tw-rounded-full tw-bg-slate-700 tw-h-24 tw-w-24"></div>
        </div>
    )
}

export default AnimatePulse;