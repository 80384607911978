import React, { useContext, useEffect, useState } from 'react';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
    Button,
    List,
    ListItem
} from "@material-tailwind/react";
import { UserContext } from '../../../contexts/UserContext';
import toast from 'react-hot-toast';
import { useApiService } from '../../../services/apiService';

function AdminPaymentListHeader({ title, handleSortChange, setFilters }) {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const [lenderUserList, setLenderUserList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");

    const handleCloseDropdown = () => setIsOpen(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleUserChange = (event) => {
        const userId = event.target.value;
        setSelectedUser(userId);

        setFilters((prevFilters) => ({
            ...prevFilters,
            user_id: userId || "",
        }));
    };

    const lenderUserPaymentList = async () => {
        try {
            const response = await postRequest(`/user/list`, {
                user_type: 'lender',
                status: '1'
            });
            if(response?.success) {
                const data = response?.data?.userList;
                setLenderUserList(data);
            } else {
                setLenderUserList([]);
            }
        } catch (err) {
            toast.error("Data fetching issue.");
        }
    };

    useEffect(() => {
        lenderUserPaymentList();
    }, [user]);

    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            {title && title !== '' && (
                <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'>
                    {title}
                </Typography>
            )}

            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>

                    {/* User Dropdown */}
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[200px] tw-border tw-border-[#DDDDDD] tw-rounded-[8px] tw-bg-[#FFFFFF]'>
                        <select
                            value={selectedUser}
                            onChange={handleUserChange}
                            className="tw-text-black tw-font-inter tw-py-2 tw-border-none tw-rounded-lg tw-w-full tw-ps-[10px]"
                        >
                            <option value="">Select User</option>
                            {lenderUserList.map((item) => (
                                <option key={item?.user_id} value={item?.user_id}>
                                    {(item?.first_name + ' ' + item?.last_name) || "Unknown User"}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Sort Dropdown */}
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                        <Popover
                            placement="bottom"
                            open={isOpen}
                            handler={toggleDropdown}
                        >
                            <PopoverHandler>
                                <Button className="tw-text-[#4F4F4F]">
                                    <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[3px]"></i>
                                    <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[10px]"></i>
                                    Sort
                                </Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[7.3%] tw-mt-[2.5px] tw-border tw-border-[#DDDDDD] tw-shadow-lg">
                                <List className="p-0 tw-bg-white">
                                    <ListItem
                                        className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-border-b-[1px]"
                                        onClick={() => {
                                            handleSortChange('oldFirst');
                                            handleCloseDropdown();
                                        }}
                                    >
                                        <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                        Old First
                                    </ListItem>
                                    <ListItem
                                        className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-items-center tw-border-b-[1px]"
                                        onClick={() => {
                                            handleSortChange('newFirst');
                                            handleCloseDropdown();
                                        }}
                                    >
                                        <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                        New First
                                    </ListItem>
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminPaymentListHeader;
