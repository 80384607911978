import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CREACData from "./jsonData/CREAC.json";

// Format function for Y-Axis (0 decimals for B, 1 decimal for T)
const formatYAxis = (num) => {
  return num >= 1000 ? `${(num / 1000).toFixed(1)}T` : `${num.toFixed(0)}B`;
};

// Format function for Tooltip (4 decimals)
const formatTooltip = (num) => {
  return num >= 1000 ? `$${(num / 1000).toFixed(4)}T` : `$${num.toFixed(4)}B`;
};

export default function CREAC() {
  return (
    <ResponsiveContainer width="100%" height={267}>
      <AreaChart
        width={500}
        height={267}
        data={CREACData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="observation_date"
          interval={39}
          tickMargin={12}
          tick={{ fontSize: "12px" }}
          tickFormatter={(tick) =>
            new Date(tick).toLocaleDateString("en-US", { year: "numeric" })
          }
        />
        <YAxis 
        tick={{ fontSize: "12px" }}
        tickFormatter={formatYAxis}
        label={{
          value: "US Dollars", // Label text
          angle: -90, // Rotates it vertically
          position: "insideLeft", // Positions it inside the chart
        
          style: { textAnchor: "middle", fontSize: "12px", fill: "#666" }, // Style adjustments
        }}
         />

        <Tooltip
          labelFormatter={(label) => {
            return new Date(label).toLocaleDateString("en-US", { month: "short", year: "numeric" });
          }}
          formatter={(value) => {
            return [formatTooltip(value)];
          }}
        />

        <Area
          type="monotone"
          dataKey="CREAC"
          stroke="#555FE1"
          strokeWidth={4}
          fill="blue"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
