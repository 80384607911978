// src/layouts/BaseLayout.js
import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import "../../../assets/css/base.css";


const BaseLayout = () => {
  return (
    <div className="base-layout">
      <Header />
      <main
        className="
          tw-flex
          tw-items-center
          tw-relative
          tw-px-[20px]
          tw-py-[20px]
          tw-w-full
          tw-h-auto 
          md:tw-px-[20px]
          md:tw-py-[20px]
          tw-w-full 
        "
      >
        <Outlet />
      </main>
    </div>
  );
};

export default BaseLayout;