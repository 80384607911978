import { useState } from "react";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePickerComponent = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
                components={[
                    'DatePicker',
                ]}
                sx={{
                    marginTop: 0,
                    paddingTop: 0
                }}
            >
                <DemoItem>
                    <DatePicker defaultValue={dayjs('2022-04-17')}/>
                </DemoItem>
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DatePickerComponent;
