
import React, { useEffect, useState, useContext, useRef } from "react";
import ApplicationTable from "../../common/applicationTable/ApplicationTable";
import FilterForm from "./FilterForm";
import Chat from "../../common/chat-new/Chat";
import AssetGenerated from "./AssetGenerated";
import { UserContext } from "../../../contexts/UserContext";
import toast from "react-hot-toast";
import PropertyDetail from "./PropertyDetail";
import SendOffers from "./modal/SendOffers";
import {
  formatTimeDifference,
  formatPrice,
  getApplicationOfferStatus,
} from "../../../utils/common";
import LenderHeader from "./LenderHeader";
import { useLoader } from "../../../contexts/LoaderContext";
import UpgradePlanModal from "./modal/UpgradePlanModal";
import { useApiService } from "../../../services/apiService";
import Accepted_application from "../../../assets/icons/accepted_application.png.png";
import Total_application from "../../../assets/icons/total_application.png.png";
import Pending_application from "../../../assets/icons/pending_application.png";
import Rejected_applicaion from "../../../assets/icons/rejected_applicaion.png";
import Archived_applications from "../../../assets/icons/archived_application.png";
import Loan_acceptance_rate from "../../../assets/icons/loan_acceptance_rate.png";
import { Box } from "@mui/material";
import StatCards from "../../layouts/homepage/StatCards";
import PageLoader, { PageLoaderProvider, usePageLoader } from "../../../contexts/PageLoaderContext";


function Lender() {
  const { user } = useContext(UserContext);
  const { setCurrentOfferPropertyDetails } = useContext(UserContext);
  const { getRequest, postRequest } = useApiService();
  // console.log("lender user: ", user);
  const initialFilterState = {
    user_id: user.id,
    hideArchived: false,
    search_text: "",
    loan_purpose_id: "",
    condition: "",
    property_type_id: "",
    property_size_min: "",
    property_size_max: "",
    property_value_min: "",
    property_value_max: "",
    appraised_value_min: "",
    appraised_value_max: "",
    application_status: "",
    application_offer_status: "",
    date_submitted: "",
    city_id: "",
    state_id: "",
    postal_code: "",
    proximity: "",
    loan_term: "",
    ltv: "",
    interest_rate_type: "",
    borrower_type: "",
    borrower_existing_dept_min: "",
    borrower_existing_dept_max: "",
    borrower_credit_score_min: "",
    borrower_credit_score_max: "",
    sort_by: "a.id",
    sort_order: "DESC",
    lat: "37.7749",
    lng: "-122.4194",
  };

  const [openSendOfferModal, setOpenSendOfferModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [userAllloanApplications, setUserAllloanApplications] = useState([]);
  const [archivedApps, setArchivedApps] = useState([]);
  const [hideArchived, setHideArchived] = useState(true)
  const [lenderApplicationsSummary, setLenderApplicationsSummary] = useState(
    []
  );
  const [propertyDetails, setPropertyDetails] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedRowBorrowerId, setSelectedBorrowerId] = useState(0);
  const [selectedRowStatus, setSelectedRowStatus] = useState("");
  const [revisedOfferDetail, setRevisedOfferDetail] = useState({});
  const [filter, setFilter] = useState(initialFilterState);
  const [isFilter, setIsFilter] = useState(false);
  const [chatWindowRender, setChatWindowRender] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [propertyDetailHeight, setPropertyDetailHeight] = useState(0);
  const propertyDetailRef = useRef(null);
  const [isTrialUser, setIsTrialUser] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [remainingOffers, setRemainingOffers] = useState(0);
  const [hasExpiredMembership, setHasExpiredMembership] = useState(false);
  const { showPageLoader, hidePageLoader } = usePageLoader()
  const [isPageReady, setIsPageReady] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [childComponentsReady, setChildComponentsReady] = useState({
    table: false,
    propertyDetail: false,
    chat: false,
    assetGenerated: false,
  });

  const isEverythingReady = Object.values(childComponentsReady).every(Boolean) && dataLoaded;
  const selectedRowRef = useRef(selectedRowId);
  const [lastValidPropertyDetails, setLastValidPropertyDetails] = useState({});


  const markChildReady = (componentName) => {
    setChildComponentsReady((prev) => ({
      ...prev,
      [componentName]: true,
    }));
  };

  const ensureOnReady = (componentName) => {
    if (!childComponentsReady[componentName]) {
      markChildReady(componentName);
    }
  };

  useEffect(() => {
    if (isEverythingReady) {
      setTimeout(() => {
        hidePageLoader();
      }, 500); // Add a small delay before hiding the page loader
    }
  }, [isEverythingReady]);

  useEffect(() => {
    const fetchLenderData = async () => {
      showPageLoader();
      try {
        await fetchLenderLoanSummary();
        await fetchAllUserLoanApplications();
        setDataLoaded(true);
      } catch (error) {
        toast.error("Failed to load initial data.");
      }
    };

    fetchLenderData();
  }, []);


  const closeModal = () => setIsModalOpen(false);
  const handleOpenSendOfferModal = () => {
    // Check if membership is expired or no remaining offers
    if (hasExpiredMembership || (remainingOffers <= 0 && user.unlimited_offer == 0)) {
      setIsModalOpen(true); // Open the upgrade modal instead
    } else {
      setOpenSendOfferModal(true); // Open the SendOffers modal if conditions are met
    }
  };
  const handleCloseModal = () => setOpenSendOfferModal(false);
  const [stats, setStats] = useState([]);
  const [fetchOfferLogsData, setFetchOfferLogsData] = useState([]);
  const [filters, setFilters] = useState({
          sort_by: 'aoh.id',
          sort_order: 'DESC',
          start_date: "",
          end_date: "",
      });

  const fetchOfferLogs = async () => {
    try {
      const response = await postRequest(`/application-offer-history/list`, {
        user_id: user?.id,
        sort_by: filters?.sort_by,
        sort_order: filters?.sort_order,
        start_date: filters?.start_date,
        end_date: filters?.end_date,
      });
      if (response?.success) {
        setFetchOfferLogsData(response?.data?.applicationOfferLogs);
      } else {
        setFetchOfferLogsData([]);
        toast.error('something went wrong.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
          fetchOfferLogs();
      }, [user]);

  //This is to get Lender Dashboard Summary
  const fetchLenderLoanSummary = async () => {

    if (!user) return;

    try {
      const response = await postRequest(`/getLenderDashboardSummary`, {
        userId: user?.id,
      });
      console.log("lender summary response: >", response);
      if (response?.success) {
        setLenderApplicationsSummary(response?.data);
      } else {
        setLenderApplicationsSummary([]);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message);
      } else {
        toast.error("An error occurred while fetching loan applications.");
      }
    }

  };

  const updatePropertyDetails = (property) => {
    setCurrentOfferPropertyDetails(property);

    setPropertyDetails((prevDetails) => {
      if (JSON.stringify(prevDetails) !== JSON.stringify(property)) {
        return property;
      }
      return prevDetails; // Prevent unnecessary re-render
    });

    setApplicationId(property?.application_id);
  };


  const fetchAllUserLoanApplications = async () => {
    if (!user) return;

    try {
      const response = await postRequest(`/application/lender/list`, filter);
      if (response?.success) {
        const applications = response?.data?.applications || [];
        const archivedApplications = response?.data?.archivedApplications || [];
        setUserAllloanApplications(applications);
        setArchivedApps(archivedApplications);

        // Check if selectedRowId is still valid
        const isSelectedStillValid = applications.some(
          (app) => app.application_id === selectedRowRef.current
        );

        if (!isSelectedStillValid && applications.length > 0) {
          const firstApplication = applications[0];
          selectedRowRef.current = firstApplication.application_id;
          setSelectedRowId(firstApplication.application_id);
          setSelectedLocation({ lat: firstApplication.lat, lng: firstApplication.lng });
          setSelectedRowStatus(firstApplication.status);
          setApplicationId(firstApplication.application_id);

          // Only update property details if different
          updatePropertyDetails(firstApplication);
        }
      } else {
        setUserAllloanApplications([]);
      }
    } catch (err) {
      toast.error("Error fetching loan applications.");
    }
  };




  // Dependant on the variables - user
  useEffect(() => {
    // Set up an interval on component mounting to fetch all loan applications it every 30 seconds
    const intervalId = setInterval(() => {
      if (hideArchived) {
        console.log("Refreshing Applications.");
        fetchAllUserLoanApplications();
      }
    }, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [user, isFilter, hideArchived]);

  const filterHandler = () => {
    console.log("Filters being applied:", filter);
    setIsFilter(!isFilter);
  };
  const resetFilterHandler = () => {
    setFilter(initialFilterState);
    setIsFilter(!isFilter);
  };

  const handleSortChange = (type) => {
    let newSortOrder = "";

    if (type === "oldFirst") {
      newSortOrder = "ASC"; // Set sort order to ASC for "Old First"
    } else if (type === "newFirst") {
      newSortOrder = "DESC"; // Set sort order to DESC for "New First"
    }

    setFilter((prevFilters) => ({
      ...prevFilters,
      sort_order: newSortOrder,
    }));
    setIsFilter(!isFilter);
  };

  /*
        Function to archive application for lender
    */
  const handleArchiveApplication = async (params, status) => {
    showLoader();
    try {
      console.log("Archiving Applcation...");
      // console.log(params)

      // This endpoint used is for admin to archive an applicatin across all databases
      // const response = await putRequest(`/application/update-status/${params}`, {status_id: status});

      // // console.log("Response: ", response)
      // // console.log(response?.statusCode)
      // if(response?.statusCode === 200) {
      //     toast.success("Application Archived.")
      //     console.log("Application Archived.")
      //     fetchAllUserLoanApplications();
      // } else {
      //     toast.error("An error occurred while archiving application.")
      //     console.log('Error: ', response)
      // }
      // fetchAllUserLoanApplications();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message);
      } else {
        toast.error("An error occurred while archiving application.");
      }
    } finally {
      hideLoader();
    }
  };

  // Dependant on the variables - user, isFilter
  useEffect(() => {
    console.log("Filter or User has changed. Reload Applications.");
    fetchAllUserLoanApplications();
    fetchLenderLoanSummary();
  }, [user, isFilter]);

  // Dependant on the variables - lenderApplicationSummary
  useEffect(() => {
    const totalOffersUsed = fetchOfferLogsData
      ? fetchOfferLogsData.reduce((sum, log) => sum + log.offer_used, 0)
      : 0;
    const stats = [
      {
        title: "Total Applications",
        value: userAllloanApplications.length
          ? rows.length
          : 0,
        change: lenderApplicationsSummary?.total_offer_sent_growth_percentage
          ? lenderApplicationsSummary?.total_offer_sent_growth_percentage
          : 0,
        icon: Total_application,
      },
      {
        title: "Accepted Applications",
        value: lenderApplicationsSummary?.total_accepted_offers
          ? lenderApplicationsSummary?.total_accepted_offers
          : 0,
        change:
          lenderApplicationsSummary?.total_accepted_application_growth_percentage
            ? lenderApplicationsSummary?.total_accepted_application_growth_percentage
            : 0,
        icon: Accepted_application,
      },
      {
        title: "Pending Applications",
        value: lenderApplicationsSummary?.total_pending_offers
          ? lenderApplicationsSummary?.total_pending_offers
          : 0,
        change:
          lenderApplicationsSummary?.pending_applications_growth_percentage
            ? lenderApplicationsSummary?.pending_applications_growth_percentage
            : 0,
        icon: Pending_application,
      },
      {
        title: "Rejected Applications",
        value: lenderApplicationsSummary?.total_rejected_offers
          ? lenderApplicationsSummary?.total_rejected_offers
          : 0,
        change:
          lenderApplicationsSummary?.total_rejected_offers_growth_percentage
            ? lenderApplicationsSummary?.total_rejected_offers_growth_percentage
            : 0,
        icon: Rejected_applicaion,
      },
      {
        title: "Archived Applications",
        value: archivedApps.length,
        change: null,
        icon: Archived_applications,
      },
      {
        title: "Loan Acceptance Rate",
        value: totalOffersUsed
          ? `${((lenderApplicationsSummary.total_accepted_offers / totalOffersUsed) * 100).toFixed(2)}%`
          : "0%",
        change: null,
        icon: Loan_acceptance_rate,
      },
    ];
    setStats(stats);
  }, [lenderApplicationsSummary, archivedApps]);

  const columns = [
    // { field: 'application_id', headerName: 'ID', width: 90, editable: true },
    {
      field: "streetAddress",
      headerName: "Address",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      sortable: true,
      renderCell: (params) => {
        const { value, row } = params;
        const createdAt = new Date(row.createdAt);
        const currentTime = new Date();
        const isNew = (currentTime - createdAt) / (1000 * 60 * 60) <= 1;

        return (
          <div className="tw-relative tw-flex tw-items-center tw-justify-center tw-text-center tw-gap-2 tw-w-full tw-py-3">
            {isNew && (
              <Box className="tw-absolute tw-top-[-8px] tw-left-[-2px] tw-rounded-full tw-p-[1.5px] tw-bg-red-400 tw-text-white tw-px-1">
                NEW
              </Box>
            )}
            <span>{value.split(",")[0]}</span>
          </div>
        );
      },
    },

    {
      field: "location",
      headerName: "Location",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      sortable: true,
      renderCell: (v) => {
        return <span className="tw-text-center">{v.value}</span>;
      },
    },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      type: "text",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      sortable: true,
      valueGetter: (v) => {
        return parseFloat(v.replace(/[,\\$]/g, ""));
      },
      renderCell: (v) => {
        return `${v.row.loanAmount}`;
      },
    },
    {
      field: "propertyValue",
      headerName: "Property Value",
      type: "text",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      sortable: true,
      valueGetter: (v) => {
        return parseFloat(v.replace(/[,\\$]/g, ""));
      },
      renderCell: (v) => {
        return `${v.row.propertyValue}`;
      },
    },
    {
      field: "propertyType",
      headerName: "Property Type",
      type: "text",
      minWidth: 160,
      flex: 0.3,
      editable: false,
      renderCell: (v) => {
        return <span className="tw-text-center">{v.value}</span>;
      },
    },
    {
      field: "purpose",
      headerName: "Loan Purpose",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      type: "text",
      align: "left",
    },
    {
      field: "ltv",
      headerName: "LTV",
      type: "text",
      minWidth: 50,
      flex: 0.3,

      editable: true,
      valueGetter: (v) => {
        return Number(v.split("%")[0]);
      },
      renderCell: (v) => {
        return `${v.formattedValue}%`;
      },
    },
    {
      field: "borrower",
      headerName: "Borrower",
      type: "text",
      minWidth: 160,
      flex: 0.3,
      editable: true,
      renderCell: (v) => <div className="tw-text-center">{v.value}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 0.3,

      type: "text",
      valueGetter: (params) => {
        // for MUI sorting
        return params.statusText;
      },
      renderCell: (params) => {
        // whats actually rendered
        const { render } = getApplicationOfferStatus(params.value);
        return render();
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 100,
      flex: 0.3,
      editable: true,
      renderCell: (params) => {
        const formattedTimes = [];
        const formattedTime = formattedTimes.find(
          (item) => item.application_id === params.row.application_id
        )?.formattedTime;
        return formattedTime || formatTimeDifference(params.value);
      },
    },
  ];
  const applicationsToUse = hideArchived ? userAllloanApplications : archivedApps;
  const oppositeApplications = hideArchived ? archivedApps : userAllloanApplications;
  const rows = applicationsToUse?.length
    ? applicationsToUse?.map((app) => ({
      application_id: app?.application_id,
      application_offers_id: app.application_offers_id
        ? app.application_offers_id
        : "",
      application_offers_id: app.application_offers_id
        ? app.application_offers_id
        : "",
      propertyType: app?.property_type,
      purpose: app?.loan_purpose,
      propertyValue: app?.property_value
        ? formatPrice(app?.property_value)
        : "",
      loanAmount: app?.loan_amount ? formatPrice(app?.loan_amount) : "",
      ltv: app?.ltv ? `${app.ltv}%` : "0%",
      borrower: app?.first_name + " " + app?.last_name,
      status: {
        statusId: app?.status_id,
        statusText: app?.application_offer_status,
      },
      streetAddress: app.property_address,
      lat: app?.lat ? app?.lat : "",
      lng: app?.lng ? app?.lng : "",
      city: app?.city ? app?.city : "",
      location: app?.city
        ? `${app?.city}, ${app?.state} ${app?.postal_code}`
        : "",
      state: app?.state ? app?.state : "",
      country: app?.country ? app?.country : "",
      postal_code: app?.postal_code ? app?.postal_code : "",
      borrower_id: app?.user_id,
      createdAt: app.application_created_at || "Just now",
    })).filter((row) =>
      row?.application_id &&
      row?.propertyType &&
      row?.purpose &&
      row?.propertyValue &&
      row?.loanAmount &&
      row?.ltv &&
      row?.borrower &&
      row?.status?.statusId &&
      row?.streetAddress &&
      row?.lat &&
      row?.lng &&
      row?.city &&
      row?.state &&
      row?.country &&
      row?.postal_code
    )
      .filter((row) => row?.status?.statusId !== 3)
    : [];



  const handleRowClick = (params) => {
    if (!params || params.application_id === selectedRowRef.current) return;

    showLoader();
    selectedRowRef.current = params.application_id;
    setSelectedRowId(params.application_id);
    setSelectedLocation({ lat: params.lat, lng: params.lng });
    setSelectedBorrowerId(params.borrower_id);
    setSelectedRowStatus(params.status);
    setApplicationId(params.application_id);

    // Save the selected application ID to local storage
    localStorage.setItem("selectedApplicationId", params.application_id);

    updatePropertyDetails(params);

    setTimeout(() => {
      hideLoader();
    }, 1000);
  };



  useEffect(() => {
    if (isInitial && rows.length > 0) {
      console.log("Selecting initial application...");
      const firstRow = rows[0]; // Select the first application
      setSelectedRowId(firstRow.application_id);
      setApplicationId(firstRow.application_id);
      updatePropertyDetails(firstRow);
      setIsInitial(false); // Mark as no longer initial
      localStorage.setItem("selectedApplicationId", firstRow.application_id);
    }
  }, [rows, isInitial]);



  useEffect(() => {
    if (rows.length === 0) return; // Don't update if no rows exist

    console.log("Ensuring stable selection...");

    // Get previously selected application from localStorage
    const storedApplicationId = localStorage.getItem("selectedApplicationId");

    let newSelection = null;

    // Check if stored selection is valid
    if (storedApplicationId && rows.some(row => row.application_id === parseInt(storedApplicationId))) {
      newSelection = rows.find(row => row.application_id === parseInt(storedApplicationId));
    }

    // Check if the currently selected row is still valid
    if (!newSelection && rows.some(row => row.application_id === selectedRowRef.current)) {
      newSelection = rows.find(row => row.application_id === selectedRowRef.current);
    }

    // If no valid selection found, fallback to first row **but don't reset propertyDetails**
    if (!newSelection) {
      newSelection = rows[0];
    }

    // Only update state if the selected application has changed
    if (selectedRowRef.current !== newSelection.application_id) {
      selectedRowRef.current = newSelection.application_id;
      setSelectedRowId(newSelection.application_id);
      setSelectedLocation({ lat: newSelection.lat, lng: newSelection.lng });
      setApplicationId(newSelection.application_id);

      // Persist to localStorage
      localStorage.setItem("selectedApplicationId", newSelection.application_id);
    }

    // Maintain the last known valid `propertyDetails` to prevent flickering
    if (JSON.stringify(propertyDetails) !== JSON.stringify(newSelection)) {
      setPropertyDetails(newSelection);
      setLastValidPropertyDetails(newSelection);
      setCurrentOfferPropertyDetails(newSelection);
    }

  }, [userAllloanApplications, hideArchived]); // Dependencies remain the same








  const lenderHeaderProps = {
    oppositeApplications,
    setHideArchived,
    hideArchived,
    handleSortChange,
    setFilter,
    setIsFilter,
    isFilter,
  };

  // Dependant on the variables - propertyDetails
  useEffect(() => {
    console.log("Height Changed for Property Details");
    const updateHeight = () => {
      if (propertyDetailRef.current) {
        setPropertyDetailHeight(propertyDetailRef.current.offsetHeight);
      }
    };
    setTimeout(() => {
      updateHeight();
    }, 500);
  }, [propertyDetails]);

  const getRevisedOfferDetails = async (application_offers_id) => {
    showLoader();
    try {
      const response = await getRequest(
        `/application-offer/get/${application_offers_id}`
      );
      if (response?.success) {
        setRevisedOfferDetail(response);
      } else {
        setRevisedOfferDetail([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setRevisedOfferDetail([]);
    } finally {
      hideLoader();
    }
  };

  // Dependant on the variables - user
  useEffect(() => {
    if (user) {
      const currentDate = new Date();
      const membershipEndDate = new Date(user.membership_end);

      // Check if membership has expired
      const membershipExpired = (membershipEndDate < currentDate) && user.subscription_plan_id == 35;

      // Calculate remaining offers
      const offersRemaining = user.available_offer || 0;
      const hasUnlimited = user.unlimited_offer;

      // Update states
      setHasExpiredMembership(membershipExpired);
      setRemainingOffers(offersRemaining);

      // Open modal only if membership has expired or there are no remaining offers
      if (membershipExpired || (offersRemaining <= 0 && hasUnlimited == 0)) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  }, [user]);


  return (
    <div className="tw-h-full tw-w-full">
      {!isEverythingReady && (
        <div style={{ backgroundColor: "#FFFFFF", width: "100vw", height: "100vh" }}>
          {/* This renders a plain white screen */}
        </div>
      )}
      <StatCards statList={stats} />
      <div className="tw-flex tw-mt-3 hidden">
        <FilterForm
          filter={filter}
          setFilter={setFilter}
          filterHandler={filterHandler}
          resetFilterHandler={resetFilterHandler}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-5 tw-mt-3 tw-h-full">
        <div className=" tw-flex tw-flex-col lg:tw-grid tw-grid-rows-[auto_1fr_auto] tw-grid-cols-2 2xl:tw-grid-cols-[minmax(0,2fr)_minmax(0,1fr)] tw-gap-5 w-full max-w-full">
          <div className="tw-w-full">
            <ApplicationTable
              dataTableProps={{
                getRowId: (row, index) => `${index}-${row?.application_id}`,
                userStatus: ["Offer Sent", "Rejected", "Pending"],
                dashboardType: "lender",
                handleSortChange,
                getRowClassName: (params) =>
                  params?.row?.application_id === selectedRowId
                    ? "selected-row"
                    : "",
              }}
              onRowClick={handleRowClick}
              rows={rows}
              selectedRow={
                rows.find((row) => row.application_id === selectedRowId) ||
                rows[rows.length - 1]
              }
              columns={columns}
              tableHeight="400px"
              maxHeight={`${propertyDetailHeight}px`} // Set maxHeight dynamically based on PropertyDetail height
              minHeight="50%"
              customHeaderProps={lenderHeaderProps}
              CustomHeaderComponent={LenderHeader}
              onReady={() => ensureOnReady("table")}
            />
          </div>
          <div className="tw-w-full tw-h-full" ref={propertyDetailRef}>
            <PropertyDetail
              property={propertyDetails && Object.keys(propertyDetails).length > 0 ? propertyDetails : lastValidPropertyDetails}
              setOpenSendOfferModal={handleOpenSendOfferModal}
              selectedLocation={selectedLocation}
              rowStatus={selectedRowStatus}
              getRevisedOfferDetails={getRevisedOfferDetails}
              disabled={isTrialUser}
              handleRowClick={handleRowClick}
              setHideArchived={setHideArchived}
              appsToUse={applicationsToUse}
              rows={rows}
              setIsModalOpen={setIsModalOpen}
              handleArchiveApplication={handleArchiveApplication}
              hideArchived={hideArchived}
              refreshApps={fetchAllUserLoanApplications}
              onReady={() => ensureOnReady("propertyDetail")}
            />
          </div>
          <div className="tw-w-full">
            <Chat
              application_id={selectedRowId}
              setOpenSendOfferModal={handleOpenSendOfferModal}
              rowStatus={selectedRowStatus}
              chatWindowRender={chatWindowRender}
              onReady={() => ensureOnReady("chat")}
            />
          </div>

          <div className="tw-w-full tw-h-full">
            <AssetGenerated
              onReady={() => ensureOnReady("assetGenerated")}
            />
          </div>
        </div>
      </div>

      {/* <div className='hidden tw-flex tw-mt-10'>
                <div className='tw-w-[67%]'>
                    <AreaInterest />
                </div>
            </div> */}

      <SendOffers
        open={openSendOfferModal}
        handleClose={handleCloseModal}
        applicationId={applicationId}
        selectedRowBorrowerId={selectedRowBorrowerId}
        fetchAllUserLoanApplications={fetchAllUserLoanApplications}
        setSelectedRowId={setSelectedRowId}
        setSendOfferSelectedAppId={setSelectedRowId}
        setChatWindowRender={setChatWindowRender}
        revisedOfferDetail={revisedOfferDetail}
      />

      {isModalOpen && (
        <UpgradePlanModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          hasExpiredMembership={hasExpiredMembership}
          remainingOffers={remainingOffers}
        />
      )}
    </div>
  );
}

export default Lender;
