import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@material-tailwind/react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { capitalizeWords } from '../../utils/common';
import { signUp } from "@aws-amplify/auth";
import toast from "react-hot-toast";
import { useLoader } from '../../contexts/LoaderContext';
import { UserContext } from '../../contexts/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import PromoCode from './PromoCode';
import { usePromoCode } from '../../contexts/PromoCodeContext';
import { useApiService } from '../../services/apiService';

function SignUp() {
    const dispatch = useDispatch();
    const { loading, error, user } = useSelector((state) => state.signUp);
    const { storeLenderFormData, userType } = useContext(UserContext);
    // const { userType } = useUserType();
    const navigate = useNavigate();
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { setPromoCodeDetail } = usePromoCode();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleLoginClick = () => {
        navigate(`/sign-in`);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
        }));

        // Validate field on change
        validateField(id, value);
    };

    const handlePhoneChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            phone: value,
        }));
        // Validate phone number
        if (value && !isValidPhoneNumber(value)) {
            setErrors(prevErrors => ({ ...prevErrors, phone: 'Invalid phone number for selected country' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
        }
    };

    const validateField = (field, value) => {
        let validationErrors = { ...errors };

        switch (field) {
            case 'firstName':
                validationErrors.firstName = value ? '' : 'First Name is required';
                break;
            case 'lastName':
                validationErrors.lastName = value ? '' : 'Last Name is required';
                break;
            case 'email':
                if (!value) {
                    validationErrors.email = 'Email Address is required';
                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    validationErrors.email = 'Please enter a valid email address';
                } else if (/^\d+$/.test(value)) {
                    validationErrors.email = 'Email Address cannot be just numbers';
                } else {
                    validationErrors.email = '';
                }
                break;
            case 'phone':
                validationErrors.phone = value ? '' : 'Phone Number is required';
                break;
            case 'password':
                if (!value) {
                    validationErrors.password = 'Password is required';
                } else if (value.length < 8) {
                    validationErrors.password = 'Password must be at least 8 characters';
                } else {
                    validationErrors.password = '';
                }
                break;
            case 'confirmPassword':
                validationErrors.confirmPassword = value === formData.password ? '' : 'Passwords do not match';
                break;
            default:
                break;
        }
        setErrors(validationErrors);
    };

    const isFormDataValid = () => {
        return Object.values(formData).every(field => field.trim() !== '');
    };

    const SignUpBorrower = async (e) => {
        if (isFormDataValid()) {  // Check if formData has values
            try {
                const user_details = await postRequest(`/check-email`, { email: formData.email });
                if (user_details?.data?.is_exist) {
                    toast.error("Email Already Exist.");
                    hideLoader();
                } else {
                    storeLenderFormData(formData);
                    const { userId } = await signUp({
                        username: formData.email,
                        password: formData.password,
                        options: {
                            userAttributes: {
                                email: formData.email,
                                phone_number: formData.phone,
                                name: `${formData.firstName} ${formData.lastName}`,
                                'custom:user_type': userType,
                                'custom:FirstName': formData.firstName,
                                'custom:LastName': formData.lastName,
                            },
                        },
                    });

                    if (userId) {
                        const response = await postRequest(`/create-user`,
                            {
                                email: formData.email,
                                phone_number: formData.phone,
                                name: formData.firstName + " " + formData.lastName,
                                'custom:user_type': userType,
                                'custom:FirstName': formData.firstName,
                                'custom:LastName': formData.lastName
                            }
                        );
                        if(response?.success) {
                            hideLoader();
                            navigate(`/otp-verify`);
                            toast.success("User registered successfully! Please validate and log in.");
                        } else {
                            toast.error("User not registerd.");
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user:", error);
                hideLoader();  // Hide the loader in case of error
            }
        } else {
            toast.error("Please enter form values.");
            hideLoader();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final validation before submission
        let validationErrors = {};
        Object.keys(formData).forEach(field => {
            validateField(field, formData[field]);
        });

        // If there are errors, set the errors state
        if (Object.values(errors).some(error => error)) {
            return;
        }

        // Clear any previous errors
        setErrors({});

        try {
            showLoader();
            if (userType === "lender") {
                if (isFormDataValid()) {  // Check if formData has values
                    try {
                        const user_details = await postRequest(`/check-email`, { email: formData.email });
                        console.log("new user_details:", user_details);
                        if(user_details.success) {
                            if (user_details?.data?.is_exist) {
                                toast.error("User Already Exist.");
                                hideLoader();
                            } else {
                                navigate(`/subscription`);
                                storeLenderFormData(formData);
                                hideLoader();
                            }
                        } else {
                            toast.error("Something went wrong.");
                        }
                    } catch (error) {
                        console.error("Error fetching user:", error);
                        hideLoader();  // Hide the loader in case of error
                    }
                } else {
                    toast.error("Please enter form values.");
                    hideLoader();
                }
            } else {
                SignUpBorrower();
            }
        } catch (err) {
            hideLoader();
            toast.error(err.message || "Cannot sign up user.");
            console.log(err);
        }
    };

    // Apply Promo Code Handler
    const handleApplyPromoCode = async(code) => {
        try {
            showLoader();
            const response = await postRequest(`/promo-code/validate`, {
                code : code
            });
            if(response?.data?.success == true) {
                const data = response?.data?.code;
                setPromoCodeDetail({
                    id: data?.id,
                    discount_amount: data?.discount_value,
                    code : data?.code
                });
                localStorage.setItem("promoCode", code);
                toast.success(response?.data?.message);
            } else {
                toast.error(response?.data?.message);
            }
        } catch(err) {
            toast.error(err.message || "Cannot Apply Promo Code.");
            console.log(err);
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        localStorage.removeItem('promoCode');
    }, []);

    return (
        <>
            <div className="tw-bg-white tw-px-4 sm:tw-px-4 md:tw-px-12 lg:tw-px-12  tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-full">
                    {/* <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block">
                        {capitalizeWords(userType)} Profile
                    </div> */}
                    <h2 className="tw-font-oswald tw-text-3xl sm:tw-text-xl md:tw-text-2xl lg:tw-text-4xl tw-text-[#333333] tw-my-3 tw-font-bold">Create an account as a {userType}</h2>
                    <p className="tw-mb-4 tw-text-[#666666] tw-text-xl">
                        Already have an account? <button onClick={handleLoginClick} className="tw-text-black tw-underline tw-text-[#666666]">Log in</button>
                    </p>
                    <form className="tw-space-y-3" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0">
                            <div className="tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="firstName" className="form-label ">
                                    First Name <span className="tw-text-red-500">*</span>
                                </label>
                                <Input
                                    id="firstName"
                                    type="text"
                                    autoFocus={true}
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.firstName ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.firstName && <p className="tw-text-red-500 tw-text-[14px] ">{errors.firstName}</p>}
                            </div>
                            <div className="tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0">
                                <label htmlFor="lastName" className="form-label">
                                    Last Name <span className="tw-text-red-500">*</span>
                                </label>
                                <Input
                                    id="lastName"
                                    type="text"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.lastName ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.lastName && <p className="tw-text-red-500 tw-text-[14px] ">{errors.lastName}</p>}
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 sm:tw-gap-x-0 tw-gap-x-0 lg:tw-gap-y-0 sm-mt-10">
                            <div className="tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="email" className="form-label">
                                    Email Address <span className="tw-text-red-500">*</span>
                                </label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px] ">{errors.email}</p>}
                            </div>
                            <div className="tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0">
                                <label htmlFor="phone" className="form-label">
                                    Phone Number <span className="tw-text-red-500">*</span>
                                </label>

                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    id="phone"
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    defaultCountry="US"
                                    containerClass="tw-w-full"
                                    inputClass="tw-h-full tw-rounded-16"
                                    buttonClass="tw-bg-gray-200 tw-border tw-rounded-l-16"
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.phone ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.phone && <p className="tw-text-red-500 tw-text-[14px] ">{errors.phone}</p>}
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0 sm-mt-10">
                            <div className="tw-relative tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="password" className="form-label">
                                    Password <span className="tw-text-red-500">*</span>
                                </label>

                                <Input
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.password ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            tabIndex={-1}
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.password && <p className="tw-text-red-500 tw-text-[14px] ">{errors.password}</p>}
                            </div>
                            <div className="tw-relative tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0">
                                <label htmlFor="confirmPassword" className="form-label">
                                    Confirm Password <span className="tw-text-red-500">*</span>
                                </label>
                                <Input
                                    id="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.confirmPassword ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            tabIndex={-1}
                                            type="button"
                                            onClick={toggleConfirmPasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showConfirmPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.confirmPassword && <p className="tw-text-red-500 tw-text-[14px] ">{errors.confirmPassword}</p>}
                            </div>
                        </div>
                        {userType === "lender" &&
                            <>
                                <PromoCode onApply={handleApplyPromoCode} />
                            </>
                        }

                        <div className="tw-flex tw-gap-4 tw-flex-col lg:tw-flex-row">
                            <Button
                                type="submit"
                                color="green"
                                className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-oswald tw-w-[185px] tw-h-[48px] tw-bg-[#7DDE92] tw-border-none tw-text-[#2A2F70] tw-rounded-full tw-flex tw-items-center tw-justify-between tw-px-[12px]"
                                id='sign_up_btn'
                            >
                                <p className='tw-w-[135px] tw-h-[27px]'>
                                    Sign Up As {capitalizeWords(userType)}
                                </p>
                                {/* <span className="tw-bg-white  tw-rounded-full">➔</span> */}
                                <div className="tw-bg-white tw-w-[36px] tw-h-[36px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                                    ➔
                                </div>
                            </Button>
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SignUp;
