import React, { useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Input, Button } from '@material-tailwind/react';
import axios from 'axios';
// import { signIn, signOut } from "@aws-amplify/auth";
import { signIn, signOut } from 'aws-amplify/auth';
import toast from "react-hot-toast";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BorrowerSignupIcon from "../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../assets/icons/lenderIcon.svg";
import { authLocal, capitalizeWords } from '../../utils/common';
import { UserContext } from '../../contexts/UserContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useApiService } from '../../services/apiService';

function SignIn() {
    const { setUser, setUserType } = useContext(UserContext)
    const navigate = useNavigate();
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // State for form data and errors
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({});

    // Function to switch to the login form
    const handleSignUpClick = () => {
        navigate(`/sign-up`);
    };

    const handleForgotPassword = () => {
        navigate(`/forgot-password`);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });

        // Validate fields on change
        if (id === 'email') {
            if (!value) {
                setErrors(prevErrors => ({ ...prevErrors, email: 'Email Address is required' }));
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                setErrors(prevErrors => ({ ...prevErrors, email: 'Please enter a valid email address' }));
            } else {
                setErrors(prevErrors => ({ ...prevErrors, email: '' })); // Clear error if valid
            }
        }

        // For other fields like password, you can add similar validation if required
        if (id === 'password' && !value) {
            setErrors(prevErrors => ({ ...prevErrors, password: 'Password is required' }));
        } else if (id === 'password') {
            setErrors(prevErrors => ({ ...prevErrors, password: '' })); // Clear error if valid
        }
    };

    const normalizeUserDetails = (user) => {
        if (!user) return null;
    
        // Ensure all fields are flattened at the root level
        return {
            ...user,
            membership_start: user?.membership_start || null,
            membership_end: user?.membership_end || null,
            subscription_plan_id: user?.subscription_plan_id || null,
            available_offer: user?.available_offer || null,
            plan: user?.plan || null,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        // Validate required fields
        if (!formData.email) {
            validationErrors.email = "Email Address is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            validationErrors.email = "Please enter a valid email address";
        }

        if (!formData.password) {
            validationErrors.password = "Password is required";
        }

        // If there are errors, set the errors state
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            showLoader();
            authLocal.remove(); // Clear any existing authentication details before sign-in

            const { nextStep } = await signIn({
                username: formData.email,
                password: formData.password,
            });

            // Check if the sign-in is complete
            if (nextStep.signInStep === "DONE") {
                // Fetch user details
                const user_details = await postRequest("/fetch-user", {
                    email: formData.email,
                });

                if (user_details?.data?.user) {
                    const user = normalizeUserDetails(user_details.data.user);

                    if (user.status === 1) {
                        authLocal.set(user); // Store normalized user details in local storage
                        setUser(user);
                        setUserType(user?.user_type);
                        hideLoader();
                        // Redirect based on user type
                        if (user.user_type === "borrower") {
                            navigate("/dashboard/borrower");
                        } else if (user.user_type === "lender") {
                            navigate("/dashboard/lender");
                        } else if (user.user_type === "admin") {
                            navigate("/dashboard/admin");
                        }

                        // Handle pending application logic...
                    } else if (user.status === 0) {
                        toast.error("User is inactive!");
                        hideLoader();
                    } else if (user.status === 2) {
                        toast.error("User is disabled!");
                        hideLoader();
                    } else if (user.status === 3) {
                        toast.error("User is Blocked!");
                        hideLoader();
                    }
                } else {
                    hideLoader();
                    toast.error("User not found.");
                    authLocal.remove();
                    await signOut();
                }
            } else {
                hideLoader();
                toast.error("User is not available.");
                authLocal.remove();
                await signOut();
            }
        } catch (error) {
            hideLoader();
            console.error("Error during sign-in:", error);
            toast.error("OOPS! Something went wrong.");
            authLocal.remove();
            await signOut();
        }
    };

    const handleUserTypeChange = (type) => {
        console.log("type:", type)
        setUserType(type);
    };

    return (
        <>
            <div className="tw-bg-white tw-px-4 sm:tw-px-4 md:tw-px-12 lg:tw-px-12 tw-py-16 tw-flex tw-items-center tw-h-screen">
                <div className="tw-w-full">
                    {/* <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block tw-text-sm">
                        {capitalizeWords(userType)} Profile
                    </div> */}
                    <h2 className="tw-font-oswald tw-leading-10 tw-text-start tw-text-2xl md:tw-text-4xl lg:tw-text-3xl tw-text-[#333333] tw-mt-2 tw-font-medium">Sign In to Re-Loan</h2>
                    <div className="tw-font-inter tw-text-[#666666] tw-text-xl">
                        {/* Don't have an account? <button onClick={handleSignUpClick} className="tw-text-black tw-underline tw-text-[#666666]">Sign up</button> */}
                        <div className='tw-flex tw-flex-wrap tw-gap-x-2 tw-items-center'>
                            <div>Don't have an account?</div>
                            <div className='tw-flex tw-gap-4 tw-text-lg xl:tw-text-xl'>
                            <Link to={"/sign-up/borrower"} tabIndex={-1}>
                                <button
                                    onClick={() => handleUserTypeChange('borrower')}
                                    className='hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-mt-3 sm:tw-mt-3 md:tw-mt-0 tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                                >
                                    <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='borrower' />
                                    <p className='tw-text-md tw-text-black tw-font-oswald'>Borrower Signup</p>
                                </button>
                            </Link>
                            <Link to={"/sign-up/lender"} tabIndex={-1}>
                                <button

                                    onClick={() => handleUserTypeChange('lender')}
                                    className='hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-mt-3 sm:tw-mt-3 md:tw-mt-0 lg:tw-mt-0 tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl'
                                >
                                    <img src={LenderSignupIcon} className='tw-w-6 tw-h-6' alt='lender' />
                                    <p className='tw-text-md tw-text-black tw-font-oswald'>Lender Signup</p>
                                </button>
                            </Link>
                            </div>
                        </div>
                    </div>
                    <form className="tw-space-y-10 tw-mt-9" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-gap-4 tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0">
                            <div className="tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <Input
                                    id="email"
                                    type="email"
                                    autoFocus={true}
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px]">{errors.email}</p>}
                            </div>
                            <div className="tw-relative tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="password" className="form-label">Password</label>
                                <Input
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.password ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            tabIndex={-1}
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.password && <p className="tw-text-red-500 tw-text-[14px]">{errors.password}</p>}
                            </div>
                        </div>
                        <div className="tw-flex tw-gap-3 xl:tw-gap-6 tw-flex-wrap tw-items-center tw-justify-center sm:tw-justify-center md:tw-justify-between lg:tw-justify-between">
                            <button className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-oswald tw-w-[105px] tw-h-[48px] tw-bg-[#7DDE92] tw-border-none tw-text-[#2A2F70] tw-rounded-full tw-flex tw-items-center tw-justify-between tw-px-[12px]  submit-button ">

                                <p className='tw-w-[50px] tw-h-[27px]'>
                                    Sign In
                                </p>
                                <div className="tw-bg-white tw-w-[30px] tw-h-[30px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                                    ➔
                                </div>
                            </button>
                            <p className="tw-text-black tw-text-lg">
                                <button onClick={handleForgotPassword} className="tw-font-oswald tw-text-[#2A2F70]">Forgot Password?</button>
                            </p>
                        </div>
                    </form>
                </div>
                {/* {isPendingDataSubmitted && <ApplicationTable />} */}
            </div>
        </>
    )
}

export default SignIn