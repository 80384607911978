import React, { useState } from 'react'
import GoogleAddressMarker from '../../common/GoogleAddressMarker';

function PropertyDetail(props) {
    // state to open streetview modal
    const [open, setOpen] = useState(false);
    // open street view modal
    const handleOpen = () => setOpen(true);
    // close street view modal
    const handleClose = () => setOpen(false);
    const properyDetails = props?.property;
    return (
        <div className='tw-w-[100%] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-[30px]'>
            <div className='tw-w-[100%] tw-py-[22px] tw-border-b-[1px] tw-border-[#DDDDDD] '>
                <h1 className='tw-property-detail-title'>Property Detail</h1>
            </div>

            <div className='tw-property-section'>
                <div className='tw-property-detail-row'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>Property Type</span>
                        <h1 className='tw-property-value'>{properyDetails?.propertyType ? properyDetails?.propertyType : '-'}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Purpose</span>
                        <h1 className='tw-property-value'>{properyDetails?.purpose ? properyDetails?.purpose : '-'}</h1>
                    </div>
                </div>

                <div className='tw-property-detail-row'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>Property Value</span>
                        <h1 className='tw-property-value'>{properyDetails?.propertyValue}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Loan Amount</span>
                        <h1 className='tw-property-value'>{properyDetails?.loanAmount}</h1>
                    </div>
                </div>

                <div className='tw-property-detail-row'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>LTV</span>
                        <h1 className='tw-property-value'>{properyDetails?.ltv ? properyDetails?.ltv : '-'}</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>City:</span>
                        <h1 className='tw-property-value'>Manchester</h1>
                    </div>
                </div>

                <div className='tw-property-detail-row'>
                    <div className='tw-property-left-partition'>
                        <span className='tw-property-label'>State:</span>
                        <h1 className='tw-property-value'>New Hampshire</h1>
                    </div>
                    <div className='tw-property-right-partition'>
                        <span className='tw-property-label'>Zip Code:</span>
                        <h1 className='tw-property-value'>03101</h1>
                    </div>
                </div>

                <div className='tw-property-detail-row'>
                    <div className='tw-property-address-full'>
                        <span className='tw-property-label'>Address:</span>
                        <p className='tw-property-address-text'>
                            {properyDetails?.streetAddress ? properyDetails?.streetAddress : '-'}
                        </p>
                    </div>
                </div>

                {
                    props.selectedLocation
                        ?
                        <div className='tw-my-5'>
                            <GoogleAddressMarker open={open} handleOpen={handleOpen} handleClose={handleClose} selectedLocation={props.selectedLocation} properyDetails={properyDetails} />
                        </div>
                        : null
                }
                <button
                    className="tw-bg-[#7DDE92] tw-mt-4 tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[5px] tw-px-[18px] tw-gap-[4px] tw-text-[18px]"
                    onClick={() => props?.setOpenSendOfferModal(true)}
                >
                    <i className="fas fa-paper-plane tw-mr-2"></i>
                    Send Offer
                </button>
            </div>
        </div>
    )
}

export default PropertyDetail