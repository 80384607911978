import { useState, useContext, useEffect } from "react";
import ProfileIcon from "../../assets/icons/profileIcon.png";
import EditIcon from "../../assets/icons/editIcon.svg";
import ChangePersonalInformationForm from "./ChangePersonalInfoForm";
import ChangePasswordForm from "./ChangePasswordForm";
import AddressForm from "../common/address-form/AddressForm";
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import { UserContext } from "../../contexts/UserContext";
import { useModalContext } from "../../contexts/ModalContexts";
import toast from "react-hot-toast";
import { useLoader } from '../../contexts/LoaderContext';


const BorrowerSettings = () => {
    const { showLoader, hideLoader } = useLoader();
    const { user } = useContext(UserContext);
    const { showModal } = useModalContext();
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        stateId: '',
        cityId: '',
        countryId: 233,
        zip: ''
    });

    const [hasChanges, setHasChanges] = useState(false); // Track changes
    const [disabledForm, setDisableForm] = useState(true);
    
    const handleChange = (updatedInfo) => {
        // This function will be called when data changes
        setHasChanges(true);
    };

    const handleSubmit = async () => {
        showLoader();
        const response = await axios.post(`${awsContextVal?.endpoint}/user/update`, {
            id: user?.id,
            user_type: user?.user_type,
            email: userInfo?.email,
            phone_number: userInfo?.phoneNumber,
            first_name: userInfo?.firstName,
            last_name: userInfo?.lastName,
            dob: userInfo?.dateOfBirth,
            address_1: userInfo?.addressLine1,
            address_2: userInfo?.addressLine2,
            city_id: userInfo?.cityId,
            state_id: userInfo?.stateId,
            country_id: userInfo?.countryId,
            postal_code: userInfo?.zip,
            status: '1'
        });
        if (response.status == 200) {
            hideLoader();
            showModal("User Data Has Been Updated!", "success");
        } else if (response.status == 500) {
            hideLoader();
            showModal("Something Went Wrong.", "success");
        }
        // You can now use formData to send to your API or handle as needed
        setDisableForm(true);
        setHasChanges(false); // Reset changes after submitting
    };
    const fetchUserDetailByEmail = async () => {
        showLoader();
        try {
            const response = await axios.post(`${awsContextVal.endpoint}/fetch-user`, { email: user.email });
            if(response.status == 200) {
                hideLoader();
                let userDetails = response?.data?.user;
                setUserInfo({
                    firstName: userDetails?.first_name,
                    lastName: userDetails?.last_name,
                    dateOfBirth: userDetails?.dob,
                    email: userDetails?.email,
                    phoneNumber: userDetails?.phone_number,
                    addressLine1: userDetails?.address_1,
                    addressLine2: userDetails?.address_2,
                    stateId: userDetails?.state_id,
                    cityId: userDetails?.city_id,
                    countryId: 233,
                    zip: userDetails?.postal_code
                });
            } else {
                hideLoader();
                toast.error( "Something Went Wrong.");
            }
           
        } catch (error) {
            if (error.response) {
                // Handle 404 or other server errors
                console.error("Server error:", error.response.status, error.response.data);
                toast.error(error.response.data?.message || "Failed to fetch user details. Please try again.");
            } else if (error.request) {
                // Network error
                console.error("Network error:", error.request);
                toast.error("Network error: Unable to reach the server.");
            } else {
                // Other errors
                console.error("Error:", error.message);
                toast.error("An unexpected error occurred. Please try again.");
            }
            hideLoader();
        }
    };

    useEffect(() => {
        fetchUserDetailByEmail();
    }, []);

    return (
        <div className="tw-flex tw-flex-col tw-w-full">
            <div className="tw-flex tw-flex-col tw-space-y-2">
                <h2 className="tw-text-[#000000] tw-font-nunito tw-font-bold tw-text-4xl">Profile Settings</h2>
                <p className="tw-text-lg tw-text-[#202224] tw-font-nunito tw-font-light">Here is the information about all your orders</p>
            </div>

            <div className="tw-flex tw-flex-col tw-border-[1px] tw-border-[#0A263F]/14 tw-rounded-lg tw-p-5 tw-my-4">
                <div className="tw-flex tw-justify-between tw-border-b-[1px] tw-pb-4">
                    <h2 className="tw-text-xl tw-text-[#202224] tw-font-semibold tw-font-nunito">Personal Information</h2>

                    {/* {hasChanges ? (
                        <button onClick={handleSubmit} className="tw-flex tw-space-x-2 tw-items-center">
                            <img src={EditIcon} alt="change icon" />
                            <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Submit All Changes</span>
                        </button>
                    ) : (
                        <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Edit Information</span>
                    )} */}

                    {!disabledForm ? (
                        <button onClick={handleSubmit} className="tw-flex tw-space-x-2 tw-items-center tw-bg-[#7DDE92] tw-rounded-[10px] tw-border tw-border-[#DDDDDD]">
                            <span className="tw-px-[10px] tw-py-[5px] tw-text-[#2A2F70] common-font tw-font-semibold">Submit All Changes</span>
                        </button>
                    ): (
                        <button onClick={() => setDisableForm(false)} className="tw-flex tw-space-x-2 tw-items-center tw-bg-[#7DDE92] tw-rounded-[10px] tw-border tw-border-[#DDDDDD]">
                            <div className="tw-px-[20px] tw-flex">
                                <img src={EditIcon} alt="change icon" />
                                <span className="tw-text-[#2A2F70] tw-px-[5px] tw-py-[5px]  common-font tw-text-lg tw-font-semibold">Change Profile Setting </span>
                            </div>
                        </button>
                    )}


                </div>

                <div className="tw-flex tw-space-x-4 tw-items-center tw-my-8">
                    <img src={ProfileIcon} alt="profile icon" className="tw-rounded-full tw-w-28 tw-h-28 tw-object-cover" />

                    <button className="tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-px-2 hover:tw-shadow-xl">Upload Image</button>
                </div>

                <ChangePersonalInformationForm
                    setUserInfo={setUserInfo}
                    onChange={handleChange} // Pass the change handler
                    userInfo= {userInfo}
                    disableForm={disabledForm}
                />

                <AddressForm
                    setUserInfo={setUserInfo}
                    onChange={handleChange} // Pass the change handler
                    userInfo={userInfo}
                    disableForm={disabledForm}
                />
            </div>

            <ChangePasswordForm />
        </div>
    );
};

export default BorrowerSettings;
