import React from 'react';


import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from "@material-tailwind/react";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./App.css";

import { UserDataProvider } from './contexts/UserContext';
import { AWSAmplifyConfigProvider } from './contexts/AWSAmplifyConfigContext';
import { LoaderProvider } from './contexts/LoaderContext';
import Loader from './components/common/Loader';
import Routers from './routers';

function App() {
  return (
    <ThemeProvider>
    <AWSAmplifyConfigProvider>
      <UserDataProvider>
        <LoaderProvider>
          <Toaster position='top-right' toastOptions={{ duration: 2000 }}/>
          <Router>
            <Loader />
            <Routers />
          </Router>
          </LoaderProvider>
      </UserDataProvider>
    </AWSAmplifyConfigProvider>
    </ThemeProvider>
  );
}

export default App;
