// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* advantagesarea.css */

/* Container for cards with hover effect */
.advantages-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 3rem; /* Adds more padding at the top */
    margin-bottom: 1rem;
}

/* Default card style */
.advantage-card {
    flex: none; /* Prevents cards from stretching or shrinking disproportionately */
    width: calc(33.333% - 1rem); /* Ensures cards maintain the same initial width */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth scaling and opacity changes */
}

/* Hover effect on the card being hovered over */
.advantage-card:hover {
    transform: scale(1.1); /* Enlarges the card being hovered */
    z-index: 10; /* Brings the hovered card to the front */
}

/* Shrinks other cards when one is hovered */
.advantages-container:hover .advantage-card:not(:hover) {
    transform: scale(0.9); /* Shrinks other cards proportionally */
    opacity: 0.7; /* Dims the opacity of non-hovered cards */
}
`, "",{"version":3,"sources":["webpack://./src/styles/AdvantagesArea.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB,0CAA0C;AAC1C;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,gBAAgB,EAAE,iCAAiC;IACnD,mBAAmB;AACvB;;AAEA,uBAAuB;AACvB;IACI,UAAU,EAAE,mEAAmE;IAC/E,2BAA2B,EAAE,kDAAkD;IAC/E,gEAAgE,EAAE,uCAAuC;AAC7G;;AAEA,gDAAgD;AAChD;IACI,qBAAqB,EAAE,oCAAoC;IAC3D,WAAW,EAAE,yCAAyC;AAC1D;;AAEA,4CAA4C;AAC5C;IACI,qBAAqB,EAAE,uCAAuC;IAC9D,YAAY,EAAE,0CAA0C;AAC5D","sourcesContent":["/* advantagesarea.css */\n\n/* Container for cards with hover effect */\n.advantages-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n    margin-top: 3rem; /* Adds more padding at the top */\n    margin-bottom: 1rem;\n}\n\n/* Default card style */\n.advantage-card {\n    flex: none; /* Prevents cards from stretching or shrinking disproportionately */\n    width: calc(33.333% - 1rem); /* Ensures cards maintain the same initial width */\n    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth scaling and opacity changes */\n}\n\n/* Hover effect on the card being hovered over */\n.advantage-card:hover {\n    transform: scale(1.1); /* Enlarges the card being hovered */\n    z-index: 10; /* Brings the hovered card to the front */\n}\n\n/* Shrinks other cards when one is hovered */\n.advantages-container:hover .advantage-card:not(:hover) {\n    transform: scale(0.9); /* Shrinks other cards proportionally */\n    opacity: 0.7; /* Dims the opacity of non-hovered cards */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
