import React, { useState, useEffect } from 'react'
import { RxDotsHorizontal } from "react-icons/rx";
import { MdFilterList } from "react-icons/md";
function ChatUserList({ chatList, setSelectedConversationId, selectedConversationId, setChatUserName }) {

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredChatList, setFilteredChatList] = useState(chatList);
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    useEffect(() => {
        const filteredChats = chatList.filter((chat) => {
          const chatName = chat?.name?.toLowerCase();
          const chatMessage = chat?.message?.toLowerCase();
      
          return (
            chatName.includes(searchQuery) || chatMessage.includes(searchQuery)
          );
        });
        
        setFilteredChatList(filteredChats);
      }, [searchQuery, chatList]);
      
    return (
        <>
            <div className='tw-custom-chat-sidebar'>
                <div className='tw-h-[888px] tw-absolute  // or tw-relative depending on your layout context tw-top-[24px]'>
                    <div className='tw-mx-[10px] tw-mb-[20px] '>
                        <div className='tw-flex tw-justify-between tw-items-center'>
                            <div className='tw-font-extrabold tw-text-black tw-text-[20px] common-font'>Messages</div>
                            <div className="tw-border tw-border-gray-300 tw-border-[2px] tw-rounded-full tw-p-[5px]">
                                <RxDotsHorizontal className='tw-text-green-500 tw-text-[20px] tw-cursor-pointer' />
                            </div>
                        </div>
                    </div>
                    <div className='tw-h-[44px] tw-px-[10px]'>
                        <div className='tw-flex tw-gap-[10px] tw-items-center'>
                            <input
                                type="text"
                                placeholder="Search Messages Here..."
                                className='tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-full tw-px-2 tw-py-3 tw-text-[#202224]'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div className='tw-flex tw-justify-between'>
                                <MdFilterList className='tw-text-black hover:tw-text-green-500 tw-text-[20px] tw-cursor-pointer' />
                            </div>
                        </div>
                    </div>
                    <div className='tw-w-full tw-h-[780px] tw-mt-[25px] tw-overflow-y-hidden hover:tw-overflow-y-auto'>
                        <div className='tw-bg-[#F9F9F9] tw-rounded-[10px] tw-py-[10px] tw-rounded-[10px] tw-mx-[5px]'>
                            {filteredChatList?.map((chat, index) => (
                                <div
                                    key={chat?.id}
                                    onClick={() => {
                                        setSelectedConversationId(chat.id);
                                        setChatUserName(chat?.name);
                                    }}
                                    className={`tw-flex 
                                    ${chat.id === "no-chat" ? 'tw-bg-green-500 tw-text-white' : selectedConversationId === chat.id ? 'tw-bg-green-500 tw-text-white' : 'hover:tw-bg-gray-300'}
                                    tw-cursor-pointer tw-rounded-[10px] tw-py-4 tw-px-[5px] tw-mx-[5px] tw-relative`}
                                >
                                    <div className='tw-h-[60px] tw-flex'>
                                        <div className='tw-h-full tw-gap-[10px] tw-flex tw-items-center'>
                                            <div className=''>
                                                <div className='tw-w-[45px] tw-h-[45px] tw-rounded-full'>
                                                    {/* Chat Profile Image */}
                                                    <div class="tw-relative">
                                                        <img class="tw-rounded-full" src={chat?.profileImg} alt="" />
                                                        <span class={`tw-top-0 tw-left-0 tw-absolute tw-w-3 tw-h-3 ${chat?.isOnline ? 'tw-bg-green-400' : 'tw-bg-gray-400'} tw-border-2 tw-border-white tw-dark:border-gray-800 tw-rounded-full`}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tw-w-[100%]'>
                                                <div className='tw-flex tw-items-center tw-justify-between'>
                                                    <div className={`tw-capitalize common-font tw-font-extrabold tw-text-[15px]  ${index === 0 ? 'tw-text-[#484848]' : 'tw-text-[#444444]'}`}>
                                                        <span>{chat?.name?.length > 20 ? `${chat.name.slice(0, 20)}...` : chat?.name}</span>
                                                    </div>
                                                    <div className={`tw-top-[15px] tw-right-[5px] tw-absolute tw-font-bold tw-text-[12px]  ${index === 0 ? 'tw-text-gray-700' : 'tw-text-gray-600'} `}>
                                                        {chat?.date}
                                                    </div>
                                                </div>
                                                <div className='tw-w-[100%]'>
                                                    <div className={` ${index === 0 ? 'tw-text-[#484848]' : 'tw-text-[#484848]'} tw-text-[14px] common-font tw-font-normal tw-italic`}>{chat?.message?.length > 24 ? `${chat.message.slice(0, 24)}...` : chat?.message}</div>
                                                    {/* <div className={` ${index === 0 ? 'tw-text-white' : 'tw-text-[#484848]'} tw-text-[14px] common-font tw-font-normal tw-italic`}>{chat?.message?.length > 24 ? `${chat.message.slice(0, 24)}...` : chat?.message}</div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatUserList