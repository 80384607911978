import React, { useContext, useEffect, useState } from 'react'
import GeographicLoanActivity from './GeographicLoanActivity';
import LoanAmountDist from './LoanAmountDist';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useApiService } from '../../../services/apiService';
import toast from 'react-hot-toast';
import Total_amount_lent from "../../../assets/icons/total_amount_lent.png";
import Loan_approval_rate from "../../../assets/icons/loan_acceptance_rate.png";
import Avg_loan_value from "../../../assets/icons/avg_loan_value.png";
import Ltv_icon from "../../../assets/icons/ltv_icon.png";
import Total_revenue from "../../../assets/icons/total_revenue.png";
import Avg_revenue_app from "../../../assets/icons/avg_revenue_app.png";
import { formatPrice } from '../../../utils/common';
import BorrowerTable from '../../common/table/BorrowerTable';
import LenderTable from '../../common/table/LenderTable';
import StatCards from '../../layouts/homepage/StatCards';

function Admin() {
  const { user } = useContext(UserContext);
  const { getRequest } = useApiService();
  const [adminApplicationsSummary, setAdminApplicationsSummary] = useState([]);
  const [stats, setStats] = useState([]);

  const fetchAdminSummary = async () => {
    if (!user) return;

    try {
      const response = await getRequest('/admin-dashboard-summary');
      if (response?.success) {
        setAdminApplicationsSummary(response?.data);
      } else {
        setAdminApplicationsSummary([]);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message);
      } else {
        toast.error("An error occurred while fetching Admin Summary.");
      }
    }
  };
  useEffect(() => {
    const stats = [
      {
        title: 'Total Amount Lent',
        value: adminApplicationsSummary?.total_amount_lent ? formatPrice(adminApplicationsSummary?.total_amount_lent) : 0,
        change: adminApplicationsSummary?.total_amount_lent_growth_percentage ? adminApplicationsSummary?.total_amount_lent_growth_percentage : 0,
        icon: Total_amount_lent
      },
      {
        title: 'Loan Approval Rate',
        value: adminApplicationsSummary?.loan_approval_rate ? `${Number(adminApplicationsSummary?.loan_approval_rate).toFixed(2)}%` : 0,
        change: adminApplicationsSummary?.loan_approval_rates_growth_percentage ? adminApplicationsSummary?.loan_approval_rates_growth_percentage : 0,
        icon: Loan_approval_rate
      },
      {
        title: 'Avg. Loan Value',
        value: adminApplicationsSummary?.avg_loan_value ? formatPrice(adminApplicationsSummary?.avg_loan_value) : 0,
        change: adminApplicationsSummary?.avg_loan_value_growth_percentage ? adminApplicationsSummary?.avg_loan_value_growth_percentage : 0,
        icon: Avg_loan_value
      },
      {
        title: 'LTV',
        value: adminApplicationsSummary?.ltv ? `${adminApplicationsSummary?.ltv?.toFixed(2)}%` : 0,
        change: adminApplicationsSummary?.ltv_growth_percentage ? adminApplicationsSummary?.ltv_growth_percentage : 0,
        icon: Ltv_icon
      },
      {
        title: 'Total Revenue',
        value: adminApplicationsSummary?.total_revenue ? formatPrice(`${adminApplicationsSummary?.total_revenue?.toFixed(2)}`) : 0,
        change: adminApplicationsSummary?.total_revenue_growth_percentage ? adminApplicationsSummary?.total_revenue_growth_percentage : 0,
        icon: Total_revenue
      },
      {
        title: 'Avg. Revenue Per App',
        value: adminApplicationsSummary?.total_avg_revenue ? formatPrice(`${adminApplicationsSummary?.total_avg_revenue?.toFixed(2)}`) : 0,
        change: adminApplicationsSummary?.total_avg_revenue_growth_percentage ? adminApplicationsSummary?.total_avg_revenue_growth_percentage : 0,
        icon: Avg_revenue_app
      },
    ];
    setStats(stats);
  }, [adminApplicationsSummary]);

  useEffect(() => {
    fetchAdminSummary();
  }, [user]);


  return (
    <div className="tw-h-full tw-w-full">
      <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[87px]">
        <div/>
        <div className='tw-flex tw-space-x-4'>
          <Link to="/dashboard/view-subscription" tabIndex={-1}>
            <div tabIndex={0} className="tw-w-[237.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center">
              <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
                <i className="fas fa-user tw-text-[12px] tw-text-[#2A2F70]"></i>
              </span>
              <h2 className='tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]'>View All Subscription</h2>
            </div>
          </Link>

          <Link to="/dashboard/view-application" tabIndex={-1}>
            <div tabIndex={0} className="tw-w-[237.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center">
              <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
                <i className="fas fa-user tw-text-[12px] tw-text-[#2A2F70]"></i>
              </span>
              <h2 className='tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]'>View All Applications</h2>
            </div>
          </Link>

          <Link to="/dashboard/user-management" tabIndex={-1}>
            <div tabIndex={0} className="tw-w-[193px] tw-h-[49px] tw-bg-[#7DDE92] tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[12px] tw-px-[18px] tw-gap-[4px] tw-text-[18px] tw-leading-[24.55px] tw-flex tw-items-center tw-justify-center">
              <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[1px]">
                <i className="fas fa-user tw-text-[12px] tw-text-[#2A2F70]"></i>
              </span>
              View All Users

            </div>
          </Link>
        </div>
      </header>
      <StatCards statList={stats} />
      <div className='tw-block'>
        <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg tw-gap-4">
          {/* <StatsDropdown /> */}
          {/* {stats?.map((stat, index) => (
            <StatsCards
              key={index}
              title={stat?.title}
              value={stat?.value}
              change={stat?.change}
              icon={stat?.icon}
            />
          ))} */}
        </div>
      </div>


      <div className='hidden tw-grid 2xl:tw-grid-cols-[minmax(0,2fr)_minmax(0,1fr)] tw-gap-5 tw-mt-10'>
        <BorrowerTable />
        <GeographicLoanActivity />
        <LenderTable/>
        <LoanAmountDist />
      </div>
    </div>
  )
}

export default Admin