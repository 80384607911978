// src/components/Loader/Loader.js
import React from 'react';
import { useLoader } from '../../contexts/LoaderContext';
import './../../assets/css/Loader.css'; // Add some CSS for styling the loader

const Loader = () => {
  const { isLoading } = useLoader();

  if (!isLoading) return null; // Don't render the loader if it's not active

  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
