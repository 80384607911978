import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StripeSettings from './StripeSettings';
import GeneralSettings from './GeneralSettings';
import toast from 'react-hot-toast';
import { useLoader } from '../../../../contexts/LoaderContext';
import { UserContext } from '../../../../contexts/UserContext';
import SmtpSettings from './SmtpSettings';
import { useApiService } from '../../../../services/apiService';

function SideSettings() {
    const { user } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();
    const { postRequest } = useApiService();
    const [storedData, setStoredData] = useState([]); // Array to store id, name, and value
    const [selectedOption, setSelectedOption] = useState(1);
    const sideSettingsOptions = [
        { id: 1, settingsName: 'General Settings', component: GeneralSettings },
        { id: 2, settingsName: 'Stripe Settings', component: StripeSettings },
        { id: 3, settingsName: 'SMTP Settings', component: SmtpSettings },
    ];

    const handleFieldChange = (name, value) => {
        setStoredData((prevData) => {
            const existingField = prevData.find((item) => item.name === name);
            if (existingField) {
                // Update existing field
                return prevData.map((item) =>
                    item.name === name ? { ...item, value } : item
                );
            }
            // Add new field without ID
            return [...prevData, { name, value }];
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if storedData is empty
        if (storedData.length === 0) {
            toast.error('No settings to save. Please fill in the fields first.');
            return;
        }

        // Prepare the data to be sent in the request
        const submissionData = {
            settings: storedData.map(item => {
                const field = {
                    name: item?.name,
                    value: item?.value,
                };
                if (item.id) {
                    field.id = item.id; 
                }
                return field;
            }),
        };
        try {
            showLoader();
            const response = await postRequest(`/setting/save`, submissionData);
            if (response.success) {
                toast.success('Settings saved successfully!');

                // Fetch the updated settings to populate IDs
                const fetchedResponse = await postRequest(`/setting/list`);
                if (fetchedResponse?.data && fetchedResponse?.data?.settings) {
                    setStoredData(fetchedResponse?.data?.settings);
                }
            } else {
                toast.error('Failed to save settings. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred while saving settings. Please try again.');
        } finally {
            hideLoader();
        }
    };

    const handleOptionClick = (id) => setSelectedOption(id);

    const fetchAllSettingData = async () => {
        try {
            const response = await postRequest(`/setting/list`);
            if (response?.success) {
                const fetchedSettings = response.data.settings.map(item => ({
                    id: item.id,
                    name: item.name,
                    value: item.value
                }));
                setStoredData(fetchedSettings); 
            } else {
                setStoredData([]); 
            }
        } catch (err) {
            console.error(err);
            toast.error('Failed to fetch settings. Please try again later.');
        }
    };

    useEffect(() => {
        fetchAllSettingData();
    }, [user]);

    // Get the selected settings option to display its header
    const selectedSetting = sideSettingsOptions.find((option) => option.id === selectedOption);

    return (
        <div className="tw-w-full tw-h-full">
            <header className="tw-flex tw-justify-between tw-items-center tw-py-4">
                <main>
                    <div className="tw-flex tw-gap-4 tw-items-center">
                        <Link to={`/dashboard/admin`}>
                            <i className="fas fa-chevron-left tw-w-[18.41px] tw-h-[15.41px] tw-text-[#2A2F70]"></i>
                        </Link>
                        <h1 className="tw-text-[#242424] tw-font-bold tw-text-[40px] common-font">
                            Settings
                        </h1>
                    </div>
                    <p className="tw-text-[#666666] tw-text-sm tw-mt-1">
                        Manage your site settings
                    </p>
                </main>
                <button
                    onClick={handleSubmit}
                    className="tw-bg-blue-500 tw-text-white tw-font-semibold tw-rounded-lg tw-h-[40px] tw-px-6 hover:tw-bg-blue-600 common-font"
                >
                    Save Settings
                </button>
            </header>

            <div className="tw-flex tw-gap-6 tw-h-full tw-w-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px]">
                {/* Side Menu */}
                <div className="tw-w-1/4 tw-h-full ">
                    {sideSettingsOptions.map(option => (
                        <div
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`tw-p-3 tw-cursor-pointer ${selectedOption === option.id ? 'tw-bg-blue-100 tw-border-l-4 tw-border-blue-500' : 'tw-hover:bg-blue-50'}`}
                        >
                            <span className={`tw-text-[#242424] common-font ${selectedOption === option.id ? 'tw-font-semibold' : ''}`}>
                                {option?.settingsName}
                            </span>
                        </div>
                    ))}
                </div>

                {/* Settings Content */}
                <div className="tw-w-3/4 tw-h-full tw-p-5 tw-border tw-border-s-[#DDDDDD]">
                    {selectedSetting && (
                        <selectedSetting.component
                            handleFieldChange={handleFieldChange}
                            storedData={storedData} // Pass storedData for pre-filling
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SideSettings;
