import React, { createContext, useState, useContext } from 'react';
import FormBoxSubmitPopup from '../components/common/FormBoxSubmitPopup';

const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    variant: "success",
    message: ""
  });

  const showModal = (message, variant = "success") => {
    setModalState({ isOpen: true, message, variant });
  };

  const hideModal = () => {
    setModalState({
      isOpen: false,
      variant: "success",
      message: ""
    });
  };

  return (
    <ModalContext.Provider value={{ modalState, showModal, hideModal }}>
      {children}
      <FormBoxSubmitPopup 
        isOpen={modalState.isOpen} 
        variant={modalState.variant} 
        message={modalState.message} 
        onClose={hideModal} 
      />
    </ModalContext.Provider>
  );
};
