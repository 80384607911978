// src/routes/PrivateRoutes.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user } = useContext(UserContext);
  // If no user is logged in, redirect to the home/login page
   if (!user) {
    return <Navigate to="/" replace />;
  }
  if (allowedRoles && !allowedRoles.includes(user.user_type)) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />
};

export default PrivateRoute;