import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useLoader } from '../../../../contexts/LoaderContext';
import { IconButton, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApplicationTable from '../../../common/applicationTable/ApplicationTable';
import CloseIcon from "@mui/icons-material/Close";
import { formatTimeDifference } from '../../../../utils/common';
import AdminPaymentListHeader from '../../admin/AdminPaymentListHeader';
import { useApiService } from '../../../../services/apiService';

function AdminPaymentList() {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();

    const [fetchPaymentList, setFetchPaymentList] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState(null);
    const [filters, setFilters] = useState({
        user_id: '',
        sort_by: 'p.id',
        sort_order: 'DESC',
    });

    const fetchAdminPaymentList = async () => {
        try {
            showLoader();
            const response = await postRequest(`/admin-payment/list`, filters);
            if(response?.success) {
                const data = response?.data?.payments;
                setFetchPaymentList(data);
            } else {
                setFetchPaymentList([]);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            hideLoader();
        }
    };

    const handleOpen = (json) => {
        try {
            const parsedJson = JSON.parse(json);
            setSelectedJson(parsedJson);
        } catch (e) {
            console.error("Invalid JSON:", e);
            setSelectedJson(null);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedJson(null);
        setOpen(false);
    };


    const handleSortChange = (type) => {
        let newSortOrder = '';

        if (type === 'oldFirst') {
            newSortOrder = 'ASC';
        } else if (type === 'newFirst') {
            newSortOrder = 'DESC';
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder,
        }));
    };

    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "user_name", headerName: "User Name", width: 150 },
        { field: "subscription_plan_name", headerName: "Plan Name", width: 150 },
        { field: "product_name", headerName: "Product Name", width: 150 },
        { field: "session_id", headerName: "Session ID", width: 250 },
        { field: "payment_status", headerName: "Payment Status", width: 150 },
        { field: "invoice_id", headerName: "Invoice ID", width: 200 },
        { field: "customer_id", headerName: "Customer ID", width: 200 },
        { field: "currency", headerName: "Currency", width: 100 },
        { field: "amount_total", headerName: "Amount Total", width: 150 },
        { field: "amount_subtotal", headerName: "Amount Subtotal", width: 150 },
        {
            field: "payment_response",
            headerName: "Payment Response",
            width: 150,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleOpen(params.value)}>
                        <VisibilityIcon />
                    </IconButton>

                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                        <DialogTitle>
                            Payment Response
                            <IconButton
                                onClick={handleClose}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {selectedJson ? (
                                <pre
                                    style={{
                                        background: "#f4f4f4",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        overflowX: "auto",
                                    }}
                                >
                                    {JSON.stringify(selectedJson, null, 2)}
                                </pre>
                            ) : (
                                <Typography>No Data Available</Typography>
                            )}
                        </DialogContent>
                    </Dialog>
                </>
            ),
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 195,
            editable: false,
            renderCell: (params) => {
                const formattedTimes = [];
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
    ];
    const rows = fetchPaymentList?.length
        ? fetchPaymentList.map((app) => ({
            id: app?.id,
            user_name: app?.user_name ? app?.user_name : '-',
            subscription_plan_name: app?.subscription_plan_name ? app?.subscription_plan_name : '-',
            product_name: app?.product_name ? app?.product_name : '-',
            session_id: app?.session_id ? app?.session_id : '-',
            payment_status: app?.payment_status ? app?.payment_status : '-',
            invoice_id: app?.invoice_id ? app?.invoice_id : '-',
            customer_id: app?.customer_id ? app?.customer_id : '-',
            currency: app?.currency ? app?.currency : '-',
            amount_total: app?.amount_total ? app?.amount_total : '-',
            amount_subtotal: app?.amount_subtotal ? app?.amount_subtotal : '-',
            payment_response: app?.payment_response ? app?.payment_response : '-',
            created_at: app?.created_at ? app?.created_at : '-'
        }))
        : [];

    const AdminPaymentListHeaderProps = {
        title: 'Payment List',
        userStatus: fetchPaymentList,
        handleSortChange,
        setFilters,
        filters,
    };

    useEffect(() => {
        fetchAdminPaymentList();
    }, [user, filters]);

    return (
        <div className="tw-w-full tw-h-full tw-my-[12px]">
            <div>
                <div className="tw-h-[60px] tw-flex tw-items-center">
                    <Link to="/dashboard/admin">
                        <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                    </Link>
                    <h1 className="tw-ml-2 tw-text-[#000000] tw-font-bold tw-text-[40px] common-font">
                        Payment List
                    </h1>
                </div>
                <p className="tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70">
                    Here is the information about all your payment list.
                </p>
            </div>
            <div className="tw-w-full tw-h-[500px] tw-mt-[20px]">
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row) => row.id,
                        dashboardType: "admin-payment-list",
                        title: "Payment",
                        showFilters: true,
                        getRowClassName: (params) =>
                            params?.row?.id === null ? "selected-row" : "",
                    }}
                    rows={rows}
                    columns={columns}
                    customHeaderProps={AdminPaymentListHeaderProps}
                    CustomHeaderComponent={AdminPaymentListHeader}
                    onRowClick={() => {
                        console.log("payment list :");
                    }}
                    height="400px"
                />
            </div>
        </div>
    );
}

export default AdminPaymentList;
