import React, { useState, useContext } from 'react'
import { get, post, put, del } from 'aws-amplify/api';
import { AWSAmplifyConfigContext } from '../contexts/AWSAmplifyConfigContext';
import { useLoader } from '../contexts/LoaderContext';


export const useApiService = () => {
  const awsContextVal = useContext(AWSAmplifyConfigContext);
  const apiName = awsContextVal?.apiName;
  const { showLoader, hideLoader } = useLoader();
  const [error, setError] = useState(null);

  /**
   * Generic POST request
   * @param {string} apiName - The name of the API Gateway API.
   * @param {string} path - The path of the endpoint.
   * @param {object} body - The request body.
   * @param {object} options - (Optional) Additional options such as headers.
   * @returns {Promise<object>} - The response from the API.
   */
  const postRequest = async (path, bodyData, options = {}) => {
    try {
      const restOperation = post({
          apiName: apiName,
          path: path,
          options: {
              body: bodyData,
          }
      })
      
      const { body } = await restOperation.response;
      const response = await body.json();
      return {success: true, data : response};
    } catch (error) {
      console.error('Error during POST request:', error);
      return {success: false, error : error};
    }
  };

  /**
   * Generic PUT request
   * @param {string} apiName - The name of the API Gateway API.
   * @param {string} path - The path of the endpoint.
   * @param {object} body - The request body.
   * @param {object} options - (Optional) Additional options such as headers.
   * @returns {Promise<object>} - The response from the API.
   */
  const putRequest = async (path, bodyData, options = {}) => {
    showLoader();
    try {
      const restOperation = put({
        apiName: apiName,
        path: path,
        options: {
            body: bodyData,
        }
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      return {success: true, data : response};
    } catch (error) {
      console.error('Error during PUT request:', error);
      return {success: false, error : error};
    } finally {
      hideLoader();
    }
  };

  /**
   * Generic GET request
   * @param {string} apiName - The name of the API Gateway API.
   * @param {string} path - The path of the endpoint.
   * @param {object} options - (Optional) Additional options such as query parameters or headers.
   * @returns {Promise<object>} - The response from the API.
  */
  const getRequest = async (path, options = {}) => {
    showLoader();
    try {
      const restOperation = get({
        apiName: apiName,
        path: path,
        options: {
            ...options
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      return {success: true, data : response};
    } catch (error) {
      console.error('Error during GET request:', error);
      return {success: false, error : error};
    } finally {
      hideLoader();
    }
  };



  /**
   * Generic DELETE request
   * @param {string} apiName - The name of the API Gateway API.
   * @param {string} path - The path of the endpoint.
   * @param {object} options - (Optional) Additional options such as headers or request body.
   * @returns {Promise<object>} - The response from the API.
   */
  const deleteRequest = async (path, bodyData, options = {}) => {
    showLoader();
    try {
      // const restOperation = del(apiName, path, options);
      const restOperation = del({
        apiName: apiName,
        path: path,
        options: {
            body: bodyData,
            ...options
        }
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      return {success: true, data : response};
    } catch (error) {
      return {success: false, error : error};
    } finally {
      hideLoader();
    }
  };

  return { getRequest, postRequest, putRequest, deleteRequest, error };
}

