import React, { useContext, useState, useEffect } from 'react';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
    Button,
    List,
    ListItem
} from "@material-tailwind/react";

function LenderHeader({title, setHideArchived, handleSortChange, setFilter, setIsFilter, isFilter, hideArchived, oppositeApplications}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleCloseDropdown = () => setIsOpen(false);

    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            <>
                {title && title != '' ?
                    <>
                        <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'> {title}</Typography>
                    </>
                    :

                    <div className='tw-hidden md:tw-block tw-flex-grow-[4] tw-border-b-[1px] tw-border-[#DDDDDD] tw-items-center tw-pl-3'>
                        <i className="fas fa-search tw-text-[#4F4F4F] tw-mr-2 tw-opacity-70"></i>
                        <input
                            type="text"
                            name="search_text"
                            placeholder="Search application here..."
                            className="tw-text-[#4F4F4F] common-font tw-text-[14px] tw-font-normal tw-italic tw-bg-transparent tw-border-none focus:tw-outline-none"
                            onChange={(e) => {
                                const { value } = e.target;
                                setFilter((prevFilters) => ({
                                    ...prevFilters,           // Spread the previous filters to preserve them
                                    search_text: value,       // Update the search_text field
                                }));
                                setIsFilter(!isFilter);
                            }}
                        />
                    </div>
                }
            </>
            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[200px] tw-border tw-border-[#FA8072] tw-rounded-[5px] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                        <Button
                            type="button"
                            onClick={() => {setHideArchived(!hideArchived)}}
                            disabled={oppositeApplications.length === 0 ? true:false}
                            className="tw-font-oswald tw-text-[#4F4F4F] tw-flex tw-items-center tw-px-[12px]"
                            id="archived_applications"
                        >
                            {!hideArchived ? "Show Incoming Applications" : "Show Archived Applications"}
                        </Button>
                    </div>
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#000000] tw-rounded-[5px] tw-bg-[#FFFFFF] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                        <Popover
                                placement="bottom"
                                open={isOpen}
                                handler={toggleDropdown}>
                            <PopoverHandler>
                                <Button className='tw-text-[#4F4F4F] '>
                                    <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[3px]"></i>
                                    <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[10px]"></i>
                                    Sort
                                </Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[6.5%] tw-mt-[2.5px] tw-border tw-border-[#DDDDDD]">
                                <List className="p-0 tw-bg-white">
                                        <ListItem
                                            className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-border-b-[1px]" 
                                            onClick={() => {
                                                handleSortChange('oldFirst'); 
                                                handleCloseDropdown();
                                            }}
                                        >
                                            <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                            Old First
                                        </ListItem>
                                        <ListItem
                                            className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-items-center tw-border-b-[1px]"
                                            onClick={() => {
                                                handleSortChange('newFirst');
                                                handleCloseDropdown();
                                            }}
                                        >
                                            <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                            New First
                                        </ListItem>
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LenderHeader