import React from 'react';


import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from "@material-tailwind/react";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./App.css";

import { UserDataProvider } from './contexts/UserContext';
import { AWSAmplifyConfigProvider } from './contexts/AWSAmplifyConfigContext';
import { LoaderProvider } from './contexts/LoaderContext';
import Loader from './components/common/Loader';
import Routers from './routers';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import { PageLoaderProvider } from './contexts/PageLoaderContext';
import "./styles/PageLoader.css"


// let token = (await fetchAuthSession()).tokens?.idToken?.toString();

function App() {
  return (
    <ThemeProvider>
    <AWSAmplifyConfigProvider>
      <PageLoaderProvider>
      <UserDataProvider>
        <LoaderProvider>
          <Toaster position='top-right' toastOptions={{ duration: 2000 }}/>
          <Router>
            <Loader />
            <Routers />
          </Router>
          </LoaderProvider>
      </UserDataProvider>
      </PageLoaderProvider>
    </AWSAmplifyConfigProvider>
    </ThemeProvider>
  );
}

export default App;
