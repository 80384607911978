import React from 'react'
import { DataGrid } from '@mui/x-data-grid'


function LenderTable() {
    const columns = [
        {
            field: 'nameOfLenders',
            headerName: 'Name of Lenders',
            width: 245,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 245,
            editable: true,
        },
        {
            field: 'lastLoanGranted',
            headerName: 'Last Loan Granted',
            width: 245,
            editable: true,
        },
        {
            field: 'totalAmountGranted',
            headerName: 'Total Amount Granted ($)',
            width: 245,
            editable: true,
        },
        {
            field: 'noOfLoansGranted',
            headerName: 'No. Of Loans Granted ($)',
            width: 245,
            editable: true,
        },
    ];

    const rows = [
        { id: 1, nameOfLenders: 'Maria Awasti', email: 'abc@gmail.com', lastLoanGranted: "October, 8, 2023", totalAmountGranted: '$2,40,000', noOfLoansGranted: 80 },
        { id: 2, nameOfLenders: 'Serena Van', email: 'Vac@gmail.com', lastLoanGranted: "October, 8, 2023", totalAmountGranted: '$4,80,000', noOfLoansGranted: 80 },
        { id: 3, nameOfLenders: 'Maria Awasti', email: 'Investment', lastLoanGranted: "October, 8, 2023", totalAmountGranted: '$4,00,000', noOfLoansGranted: 80 },
        { id: 4, nameOfLenders: 'Maria Awasti', email: 'abc@gmail.com', lastLoanGranted: "October, 8, 2023", totalAmountGranted: '$1,20,000', noOfLoansGranted: 80 },
        { id: 5, nameOfLenders: 'Maria Awasti', email: 'abc@gmail.com', lastLoanGranted: "October, 8, 2023", totalAmountGranted: '$6,40,000', noOfLoansGranted: 80 },
    ];
  return (
    <div className='tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px]'>
            <h2 className=' tw-h-[30px] tw-px-[20px] tw-py-[20px] tw-text-[#202224] tw-font-semibold common-font tw-text-[22px]'>
                Top Borrowers
            </h2>
            <div className='tw-h-[284px] tw-mt-[37px]'>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    hideFooterPagination
                    hideFooter
                    sx={{
                        border: '2px solid #DDDDDD', // Apply border to the entire table
                        // borderRadius: '10px', // Removed to make the table corners square
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid #DDDDDD',
                            borderRight: '1px solid #DDDDDD', // Add vertical border
                        },
                        '& .MuiDataGrid-columnHeaders *': {
                            backgroundColor: "#DDE1EE",
                        },
                    }}
                />
            </div>
        </div>
  )
}

export default LenderTable