import React, { useEffect } from "react";
import LogoImage from "../../../src/assets/icons/Logo.png";
import { useNavigate, Link } from "react-router-dom";
import Vector1 from "../../../src/assets/icons/big_lines.png";
import Vector2 from "../../../src/assets/icons/small_lines.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CmsHeader from "../layouts/base/CmsHeader";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const policyinfo = [
    {
      title: "Introduction",
      pg: `Re-Loan Corp ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website ("Website") and services ("Services").
      
          By accessing our Website or using our Services, you consent to the data practices described in this Privacy Policy.`,
    },
    {
      title: "Information We Collect",
      pg: (
        <div>
          We may collect the following types of information:
          <ul className="tw-list-disc tw-ml-6 lg:tw-p-7 tw-mt-6">
            <li>
              <span className="tw-font-bold tw-text-gray-900">
                Personal Information:
              </span>{" "}
              Includes your name, email address, phone number, and other
              information you provide when creating an account or submitting a
              loan application.
            </li>
            <li>
              <span className="tw-font-bold tw-text-gray-900">Usage Data:</span>{" "}
              Information about how you interact with our Website, such as your
              IP address, browser type, pages visited, and time spent on the
              site.
            </li>
            <li>
              <span className="tw-font-bold tw-text-gray-900">
                Cookies and Tracking Data:
              </span>{" "}
              We use cookies and similar technologies to track your activity on
              our Website and improve user experience.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "How We Use Your Information",
      pg: (
        <div>
          We use the information we collect for the following purposes:
          <ul className="tw-list-disc tw-ml-6 lg:tw-p-7">
            <li>To provide and maintain our Services.</li>
            <li>
              To process loan applications and facilitate transactions between
              borrowers and lenders.
            </li>
            <li>
              To improve our Website and Services through analytics and user
              feedback.
            </li>
            <li>
              To communicate with you about your account, transactions, and
              updates.
            </li>
            <li>
              To comply with legal obligations and enforce our Terms and
              Conditions.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Sharing Your Information",
      pg: (
        <div>
          We do not sell or rent your personal information to third parties.
          However, we may share your information in the following cases:
          <ul className="tw-list-disc tw-ml-6 lg:tw-p-7">
            <li>
              With lenders to process loan applications or facilitate
              transactions as requested by you.
            </li>
            <li>
              With service providers who assist us in operating our Website and
              providing our Services.
            </li>
            <li>
              To comply with legal requirements or respond to lawful requests
              from authorities.
            </li>
            <li>
              To protect the rights, property, or safety of Re-Loan Corp, its
              users, or the public.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Your Rights",
      pg: (
        <div>
          You have the right to:
          <ul className="tw-list-disc tw-ml-6 lg:tw-p-7">
            <li>
              Access, update, or delete the personal information we hold about
              you.
            </li>
            <li>Opt out of receiving marketing communications from us.</li>
            <li>Request a copy of the data we have collected about you.</li>
            <li>
              Withdraw consent for certain data practices, where applicable.
            </li>
            <li>
              To exercise these rights, please contact us at the information
              provided below.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Security of Your Information",
      pg: `We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.`,
    },
    {
      title: "Changes to This Privacy Policy",
      pg: `We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with a revised effective date.`,
    },
    {
      title: "Contact Us",
      pg: (
        <div>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please{" "}
          <a
            target="__blank"
            href="/contact"
            className="tw-text-light-blue-500 tw-font-semibold tw-underline"
          >
            contact us
          </a>
          .
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="xl:tw-hidden tw-block">
        <CmsHeader />
      </div>
      <div className="tw-flex tw-justify-center">
        {/* Sidebar - Fixed position */}
        <div className="tw-w-1/6 tw-bg-[#3748E8] tw-h-full tw-flex-col tw-justify-center tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-10 tw-hidden xl:tw-flex">
          <div className="tw-absolute tw-top-4 tw-left-4">
            <img
              src={LogoImage}
              alt="Re-loan logo"
              className="tw-w-40 tw-cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>

          {/* Vector Decorations */}
          <img
            src={Vector2}
            alt="vector2"
            className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-50 tw-h-1/3"
          />
          <img
            src={Vector1}
            alt="vector1"
            className="tw-absolute tw-top-20 tw-left-0 tw-opacity-50 tw-h-2/3"
          />
        </div>

        {/* Main Content */}
        <div className="tw-w-5/6 tw-bg-white tw-h-full tw-justify-center tw-text-2xl tw-overflow-y-auto tw-scrollbar xl:tw-ml-[22%] tw-pt-12">
          <div className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-reloan tw-relative tw-flex">
            <a href="/">
              <ChevronLeftIcon fontSize={"12px"} className="tw-text-gray-800" />
            </a>
            Our Privacy Policy
          </div>
          <div className="tw-text-gray-600 tw-flex tw-flex-col tw-gap-6 lg:tw-p-6">
            {policyinfo.map((p, index) => (
              <div key={index} className="tw-flex tw-flex-col tw-gap-2">
                <h2 className="tw-text-2xl tw-text-black tw-font-semibold">
                  {p.title}
                </h2>
                <p className="tw-p-2">{p.pg}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
