import React from 'react'
import { Chart } from 'react-charts';


function GeographicLoanActivity() {
    const barChartData = React.useMemo(
        () => [
            {
                label: 'Loans by Type',
                data: [
                    ['New York', 4000],
                    ['California', 3000],
                    ['Texas', 2000],
                    ['Florida', 1500],
                    ['Ohio', 2500],
                ],
            },
        ],
        []
    );

    const barChartAxes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom', label: 'Loan Type' }, // X-axis with categories
            { type: 'linear', position: 'left', label: 'Amount (k)', hardMin: 0, hardMax: 4000 }, // Y-axis with values
        ],
        []
    );
    return (
        <div className='tw-w-[32%] tw-h-[760px] tw-ms-[15px] tw-my-[40px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-pt-[20px] tw-pl-[20px]'>
            <div className='tw-w-full tw-h-[30px] '>
                <h2 className='tw-text-[#202224] tw-text-[17px] tw-font-semibold tw-leading-[30.01px] common-font'>Geographic Loan Activity ( Loan Volume by State)</h2>
            </div>

            <div className='tw-w-full tw-h-[568.86px] tw-mt-[20%]'>
                <Chart
                    data={barChartData}
                    axes={barChartAxes}
                    series={{ type: 'bar' }} // Specify bar chart
                    getSeriesStyle={() => ({
                        color: '#555FE1', // Set your bar color here
                        width: 30, // You can adjust this value for bar width
                    })}
                    getDatumStyle={() => ({
                        color: '#555FE1', // Set color for individual bars (optional)
                    })}
                    options={{
                        backgroundColor: '#f0f4f8',
                        grid: {
                            show: false, // Hide grid lines
                        },
                        series: {
                            showPoints: false, // No points for bar chart
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default GeographicLoanActivity