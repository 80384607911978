import React, { createContext, useContext, useState, useEffect } from "react";
import logoImage from "../assets/icons/Logo_blue.png";
import "../styles/PageLoader.css";

// Create a context for the page loader
const PageLoaderContext = createContext();

// Create a provider for the page loader
export const PageLoaderProvider = ({ children }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  const showPageLoader = () => setIsPageLoading(true);
  const hidePageLoader = () => setIsPageLoading(false);

  useEffect(() => {
    // Add or remove the "page-loading" class on the <body> element
    if (isPageLoading) {
      document.body.classList.add("page-loading");
    } else {
      document.body.classList.remove("page-loading");
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove("page-loading");
    };
  }, [isPageLoading]);

  return (
    <PageLoaderContext.Provider value={{ isPageLoading, showPageLoader, hidePageLoader }}>
      {isPageLoading && <PageLoader />}
      {children}
    </PageLoaderContext.Provider>
  );
};

// Custom hook to use the page loader context
export const usePageLoader = () => {
  const context = useContext(PageLoaderContext);
  if (!context) {
    throw new Error("usePageLoader must be used within a PageLoaderProvider");
  }
  return context;
};

// Page Loader component
export const PageLoader = () => {
  return (
    <div className="page-loader-overlay">
      <div className="page-loader-content">
        <img
          src={logoImage} // Replace with the path to your logo
          alt="Logo"
          className="page-loader-logo"
        />
        <div className="page-loader-dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
