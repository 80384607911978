import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom'
import { UserContext } from '../../../../contexts/UserContext';
import ApplicationTable from '../../../common/applicationTable/ApplicationTable';
import ApplicationOfferLogsHeader from './ApplicationOfferLogsHeader';
import { useApiService } from '../../../../services/apiService';
import PageLoader, { usePageLoader } from '../../../../contexts/PageLoaderContext';

function ApplicationOffersLogs() {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const [fetchOfferLogsData, setFetchOfferLogsData] = useState([]);
    const [isPageReady, setIsPageReady] = useState(false);
    const { showPageLoader, hidePageLoader } = usePageLoader();

    const [filters, setFilters] = useState({
        sort_by: 'aoh.id',
        sort_order: 'DESC',
        start_date: "",
        end_date: "",
    });

    useEffect(() => {
        const initializePage = async () => {
            showPageLoader(); // Show the loader
            try {
                await fetchOfferLogs();
            } catch (error) {
                toast.error("Failed to initialize the page.");
            } finally {
                setIsPageReady(true); // Set page ready
            }
        };

        initializePage();
    }, []);

    useEffect(() => {
        if (isPageReady) {
            setTimeout(() => {
                hidePageLoader();
            }, 500); // Add a small delay before hiding the page loader
        }
    }, [isPageReady]);

    const fetchOfferLogs = async () => {
        try {
            const response = await postRequest(`/application-offer-history/list`, {
                user_id: user?.id,
                sort_by: filters?.sort_by,
                sort_order: filters?.sort_order,
                start_date: filters?.start_date,
                end_date: filters?.end_date,
            });
            if (response?.success) {
                setFetchOfferLogsData(response?.data?.applicationOfferLogs);
            } else {
                setFetchOfferLogsData([]);
                toast.error('something went wrong.');
            }
        } catch (err) {
            toast.error(err);
        }
    };
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'property_address', headerName: 'Property Address', width: 200 },
        { field: 'property_value', headerName: 'Property Value', width: 150 },
        { field: 'loan_duration', headerName: 'Loan Duration', width: 150 },
        { field: 'loan_amount', headerName: 'Loan Amount', width: 150 },
        { field: 'interest_type', headerName: 'Interest Type', width: 150 },
        { field: 'interest_on_loan', headerName: 'Interest On Loan', width: 150 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'offer_used', headerName: 'Offers Used', width: 150 },
        { field: 'created_at', headerName: 'Created At', width: 200 },
    ];

    const offerCountMap = fetchOfferLogsData.reduce((acc, app) => {
        if (acc[app.application_offer_id]) {
            acc[app.application_offer_id] += 1;
        } else {
            acc[app.application_offer_id] = 1;
        }
        return acc;
    }, {});
    
    const uniqueOfferLogs = [];
    const seenApplications = new Set();
    
    fetchOfferLogsData.forEach((app) => {
        if (!seenApplications.has(app.application_offer_id)) {
            seenApplications.add(app.application_offer_id);
            uniqueOfferLogs.push({ ...app, total_offers: offerCountMap[app.application_offer_id] });
        }
    });

    const rows = uniqueOfferLogs.map((app) => ({
        id: app?.id,
        application_offer_id: app?.application_offer_id ?? '-',
        property_address: app?.property_address ?? '-',
        property_value: app?.property_value ?? '-',
        loan_duration: app?.loan_duration ?? '-',
        loan_amount: app?.loan_amount ?? '-',
        interest_type: app?.interest_type ?? '-',
        interest_on_loan: app?.interest_on_loan ?? '-',
        type: app?.type ?? '-',
        offer_used: app?.total_offers ?? '-',
        created_at: app?.created_at ?? '-',
    }));


    const handleSortChange = (type) => {
        let newSortOrder = '';

        if (type === 'oldFirst') {
            newSortOrder = 'ASC';
        } else if (type === 'newFirst') {
            newSortOrder = 'DESC';
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder,
        }));
    };

    const ApplicationOfferHeaderProps = {
        title: 'Application Offer Logs',
        userStatus: fetchOfferLogsData,
        handleSortChange,
        setFilters,
        filters,
    };

    useEffect(() => {
        fetchOfferLogs();
    }, [user, filters]);

    return (
        <div className="tw-w-full tw-h-full tw-my-[12px]">
            {!isPageReady && <PageLoader />}
            <div>
                <div className="tw-h-[60px] tw-flex tw-items-center">
                    <Link to="/dashboard/lender">
                        <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                    </Link>
                    <h1 className="tw-ml-2 tw-text-[#000000] tw-font-bold tw-text-[40px] common-font">
                        Application Offer Logs
                    </h1>
                </div>
                <p className="tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70">
                    Here is the information about all your application offer logs.
                </p>
            </div>
            <div className="tw-w-full tw-h-[500px] tw-mt-[20px]">
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row) => row.id,
                        dashboardType: "offer-logs",
                        showFilters: true,
                        getRowClassName: (params) =>
                            params?.row?.id === null ? "selected-row" : "",
                    }}
                    rows={rows}
                    columns={columns}
                    customHeaderProps={ApplicationOfferHeaderProps}
                    CustomHeaderComponent={ApplicationOfferLogsHeader}
                    onRowClick={() => {
                        console.log("Application Offer Logs :");
                    }}
                    height="400px"
                />
            </div>
        </div>
    )
}

export default ApplicationOffersLogs
