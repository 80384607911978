import React, { useContext, useState, useEffect, useRef } from 'react';
import { signOut } from '@aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import LogoImage from "../../../assets/icons/Logo.png";
import UserProfile from "../../../assets/icons/usericon.svg";
import { authLocal, saveUserTypeToLocalStorage } from '../../../utils/common';
import Badge from '@mui/material/Badge';
import ActiveSubscription from "../../common/applicationTable/ActiveSubscription";
import { useApiService } from '../../../services/apiService';
import { Menu, MenuItem } from '@mui/material';
import PageLoader, { usePageLoader } from '../../../contexts/PageLoaderContext';
import { hide } from '@popperjs/core';

const Header = () => {
  const { user } = useContext(UserContext);
  const { postRequest } = useApiService();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { resetUser, userType } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { showPageLoader, hidePageLoader } = usePageLoader();
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    const initializePage = async () => {
      try {
        await fetchNotification();
      } catch (error) {
        toast.error("Failed to initialize the page.");
      } finally {
        setIsPageReady(true); // Set page ready
      }
    };

    initializePage();
  }, []);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToHome = () => {
    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotificationBox = async () => {
    setIsNotificationOpen(!isNotificationOpen);
    await markNotificationsAsRead();
  };

  const markNotificationsAsRead = async () => {
    try {
      const response = await postRequest(`/notification/mark-read`, {
        user_id: user?.id,
      });
      if (response?.success) {
        setIsRead(false);
      } else {
        toast.error('Failed to mark notifications as read');
      }
    } catch (error) {
      toast.error('An error occurred while marking notifications as read');
    }
  };

  const handleLogOut = async () => {
    try {
      await signOut();
      toast.success("Signed Out!");
      window.localStorage.removeItem("re_loan_info");
      window.localStorage.removeItem("user");
      authLocal.remove();
      window.localStorage.removeItem("pendingApplicationData");
      resetUser();
      navigate("/");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const fetchNotification = async () => {
    if (!user || !(userType === "borrower" || userType === "lender")) return;

    try {
      const response = await postRequest(`/notification/list`, {
        user_id: user?.id,
        sort_by: "n.id",
        sort_order: "DESC",
        start: '0',
        limit: '10'
      });
      const hasUnreadNotifications = response?.data?.notificationList?.some(notification => notification?.is_read === 0);
      if (hasUnreadNotifications) {
        setIsRead(true);
      }
      setNotificationCount(response?.data?.notificationCount);
      setNotificationDetails(response?.data?.notificationList);
    } catch (err) {
      toast.error("An error occurred while fetching notifications.");
    }
  };

  useEffect(() => {
    fetchNotification();
    const intervalId = setInterval(fetchNotification, 100000);
    return () => clearInterval(intervalId);
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function capitalizeFirstLetter(string) {
    if (!string) return ''; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <> 
    {isPageReady ? (
    <header className="tw-bg-[#555FE1] tw-w-full tw-h-[87px] tw-flex tw-items-center tw-px-[20px] tw-py-[8px] tw-relative">
      
      {/* Left Side: Company Logo */}
      <div
        className="tw-flex tw-items-center tw-w-[137px] tw-h-[21px]"
        onClick={goToHome}
      >
        <img src={LogoImage} alt="Re-loan logo" />
      </div>

      {/* Center Section: Dashboard Title */}
      <div className="tw-flex-1 tw-flex tw-justify-center">
        {(userType === "admin" || userType === "borrower" || userType === "lender") && (
          <h1 className="tw-text-white tw-font-semibold common-font tw-hidden md:tw-block md:tw-text-2xl lg:tw-text-[30px] xl:tw-text-[35px] tw-leading-[60px] tw-text-center tw-cursor-pointer" onClick={() => { navigate(`/dashboard/${user?.user_type}`) }}>
            {(userType).toUpperCase()} DASHBOARD
          </h1>
        )}
      </div>

      {/* Right Side: Profile Image, Username, and Dropdown */}
      <div
        className="tw-relative tw-flex tw-items-center tw-gap-4 tw-justify-end tw-w-[137px]"
        ref={dropdownRef}
      >
        {/* Right side: Profile Image, Username, and Dropdown */}
        <div className="tw-relative tw-flex tw-items-center tw-gap-4" ref={dropdownRef}>
          {userType === "lender" && (
            <div className="tw-mx-2">
              <ActiveSubscription
                plan={user?.plan}
                offer={user?.available_offer}
              />
            </div>
          )}
          {(userType === "lender" || userType === "borrower") && (
            <>
              <div className="tw-w-[24px] tw-h-[24px] tw-relative tw-flex tw-items-center" onClick={toggleNotificationBox} ref={notificationRef}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Badge
                    badgeContent={isRead ? notificationCount : 0}
                    color="error"
                    sx={{
                      "& .MuiBadge-badge": {
                        top: -5,
                        right: -5,
                      },
                    }}
                  >
                    <i className="fas fa-bell tw-text-white tw-cursor-pointer hover:-tw-translate-y-1 tw-transition-all tw-duration-300"></i>
                  </Badge>
                </div>
                {/* Notification Box */}
                {isNotificationOpen && (
                  <div
                    className="tw-absolute tw-bg-white tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-opacity-100 tw-shadow-lg tw-p-[10px_20px] tw-top-[40px] tw-left-[-370px] tw-z-50"
                    style={{ width: "394px" }}
                  >
                    <div className="tw-w-full tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD] tw-mb-2">
                      <h2 className="tw-text-[#444444] tw-text-semibold tw-text-[20px] common-font">
                        New Notifications
                      </h2>

                      <Link
                        to={`notifications`}
                        className="tw-text-[#2A2F70] tw-text-semibold tw-text-[16px] common-font tw-cursor-pointer"
                      >
                        View All
                      </Link>

                    </div>

                    {/* Scrollable content container */}
                    <div
                      className="tw-overflow-y-auto tw-w-full"
                      style={{ maxHeight: "200px" }}
                    >
                      {notificationDetails?.length > 0 ? (
                        notificationDetails.map((item, index) => (
                          <div
                            key={item?.id}
                            className="tw-w-full tw-h-[59px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD] tw-py-[10px]"
                          >
                            <img
                              src={
                                item?.profile_image_url
                                  ? item?.profile_image_url
                                  : UserProfile
                              }
                              alt={`Profile-${item?.created_user_name}`}
                              title={`${item?.created_user_name}`}
                              className="tw-w-[32px] tw-h-[32px] tw-rounded-full tw-border-[1px] tw-border-[#DDDDDD] tw-bg-[#DDDDDD]"
                            />
                            <div className="tw-flex tw-flex-col tw-justify-start tw-ml-4 tw-flex-grow">
                              <h2
                                className={`tw-text-[#444444] tw-font-semibold tw-text-[16px] common-font ${item?.is_read === 0
                                  ? "tw-bg-[#f0f8ff] tw-text-black tw-hover:tw-bg-[#e0f0ff] tw-shadow-lg"
                                  : ""
                                  } ${item?.notification?.length > 10
                                    ? "tw-whitespace-normal"
                                    : "tw-whitespace-nowrap"
                                  } tw-overflow-hidden tw-text-ellipsis tw-flex-grow`}
                              >
                                {item?.notification}
                              </h2>
                            </div>
                            <p
                              className={`tw-text-[#484848] tw-opacity-70 tw-text-normal tw-text-[14px] common-font ${item?.is_read === 0
                                ? "tw-bg-[#f0f8ff] tw-text-black tw-hover:tw-bg-[#e0f0ff] tw-shadow-lg"
                                : ""
                                }`}
                            >
                              {new Date() === new Date(item?.created_at)
                                ? new Date(item?.created_at).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                                : new Date(item?.created_at).toLocaleTimeString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }) || "N/A"}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div className="tw-w-full tw-h-[59px] tw-flex tw-items-center tw-justify-center">
                          <p className="tw-text-[#888888] tw-text-[16px] common-font">
                            No new notifications
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {/* Profile Image */}
          <img
            src={user?.profile_image_url || UserProfile}
            alt="Profile"
            className="tw-w-[36px] tw-h-[36px] tw-rounded-full tw-object-cover"
          />
          <div
            className="tw-flex tw-items-center tw-cursor-pointer tw-nowrap tw-pr-5"
            onClick={handleClick}
          >
            <span className="tw-text-white common-font tw-font-normal tw-text-[17px] tw-truncate">
              {user?.first_name + " " + user?.last_name}
            </span>
            <i className="tw-ml-2 tw-text-white fas fa-chevron-down"></i>
          </div>

          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            className="[&>*]:tw-p-2 tw-rounded-2xl tw-mt-4 tw-font-inter"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}

          >
            <MenuItem
              component={Link}
              to={`/dashboard/${userType}`}
              className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black"
            >
              <i className="fas fa-dashboard tw-mr-2"></i>
              {capitalizeFirstLetter(userType)} Dashboard
            </MenuItem>
            <MenuItem
              component={Link}
              to="settings"
              className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black"
            >
              <i className="fas fa-cog tw-mr-2"></i>
              Account Settings
            </MenuItem>
            {userType === "lender" && (
              <>
                <MenuItem component={Link} to="payment-list" className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black">
                  <i className="tw-mr-2 tw-text-black fas fa-list"></i>
                  Payment List
                </MenuItem>

                <MenuItem component={Link} to="change-subscription" className="tw-flex tw-items-center tw-gap-2 hover:tw-bg-gray-100 tw-text-black">
                  <i className="tw-mr-2 tw-text-black fas fa-user-edit"></i>
                  Update Membership
                </MenuItem>

                <MenuItem component={Link} to="membership-history" className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black">
                  <i className="tw-mr-2 tw-text-black fas fa-history"></i>
                  Membership History
                </MenuItem>

                <MenuItem component={Link} to="offer-logs" className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black">
                  <i className="tw-mr-2 tw-text-black fas fa-history"></i>
                  Application Offer Logs
                </MenuItem>

              </>
            )}
            {userType === "admin" && (
              <>
                <MenuItem
                  component={Link}
                  to="cms-pages"
                  className="tw-flex tw-items-center tw-gap-3 hover:tw-bg-gray-100 tw-text-black"
                >
                  <i className="fas fa-file-alt tw-mr-2"></i>
                  CMS Pages
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="promo-code"
                  className="tw-flex tw-items-center tw-gap-3 tw-w-full tw-text-left hover:tw-bg-gray-100 tw-text-black"
                >
                  <i className="fas fa-tag tw-text-black tw-mr-2"></i>
                  Promo Code
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="admin-payment-list"
                  className="tw-flex tw-items-center tw-gap-3 tw-w-full tw-text-left hover:tw-bg-gray-100 tw-text-black"
                >
                  <i className="fas fa-list-alt tw-text-black tw-mr-2"></i>
                  Payment List
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="side-setting"
                  className="tw-flex tw-items-center tw-gap-3 tw-w-full tw-text-left hover:tw-bg-gray-100 tw-text-black"
                >
                  <i className="fas fa-sliders-h tw-text-black tw-mr-2"></i>
                  Settings
                </MenuItem>
              </>
            )}
            <MenuItem
              className="logout_btn tw-flex tw-items-center tw-gap-3 tw-w-full tw-text-left tw-text-black hover:tw-bg-gray-100"
              onClick={handleLogOut}
            >
              <i className=" tw-text-red-300 fas fa-sign-out-alt tw-mr-2"></i>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header >
    ) : (
      <div style={{ backgroundColor: "#FFFFFF", width: "100vw", height: "100vh" }}>
        {/* This renders a plain white screen */}
      </div>
    )}
    </>
  );
};

export default Header;
