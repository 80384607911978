import React from 'react'
import LoanVolPropFrame from "../../../assets/icons/admin-frame-1.png";

function LoanVolByPropType() {
  return (
    <div className='tw-w-full tw-h-[596px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px]'>
        <img className='tw-w-full tw-h-full' src={LoanVolPropFrame}  alt="Re-loan logo" />
        
        {/* <h1 className='tw-text-[#202224] tw-text-[20px] tw-mt-[20px] tw-ml-[20px] tw-font-semibold common-font '>Loan Volume By Property Type</h1> */}
    </div>
  )
}

export default LoanVolByPropType