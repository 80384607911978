import { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useApiService } from '../services/apiService';

export const useFetchUserSubscription = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user, updateMembership } = useContext(UserContext);
    const { postRequest } = useApiService();


    const fetchUserSubscription = async () => {
        setLoading(true);
        try {
            const response = await postRequest(`/user/get-membership`,{ user_id: user.id });
            if (response?.success) {
                updateMembership(response?.data?.user);
            } else {
                console.error('Failed to fetch user details:', response.data);
                setError('Failed to fetch user details');
            }
        } catch (err) {
            console.error('Error fetching user details:', err);
            setError('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return { fetchUserSubscription, loading, error };
};
