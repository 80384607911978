import React from 'react';

function FormBoxSubmitPopup({ isOpen, onClose, variant, message }) {
  if (!isOpen) return null;

  // Determine the icon and border color based on the variant
  const isSuccess = variant === 'success';
  const iconClass = isSuccess ? 'fa-check' : 'fa-times';
  const iconColor = isSuccess ? '#7DDE92' : '#FF5A5A'; // Green for success, Red for fail
  const borderColor = isSuccess ? '#7DDE92' : '#FF5A5A';

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-40">
      <div className="tw-bg-white tw-w-[390.26px] tw-h-[290px] tw-p-6 tw-rounded-lg tw-shadow-lg tw-max-w-md">
        <div className="tw-flex tw-justify-end tw-items-center tw-text-black">
          <i className="fas fa-times tw-cursor-pointer tw-text-lg" onClick={onClose} aria-hidden="true"></i>
        </div>
        <div className="tw-flex tw-justify-center tw-items-center">
          <div className={`tw-mt-[30px] tw-w-[60px] tw-h-[60px] tw-border-[5px] tw-rounded-[15px] tw-flex tw-justify-center tw-items-center`} style={{ borderColor }}>
            <i className={`tw-w-[21.25px] tw-h-[29.15px] fas ${iconClass} tw-text-[30px]`} style={{ color: iconColor }} aria-hidden="true"></i>
          </div>
        </div>
        <div className="tw-flex tw-justify-center tw-items-center tw-mt-[20px]">
          <div className="tw-w-[230.26px] tw-h-[54px] tw-flex tw-justify-center tw-items-center">
            <h1 className="tw-text-[#444444] tw-text-[20px] tw-font-semibold common-font tw-text-center tw-overflow-hidden tw-text-ellipsis tw-whitespace-normal tw-display-webkit-box tw-webkit-line-clamp-2 tw-webkit-box-orient-vertical">
              {message}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormBoxSubmitPopup;
