const GenericButton = ({ icon, title, openModalCallback }) => {
    return (
        <button
            onClick={openModalCallback}
            className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl tw-justify-center'
        >
            <img src={icon} className='tw-w-6 tw-h-6' alt='borrower' />
            <p className='tw-text-md tw-text-black tw-font-oswald'>{title}</p>
        </button>
    )
}

export default GenericButton;