import React, { useContext } from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { FaVideo } from "react-icons/fa";
import { LiaBusinessTimeSolid } from "react-icons/lia";

function ChatHeader({ chatUserName }) {
    const { currentOfferPropertyDetails } = useContext(UserContext);

    return (
        <div className='tw-flex tw-justify-between tw-items-center tw-px-[20px] tw-pt-[20px]'>
            <div className='common-font'>
                <div className=''>
                    <div className='tw-font-extrabold tw-text-[25px] tw-text-black tw-capitalize'>
                        <span>
                            {currentOfferPropertyDetails && chatUserName && currentOfferPropertyDetails?.streetAddress
                                ? `${`${chatUserName}, ${currentOfferPropertyDetails?.streetAddress}`.length > 40
                                    ? `${`${chatUserName}, ${currentOfferPropertyDetails?.streetAddress}`.slice(0, 24)}...`
                                    : `${chatUserName}, ${currentOfferPropertyDetails?.streetAddress}`
                                }`
                                : 'No conversation available'
                            }
                        </span>
                    </div>
                    <div className='tw-font-normal tw-text-[15px] tw-text-gray-600 tw-flex tw-gap-[10px]'>
                        <div className='tw-w-[24px] tw-h-[24px]'><svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img"><path vector-effect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M17.8 20.2h-12c-1.7 0-3-1.3-3-3v-8c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v8c0 1.6-1.4 3-3 3z" clip-rule="evenodd"></path><path vector-effect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M7.7 13.9v-3m8.1 3v-3m-13 1.3h18m-7.6-8.4h-3c-.6 0-1 .4-1 1v1.4h5V4.8c0-.6-.4-1-1-1z"></path></svg></div>
                        <span className='tw-capitalize'>{currentOfferPropertyDetails?.propertyType}</span>
                    </div>
                </div>
            </div>
            <div className='tw-action'>
                <div className='tw-flex tw-gap-[10px]'>
                    <div className="tw-border tw-border-gray-300 tw-border-[2px] tw-rounded-full tw-p-[5px]">
                        <FaVideo className='tw-text-green-500 tw-text-[20px] tw-cursor-pointer' />
                    </div>
                    <div className="tw-border tw-border-gray-300 tw-border-[2px] tw-rounded-full tw-p-[5px]">
                        <LiaBusinessTimeSolid className='tw-text-green-500 tw-text-[20px] tw-cursor-pointer' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatHeader
