import React, { useEffect } from "react";
import LogoImage from "../../../src/assets/icons/Logo.png";
import { useNavigate, Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Vector1 from "../../../src/assets/icons/big_lines.png";
import Vector2 from "../../../src/assets/icons/small_lines.png";
import Header from "../layouts/homepage/Header";
import CmsHeader from "../layouts/base/CmsHeader";

const WebsiteTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const policyinfo = [
    {
      title: "Introduction",
      pg: `Welcome to Re-Loan Corp. By accessing or using our website (the "Website") and services ("Services"), 
                you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, 
                please do not use our Website or Services.`,
    },
    {
      title: "Services Provided",
      pg: `Re-Loan Corp provides an online platform for borrowers and lenders to connect, apply for loans, review terms, 
                and manage loan agreements. We are not a direct lender. Re-Loan Corp acts as an intermediary to facilitate 
                connections between parties.`,
    },
    {
      title: "User Responsibilities",
      pg: (
        <>
          <p className="tw-mb-6 tw-text-xl">
            <strong>Account Registration</strong>
          </p>
          <p className="tw-mb-6">
            You may be required to create an account to access certain features.
            You are responsible for:
          </p>
          <ul className="tw-list-disc tw-ml-6 tw-mb-6">
            <li>
              Providing accurate and complete information during registration.
            </li>
            <li>
              Maintaining the confidentiality of your account credentials.
            </li>
            <li>
              Notifying us immediately if you suspect unauthorized use of your
              account.
            </li>
          </ul>
          <p className="tw-mb-6 tw-text-xl">
            <strong>Prohibited Conduct</strong>
          </p>
          <p className="tw-mb-6">You agree not to:</p>
          <ul className="tw-list-disc tw-ml-6 tw-mb-6">
            <li>Use the Website for illegal purposes.</li>
            <li>Submit false or misleading information.</li>
            <li>
              Attempt to hack, disrupt, or damage the Website or Services.
            </li>
            <li>Engage in fraudulent activity.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Loan Transactions",
      pg: `Any loan agreements entered into through the Website are solely between the borrower and lender. 
                Re-Loan Corp is not a party to such agreements. It is your responsibility to review and comply 
                with the terms of any loan agreement.`,
    },
    {
      title: "Fees and Payments",
      pg: `Certain features of our Services may require payment of fees. These will be clearly disclosed at the 
                time of purchase or registration. You agree to pay all applicable fees and taxes promptly.`,
    },
    {
      title: "Intellectual Property",
      pg: `All content on the Website, including but not limited to text, graphics, logos, and software, is the 
                property of Re-Loan Corp or its licensors. You may not copy, distribute, or modify any content without 
                prior written consent from Re-Loan Corp.`,
    },
    {
      title: "Privacy Policy",
      pg: (
        <p className="tw-mb-6">
          Your use of the Website is also governed by our Privacy Policy, which
          outlines how we collect, use, and protect your data. By using the
          Website, you consent to the practices described in our Privacy Policy.
          Read our{" "}
          <a href="/privacy-policy" className="tw-text-[#4853e4]">
            Privacy Policy
          </a>
          .
        </p>
      ),
    },
    {
      title: "Third-Party Links",
      pg: `The Website may include links to third-party websites. Re-Loan Corp does not endorse or control these websites 
                and is not responsible for their content or practices.`,
    },
    {
      title: "Limitation of Liability",
      pg: (
        <div>
          <p className="tw-mb-6">
            To the fullest extent permitted by law, Re-Loan Corp is not liable
            for any:
          </p>
          <ul className="tw-list-disc tw-ml-6 tw-mb-6">
            <li>
              Direct, indirect, incidental, or consequential damages arising
              from your use of the Website or Services.
            </li>
            <li>
              Errors, omissions, or inaccuracies in the information provided.
            </li>
            <li>
              Actions or inactions of borrowers, lenders, or other third
              parties.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Indemnification",
      pg: (
        <div>
          {" "}
          <p className="tw-mb-6">
            You agree to indemnify and hold harmless Re-Loan Corp, its officers,
            directors, employees, and affiliates from any claims, losses, or
            damages arising from:
          </p>
          <ul className="tw-list-disc tw-ml-6 tw-mb-6">
            <li>Your violation of these Terms.</li>
            <li>Your use of the Website or Services.</li>
            <li>Your interactions with other users or third parties.</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Termination",
      pg: `We may terminate or suspend your access to the Website or Services at any time, without notice, if you violate these Terms.  
                Upon termination, your right to use the Website will immediately cease.`,
    },
    {
      title: "Governing Law",
      pg: `These Terms are governed by the laws of **[Insert Jurisdiction]**. Any disputes will be resolved exclusively 
                in the courts of **[Insert Jurisdiction]**.`,
    },
    {
      title: "Changes to Terms",
      pg: `Re-Loan Corp reserves the right to modify these Terms at any time. Changes will be effective immediately 
                upon posting. Your continued use of the Website constitutes acceptance of the updated Terms.`,
    },
    {
      title: "Contact Information",
      pg: (
        <p className="tw-pb-6">
          If you have questions or concerns about these Terms, please{" "}
          <a href="/contact" className="tw-text-[#4853e4]">
            contact
          </a>{" "}
          us.
        </p>
      ),
    },
  ];

  return (
  <>
  <div className="tw-block xl:tw-hidden">
  <CmsHeader />
  </div>
    <div className="tw-flex tw-justify-center">
      
      {/* Sidebar - Fixed position */}
      <div className="tw-w-1/6 tw-bg-[#3748E8] tw-h-full tw-flex-col tw-justify-center tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-10 tw-hidden xl:tw-flex">
        <div className="tw-absolute tw-top-4 tw-left-4">
          <img
            src={LogoImage}
            alt="Re-loan logo"
            className="tw-w-40 tw-cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        {/* Vector Decorations */}
        <img
          src={Vector2}
          alt="vector2"
          className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-50 tw-h-1/3"
        />
        <img
          src={Vector1}
          alt="vector1"
          className="tw-absolute tw-top-20 tw-left-0 tw-opacity-50 tw-h-2/3"
        />
      </div>

      {/* Main Content */}
      <div className="tw-w-5/6 tw-bg-white tw-h-full tw-justify-center tw-text-2xl tw-overflow-y-auto tw-scrollbar xl:tw-ml-[22%] tw-pt-12">
        <div className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-reloan tw-relative tw-flex">
          <a href="/">
            <ChevronLeftIcon fontSize={"12px"} className="tw-text-gray-800" />
          </a>
          Terms of Service
        </div>
        <div className="tw-text-gray-600 tw-flex tw-flex-col tw-gap-6 lg:tw-p-6">
          {policyinfo.map((p, index) => (
            <div key={index} className="tw-flex tw-flex-col tw-gap-2">
              <h2 className="tw-text-2xl tw-text-black tw-font-semibold">
                {p.title}
              </h2>
              <p className="tw-p-2">{p.pg}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default WebsiteTerms;
