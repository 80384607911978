import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { fetchGraphData } from '../../../utils/services/apiService';
import { useApiService } from '../../../services/apiService';
import Sofr30AvgChart from '../../common/graphs/Sofr30AvgChart';
import COMREPUS from '../../common/graphs/COMREPUS';
import CREAC from '../../common/graphs/CREAC';

function AssetGenerated({onReady}) {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const [graphData, setGraphData] = useState({
        originationFeeData: [],
        annualInterestData: [],
        lengthOfLoanData: [],
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    
    useEffect(() => {
        // Notify the parent that the ApplicationTable has completed rendering
        if (onReady) {
            onReady();
        }
    }, [onReady]);


    

    return (
        <div className="tw-flex tw-flex-col tw-space-y-3 tw-text-black">
            <h2 className='tw-text-xl tw-px-8'>30-Day Average SOFR</h2>
            <Sofr30AvgChart />
            <h2 className='tw-text-xl tw-px-8'>Commercial Real Estate Prices</h2>
            <COMREPUS />
            <h2 className='tw-text-xl tw-px-8'>Commercial Real Estate Loans</h2>
            <CREAC />
        </div>
    );
}

export default AssetGenerated;
