import React, { useState } from 'react';
import BorrowerSignupIcon from "../../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../../assets/icons/lenderIcon.svg";
import HomeImage from "../../../assets/images/home_background.png";
import { Link } from 'react-router-dom';
import { useUserType } from '../../../contexts/UserTypeContext';

const HomeInfoArea = ({ isUserLoggedIn }) => {
  const { userType, setUserType } = useUserType();

  const handleUserTypeChange = (type) => {
    setUserType(type);
  };
  return (
    <>
      <div className="tw-relative tw-flex tw-space-x-4">
        <div className='tw-flex tw-flex-col  tw-space-y-14 tw-w-1/2'>
          <div className='tw-flex tw-flex-col'>
            <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-7xl tw-text-black tw-my-4 tw-font-bold tw-text-center">
              The All-in-One<br /> Platform for <span className='tw-text-[#4853e4]'> Real<br /> Estate Financing</span>
            </h2>

            <p className='tw-font-inter tw-text-[#404040] tw-text-lg'>
              Effortlessly connect with verified lenders for quick, secure financing solutions. Our platform
              combines real-time data and AI technology to simplify loan processing, making it easy to apply,
              communicate, and close deals with confidence.
            </p>
          </div>

          <div className='tw-flex tw-gap-x-2 tw-justify-start tw-bottom-0'>
            {!isUserLoggedIn ? (
              <>
                <Link to={"/sign-up/borrower"} >
                  <button onClick={() => handleUserTypeChange('borrower')} className='tw-flex tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'>
                    <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='borrower' />
                    <p className='tw-text-md tw-text-black tw-font-oswald'>Borrower Signup</p>
                  </button>
                </Link>
                <Link to={"/sign-up/lender"}>
                  <button onClick={() => handleUserTypeChange('lender')} className='tw-flex tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl'>
                    <img src={LenderSignupIcon} className='tw-w-6 tw-h-6' alt='lender' />
                    <p className='tw-text-md tw-text-black tw-font-oswald'>Lender Signup</p>
                  </button>
                </Link>
              </>
            ) : (
              <Link to={"/sign-up/borrower"} >
                <button
                  // onClick={openSignupOptionsModal}
                  className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-px-3 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                >
                  <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='dashboard' />
                  <p className='tw-text-md tw-text-black tw-font-oswald'>Dashboard</p>
                </button>
              </Link>
            )}
          </div>
        </div>

        <div className='tw-flex tw-w-1/2 tw-justify-end'>
          <img src={HomeImage} alt='main image' loading='lazy' />
        </div>
      </div>
    </>
  );
};

export default HomeInfoArea;
