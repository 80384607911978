import React, { useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { signUp, signOut } from "@aws-amplify/auth";
import { useLoader } from "../../contexts/LoaderContext";
import { authLocal, getMembershipDates } from "../../utils/common";
import { useFetchUserSubscription } from '../../hook/useFetchUserSubscription';
import { useApiService } from "../../services/apiService";

const PackageSuccessPage = () => {
    const { signUpUser, userType, user, setUser, updateMembership } = useContext(UserContext);
    const { fetchUserSubscription } = useFetchUserSubscription();
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const location = useLocation();
    const isCalled = useRef(false);
    const navigate = useNavigate();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionIdFromUrl = params.get("session_id");

        if (sessionIdFromUrl && !isCalled.current) {
            isCalled.current = true; // Prevent duplicate calls
            fetchSessionDetails(sessionIdFromUrl);
        }
    }, [location.search]);

    const fetchSessionDetails = async (sessionId) => {
        try {
            showLoader();
            const response = await postRequest(`/payment/session-details`, { sessionId });

            if (response?.success) {
                const session = response?.data;

                // Check if payment is successful
                if (
                    session.session.payment_status === "paid" &&
                    session.session.status === "complete"
                ) {
                    const subscriptionType = session?.session?.metadata?.type;

                    if (user?.id) {
                        await handlePayment(user.id, session);
                        await handleMembership(user.id, session, subscriptionType);
                    } else {
                        await handleSignUpClick(session, subscriptionType);
                    }
                } else {
                    navigate("/sign-up/lender");
                    toast.error("Payment failed. Please sign up again.");
                }
            } else {
                throw new Error("Failed to fetch valid session details");
            }
        } catch (error) {
            console.error("Error fetching session details:", error);
        } finally {
            hideLoader();
        }
    };

    const handlePayment = async (user_id, session) => {
        try {
            const paymentData = {
                user_id,
                price_id: session?.session?.price_id,
                subscription_plan_id: session?.session?.metadata?.subscription_plan_id,
                plan_name: session?.session?.metadata?.plan_name,
                discount_amount: session?.session?.metadata?.discount_amount,
                discount_code: session?.session?.metadata?.discount_code,
                promo_code_id: session?.session?.metadata?.promo_code_id,
                session: session.session,
            };

            await postRequest(`/payment/success`, paymentData);
        } catch (err) {
            toast.error(err.message || "Cannot process payment.");
            console.error(err);
        }
    };

    const handleMembership = async (user_id, session, subscriptionType) => {
        try {
            const isActive = session?.subscription?.status === "active" || session?.subscription?.status === "trialing";
            console.log("Previous subscription canceled successfully.");
            
        

            const response = await postRequest(`/user/update-membership`, {
                stripe_subscription_id: session?.session?.subscription,
                subscription_plan_id: session?.session?.metadata?.subscription_plan_id,
                available_offer: session?.session?.metadata?.offer,
                membership_start: new Date(session?.subscription?.current_period_start * 1000).toISOString(),
                membership_end: new Date(session?.subscription?.current_period_end * 1000).toISOString(),
                id: user_id,
            });

            if (!response) {
                throw new Error("Failed to update membership");
            }

            const data = await response?.data;

            if (data?.is_success) {
                // Update membership details locally
                updateMembership({
                    stripe_subscription_id: session?.session?.subscription,
                    subscription_plan_id: session?.session?.metadata?.subscription_plan_id,
                    membership_start: new Date(session?.subscription?.current_period_start * 1000).toISOString(),
                    membership_end: new Date(session?.subscription?.current_period_end * 1000).toISOString(),
                    available_offer: session?.session?.metadata?.offer,
                }
                );

                toast.success("Membership updated successfully!");
            } else {
                throw new Error(data?.message || "Failed to update membership.");
            }
        } catch (err) {
            toast.error(err.message || "Cannot update membership.");
            console.error(err);
        }
    };

    const handleSignUpClick = async (session, subscriptionType) => {
        try {
            showLoader();

            const { userId } = await signUp({
                username: signUpUser.email,
                password: signUpUser.password,
                options: {
                    userAttributes: {
                        email: signUpUser.email,
                        phone_number: signUpUser.phone,
                        name: `${signUpUser.firstName} ${signUpUser.lastName}`,
                        "custom:user_type": userType,
                        "custom:FirstName": signUpUser.firstName,
                        "custom:LastName": signUpUser.lastName,
                    },
                },
            });

            if (userId) {
                const createUserResponse = await postRequest(`/create-user`, {
                    email: signUpUser.email,
                    phone_number: signUpUser.phone,
                    name: `${signUpUser.firstName} ${signUpUser.lastName}`,
                    "custom:user_type": userType,
                    "custom:FirstName": signUpUser.firstName,
                    "custom:LastName": signUpUser.lastName,
                });

                if (createUserResponse?.success) {
                    const user_id = createUserResponse.data.id;

                    await handlePayment(user_id, session);
                    await handleMembership(user_id, session, subscriptionType);
                } else {
                    toast.error(`Unexpected response: ${createUserResponse.status}`);
                }
            }
        } catch (err) {
            toast.error(err.message || "Cannot sign up user.");
            console.error(err);
        } finally {
            hideLoader(); // Ensure the loader is hidden even if there's an error in this function
        }
    };


    const handleNavigate = async () => {
        try {
            if (!user?.id) return navigate("/otp-verify");
    
            showLoader();
            const userDetailsResponse = await postRequest(`/fetch-user`, { email: user.email });
    
            if (!userDetailsResponse?.data?.user || userDetailsResponse.data.user.status !== 1) {
                throw new Error("User fetch failed or user inactive");
            }
    
            const updatedUser = userDetailsResponse.data.user;
    
            // Update local storage and user context
            authLocal.set(updatedUser);
            setUser(updatedUser);
    
            navigate(`/dashboard/payment-list`);
        } catch (error) {
            console.error("Error updating user details:", error);
            authLocal.remove();
            toast.error("User update failed. Please try again.");
            await signOut();
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchUserSubscription();
    }, [user]);

    return (
        <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-p-4">
            <div className="tw-bg-white tw-p-8 lg:tw-p-20 md:tw-p-14 sm:tw-p-8 tw-rounded-lg tw-shadow-lg tw-text-center tw-w-full tw-max-w-[90%] lg:tw-max-w-[30%] md:tw-max-w-[50%] sm:tw-max-w-full">
                <div className="tw-flex tw-justify-center">
                    <div className="tw-mb-4 tw-flex tw-justify-center tw-items-center tw-bg-green-100 tw-w-[100px] tw-h-[100px] tw-rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="tw-w-20 tw-h-24 tw-text-green-500"
                        >
                            <path d="M20 6L9 17l-5-5" />
                        </svg>
                    </div>
                </div>
                <h1 className="tw-text-3xl tw-font-extrabold tw-text-green-600">Payment Success</h1>
                <p className="tw-text-lg tw-text-gray-700 tw-mt-4">
                    Thank you for your payment! Your transaction has been successfully completed.
                </p>
                <div className="tw-mt-8">
                    <button
                        onClick={handleNavigate}
                        className="tw-bg-green-500 tw-text-white tw-font-semibold tw-px-8 tw-py-4 tw-rounded-full hover:tw-bg-green-600"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PackageSuccessPage;
