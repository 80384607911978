import React, { useState } from 'react';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";

const StatsDropdown = () => {
    const [selectedDate, setSelectedDate] = useState('Yesterday');
    const dateOptions = ['Last Month', 'Last Year', 'Last Week', 'Custom Date'];

    return (
        <div className="tw-flex tw-justify-end tw-relative tw-w-full">
            <Menu>
                <MenuHandler>
                    {/* Dropdown Trigger */}
                    <div className="tw-w-[125px] tw-h-[40px] tw-py-[8px] tw-ps-8 tw-flex tw-items-center tw-justify-between tw-text-[16px] tw-text-[#4F4F4F] common-font tw-cursor-pointer">
                        <span>{selectedDate}</span>
                        {/* Down Arrow Icon */}
                        <i className="fas fa-chevron-down tw-text-black tw-text-xs tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center"></i>
                    </div>
                </MenuHandler>

                {/* Dropdown Options */}
                <MenuList className="tw-w-[142px] tw-my-[-12px] tw-bg-white tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-py-[5px] tw-shadow-md tw-z-10">
                    {dateOptions?.map(option => (
                        <MenuItem
                            key={option}
                            onClick={() => {
                                setSelectedDate(option);
                            }}
                            className=" tw-px-7 tw-cursor-pointer tw-text-[#4F4F4F]"
                        >
                            {option}
                            {/* Fixed bottom border */}
                            <div className="tw-border-b tw-border-[#DDDDDD] tw-w-full tw-my-2"></div>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </div>
    );
};

export default StatsDropdown;
