import React, { useEffect, useState, useContext, useRef } from 'react';
import FormBox from './FormBox';
import NewApplicationModal from './modal/NewApplicationModal';
import { UserContext } from '../../../contexts/UserContext';
import toast from 'react-hot-toast';
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import { GoogleMapsProvider } from '../../../contexts/GoogleMapsContext';
import AssetGenerated from '../lender/AssetGenerated';
import BorrowerHeader from '../../../components/dashboards/borrower/BorrowerHeader';
import Chat from '../../common/chat-new/Chat';
import { useLoader } from '../../../contexts/LoaderContext';
import { formatTimeDifference, formatPrice, getApplicationStatus } from '../../../utils/common';
import { useApiService } from '../../../services/apiService';
import Accepted_application from "../../../assets/icons/accepted_application.png.png";
import Total_application from "../../../assets/icons/total_application.png.png";
import Pending_application from "../../../assets/icons/pending_application.png";
import Rejected_applicaion from "../../../assets/icons/rejected_applicaion.png";
import Total_amount_lent from "../../../assets/icons/total_amount_lent.png";
import Loan_acceptance_rate from "../../../assets/icons/loan_acceptance_rate.png";
import StatCards from '../../layouts/homepage/StatCards';
import { PageLoader, usePageLoader } from "../../../contexts/PageLoaderContext";

function Borrower() {
    const { user } = useContext(UserContext);
    const { getRequest, postRequest } = useApiService();

    const chatRef = useRef(null);
    const handleCloseModal = () => setOpenModal(false);
    const [openModal, setOpenModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(Math.random());
    const [loanApplications, setLoanApplications] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [borrowerApplicationsSummary, setBorrowerApplicationsSummary] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [formattedTimes] = useState([]);
    const [applicationStatus, setApplicationStatus] = useState([]);
    const [filters, setFilters] = useState({
        user_id: user?.id,
        search_text: '',
        loan_purpose_id: '',
        property_type_id: '',
        state_id: '',
        city_id: '',
        property_value_min: '',
        property_value_max: '',
        status_id: '',
        sort_by: 'a.id',
        sort_order: 'DESC'
    });

    const { setCurrentOfferPropertyDetails } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();

    const [showClosed, setShowClosed] = useState(false);
    const [stats, setStats] = useState([]);
    const { showPageLoader, hidePageLoader } = usePageLoader();
    const [dataLoaded, setDataLoaded] = useState(false);

    const [childComponentsReady, setChildComponentsReady] = useState({
        table: false,
        form: false,
        chat: false,
        assetGenerated: false,
    });

    const isEverythingReady = Object.values(childComponentsReady).every(Boolean) && dataLoaded;

    const markChildReady = (componentName) => {
        console.log("ready")
        setChildComponentsReady((prev) => ({
            ...prev,
            [componentName]: true,
        }));
    };

    const ensureOnReady = (componentName) => {
        if (!childComponentsReady[componentName]) {
          markChildReady(componentName);
        }
      };

    useEffect(() => {
        if (isEverythingReady) {
            setTimeout(() => {
                hidePageLoader();
            }, 500); // Add a small delay before hiding the page loader
        }
    }, [isEverythingReady]);

    useEffect(() => {
        const fetchBorrowerData = async () => {
            showPageLoader();
            try {
                await fetchBorrowerLoanSummary();
                await fetchUserLoanApplications();
                setDataLoaded(true);
            } catch (error) {
                toast.error("Failed to load initial data.");
            }
        };

        fetchBorrowerData();
    }, []);


    const fetchBorrowerLoanSummary = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await postRequest(`/getBorrowerDashboardSummary`, { userId: user?.id });
            if (response?.success) {
                setBorrowerApplicationsSummary(response?.data);
            } else {
                setBorrowerApplicationsSummary([]);
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
        hideLoader();
    };

    const fetchUserLoanApplications = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await postRequest(`/application/borrower/list`, filters);
            if (response?.success) {
                setLoanApplications(response?.data?.applications);
            } else {
                setLoanApplications([]);
            }
        } catch (err) {
            setLoanApplications([]);
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
        hideLoader();
    };

    const handleClosed = () => {
        if (filters.status_id !== "5") {
            console.log("Show Closed Applications");
            setFilters((prevFilters) => ({
                ...prevFilters,
                status_id: "5",
            }));
            setShowClosed(true);
        } else {
            console.log("Show Opened Applications");
            setFilters((prevFilters) => ({
                ...prevFilters,
                status_id: "",
            }));
            setShowClosed(false);
        }
        // setIsFilter(!isFilter);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log('Refreshing Applications.');
            fetchUserLoanApplications();
        }, 30000);

        return () => clearInterval(intervalId);
    }, [user, filters]);

    useEffect(() => {
        fetchUserLoanApplications();
    }, [filters]);

    // const handleSortChange = (type) => {
    //     let newSortOrder = '';

    //     if (type === 'oldFirst') {
    //         newSortOrder = 'ASC';  // Set sort order to ASC for "Old First"
    //     } else if (type === 'newFirst') {
    //         newSortOrder = 'DESC'; // Set sort order to DESC for "New First"
    //     }

    //     // Update both the local sortOrder state (if necessary) and filters state
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         sort_order: newSortOrder,  // Update the sort_order in filters
    //     }));
    // };
    const handleSortChange = (type) => {
        let newSortOrder = '';
        let sortByField = '';
        switch (type) {
            case 'oldFirst':
                newSortOrder = 'ASC';
                sortByField = 'a.id';
                break;
            case 'newFirst':
                newSortOrder = 'DESC';
                sortByField = 'a.id';
                break;

            // LTV Sorting
            case 'oldLtv':
                newSortOrder = 'ASC';
                sortByField = 'a.ltv';
                break;
            case 'newLtv':
                newSortOrder = 'DESC';
                sortByField = 'a.ltv';
                break;

            // Property Value Sorting
            case 'oldPropertyValue':
                newSortOrder = 'ASC';
                sortByField = 'a.property_value';
                break;
            case 'newPropertyValue':
                newSortOrder = 'DESC';
                sortByField = 'a.property_value';
                break;

            // Loan Amount Sorting
            case 'oldLoanAmount':
                newSortOrder = 'ASC';
                sortByField = 'a.loan_amount';
                break;
            case 'newLoanAmount':
                newSortOrder = 'DESC';
                sortByField = 'a.loan_amount';
                break;

            // Number of Offers Sorting
            case 'oldNumberOfOffers':
                newSortOrder = 'ASC';
                sortByField = 'a.potential_lenders';
                break;
            case 'newNumberOfOffers':
                newSortOrder = 'DESC';
                sortByField = 'a.potential_lenders';
                break;

            default:
                console.warn(`Unhandled sort type: ${type}`);
                return;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder,
            sort_by: sortByField,
            sort_order: newSortOrder,
            sort_by: sortByField,
        }));
    };

    useEffect(() => {
        fetchUserLoanApplications();
        fetchBorrowerLoanSummary();
    }, [refreshKey]);

    useEffect(() => {
        const stats = [
            {
                title: 'Total Applications',
                value: borrowerApplicationsSummary?.total_applications ? borrowerApplicationsSummary?.total_applications : 0,
                icon: Total_application
            },
            {
                title: 'Accepted Applications',
                value: borrowerApplicationsSummary?.accepted_applications ? borrowerApplicationsSummary?.accepted_applications : 0,
                icon: Accepted_application
            },
            {
                title: 'Pending Applications',
                value: borrowerApplicationsSummary?.pending_applications ? borrowerApplicationsSummary?.pending_applications : 0,
                icon: Pending_application
            },
            {
                title: 'Rejected Applications',
                value: borrowerApplicationsSummary?.rejected_applications ? borrowerApplicationsSummary?.rejected_applications : 0,
                icon: Rejected_applicaion
            },
            {
                title: 'Total Amount Recieved',
                value: borrowerApplicationsSummary?.total_amount_lent ? formatPrice(borrowerApplicationsSummary?.total_amount_lent) : 0,
                icon: Total_amount_lent
            },
            {
                title: 'Loan Acceptance Rate',
                value: borrowerApplicationsSummary?.loan_acceptance_rate_percentage ? `${borrowerApplicationsSummary?.loan_acceptance_rate_percentage?.toFixed(2)}%` : 0,
                icon: Loan_acceptance_rate
            },
        ];
        setStats(stats)
    }, [borrowerApplicationsSummary]);

    const handleNewApplication = async () => {
        await fetchUserLoanApplications();
        handleCloseModal();
    };

    const columns = [
        {
            field: 'property_address',
            headerName: 'Address',
            minWidth: 160,
            flex: 0.3,
            editable: true,
            sortable: true,
            renderCell: (v) => (
                <div className="tw-text-center">
                    <span>{v.value.split(',')[0]}</span>
                </div>
            ),
        },
        {
            field: 'location',
            headerName: 'Location',
            minWidth: 160,
            flex: 0.3,
            editable: true,
            sortable: true,
            renderCell: (v) => (
                <div className="tw-text-center">
                    {v.value}
                </div>
            ),
        },
        {
            field: 'loan_amount',
            headerName: 'Loan Amount',
            minWidth: 160,
            flex: 0.3,
            editable: false,
            sortable: true,
            valueGetter: (v) => {
                return parseFloat(v.replace(/[,\\$]/g, ''))
            },
            renderCell: (v) => {
                return `${v.row.loan_amount}`
            }
        },
        {
            field: 'property_value',
            headerName: 'Property Value',
            minWidth: 160,
            flex: 0.3,
            type: "text",
            editable: false,
            sortable: true,
            valueGetter: (v) => {
                return parseFloat(v.replace(/[,\\$]/g, ''))
            },
            renderCell: (v) => {
                return `${v.row.property_value}`
            }
        },
        {
            field: 'property_type',
            headerName: 'Property Type',
            minWidth: 160,
            maxWidth: 300,
            flex: 0.3,
            editable: false,
            renderCell: (params) => (
                <div
                    style={{
                        textAlign: 'center',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.5',
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'loan_purpose',
            headerName: 'Loan Purpose',
            minWidth: 160,
            flex: 0.3,
            editable: false,
        },
        {
            field: 'ltv',
            headerName: 'LTV',
            minWidth: 50,
            maxWidth: 75,
            flex: 0.3,
            editable: false,
            valueGetter: (v) => {
                return Number(v.split("%")[0])
            },
            renderCell: (v) => {
                return `${v.formattedValue}%`
            }
        },
        {
            field: 'number_of_offers',
            headerName: 'Offers',
            minWidth: 50,
            maxWidth: 75,
            flex: 0.3,
            editable: false,
        },
        {
            field: 'property_status',
            headerName: 'Status',
            minWidth: 150,
            maxWidth: 200,
            flex: 0.3,
            renderCell: (params) => {
                const statusId = params?.value;
                const { render } = getApplicationStatus(statusId, user?.user_type);
                return render();
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            minWidth: 100,
            maxWidth: 120,
            flex: 0.3,
            editable: false,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find(
                    (item) => item.application_id === params.row.application_id
                )?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
    ];

    const rows = loanApplications?.length ? loanApplications?.map(app => ({
        application_id: app?.application_id || '',
        property_address: app?.property_address || '',
        loan_purpose: app?.loan_purpose || '',
        property_value: app?.property_value ? formatPrice(app?.property_value) : '',
        property_type: app?.property_type,
        loan_amount: app?.loan_amount ? formatPrice(app?.loan_amount) : '',
        number_of_offers: app?.total_application_offer || "0",
        property_status: app?.property_status || '',
        ltv: app?.ltv ? `${app.ltv}%` : '0%',
        lat: app?.lat || '40.758896',
        lng: app?.lng || '-73.985130',
        city: app?.city ? app?.city : '',
        location: app?.city ? `${app?.city}, ${app?.state} ${app?.postal_code}` : '',
        streetAddress: app.property_address,
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    let isScrolling = false;

    const smoothScrollTo = (target, duration = 500) => {
        if (!target) return;

        const start = window.scrollY;
        const end = target.offsetTop;
        const distance = end - start;
        let startTime = null;

        const easeInOutCubic = (t) => {
            return t < 0.5
                ? 4 * t * t * t
                : 1 - Math.pow(-2 * t + 2, 3) / 2;
        };

        const step = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = Math.min((timestamp - startTime) / duration, 1);
            const easedProgress = easeInOutCubic(progress);
            const scrollPosition = start + distance * easedProgress;

            window.scrollTo(0, scrollPosition);

            if (progress < 1) {
                requestAnimationFrame(step);
            } else {
                isScrolling = false;
            }
        };

        requestAnimationFrame(step);
    };

    const handleRowClick = (params) => {
        if (isScrolling) return;
        isScrolling = true;

        showLoader();

        const location = rows.find((loc) => loc.application_id === params.application_id);
        if (location) {
            setSelectedLocation({ lat: location.lat, lng: location.lng });
            setSelectedRowId(params.application_id);
            setCurrentOfferPropertyDetails(params);

            setTimeout(() => {
                smoothScrollTo(chatRef.current, 500);
                hideLoader();
            }, 1000);
        } else {
            hideLoader();
        }
    };

    useEffect(() => {
        if (rows.length > 0) {
            if (selectedRowId > 0) {
                const selecteApplication =
                    rows.find((application) => application?.application_id === selectedRowId);
                setSelectedRowId(selecteApplication?.application_id);
                setCurrentOfferPropertyDetails(selecteApplication);
            } else {
                const firstRow = rows[0];
                setSelectedRowId(firstRow?.application_id);
                setCurrentOfferPropertyDetails(firstRow);
                setSelectedLocation({ lat: firstRow.lat, lng: firstRow.lng });
            }
        }
    }, [loanApplications]);

    const fetchApplicationStatusList = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await getRequest('/application-status/list');
            if (response.success) {
                setApplicationStatus(response?.data?.applications_status);
            } else {
                setApplicationStatus([]);
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching applications status.");
            }
        } finally {
            hideLoader();
        }
    };

    const handleItemClick = (id) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            status_id: id,
        }));
    };

    useEffect(() => {
        fetchApplicationStatusList();
    }, [user]);

    const borrowerHeaderProps = {
        userStatus: applicationStatus,
        handleClosed,
        handleSortChange,
        handleItemClick,
        setFilters,
        filters,
        showClosed,
    };


    return (
        <GoogleMapsProvider>
            {!isEverythingReady && (
            <PageLoader />
            )}
            <div className="tw-h-full tw-w-full">
                <div className="tw-mt-5 tw-flex tw-flex-col lg:tw-grid tw-grid-rows-[auto_1fr_auto] tw-grid-cols-2 2xl:tw-grid-cols-[minmax(0,2fr)_minmax(0,1fr)] tw-gap-5 w-full max-w-full">
                    <div className="tw-w-full">
                        <ApplicationTable
                            dataTableProps={{
                                getRowId: (row, index) => `${index}-${row?.application_id}`,
                                userStatus: applicationStatus,
                                dashboardType: 'borrower',
                                handleSortChange,
                                handleItemClick,
                                setFilters,
                                getRowClassName: (params) =>
                                    params.row.application_id === selectedRowId ? 'selected-row' : ''
                            }}
                            rows={rows}
                            selectedRow={selectedLocation || rows[rows.length - 1]}
                            columns={columns}
                            onRowClick={handleRowClick}
                            customHeaderProps={borrowerHeaderProps}
                            CustomHeaderComponent={BorrowerHeader}
                            tableHeight="400px"
                            maxHeight='120vh'
                            minHeight='50%'
                            onReady={() => ensureOnReady('table')}
                        />
                    </div>
                    <div className='tw-w-full'>
                        <FormBox
                            callBack={() => setRefreshKey(Math.random())}
                            selectedLocation={selectedLocation}
                            onReady={() => ensureOnReady('form')}
                        />
                    </div>
                    <div className='tw-w-full'>
                        <Chat
                            ref={chatRef}
                            application_id={selectedRowId}
                            fetchUserLoanApplications={fetchUserLoanApplications}
                            setSelectedAppId={setSelectedRowId}
                            onReady={() => ensureOnReady('chat')}
                        />
                    </div>
                    <div className='tw-w-full'>
                        <AssetGenerated onReady={() => ensureOnReady('assetGenerated')} />
                    </div>
                </div>
                <NewApplicationModal open={openModal} handleClose={handleCloseModal} onNewApplication={handleNewApplication} />
            </div>
        </GoogleMapsProvider >
    );
}
export default Borrower;
