import React, { useState } from 'react'
import UserProfile from "../../../assets/icons/usericon.svg";
import StatsDropdown from '../StatsDropdown';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

function Notification() {
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const messages = [
        {
            id: 1,
            sender: "Marry John",
            message: "Lorem ipsum dolor sit amet consectetur. Tellus ac faucibus justo adipiscing sit.",
            time: "04:50 PM",
        },
        {
            id: 2,
            sender: "Alice Smith",
            message: "Hello! How are you doing today?",
            time: "04:45 PM",
        },
        {
            id: 3,
            sender: "Bob Johnson",
            message: "Let's meet tomorrow at 10 AM.",
            time: "04:40 PM",
        },
        {
            id: 4,
            sender: "Charlie Brown",
            message: "Don't forget to send the report.",
            time: "04:35 PM",
        },
        {
            id: 5,
            sender: "Daisy Hill",
            message: "Can you review my proposal?",
            time: "04:30 PM",
        },
        {
            id: 6,
            sender: "Eve Adams",
            message: "The project deadline is next Friday.",
            time: "04:25 PM",
        },
        {
            id: 7,
            sender: "Frank White",
            message: "Are we still on for lunch tomorrow?",
            time: "04:20 PM",
        },
        {
            id: 8,
            sender: "Grace Lee",
            message: "I have attached the document you requested.",
            time: "04:15 PM",
        },
        {
            id: 9,
            sender: "Hannah King",
            message: "Looking forward to our meeting next week!",
            time: "04:10 PM",
        },
        {
            id: 10,
            sender: "Ian Wright",
            message: "Thank you for your help on this task.",
            time: "04:05 PM",
        },
    ];

    return (
        <div className="tw-w-full tw-h-full tw-mx-[30px] tw-my-[12px]">
            <h1 className=' tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                Notifications
            </h1>
            <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your notifications.</p>

            {/* Notification Main Container */}
            <div className='tw-w-full tw-h-[813px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-mt-[30px]'>
                {/* Notification Main container Header */}
                <div className='tw-mx-[20px] tw-h-[79px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-flex tw-justify-between tw-items-center'>
                    <div className=' tw-flex '>
                        <div className='tw-w-[104px] tw-h-[39px] tw-flex tw-items-center'>
                            <div
                                className={`tw-cursor-pointer tw-w-[24px] tw-h-[24px] tw-rounded-[5px] tw-flex tw-justify-center tw-items-center tw-border  ${isChecked ? 'tw-border-[#00B69B] tw-bg-[#00B69B14]' : 'tw-border-[#DDDDDD]'
                                    }`}
                                onClick={() => setIsChecked(!isChecked)}
                            >
                                {isChecked ?
                                    <>
                                        <i className="fas fa-check tw-text-green-500 tw-text-xs"></i>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <label htmlFor="selectAll" className="tw-ps-[8px] tw-text-[#4F4F4F] tw-font-semibold common-font tw-text-[16px] tw-cursor-pointer">
                                {isChecked ? "Deselect" : "Select All"}
                            </label>
                        </div>

                        {isChecked && (
                            <div className='tw-ms-[10px] tw-w-[164px] tw-h-[42px] tw-border tw-border-[#DDDDDD] tw-bg-[#7DDE92] tw-rounded-[10px]'>
                                <div className='tw-w-[128px] tw-h-[22px] tw-ms-[17px] tw-mt-[9px] tw-flex tw-justify-between tw-justify-center tw-items-center'>
                                    <div className='relative inline-block'>
                                        <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                    </div>
                                    <button className=' tw-font-semibold tw-text-[#4F4F4F] tw-text-[16px] common-font'>
                                        Mark As Read
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='tw-w-[248px] tw-h-[39px] tw-flex'>
                        <div className='tw-relative tw-w-[133px] tw-h-[39px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                            <div
                                className='tw-w-full tw-h-[28.05px] tw-py-[10px] tw-mt-[5px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2'
                                onClick={toggleDropdown}
                            >
                                <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                <span className='tw-text-[#4F4F4F] tw-text-[14px] tw-font-semibold common-font'>Show All</span>
                            </div>

                            {isOpen && (
                                <div className='borrower-common-menu'>
                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100 tw-border-b-[2px]'>
                                        <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                        <span className='tw-text-[#4F4F4F]'>Show Read</span>
                                    </div>

                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100'>
                                        <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                        <span className='tw-text-[#4F4F4F]'>Show Unread</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="">
                            <StatsDropdown />
                        </div>

                    </div>
                </div>


                <div className='tw-mx-[20px] tw-mt-[20px] tw-h-[694px]'>
                    <div className='tw-overflow-y-auto tw-max-h-[694px]  custom-scroll'>
                        {messages.map((msg) => (
                            <div key={msg.id} className='tw-mx-[20px] tw-h-[92px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[10px]'>
                                <div className='tw-w-full tw-h-[60px] tw-my-[15px] tw-flex'>
                                    <div className='tw-w-[70%] tw-flex'>
                                        <div className='tw-w-[60px] tw-h-[60px]'>
                                            <img
                                                src={UserProfile}
                                                alt={`Profile of ${msg.sender}`}
                                                className="tw-rounded-full tw-bg-[#DDDDDD] tw-border-[1px]"
                                            />
                                        </div>
                                        <div className='tw-w-full tw-ms-[21px] tw-h-[60px]'>
                                            <p className='tw-text-[20px] tw-text-[#202224] tw-font-semibold common-font'>
                                                New message from {msg.sender}
                                            </p>
                                            <p className='tw-text-[18px] tw-text-[#484848] tw-font-normal common-font'>
                                                {msg.message}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='tw-w-[30%] tw-flex tw-items-center tw-justify-between'>
                                        <div className='tw-flex-1 tw-text-center'>
                                            <p className='tw-text-[16px] common-font tw-text-[#484848] tw-opacity-70'>{msg?.time}</p>
                                        </div>
                                        <div className='tw-flex tw-items-center tw-justify-end tw-pl-2'>
                                            <Button aria-describedby={id} onClick={handleClick}>
                                                <span className='tw-cursor-pointer'>
                                                    <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-xl tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
                                                </span>
                                            </Button>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        elevation: 0, 
                                                        ml: 4,
                                                    },
                                                    elevation: 1,
                                                }}
                                            >
                                                <Typography sx={{ p: 1 }}>Mark As Read.</Typography>
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification