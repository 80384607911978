import React, { useState, useEffect, useContext } from 'react';
import '../../../styles/Header.css';
import LogoImage from '../../../assets/icons/Logo_blue.png';
import BorrowerSignupIcon from '../../../assets/icons/borrowerIcon.svg';
import LenderSignupIcon from '../../../assets/icons/lenderIcon.svg';
import SignupOptionsModal from '../../modals/SignupOptionsModal';
import SignupModal from '../../modals/SignupModal';
import ValidationModal from '../../modals/ValidationModal';
import LoginModal from '../../modals/LoginModal';
import { signOut } from '@aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AnimatePulse from '../../loaders/AnimatePulse';
import { UserContext } from '../../../contexts/UserContext';
import { useApiService } from '../../../services/apiService';

function Header({ isUserLoggedIn }) {
  const { setUserType , userType} = useContext(UserContext);
  const navigate = useNavigate();
  const { setUser, resetUser } = useContext(UserContext);
  const { postRequest } = useApiService();

  const [showLoader, setShowLoader] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const [showSignupOptionsModal, setShowSignupOptionsModal] = useState(false);
  const [showSignupFormModal, setShowSignupFormModal] = useState(false);
  const [showSigninFormModal, setShowSigninFormModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [signupRole, setSignupRole] = useState('');
  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem('re_loan_info'));
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState(window.localStorage.getItem('user_email') || '');
  

  const openSignupOptionsModal = () => setShowSignupOptionsModal(true);

  const closeSignupOptionsModal = (role) => {
    if (role) setShowSignupFormModal(true);
    setSignupRole(role);
    setShowSignupOptionsModal(false);
  };

  const closeSignupFormModal = (success = false) => {
    setShowSignupFormModal(false);
    if (success) setShowValidationModal(true);
  };

  const openSigninFormModal = () => {
    setSignupRole('');
    setShowSignupOptionsModal(false);
    setShowSignupFormModal(false);
    setShowSigninFormModal(true);
  };

  const closeSigninFormModal = () => setShowSigninFormModal(false);

  const closeValidationModal = (success = false) => {
    setShowValidationModal(false);
    if (success) setShowSigninFormModal(true);
  };

  const handleLogOut = async () => {
    try {
      await signOut();
      toast.success('Signed Out!');
      window.localStorage.removeItem('re_loan_info');
      window.localStorage.removeItem('user_email');
      setUserName('');
      setUserEmail('');
      setLoggedIn(0);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const goToHome = () => navigate('/');

  const goToDashboard = () => navigate('/dashboard');

  const setUpUserContext = async () => {
    setShowLoader(true);
    try {
      const _user = await postRequest(`/fetch-user`, { email: userEmail })
        .then((response) => response.data)
        .catch((err) => {
          if (err.response) toast.error(err.response.data?.message);
        });

      const _userFromRDS = typeof _user.user === 'string' ? JSON.parse(_user.user) : _user.user;

      if (_userFromRDS) {
        setUser(_userFromRDS);
        setUserName(_userFromRDS.first_name);
        setSignupRole(_userFromRDS.user_type);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error Fetching User Data!');
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (userEmail && loggedIn) setUpUserContext();
  }, [userEmail, loggedIn]);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <header className={`header tw-flex lg:tw-space-y-0 tw-flex-row tw-items-center tw-w-full tw-pt-[34px] ${isScrolled ? 'scrolled' : ''}`}>
        <div className="tw-flex tw-justify-center tw-cursor-pointer" onClick={goToHome}>
          <img src={LogoImage} className="tw-w-[230px] lg:tw-w-[168px]" alt="re-loan logo" />
        </div>

        {showLoader ? (
          <div className="tw-flex-1 tw-flex tw-justify-end">
            <AnimatePulse />
          </div>
        ) : (
          <div className="tw-flex-1 tw-flex tw-justify-end tw-items-center tw-hidden lg:tw-flex">
            {!isUserLoggedIn ? (
              <div className="tw-flex tw-gap-x-4 tw-justify-evenly tw-items-center">
                <Link to={`/sign-in`} tabIndex={-1}>
                  <button className='hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                    <p className="tw-text-md tw-text-black tw-font-oswald">Sign In</p>
                  </button>
                </Link>

                <div className="tw-border-[#4853E4] tw-h-8 tw-w-1 tw-border-2"></div>
                <div className="tw-flex tw-gap-x-2 tw-justify-center">
                  <Link to="/sign-up/borrower" tabIndex={-1}>
                    <button onClick={() => setUserType('borrower')} className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl">
                      <img src={BorrowerSignupIcon} className="tw-w-6 tw-h-6" alt="borrower" />
                      <p className="tw-text-md tw-text-black tw-font-oswald">Borrower Signup</p>
                    </button>
                  </Link>
                  <Link to="/sign-up/lender" tabIndex={-1}>
                    <button onClick={() => setUserType('lender')} className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl">
                      <img src={LenderSignupIcon} className="tw-w-6 tw-h-6" alt="lender" />
                      <p className="tw-text-md tw-text-black tw-font-oswald">Lender Signup</p>
                    </button>
                  </Link>
                </div>
              </div>
            ) : (
              <Link to={`/dashboard/${userType}`} tabIndex={-1}>
                  <button
                    // onClick={openSignupOptionsModal}
                    className='hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-px-3 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                  >
                    <img src={BorrowerSignupIcon} className='tw-w-7 tw-h-7' alt='dashboard' />
                    <p className='tw-text-md tw-text-black tw-font-oswald'>Dashboard</p>
                  </button>
                </Link>
            )}
          </div>
        )}
      </header>
      {loggedIn && (
        <div className="tw-flex tw-justify-center tw-font-oswald tw-text-[#4853e4] tw-cursor-pointer" onClick={goToDashboard}>
          <h2 className="tw-text-2xl">{`${signupRole.toLocaleUpperCase()} DASHBOARD`}</h2>
        </div>
      )}
      <SignupOptionsModal showSignupOptionsModal={showSignupOptionsModal} onClose={closeSignupOptionsModal} />
      <SignupModal role={signupRole} isOpen={showSignupFormModal} onClose={closeSignupFormModal} />
      <LoginModal isOpen={showSigninFormModal} onClose={closeSigninFormModal} />
      <ValidationModal isOpen={showValidationModal} onClose={closeValidationModal} />
    </>
  );
}

export default Header;
