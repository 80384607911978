import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Button } from '@material-tailwind/react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { capitalizeWords } from '../../utils/common';
import { signUp } from "aws-amplify/auth";
import axios from 'axios';
import toast from "react-hot-toast";
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useUserType } from '../../contexts/UserTypeContext';

function SignUp() {
    // const { userType } = useParams();
    const { userType } = useUserType();
    const navigate = useNavigate();
    const awsContextVal = useContext(AWSAmplifyConfigContext)
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Function to switch to the login form
    const handleLoginClick = () => {
        navigate(`/sign-in/${userType || 'borrower'}`);
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    // const handlePhoneChange = (value) => {
    //     setFormData({ ...formData, phone: value });
    // };
    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
        if (value && !isValidPhoneNumber(value)) {
            setErrors({ ...errors, phone: 'Invalid phone number for selected country' });
        } else {
            setErrors({ ...errors, phone: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        // Validate required fields
        if (!formData.firstName) {
            validationErrors.firstName = 'First Name is required';
        }
        if (!formData.lastName) {
            validationErrors.lastName = 'Last Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email Address is required';
        }
        if (!formData.phone) {
            validationErrors.phone = 'Phone Number is required';
        }
        if (!formData.password) {
            validationErrors.password = 'Password is required';
        }
        if (!formData.confirmPassword) {
            validationErrors.confirmPassword = 'Confirm Password is required';
        }
        if (formData.password !== formData.confirmPassword) {
            validationErrors.confirmPassword = 'Passwords do not match';
        }

        // If there are errors, set the errors state
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Clear any previous errors
        setErrors({});

        try {
            showLoader()
            const { userId } = await signUp({
                username: formData.email,
                password: formData.password,
                options: {
                    userAttributes: {
                        email: formData.email,
                        phone_number: formData.phone,
                        name: formData.firstName + " " + formData.lastName,
                        'custom:user_type': userType,
                        'custom:FirstName': formData.firstName,
                        'custom:LastName': formData.lastName
                    }
                }
            });

            if (userId) {

                const _applicationsResponse = await axios.post(`${awsContextVal?.endpoint}/create-user`,
                    {
                        email: formData.email,
                        phone_number: formData.phone,
                        name: formData.firstName + " " + formData.lastName,
                        'custom:user_type': userType,
                        'custom:FirstName': formData.firstName,
                        'custom:LastName': formData.lastName
                    }
                );
                hideLoader();
                // console.log("_applicationsResponse", _applicationsResponse);
                toast.success("User registered successfully! Please validate and log in.");
                navigate(`/otp-verify`);
            }
        } catch (err) {
            hideLoader();
            console.log(err);
            toast.error("Cannot sign up user.");
        }
    };

    const handleReset = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
        });
        setErrors({});
    };

    return (
        <>
            <div className="tw-basis-[60%] tw-bg-white tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-[827px] tw-mx-auto">
                    {/* <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block">
                        {capitalizeWords(userType)} Profile
                    </div> */}
                    <h2 className="tw-font-oswald tw-text-4xl tw-text-[#333333] tw-my-3 tw-font-bold">Create an account as a {capitalizeWords(userType)}</h2>
                    <p className="tw-mb-4 tw-text-[#666666] tw-text-xl">
                        Already have an account? <button onClick={handleLoginClick} className="tw-text-black tw-underline tw-text-[#666666]">Log in</button>
                    </p>
                    <form className="tw-space-y-5 tw-mt-10" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-w-1/2">
                                <label htmlFor="firstName" className="form-label ">
                                    First Name <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="firstName"
                                    type="text"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.firstName ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.firstName && <p className="tw-text-red-500 tw-text-[14px] ">{errors.firstName}</p>}
                            </div>
                            <div className="tw-w-1/2">
                                <label htmlFor="lastName" className="form-label">
                                    Last Name <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="lastName"
                                    type="text"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.lastName ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.lastName && <p className="tw-text-red-500 tw-text-[14px] ">{errors.lastName}</p>}
                            </div>
                        </div>

                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-w-1/2">
                                <label htmlFor="email" className="form-label">
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px] ">{errors.email}</p>}
                            </div>
                            <div className="tw-w-1/2">
                                <label htmlFor="phone" className="form-label">
                                    Phone Number <span className="text-red-500">*</span>
                                </label>

                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    id="phone"
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    defaultCountry="US"
                                    containerClass="tw-w-full"
                                    inputClass="tw-h-full tw-rounded-16"
                                    buttonClass="tw-bg-gray-200 tw-border tw-rounded-l-16"
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.phone ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.phone && <p className="tw-text-red-500 tw-text-[14px] ">{errors.phone}</p>}
                            </div>
                        </div>

                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-relative tw-w-1/2">
                                <label htmlFor="password" className="form-label">
                                    Password <span className="tw-text-red-500">*</span>
                                </label>

                                <Input
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.password ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.password && <p className="tw-text-red-500 tw-text-[14px] ">{errors.password}</p>}
                            </div>
                            <div className="tw-relative tw-w-1/2">
                                <label htmlFor="confirmPassword" className="form-label">
                                    Confirm Password <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.confirmPassword ? 'tw-border-red-500' : ''}`}
                                    icon={
                                        <button
                                            type="button"
                                            onClick={toggleConfirmPasswordVisibility}
                                            className="tw-absolute tw-right-2 tw-pt-4 tw-transform"
                                        >
                                            {
                                                showConfirmPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                            }
                                        </button>
                                    }
                                />
                                {errors.confirmPassword && <p className="tw-text-red-500 tw-text-[14px] ">{errors.confirmPassword}</p>}
                            </div>
                        </div>

                        <div className="tw-flex ">
                            <Button type="submit" color="green" className="tw-w-1/6 tw-bg-green-300 tw-border tw-text-[#2A2F70] tw-font-bold tw-py-4 tw-rounded-full tw-mt-4">
                                Sign Up
                                <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                            </Button>
                            <Button type="button" color="red" onClick={handleReset} className="tw-w-1/6 tw-bg-green-300 tw-border tw-text-[#2A2F70] tw-font-bold tw-py-4 tw-rounded-full tw-mt-4">
                                Reset
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SignUp;
