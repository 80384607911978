import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { Input, Button } from '@material-tailwind/react';
import { confirmSignUp } from "aws-amplify/auth";
import toast from "react-hot-toast";

import { useLoader } from '../../contexts/LoaderContext';
import { capitalizeWords } from '../../utils/common';
import { useUserType } from '../../contexts/UserTypeContext';

function OtpVerify() {
    const { userType } = useUserType();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();

    const [formData, setFormData] = useState({
        email: '',
        authenticationCode: '',    
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        if (!formData.email) {
            validationErrors.email = 'Email Address is required';
        }
        if (!formData.authenticationCode) {
            validationErrors.authenticationCode = 'Authentication Code is required';
        }
        // If there are errors, set the errors state
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        try {
            showLoader();
            const {isSignUpComplete} = await confirmSignUp({username: formData.email, confirmationCode: formData.authenticationCode});
            if (isSignUpComplete) {
                hideLoader();
                toast.success("OTP verified successfully.");
                navigate(`/sign-in/${userType || 'borrower'}`);
            }
        } catch (err) {
            hideLoader();
            console.log(err);
        }
    }

    return (
        <>
            <div className="tw-basis-[60%] tw-bg-white tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-[827px] tw-mx-auto">
                    <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block tw-text-sm">
                        {capitalizeWords(userType)} Profile
                    </div>
                    <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-3xl tw-text-[#333333] tw-my-4 tw-font-medium tw-text-center">Confirm OTP</h2>
                    <form className="tw-space-y-10 tw-mt-9" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-space-x-4">
                            <div className="tw-w-1/2">
                                <label htmlFor="email" className="form-label">
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px]">{errors.email}</p>}
                            </div>
                            <div className="tw-w-1/2">
                                <label htmlFor="email" className="form-label">
                                    Authentication code <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="authenticationCode"
                                    type="text"
                                    value={formData.authenticationCode}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[405px] tw-rounded-16 tw-text-black tw-font-oswald ${errors?.authenticationCode ? 'tw-border-red-500' : ''}`}
                                />
                                {errors?.authenticationCode && <p className="tw-text-red-500 tw-text-[14px]">{errors?.authenticationCode}</p>}
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                            <button className="tw-w-1/6 tw-bg-green-300 tw-border tw-text-[#2A2F70] tw-font-bold tw-py-4 tw-rounded-full tw-mt-4">
                                Confirm
                                <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                            </button>

                            <p className="tw-mb-4 tw-text-black tw-text-lg">
                                <button onClick={() => navigate(`/sign-up/borrower`)} className="tw-font-oswald tw-text-[#2A2F70]">Back to Sign-up</button>
                            </p>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default OtpVerify