import React, { useState, useRef, useEffect } from "react";
import BorrowerSignupIcon from "../../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../../assets/icons/lenderIcon.svg";
import { Link } from "react-router-dom";
import VideoWMusic from "../../../assets/videos/VideoWMusic.mp4";
import "../../../styles/HomeInfoArea.css";

const HomeInfoArea = ({ isUserLoggedIn, onReady }) => {
  const videoRef = useRef(null);
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const handleVideoLoaded = () => {
    setTimeout(() => {
      setIsVideoLoading(false);
      if (onReady) onReady(); // Notify Home component that the video is ready
    }, 1000); // Optional delay for smoother transition
  };

  const handleVideoError = () => {
    setIsVideoLoading(false);
    if (onReady) onReady(); // Notify Home even if there's an error
    console.error("Error loading video.");
  };

  return (
    <div className="tw-flex 2xl:tw-flex-row tw-flex-col tw-items-center tw-justify-center lg:tw-space-x-8">
      {/* Left Text Container */}
      <div className="tw-flex tw-flex-col tw-space-y-14 tw-w-full 2xl:tw-w-1/2">
        <div className="tw-flex tw-flex-col">
          <h2
            style={{ lineHeight: "1.2" }}
            className="fade-in-title tw-font-oswald tw-leading-10 tw-text-center lg:tw-text-start tw-text-6xl lg:tw-text-7xl tw-text-black tw-my-4 tw-font-bold"
          >
            Building Relationships
            <br /> In{" "}
            <span className="tw-text-[#4853e4]"> CRE Financing </span>
          </h2>
          <p
            style={{ lineHeight: "1.5", fontSize: 25 }}
            className="typing-paragraph-1 typing-effect tw-font-inter tw-text-[#404040] tw-text-lg lg:pr-[20px] tw-text-center lg:tw-text-start"
          >
            Find the right commercial real estate financing tailored to your
            needs—without broker commissions or endless searching. Our platform
            simplifies the process with real-time data and access to a large
            nationwide lender network, making it easy to apply, connect with
            lenders, and secure funding quickly.
          </p>
        </div>

        <div className="tw-flex tw-gap-x-4">
          {!isUserLoggedIn ? (
            <>
              <Link to={"/sign-up/borrower"} tabIndex={-1}>
                <button
                  className="tw-hidden xl:tw-flex hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl"
                >
                  <img
                    src={BorrowerSignupIcon}
                    className="tw-w-10 tw-h-10"
                    alt="borrower"
                  />
                  <p className="tw-text-md tw-text-black tw-font-oswald">
                    Borrower Signup
                  </p>
                </button>
              </Link>
              <Link to={"/sign-up/lender"} tabIndex={-1}>
                <button
                  className="tw-hidden xl:tw-flex hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl"
                >
                  <img
                    src={LenderSignupIcon}
                    className="tw-w-10 tw-h-10"
                    alt="lender"
                  />
                  <p className="tw-text-md tw-text-black tw-font-oswald">
                    Lender Signup
                  </p>
                </button>
              </Link>
            </>
          ) : null}
        </div>
      </div>

      <div className="tw-relative 2xl:tw-w-1/2 tw-flex lg:tw-justify-center tw-h-full tw-my-10 lg:tw-my-0">
        <video
          ref={videoRef}
          className="tw-rounded-lg tw-w-full tw-h-auto tw-object-cover"
          src={VideoWMusic}
          muted
          playsInline
          loop
          autoPlay
          onLoadedData={handleVideoLoaded}
          onError={handleVideoError}
        />
      </div>
    </div>
  );
};

export default HomeInfoArea;
