import { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Sofr30Data from "./jsonData/sofr30.json"

const formatTooltip = (num) => {
  return `${num.toFixed(4)}%`;
};

export default function Sofr30AvgChart() {
  return (
    <ResponsiveContainer width="100%" height={267}>
      <AreaChart
        width={500}
        height={267}
        data={Sofr30Data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          interval={240}
          tickMargin={12}

          // angle={-25}
          tick={{ fontSize: "12px" }} // Smaller text for better fit
          tickFormatter={(tick) =>
            new Date(tick).toLocaleDateString("en-US", { month: "short", year: "numeric" })
          }
        />
        <YAxis
          tick={{ fontSize: "12px" }} // Smaller text for better fit
          domain={[0, 6]}
          tickFormatter={(tick) => {
            return `${tick}`;
          }}
          label={{
            value: "Percent", // Label text
            angle: -90, // Rotates it vertically
            position: "insideLeft", // Positions it inside the chart
            style: { textAnchor: "middle", fontSize: "12px", fill: "#666" }, // Style adjustments
          }}
        />
        <Tooltip
          labelFormatter={(label) =>
            new Date(label).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
          }
          formatter={(value) => {
            return [formatTooltip(value)];
          }}

        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#555FE1"
          strokeWidth={4}
          fill="blue"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}