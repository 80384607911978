import React, { useContext, useEffect, useState } from 'react';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
    Button,
    List,
    ListItem
} from "@material-tailwind/react";
import toast from 'react-hot-toast';
import axios from 'axios';
import { UserContext } from '../../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../../contexts/AWSAmplifyConfigContext';
import DatePicker from "react-datepicker";

function ApplicationOfferLogsHeader({ title, handleSortChange, setFilters }) {
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    console.log("startDate:::", startDate)
    const handleCloseDropdown = () => setIsOpen(false);
    const toggleDropdown = () => setIsOpen(!isOpen);


    const formatToUSDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
    };

    const updateFilters = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            start_date: formatToUSDate(startDate),
            end_date: formatToUSDate(endDate),
        }));
    };

    // Update filters when startDate or endDate changes
    useEffect(() => {
        updateFilters();
    }, [startDate, endDate]);
    
    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            {title && title !== '' && (
                <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'>
                    {title}
                </Typography>
            )}

            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>

                    {/* Sort Dropdown */}
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                        <Popover
                            placement="bottom"
                            open={isOpen}
                            handler={toggleDropdown}
                        >
                            <PopoverHandler>
                                <Button className="tw-text-[#4F4F4F]">
                                    <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[3px]"></i>
                                    <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[10px]"></i>
                                    Sort
                                </Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[7.3%] tw-mt-[2.5px] tw-border tw-border-[#DDDDDD] tw-shadow-lg">
                                <List className="p-0 tw-bg-white">
                                    <ListItem
                                        className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-border-b-[1px]"
                                        onClick={() => {
                                            handleSortChange('oldFirst');
                                            handleCloseDropdown();
                                        }}
                                    >
                                        <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                        Old First
                                    </ListItem>
                                    <ListItem
                                        className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[10px] tw-flex tw-justify-center tw-items-center tw-border-b-[1px]"
                                        onClick={() => {
                                            handleSortChange('newFirst');
                                            handleCloseDropdown();
                                        }}
                                    >
                                        <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                        New First
                                    </ListItem>
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>


                    <div className="tw-flex tw-gap-4">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="tw-border tw-rounded-md tw-px-2 tw-text-black"
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="tw-border tw-rounded-md tw-px-2 tw-text-black"
                />
            </div>
                </div>
            </div>
         
        </div>
    );
}

export default ApplicationOfferLogsHeader;
