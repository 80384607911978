import React, { useContext, useEffect, useRef, useState } from 'react'
import ChatProfile from "../../../assets/icons/Chat_profile-img.png";
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import { useLoader } from '../../../contexts/LoaderContext';
import OfferDetailModal from './OfferDetailModal';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import ChatFooter from './ChatFooter';

function ChatWindow({ chatUserName, chatMessages, fetchChatMessages, showTypingIndicator, setShowTypingIndicator }) {
    const { user } = useContext(UserContext);
    const [message, setMessage] = useState("");
    const [offerDetails, setOfferDetails] = useState(null); // State to store offer details
    const [isModalOpen, setIsModalOpen] = useState(false);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    
    // State to track loading or error
    const [isSending, setIsSending] = useState(false);
    const { showLoader, hideLoader } = useLoader();
    const chatContainerRef = useRef(null);
    // API call to send the message
    const sendMessage = async () => {
        setShowTypingIndicator(true); // Show typing indicator
        try {
            // Check if message is not empty
            if (message.trim() === "") {
                return;
            }

            setIsSending(true);

            // Simulate typing delay before sending the message
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay

            const response = await axios.post(`${awsContextVal?.endpoint}/message/send`, {
                conversation_id: chatMessages[0]?.conversation_id,
                sender_id: user?.id,
                message_text: message
            });

            if (response.status === 200) {
                fetchChatMessages(chatMessages[0]?.conversation_id);
                setMessage("");
            } else {
                toast.error('Failed to send message');
            }
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsSending(false);
            setShowTypingIndicator(false); // Hide typing indicator after message is sent
        }
    };

    // Function to scroll to the bottom of the chat container
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const handleViewOfferDetails = async(offerId) => {
        showLoader();
        try {
            const response = await axios.get(
                `${awsContextVal?.endpoint}/application-offer/get/${offerId}`
            );
            if (response?.status === 200) {
                setOfferDetails(response?.data);
                setIsModalOpen(true);
            } else {
                toast.error('Something went wrong.');
            }
        } catch (err) {
            toast.error(err?.response?.data?.error);
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages, showTypingIndicator]);
    
    console.log("offerDetails",offerDetails);

    return (
        <>
            <div className='tw-chat-window '>
                {/* Chat header for chat window */}
                <ChatHeader chatUserName={chatUserName}/>
                <div className='tw-chat-section tw-bg-[#FAFAFA] tw-rounded-b-[10px]'>
                    <div className='tw-chat-container tw-h-[707px] tw-rounded-[10px]'>
                        <ChatList 
                            chatMessages={chatMessages} 
                            handleViewOfferDetails={handleViewOfferDetails}
                            showTypingIndicator={showTypingIndicator}    
                        />
                        <ChatFooter
                            sendMessage={sendMessage}
                            message={message}
                            setMessage={setMessage}
                            isSending={isSending}
                            setShowTypingIndicator={setShowTypingIndicator}
                        />
                    </div>
                </div>
            </div>
            <OfferDetailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                details={offerDetails}
            />
        </>
    )
}

export default ChatWindow