import React from 'react';

function GeneralSettings({ handleFieldChange, storedData }) {
    // Function to get the value of a specific setting by name
    const getSettingValue = (name) => {
        const setting = storedData.find(item => item.name === name);
        return setting ? setting.value : '';
    };

    return (
        <div className="tw-h-full tw-w-full">
            <form className="tw-space-y-5 tw-p-5">
                <div className="tw-flex tw-justify-between tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-w-[50%] tw-mb-4">
                        <label className="tw-font-semibold tw-text-[#333333] tw-mb-1 common-font">
                            Site Name
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Site Name"
                            value={getSettingValue('site_name')} // Set input value from stored data
                            onChange={(e) => handleFieldChange('site_name', e.target.value)}
                            className="tw-h-[40px] tw-border-[1px] tw-border-[#AAAAAA] tw-rounded-[5px] tw-bg-white tw-px-2 tw-text-[#333333] common-font"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default GeneralSettings;
