import React, { createContext, useState, useContext } from 'react';
import { authLocal, initialUser, saveUserToLocalStorage, removeUserFromLocalStorage, savedUserType, saveUserTypeToLocalStorage, removeUserTypeToLocalStorage } from '../utils/common';

// Create a context
export const UserContext = createContext();

// Create a provider component
export const UserDataProvider = ({ children }) => {
  const [user, setUser] = useState(authLocal.get());
  const [currentOfferPropertyDetails, setCurrentOfferPropertyDetails] = useState(null);
  // const [userType, setUserType] = useState('borrower');
  const [userType, setUserType] = useState(savedUserType);
  const [isOfferSend, setIsOfferSend] = useState(false);
  const [signUpUser, setSignUpUser] = useState(initialUser);


  const updateUserType = (newUserType) => {
    setUserType(newUserType);
    saveUserTypeToLocalStorage(newUserType);
  };

  // Function to update user data and store it in localStorage
  const updateUser = (userData) => {
    setUser(userData);
    localStorage.setItem('user_auth', JSON.stringify(userData));
  };

  // function to store sign up form data of lender only to signup after subscription 
  const storeLenderFormData = (userData) => {
    setSignUpUser(userData);
    saveUserToLocalStorage(userData)
  }

  // Function to reset the context
  const resetUser = () => {
    setUser(null); // Reset the data to null or initial state
    removeUserFromLocalStorage();
    removeUserTypeToLocalStorage()
  };

  const updateMembership = (data) => {
    setUser(prevUser => {
      const updatedUser = {
        ...prevUser,
        stripe_subscription_id: data?.stripe_subscription_id,
        subscription_plan_id: data?.subscription_plan_id,
        available_offer: data?.available_offer,
        membership_start: data?.membership_start,
        membership_end: data?.membership_end,
        plan: data?.plan ? data?.plan : prevUser?.plan || {},
      };
      localStorage.setItem('user_auth', JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  return (
    <UserContext.Provider value={{ user, setUser, resetUser, currentOfferPropertyDetails, setCurrentOfferPropertyDetails, isOfferSend, setIsOfferSend, userType, setUserType: updateUserType, signUpUser, updateUser, storeLenderFormData, updateMembership }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access user data
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserDataProvider');
  }
  return context?.user ?? ""; // Return boolean value indicating if user is authenticated
};
