import toast from 'react-hot-toast';

export const fetchGraphData = async (url, payload, postRequest) => {
    try {
        const response = await postRequest(url, payload);
        if(response?.success) {
            return response?.data;
        } else {
            return {};
        }
    } catch (error) {
        console.error("API Error:", error);
        toast.error('An error occurred while fetching graph data.');
        throw error;
    }
};

export const fetchSettings = async (url, payload, postRequest) => {
    try {
        const response = await postRequest(url, payload);
        let setting = {};
        if(response?.success){
            response?.data?.settings.forEach(item => {
                setting[item.name] = item?.value
            });
        } else {
            return {};
        }
        return setting;
    } catch (error) {
        console.error("API Error:", error);
        toast.error('An error occurred while fetching data.');
        throw error;
    }
};
