import React, { useContext } from 'react'
import { Modal, Box, Typography, Button, Input, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { UserContext } from '../../../contexts/UserContext';

function OfferDetailModal({ isOpen, onClose, details, offerAcceptStatusHandler, offerRejectStatusHandler }) {
    const { user } = useContext(UserContext);
    if (!isOpen || !details?.application_offer) return null;
    const offer = details.application_offer;
    console.log("offer >>> ", offer);
    const formattedFees = `$${offer.fees.toLocaleString()}`;
    const formattedInterest = `${offer.interest_on_loan}%`;

    return (
        <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title">
            <Box className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-lg tw-mx-auto" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                width: {
                    xs: '90%', // 90% width on small screens
                    md: '40%', // 500px width on medium and large screens
                    lg: '30%',
                },
            }}>
                {/* Modal Header */}
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <Typography id="modal-title" className="tw-modal-title tw-text-2xl tw-font-semibold">
                        Offer Details
                    </Typography>
                    <Button onClick={onClose} className="tw-text-gray-500">X</Button>
                </div>

                {/* Percent Interest Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Percent Interest on Loan</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <Input
                            type="text"
                            value={formattedInterest}
                            disabled
                            className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px] interestOnLoan"
                            name='interest_on_loan'
                        />
                        <FormControl className="tw-w-1/2">
                            <Select value={offer.interest_type} disabled className="tw-text-sm tw-h-[40px] interest_on_loan_dropdown">
                                <MenuItem value="day">Per Day</MenuItem>
                                <MenuItem value="week">Per Week</MenuItem>
                                <MenuItem value="month">Per Month</MenuItem>
                                <MenuItem value="year">Per Year</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Origination Fee Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Origination Fee</InputLabel>
                    <Input
                        type="text"
                        value={formattedFees}
                        disabled
                        className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px] origination_fee"
                        name='origination_fee'
                    />
                </div>

                {/* Duration Field */}
                <div className="tw-mb-4">
                    <InputLabel className="tw-font-semibold tw-mb-1">Loan Duration</InputLabel>
                    <div className="tw-flex tw-gap-2">
                        <Input
                            type="number"
                            value={offer.loan_duration}
                            disabled
                            className="tw-w-full tw-h-[38px] tw-text-[#202224] tw-border-[#FFFFFF] tw-border-[#DDDDDD] tw-rounded-[7px] tw-px-[10px] loan_duration"
                            name='loan_duration'
                        />
                        <FormControl className="tw-w-1/2">
                            <Select value={offer.loan_duration_type} disabled className="tw-text-sm tw-h-[40px] loan_duration_dropdown">
                                <MenuItem value="weekly">Weeks</MenuItem>
                                <MenuItem value="monthly">Months</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Action Buttons */}
                {user?.user_type === 'borrower' ? (
                    [1, 2, 3].includes(offer?.property_status) && offer?.status_id === 2 ? ( 
                        <div className="tw-flex tw-justify-between tw-gap-4 tw-mt-6">
                            <Button
                                variant="contained"
                                color="success"
                                className="tw-bg-green-500 tw-text-white offer_accept_btn"
                                onClick={() => offerAcceptStatusHandler(offer?.id, 'borrower')}
                            >
                                Accept
                            </Button>
                            <Button
                                variant="outlined"
                                className="offer_reject_btn"
                                sx={{
                                    color: 'red',
                                    borderColor: 'red',
                                    '&:hover': {
                                        borderColor: 'darkred',
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    },
                                }}
                                onClick={() => offerRejectStatusHandler(offer?.id, 'borrower')}
                            >
                                Reject
                            </Button>
                        </div>
                    ) : (
                        <div className="tw-mt-6 tw-text-center tw-text-gray-600">
                            {offer?.status_id === 4 || offer?.status_id === 5 ? (
                                <p className="tw-text-green-600">This offer has been accepted.</p>
                            ) : offer?.status_id === 3 ? (
                                <p className="tw-text-red-600">This offer has been rejected.</p>
                            ) : (
                                <p>This offer cannot be acted upon at this time.</p>
                            )}
                        </div>
                    )
                ) : (
                    // Show message for lender user types
                    <div className="tw-mt-6 tw-text-center tw-text-gray-600">
                        {user?.user_type == 'lender' && (offer?.status_id == 4 && [1, 2, 3].includes(offer?.property_status)) ? (
                            <div className="tw-flex tw-justify-between tw-gap-4 tw-mt-6">
                                <Button
                                    variant="contained"
                                    color="success"
                                    className="tw-bg-green-500 tw-text-white"
                                    onClick={() => offerAcceptStatusHandler(offer?.id, 'lender')}
                                >
                                    Accept
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: 'red',
                                        borderColor: 'red',
                                        '&:hover': {
                                            borderColor: 'darkred',
                                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                        },
                                    }}
                                    onClick={() => offerRejectStatusHandler(offer?.id, 'lender')}
                                >
                                    Reject
                                </Button>
                            </div>
                        ) :(
                            <p>This offer cannot be acted upon at this time.</p>
                        )}
                    </div>
                )}
            </Box>
        </Modal>
    )
}

export default OfferDetailModal
