import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext';

function ChatHeader({ chatUserName }) {
    const { currentOfferPropertyDetails } = useContext(UserContext);
    const [delayedPropertyDetails, setDelayedPropertyDetails] = useState(currentOfferPropertyDetails);
    const [isLoading, setIsLoading] = useState(true);

    // Introduce a delay when switching applications
    useEffect(() => {
        setIsLoading(true);

        const delayTimeout = setTimeout(() => {
            if (currentOfferPropertyDetails && Object.keys(currentOfferPropertyDetails).length > 0) {
                setDelayedPropertyDetails(currentOfferPropertyDetails);
            }
            setIsLoading(false);
        }, 200); // 300ms delay to prevent flicker

        return () => clearTimeout(delayTimeout); // Clear timeout if component unmounts
    }, [currentOfferPropertyDetails]);

    return (

        <div 
            style={{ position: "sticky", top: "0px", zIndex: 11, background: "#fff", minHeight: "80px" }} // Ensures height remains constant
            className='tw-flex tw-justify-between tw-items-center tw-px-[20px] tw-pt-[20px]'
        >
            <div className='common-font'>
                <div>
                    {/* Title Section */}
                    <div className='tw-font-extrabold tw-text-[25px] tw-text-black tw-capitalize' 
                         style={{ minHeight: "30px", display: "flex", alignItems: "center" }} // Maintains consistent height
                    >
                        <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
                            {delayedPropertyDetails?.streetAddress || "No Address Available"}
                        </span>
                    </div>

                    {/* Subtitle Section */}
                    <div className='tw-font-normal tw-text-[15px] tw-text-gray-600 tw-flex tw-gap-[10px]' 
                         style={{ minHeight: "24px", display: "flex", alignItems: "center" }} // Maintains spacing
                    >
                        {/* Icon */}
                        <div className='tw-w-[24px] tw-h-[24px]' style={{ visibility: isLoading ? "hidden" : "visible" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" 
                                    strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" 
                                    d="M17.8 20.2h-12c-1.7 0-3-1.3-3-3v-8c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v8c0 1.6-1.4 3-3 3z" clipRule="evenodd">
                                </path>
                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" 
                                    strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" 
                                    d="M7.7 13.9v-3m8.1 3v-3m-13 1.3h18m-7.6-8.4h-3c-.6 0-1 .4-1 1v1.4h5V4.8c0-.6-.4-1-1-1z">
                                </path>
                            </svg>
                        </div>

                        {/* Property Type / Loan Purpose */}
                        <span className='tw-capitalize' style={{ visibility: isLoading ? "hidden" : "visible" }}>
                            {delayedPropertyDetails?.propertyType || delayedPropertyDetails?.loan_purpose || "N/A"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatHeader;
