import React from "react";
import LogoImage from "../../../src/assets/icons/Logo.png";
import { useNavigate, Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Vector1 from "../../../src/assets/icons/big_lines.png";
import Vector2 from "../../../src/assets/icons/small_lines.png";
import { useEffect } from "react";
import CmsHeader from "../layouts/base/CmsHeader";

const CookiesPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();

  const policyinfo = [
    {
      title: "Introduction",
      pg: `This Cookies Policy explains how Re-Loan Corp ("we", "our", or "us") uses cookies and similar technologies to enhance your experience on our website ("Website"). By using our Website, you agree to the use of cookies as described in this policy.`,
    },
    {
      title: "What Are Cookies?",
      pg: `Cookies are small text files that are stored on your device when you visit a website. They help the website remember your preferences and activity, enabling a more personalized and efficient browsing experience.`,
    },
    {
      title: "Types of Cookies We Use",
      pg: `We use the following types of cookies on our Website:
      \n\n
      Essential Cookies: These cookies are necessary for the website to function and cannot be turned off in our systems. They are usually set in response to your actions, such as logging in or filling out forms.
      \n\n
      Performance Cookies: These cookies collect information about how you use our Website, such as which pages you visit most often. This helps us improve the performance and usability of our Website.
      \n\n
      Functional Cookies: These cookies enable the Website to provide enhanced functionality and personalization, such as remembering your preferences or login details.
      \n\n
      Targeting Cookies: These cookies track your browsing habits to deliver personalized advertisements that are relevant to your interests.`,
    },
    {
      title: "How We Use Cookies",
      pg: `We use cookies to enhance your browsing experience, analyze website performance, and deliver tailored content. Cookies help us understand user behavior and improve our services for all visitors.`,
    },
    {
      title: "Managing Cookies",
      pg: `You have the option to manage or disable cookies through your browser settings. Please note that disabling certain cookies may affect the functionality of our Website.`,
    },
    {
      title: "Changes to This Cookies Policy",
      pg: `We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with a revised effective date.`,
    },
    {
      title: "Contact Us",
      pg: (
        <span>
          If you have any questions about this Cookies Policy or our use of
          cookies, please{" "}
          <a
            target="__blank"
            href="/contact"
            className="tw-text-light-blue-500 tw-font-semibold tw-underline"
          >
            contact us
          </a>
          .
        </span>
      ),
    },
  ];

  return (
    <>
    <div className="xl:tw-hidden tw-block">
      <CmsHeader />
    </div>
    <div className="tw-flex tw-w-full tw-relative tw-justify-center">
      {/* Sidebar */}
      <div className="tw-w-1/6 tw-bg-[#3748E8] tw-h-full tw-flex-col tw-justify-center tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-10 tw-hidden xl:tw-flex">
          <div className="tw-absolute tw-top-4 tw-left-4">
            <img
              src={LogoImage}
              alt="Re-loan logo"
              className="tw-w-40 tw-cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>

          {/* Vector Decorations */}
          <img
            src={Vector2}
            alt="vector2"
            className="tw-absolute tw-bottom-0 tw-left-0 tw-opacity-50 tw-h-1/3"
          />
          <img
            src={Vector1}
            alt="vector1"
            className="tw-absolute tw-top-20 tw-left-0 tw-opacity-50 tw-h-2/3"
          />
        </div>

      {/* Main Content */}
      <div className="tw-w-5/6 tw-bg-white tw-h-full tw-justify-center tw-text-2xl tw-overflow-y-auto tw-scrollbar xl:tw-ml-[22%] tw-pt-12">
        <div className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-4 tw-text-reloan tw-relative tw-flex">
          <a href="/">
            <ChevronLeftIcon fontSize={"12px"} className="tw-text-gray-800" />
          </a>
          Our Cookies Policy
        </div>
        <div className="tw-text-gray-600 tw-flex tw-flex-col tw-gap-6 lg:tw-p-6">
          {policyinfo.map((p, index) => (
            <div key={index} className="tw-flex tw-flex-col tw-gap-2">
              <h2 className="tw-text-2xl tw-text-black tw-font-semibold">
                {p.title}
              </h2>
              <p>{p.pg}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default CookiesPolicy;
