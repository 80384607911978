import React, { createContext, useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

export const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
    const [autocomplete, setAutocomplete] = useState(null);
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY; // Replace with your actual API key

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries: ["places"]
    });

    useEffect(() => {
        if (isLoaded && window.google && document.getElementById('address')) {
            const input = document.getElementById('address');
            const options = { types: ["address"] };
            const autocompleteInstance = new window.google.maps.places.Autocomplete(input, options);
            setAutocomplete(autocompleteInstance);

            const handlePlaceChanged = () => {
                const place = autocompleteInstance.getPlace();
                if (place && place.formatted_address) {
                    console.log(place.formatted_address);
                }
            };

            const listener = autocompleteInstance.addListener('place_changed', handlePlaceChanged);

            // Cleanup listener safely
            return () => {
                if (listener && window.google.maps && window.google.maps.event) {
                    window.google.maps.event.removeListener(listener);
                }
            };
        }
    }, [isLoaded]);

    return (
        <GoogleMapsContext.Provider value={{ autocomplete, isLoaded }}>
            {isLoaded ? children : <div>Loading Google Maps...</div>}
        </GoogleMapsContext.Provider>
    );
};
