// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Fade-in from left */
.fade-in {
    opacity: 0;
    transition: opacity 1.5s ease-out; /* Adjust duration */    
    transition-delay: .5s; /* Delay fade-in by 1 second */
}

.fade-in.active {
    opacity: 1;
}

.slide-in-right-txt {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity .75s ease-out, transform .75s ease-out;
    transition-delay: .5s;
}

.slide-in-right-txt.active {
    opacity: 1;
    transform: translateX(0);
}

.slide-in-left-txt {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity .75s ease-out, transform .75s ease-out;
    transition-delay: .5s;
}

.slide-in-left-txt.active {
    opacity: 1;
    transform: translateX(0);
}


/* Slide-in from right */
.slide-in-right {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity .75s ease-out, transform .75s ease-out;
}

.slide-in-right.active {
    opacity: 1;
    transform: translateX(0);
}

.slide-in-left {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity .75s ease-out, transform .75s ease-out;
}

.slide-in-left.active {
    opacity: 1;
    transform: translateX(0);
}


`, "",{"version":3,"sources":["webpack://./src/styles/ProductArea.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,UAAU;IACV,iCAAiC,EAAE,oBAAoB;IACvD,qBAAqB,EAAE,8BAA8B;AACzD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,2BAA2B;IAC3B,0DAA0D;IAC1D,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,4BAA4B;IAC5B,0DAA0D;IAC1D,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B;;;AAGA,wBAAwB;AACxB;IACI,UAAU;IACV,2BAA2B;IAC3B,0DAA0D;AAC9D;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,4BAA4B;IAC5B,0DAA0D;AAC9D;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":["/* Fade-in from left */\n.fade-in {\n    opacity: 0;\n    transition: opacity 1.5s ease-out; /* Adjust duration */    \n    transition-delay: .5s; /* Delay fade-in by 1 second */\n}\n\n.fade-in.active {\n    opacity: 1;\n}\n\n.slide-in-right-txt {\n    opacity: 0;\n    transform: translateX(50px);\n    transition: opacity .75s ease-out, transform .75s ease-out;\n    transition-delay: .5s;\n}\n\n.slide-in-right-txt.active {\n    opacity: 1;\n    transform: translateX(0);\n}\n\n.slide-in-left-txt {\n    opacity: 0;\n    transform: translateX(-50px);\n    transition: opacity .75s ease-out, transform .75s ease-out;\n    transition-delay: .5s;\n}\n\n.slide-in-left-txt.active {\n    opacity: 1;\n    transform: translateX(0);\n}\n\n\n/* Slide-in from right */\n.slide-in-right {\n    opacity: 0;\n    transform: translateX(50px);\n    transition: opacity .75s ease-out, transform .75s ease-out;\n}\n\n.slide-in-right.active {\n    opacity: 1;\n    transform: translateX(0);\n}\n\n.slide-in-left {\n    opacity: 0;\n    transform: translateX(-50px);\n    transition: opacity .75s ease-out, transform .75s ease-out;\n}\n\n.slide-in-left.active {\n    opacity: 1;\n    transform: translateX(0);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
