import { useState, useContext, useEffect, useRef } from "react";

import EditIcon from "../../assets/icons/editIcon.svg";
import ChangePersonalInformationForm from "./ChangePersonalInfoForm";
import ChangePasswordForm from "./ChangePasswordForm";
import AddressForm from "../common/address-form/AddressForm";
import { UserContext } from "../../contexts/UserContext";
import { useModalContext } from "../../contexts/ModalContexts";
import toast from "react-hot-toast";
import { useLoader } from "../../contexts/LoaderContext";
import { useNavigate, Link } from "react-router-dom";
import { uploadData, getUrl, remove } from "aws-amplify/storage";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import UserProfile from "../../assets/images/user_profile_img.jpg";
import { useApiService } from "../../services/apiService";
import PfpCropModal from "../modals/PfpCropModal";
import PageLoader, {usePageLoader} from "../../contexts/PageLoaderContext";

const LenderSettings = () => {
  //Crop Modal States
  const [showPfpModal, setPfpModal] = useState(false);
  const [pfpFile, setPfpFile] = useState()


  const { showLoader, hideLoader } = useLoader();
  const { user, updateUser } = useContext(UserContext);
  const { showModal } = useModalContext();
  const { postRequest } = useApiService();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    stateId: "",
    cityId: "",
    countryId: 233,
    zip: "",
  });

  const [hasChanges, setHasChanges] = useState(false); // Track changes
  const [disabledForm, setDisableForm] = useState(true);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const { showPageLoader, hidePageLoader } = usePageLoader();
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    const initializePage = async () => {
        showPageLoader(); // Show the loader
        try {
            await fetchUserDetailByEmail(); // Fetch user details
            // Add other data fetches here if needed
        } catch (error) {
            toast.error("Failed to initialize the page.");
        } finally {
            setIsPageReady(true); // Set page ready
            hidePageLoader(); // Hide the loader
        }
    };

    initializePage();
}, []);

  const handleConfirmOpen = () => {
    setOpenConfirm(true);
  };
  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const removeProfileImageFromS3 = async (profileImageUrl) => {
    if (!profileImageUrl) {
      throw new Error("No profile image to remove.");
    }
    const url = new URL(profileImageUrl);
    const s3Key = url.pathname.slice(1); // Extract S3 key from URL

    await remove({
      path: s3Key,
      options: { level: "public" },
    });
  };

  // Helper function to update the user data on the server
  const updateUserDataOnServer = async (userData) => {
    const response = await postRequest(
      `/user/update`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response?.success) {
      throw new Error("Failed to update user data on the server.");
    }

    return response.data;
  };

  // Main function for handling profile image deletion
  const handleDeleteConfirmation = async () => {
    showLoader();
    try {
      // Remove profile image from S3
      await removeProfileImageFromS3(profileImageUrl);

      // Prepare updated user data
      const updatedUserData = {
        id: user?.id,
        user_type: user?.user_type,
        email: userInfo?.email,
        phone_number: userInfo?.phoneNumber,
        first_name: userInfo?.firstName,
        last_name: userInfo?.lastName,
        dob: userInfo?.dateOfBirth,
        address_1: userInfo?.addressLine1,
        address_2: userInfo?.addressLine2,
        city_id: userInfo?.cityId,
        state_id: userInfo?.stateId,
        country_id: userInfo?.countryId,
        postal_code: userInfo?.zip,
        status: "1",
        profile_image_url: "",
      };

      // Update user data on the server
      await updateUserDataOnServer(updatedUserData);

      // Update local user state and fetch latest details
      updateUser({ ...user, ...updatedUserData });
      fetchUserDetailByEmail();

      toast.success("Profile image removed successfully.");
    } catch (error) {
      console.error(
        "Error in handleDeleteConfirmation:",
        error.message || error
      );
      toast.error(error.message || "Failed to remove profile image.");
    } finally {
      handleConfirmClose();
      hideLoader();
    }
  };

  const handleChange = (updatedInfo) => {
    // This function will be called when data changes
    setHasChanges(true);
  };

    const handleSubmit = async () => {
        showLoader();
        const response = await postRequest(`/user/update`, {
            id: user?.id,
            user_type: user?.user_type,
            email: userInfo?.email,
            phone_number: userInfo?.phoneNumber,
            first_name: userInfo?.firstName,
            last_name: userInfo?.lastName,
            dob: userInfo?.dateOfBirth,
            address_1: userInfo?.addressLine1,
            address_2: userInfo?.addressLine2,
            city_id: userInfo?.cityId,
            state_id: userInfo?.stateId,
            country_id: userInfo?.countryId,
            postal_code: userInfo?.zip,
            status: '1',
            profile_image_url: profileImageUrl
        });
        if (response?.success) {
            updateUser({
                ...user,
                ...response, // Merge the current user data with the updated data
                profile_image_url: profileImageUrl // Ensure to update the profile image URL specifically
            });
            showModal("User Data Has Been Updated!", "success");
        } else if (response.status === 500) {
            showModal("Something Went Wrong.", "success");
        }
        hideLoader();
        setDisableForm(true);
        // You can now use formData to send to your API or handle as needed
        setHasChanges(false); // Reset changes after submitting
    };
    const fetchUserDetailByEmail = async () => {
        showLoader();
        try {
            const response = await postRequest(`/fetch-user`, { email: user.email });
            if (response?.success) {
                hideLoader();
                let userDetails = response?.data?.user;
                setUserInfo({
                    firstName: userDetails?.first_name,
                    lastName: userDetails?.last_name,
                    dateOfBirth: userDetails?.dob,
                    email: userDetails?.email,
                    phoneNumber: userDetails?.phone_number,
                    addressLine1: userDetails?.address_1,
                    addressLine2: userDetails?.address_2,
                    stateId: userDetails?.state_id,
                    cityId: userDetails?.city_id,
                    countryId: 233,
                    zip: userDetails?.postal_code,
                    profile_image_url: userDetails?.profile_image_url
                });
                if (userDetails?.profile_image_url) {
                    setProfileImageUrl(userDetails?.profile_image_url);
                } else {
                    setProfileImageUrl(UserProfile);
                }
            } else {
                hideLoader();
                toast.error("Something Went Wrong.");
                console.log("oh no");
            }

        } catch (error) {
            if (error.response) {
                // Handle 404 or other server errors
                console.error("Server error:", error.response.status, error.response.data);
                toast.error(error.response.data?.message || "Failed to fetch user details. Please try again.");
            } else if (error.request) {
                // Network error
                console.error("Network error:", error.request);
                toast.error("Network error: Unable to reach the server.");
            } else {
                // Other errors
                console.error("Error:", error.message);
                toast.error("An unexpected error occurred. Please try again.");
            }
            hideLoader();
        }
    };

  useEffect(() => {
    fetchUserDetailByEmail();
  }, []);

  const handleChangeSubscription = () => {
    navigate("/dashboard/change-subscription");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPfpModal(true);
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      setPfpFile(reader.result)
    })
    reader.readAsDataURL(file)
  };

  const uploadFile = async (file) => {
    try {
      showLoader();
      if (!file) {
        console.error("Please choose a file first!");
        toast.error("Please choose a file first!");
        return;
      }

            if(user.profile_image_url !== '') {
                if (profileImageUrl) {
                    try {
                        const url = new URL(profileImageUrl);
                        const s3Key = url.pathname.slice(1); // Extract S3 key from URL
                        await remove({
                            path: s3Key,
                            options: { level: 'public' },
                        });
                        console.log('Old image removed successfully from S3:', s3Key);
                    } catch (error) {
                        console.error('Error removing old image from S3:', error);
                        toast.error('Failed to remove old image from S3. Proceeding with new upload.');
                    }
                }
            }

      // Extract the base name (without extension) and the file extension
      const fileBaseName = file.name
        .split(".")
        .slice(0, -1)
        .join(".")
        .replace(/\s+/g, "_"); // Handle spaces
      const fileExtension = file.name.split(".").pop(); // Extract file extension
      const timestamp = Date.now(); // Current timestamp

      // Format the unique file name as originalName{timestamp}.extension
      const uniqueFileName = `profile-images/${fileBaseName}_${timestamp}.${fileExtension}`;

            // Upload the file with the unique name
            console.log("uniqueFileName", uniqueFileName);
            try {
                const result = await uploadData({
                    path: uniqueFileName,
                    data: file,
                    options: {
                        level: 'public',
                        contentType: file.type,
                        onProgress: ({ transferredBytes, totalBytes }) => {
                            if (totalBytes) {
                                console.log(
                                    `Upload progress ${Math.round((transferredBytes / totalBytes) * 100)} %`
                                );
                            }
                        }
                    }
                }).result;

                console.log('uploadData result:', result);
                
            } catch (error) {
                console.error('Error removing old image from S3:', error);
                // toast.error('Error :'+error);
            }

            

      // Get the public URL of the uploaded file
      const publicUrl = await getUrl({
        key: uniqueFileName,
        options: { level: "public", contentType: file.type },
      });
      const profile_image_url =
        publicUrl?.url?.origin + publicUrl?.url?.pathname;

      // Set the profile image URL with the unique name
      setProfileImageUrl(profile_image_url.replace("public/", ""));
      console.log("File uploaded successfully. Public URL:", publicUrl);
      hideLoader();
    } catch (error) {
      hideLoader();
      // toast.error("Error uploading file:" + error);
      console.error("Error uploading file:", error);
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      {!isPageReady && (
        <PageLoader/>
      )}
      <div className="tw-w-[377px] tw-h-[60px] tw-flex tw-items-center tw-my-1">
        <Link to="/dashboard/lender">
          <i className="fas fa-chevron-left tw-w-[18.41px] tw-h-[15.41px] tw-text-[#2A2F70]"></i>
        </Link>
        <h1 className="tw-text-[#000000] tw-font-bold tw-text-[40px] common-font">
          Profile Settings
        </h1>
      </div>
      <p className="tw-text-lg tw-text-[#202224] tw-font-nunito tw-font-light">
        Here is the information about all your orders.
      </p>

      <div className="tw-flex tw-flex-col tw-border-[1px] tw-border-[#0A263F]/14 tw-rounded-lg tw-p-5 tw-mt-[30px]">
        <div className="tw-flex tw-justify-between tw-border-b-[1px] tw-pb-4">
          <h2 className="tw-text-xl tw-text-[#202224] tw-font-semibold tw-font-nunito">
            Personal Information
          </h2>

          {/* {hasChanges ? (
                        <button onClick={handleSubmit} className="tw-flex tw-space-x-2 tw-items-center">
                            <img src={EditIcon} alt="change icon" />
                            <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Submit All Changes</span>
                        </button>
                    ) : (
                        <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Edit Information</span>
                    )} */}
          {!disabledForm ? (
            <button
              onClick={handleSubmit}
              className="tw-flex tw-space-x-2 tw-items-center tw-bg-[#7DDE92] tw-rounded-[10px] tw-border tw-border-[#DDDDDD]"
            >
              <span className="tw-px-[10px] tw-py-[5px] tw-text-[#2A2F70] common-font tw-font-semibold">
                Submit All Changes
              </span>
            </button>
          ) : (
            <button
              onClick={() => setDisableForm(false)}
              className="tw-flex tw-space-x-2 tw-items-center tw-bg-[#7DDE92] tw-rounded-[10px] tw-border tw-border-[#DDDDDD] hover:-tw-translate-y-1 tw-transition-all tw-duration-300"
            >
              <div className="tw-px-[20px] tw-flex">
                <img src={EditIcon} alt="change icon" />
                <span className="tw-text-[#2A2F70] tw-px-[5px] tw-py-[5px]  common-font tw-text-lg tw-font-semibold">
                  Change Profile Setting{" "}
                </span>
              </div>
            </button>
          )}
        </div>

        <div className="tw-flex tw-space-x-4 tw-items-center tw-my-8">
          <img
            src={profileImageUrl}
            alt="profile icon"
            className="tw-rounded-full tw-w-28 tw-h-28 tw-object-cover"
          />
          {/* {!disabledForm && (
                        <button className="tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-px-2 hover:tw-shadow-xl">Upload Image</button>
                    )} */}
          {!disabledForm && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: "none" }} // Hide the file input
              />

              {/* Button to trigger the file input */}
              <button
                type="button"
                onClick={handleButtonClick}
                className="tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-px-2 hover:tw-shadow-xl"
              >
                Upload Image
              </button>
            </>
          )}
          {disabledForm && user?.profile_image_url && (
            <>
              <button
                type="button"
                className="hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-font-nunito tw-border-[1px] tw-border-[#2A2F70] tw-text-[#2A2F70] tw-rounded-lg tw-h-10 tw-px-2 hover:tw-shadow-xl"
                onClick={handleConfirmOpen}
              >
                Remove Profile Image
              </button>
              <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete your profile image?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteConfirmation}
                    color="secondary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>

        <ChangePersonalInformationForm
          setUserInfo={setUserInfo}
          onChange={handleChange} // Pass the change handler
          userInfo={userInfo}
          disableForm={disabledForm}
        />

        <AddressForm
          setUserInfo={setUserInfo}
          onChange={handleChange} // Pass the change handler
          userInfo={userInfo}
          disableForm={disabledForm}
        />
      </div>

      <ChangePasswordForm />

      <PfpCropModal
      pfpFile={pfpFile}
        closeCb={() => {
          setPfpModal(false);
        }}
        openState={showPfpModal}
        uploadCallback={uploadFile}
      />
    </div>
  );
};

export default LenderSettings;
