import React from 'react';

const SignupOptionsModal = ({ showSignupOptionsModal, onClose }) => {
  if (!showSignupOptionsModal) return null; // If showSignupOptionsModal is false, don't render anything

  const handleOverlayClick = (e) => {
    // Check if the click was outside the modal content
    if (e.target === e.currentTarget) {
      onClose(""); // Close the modal
    }
  };

  return (
    <div onClick={handleOverlayClick} className="tw-fixed tw-font-oswald tw-inset-0 tw-bg-black tw-border-white tw-shadow-xl tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-flex tw-flex-col tw-bg-black tw-rounded-lg tw-p-10 tw-w-full tw-max-w-sm tw-space-y-10">
        <span className='tw-bg-logo-image tw-flex tw-left-0 tw-h-16 tw-bg-contain tw-w-full tw-bg-no-repeat'></span>
        <h2 className="tw-text-lg tw-font-semibold tw-mb-4">Join our Nationwide Network</h2>
        <button 
          className="tw-w-full tw-font-bold tw-bg-white tw-text-black tw-py-2 tw-px-4 tw-rounded tw-mb-3 hover:tw-bg-black hover:tw-text-white"
          onClick={() => onClose('borrower')}
        >
          Borrower Signup
        </button>
        <button 
          className="tw-w-full tw-font-bold tw-bg-white tw-text-black tw-py-2 tw-px-4 tw-rounded tw-mb-3 hover:tw-bg-black hover:tw-text-white"
          onClick={() => onClose('lender')}
        >
          Lender Signup
        </button>
      </div>
    </div>
  );
};

export default SignupOptionsModal;
