import { GoogleMapsProvider } from "../../../contexts/GoogleMapsContext";
import LoanCalculatorComponent from "../../LoanCalculatorComponent";

const LoanCalculatorArea = ({ openSignupBorrowerModal }) => {
    return (
        <div className="tw-bg-[#4853E4] tw-w-full tw-relative tw-rounded-b-xl tw-p-2">
            <div className="tw-flex tw-flex-col tw-gap-8 lg:tw-gap-0 lg:tw-flex-row tw-items-center tw-mx-10 tw-mb-12 md:tw-mt-12 lg:tw-space-x-20 lg:tw-mx-20">
                <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2">
        

                    <div className="tw-flex tw-flex-col tw-space-y-4">
                        <h2 className="tw-text-[#FFFFFF] tw-font-oswald tw-font-bold tw-text-5xl lg:tw-text-5xl">Easily Apply: </h2>
                        <h2 className="tw-text-[#FFFFFF] tw-font-oswald tw-font-bold tw-text-3xl lg:tw-text-4xl">Simplify Your Real Estate Financing Journey </h2>
                        <p className="tw-font-inter tw-text-[#FFFFFF] tw-text-xl lg:tw-text-3xl">
                            Easily explore tailored loan options for your next real estate investment. 
                            Input your property details to calculate key metrics like LTV and connect 
                            with lenders that fit your needs. Re-Loan enables direct access to a network 
                            of trusted lenders, providing clear, side-by-side comparisons to streamline your 
                            decision-making process.
                        </p>
                    </div>
                </div>

                <div className="lg:tw-w-1/2 tw-w-full tw-flex-col tw-hidden xl:tw-flex">
                    <GoogleMapsProvider>
                        <LoanCalculatorComponent openSignupBorrowerModal={openSignupBorrowerModal} />
                    </GoogleMapsProvider>
                </div>
            </div>
        </div>
    )
}

export default LoanCalculatorArea;