import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ApplicationTable from '../../common/applicationTable/ApplicationTable'
import { UserContext } from '../../../contexts/UserContext';
import { useLoader } from '../../../contexts/LoaderContext';
import toast from 'react-hot-toast';
import { formatDate, formatTimeDifference } from '../../../utils/common';
import { useApiService } from '../../../services/apiService';
import PageLoader, {usePageLoader} from '../../../contexts/PageLoaderContext';

function MembershipHistory() {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const { showLoader, hideLoader } = useLoader();
    const [membershipHistory, setMembershipHistory] = useState([]);
    const { showPageLoader, hidePageLoader } = usePageLoader();
    const [isPageReady, setIsPageReady] = useState(false);

    useEffect(() => {
        const initializePage = async () => {
            showPageLoader(); // Show the loader
            try {
                await fetchMembershipHistoryData();
            } catch (error) {
                toast.error("Failed to initialize the page.");
            } finally {
                setIsPageReady(true); // Set page ready
            }
        };
    
        initializePage();
    }, []);

    useEffect(() => {
              if (isPageReady) {
                  setTimeout(() => {
                      hidePageLoader();
                  }, 500); // Add a small delay before hiding the page loader
              }
          }, [isPageReady]);


    const fetchMembershipHistoryData = async () => {
        showLoader();
        try {
            const response = await postRequest(`/user-membership/list`,
                { user_id: user?.id }
            );
            if (response?.success) {
                const membershipData = response?.data?.userMembershipList;
                setMembershipHistory(membershipData || []);
            } else {
                console.error("Unexpected response:", response);
                setMembershipHistory([]);
                toast.error("Failed to fetch membership history. Please try again later.");
            }
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || "An error occurred while fetching membership history.";
            console.error("Error fetching membership history:", error);
            toast.error(errorMessage);
        } finally {
            hideLoader();
        }
    };

    const columns = [
        {
            field: "plan",
            headerName: "Plan",
            width: 195,
        },
        {
            field: "membership_start",
            headerName: "Membership Start",
            width: 195,
        },
        {
            field: "membership_end",
            headerName: "Membership End",
            width: 195,
        },
        {
            field: "billing_start_date",
            headerName: "Billing Start Date",
            width: 195,
        },
        {
            field: "next_billing_date",
            headerName: "Next Billing Date",
            width: 195,
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) =>
                params.value ? (
                    <span className={`status-${params.value.toLowerCase()}`}>{params.value}</span>
                ) : "-",
        },
        {
            field: "created_at",
            headerName: "Created At",
            width: 195,
            renderCell: (params) => {
                const formattedTimes = [];
                const formattedTime = formattedTimes.find((item) => item.id === params.row.id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
    ];

    const rows = membershipHistory?.length
        ? membershipHistory.map((app) => ({
            id: app?.id || "N/A",
            plan: app?.plan ? app?.plan : '-',
            membership_start: app?.membership_start ? formatDate(app?.membership_start) : null,
            membership_end: app?.membership_end ? formatDate(app?.membership_end) : null,
            billing_start_date: app?.billing_start_date ? formatDate(app?.billing_start_date) : '-',
            next_billing_date: app?.next_billing_date ? formatDate(app?.next_billing_date) : '-',
            status: app?.status || "-",
            created_at: app?.created_at ? app?.created_at : '-'
        }))
        : [];

    useEffect(() => {
        fetchMembershipHistoryData();
    }, [user]);

    return (
        <div className="tw-w-full tw-h-full tw-my-[12px]">
            {!isPageReady && <PageLoader />}
            <div>
                <div className='tw-h-[60px] tw-flex tw-items-center'>
                    <Link to="/dashboard/lender">
                        <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                    </Link>
                    <h1 className='tw-ml-2 tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                        Membership History
                    </h1>
                </div>
                <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your membership history.</p>
            </div>


            <div className='tw-w-full tw-h-[500px] tw-mt-[20px]'>
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row) => row.id, // Use the unique id from your data
                        userStatus: [""],
                        dashboardType: 'payment',
                        title: "Membership History",
                        getRowClassName: (params) =>
                            params?.row?.id === null ? 'selected-row' : ''
                    }}
                    rows={rows}
                    columns={columns}
                    onRowClick={() => { }}
                    height="400px"
                />
            </div>
        </div>
    )
}

export default MembershipHistory
