import React, { useContext, useState, useEffect, useRef } from 'react';
import { signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import LogoImage from "../../../assets/icons/Logo.png";
import UserProfile from "../../../assets/icons/usericon.svg";
import { authLocal } from '../../../utils/common';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { resetUser } = useContext(UserContext);
  const { user } = useContext(UserContext);

  const goToHome = () => {
      navigate("/");
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotificationBox = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleLogOut = async () => {
    try {
      await signOut();
      toast.success("Signed Out!");
      window.localStorage.removeItem("re_loan_info");
      window.localStorage.removeItem("user");
      authLocal.remove();
      window.localStorage.removeItem("pendingApplicationData");
      resetUser();
      navigate("/");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="tw-bg-[#555FE1] tw-w-full lg:tw-w-full tw-h-[87px] tw-flex tw-items-center tw-justify-between tw-px-[20px] tw-py-[8px]">
      {/* Left side: Company Logo */}
      <div className="tw-flex tw-items-center tw-w-[137px] tw-h-[21px]" onClick={goToHome}>
        <img src={LogoImage} alt="Re-loan logo" />
      </div>

      {/* Right side: Profile Image, Username, and Dropdown */}
      <div className="tw-relative tw-flex tw-items-center" ref={dropdownRef}>
        <div className="tw-w-[24px] tw-h-[24px] tw-me-[15px] tw-relative tw-flex tw-items-center" onClick={toggleNotificationBox} ref={notificationRef}>
          <i className="fas fa-bell tw-text-white tw-cursor-pointer"></i>
          <span className="tw-absolute tw-top-[-3px] tw-right-[5px] tw-w-1 tw-h-1 tw-bg-red-500 tw-rounded-full"></span>

          {/* Notification Box */}
          {isNotificationOpen && (
            <div className="tw-absolute tw-bg-white tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-opacity-100 tw-shadow-lg tw-p-[10px_20px] tw-top-[40px] tw-left-[-370px] tw-z-50">
              <div className='tw-w-[354px] tw-h-[59px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD]'>
                <h2 className='tw-text-[#444444] tw-text-semibold tw-text-[20px] common-font'>
                  New Notifications
                </h2>
                <Link
                  to={`notifications`}
                  className="tw-text-[#2A2F70] tw-text-semibold tw-text-[16px] common-font tw-cursor-pointer"
                >
                  View All
                </Link>
              </div>

              <div className='tw-w-[354px] tw-h-[59px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD]'>
                <img
                  src={UserProfile}
                  alt="Profile-one"
                  className="tw-w-[32px] tw-h-[32px] tw-rounded-full tw-border-[1px] tw-border-[#DDDDDD] tw-bg-[#DDDDDD]"
                />
                <h2 className='tw-text-[#444444] tw-text-semibold tw-text-[16px] common-font'>
                  New Message From Mary Jones
                </h2>
                <p className='tw-text-[#484848] tw-opacity-70 tw-text-normal tw-text-[16px] common-font'>04:50 PM</p>
              </div>

              <div className='tw-w-[354px] tw-h-[59px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD]'>
                <img
                  src={UserProfile}
                  alt="Profile-one"
                  className="tw-w-[32px] tw-h-[32px] tw-rounded-full tw-border-[1px] tw-border-[#DDDDDD] tw-bg-[#DDDDDD]"
                />
                <h2 className='tw-text-[#444444] tw-text-semibold tw-text-[16px] common-font'>
                  New Message From Andy H.
                </h2>
                <p className='tw-text-[#484848] tw-opacity-70 tw-text-normal tw-text-[16px] common-font'>04:50 PM</p>
              </div>
            </div>
          )}
        </div>

        {/* Profile Image */}
        <img
          src={UserProfile}
          alt="Profile"
          className="tw-w-[32px] tw-h-[32px] tw-me-[10px] tw-rounded-full"
        />

        <div
          className="tw-ml-2 tw-flex tw-items-center tw-cursor-pointer"
          onClick={toggleDropdown}
        >
          <span className="tw-text-white common-font tw-font-normal tw-text-[17px] tw-capitalize">{user.first_name +" "+ user.last_name}</span>
          <i className="tw-ml-2 tw-text-white fas fa-chevron-down"></i>
        </div>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div className="tw-absolute tw-right-0 tw-top-full tw-mt-2 tw-w-48 tw-bg-white tw-rounded-md tw-shadow-lg tw-border tw-py-2 tw-z-50">
            <Link
              to="settings"
              className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
            >
              <i className="tw-mr-2 tw-text-black fas fa-cog"></i>
              Account Setting
            </Link>
            <button
              className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
              onClick={handleLogOut}
            >
              <i className="tw-mr-2 tw-text-black fas fa-sign-out-alt"></i>
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
