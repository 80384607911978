import { Box, Card, CardContent } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import IcTrendingUp from "../../../assets/icons/ic-trending-up.png.png";

export default function StatCards({ statList }) {
  return (
    <div className="tw-block tw-mt-4">
      <div>
        <div className="4xl:tw-flex tw-justify-between tw-gap-4 tw-hidden">
          {statList?.map((stat, index) => (
            <div key={index} className="tw-w-[16%] tw-flex-shrink-0">
              <Card
                className="tw-w-full !tw-border !tw-border-[#DDDDDD] !tw-rounded-xl 4xl:tw-block"
                variant="outlined"
              >
                <CardContent
                  sx={{ height: "100%" }}
                  className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-5 !tw-px-5 !tw-py-3 tw-text-center"
                >
                <div className="tw-flex-col tw-gap-2">
                  <div className="tw-flex tw-gap-2 tw-justify-center">
                    <img
                      src={stat?.icon}
                      width={40}
                      height={40}
                      className="tw-object-contain"
                    />
                    <div className="tw-text-gray-600 tw-flex tw-justify-center tw-items-center tw-text-nowrap">
                      {stat?.title}:{" "}
                      <span className="tw-text-gray-900">{stat?.value}</span>
                    </div>
                  </div>
                  {stat?.change !== null && document.location.pathname.endsWith("admin") && (
                    <div className="tw-flex tw-items-center tw-space-x-2 common-font tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-justify-center">
                      <img
                        src={IcTrendingUp}
                        alt="trending-up-icon"
                        className="tw-w-auto tw-h-auto"
                      />

                      <p className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224]">
                        <span className="tw-text-[#00B69B] tw-me-[5px]">
                          {stat?.change}%
                        </span>
                        {stat?.change >= 0
                          ? "Up from last week"
                          : "Down from last week"}
                      </p>
                    </div>
                  )}
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>

        <div className="tw-flex tw-justify-center tw-gap-4">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            loop={true}
            centeredSlides={true}
            slidesPerView="auto"
            className="my-swiper-container tw-w-full tw-max-w-screen-lg 4xl:tw-hidden"
          >
            {statList?.map((stat, index) => (
              <SwiperSlide
                key={index}
                className="!tw-w-[350px] 2xl:!tw-w-[430px] !tw-opacity-100 tw-mx-3"
              >
                <Card
                  className="tw-w-full tw-border-2 !tw-rounded-xl"
                  variant="outlined"
                >
                  <CardContent
                    sx={{ height: "100%" }}
                    className="tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center !tw-px-3 tw-m-1 !tw-py-3 tw-text-center"
                  >
                    <div className="tw-flex tw-gap-3">
                    <img src={stat?.icon} width={40} height={40} />
                    <div className="tw-text-gray-600 tw-flex tw-items-center">
                      {stat?.title}:{" "}
                      <span className="tw-text-gray-900">{stat?.value}</span>
                    </div>
                    </div>
                    {stat?.change !== null && document.location.pathname.endsWith("admin") && (
                      <div className="tw-flex tw-justify-center tw-items-center tw-space-x-2 common-font tw-font-semibold tw-text-[16px] tw-leading-[21.82px]">
                        <img
                          src={IcTrendingUp}
                          alt="trending-up-icon"
                          className="tw-w-auto tw-h-auto"
                        />

                        <p className="tw-opacity-55 tw-font-semibold tw-text-[16px] tw-leading-[21.82px] tw-text-left common-font tw-text-[#202224]">
                          <span className="tw-text-[#00B69B] tw-me-[5px]">
                            {stat?.change}%
                          </span>
                          {stat?.change >= 0
                            ? "Up from last week"
                            : "Down from last week"}
                        </p>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
