import React, { useState } from 'react';
import UserProfile from "../../../assets/icons/usericon.svg";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

function NotificationList({ notification, pfp, onMarkAsRead }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationDate = new Date(notification?.created_at);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = () => {
    if (onMarkAsRead) {
      onMarkAsRead(notification?.id); // Pass the notification id to the parent handler
    }
  };

  const id = anchorEl ? `popover-${notification?.id}` : undefined;
  const open = Boolean(anchorEl);

  // Check if notification data is empty or null
  if (!notification) {
    return (
      <div className="tw-mx-[20px] tw-h-[92px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[10px] tw-flex tw-items-center tw-justify-center">
        <p className="tw-text-[18px] tw-text-[#484848] common-font">
          No notifications available.
        </p>
      </div>
    );
  }

  return (
    <div className="tw-mx-[20px] tw-h-[92px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[10px]">
      <div className="tw-w-full tw-h-[60px] tw-my-[15px] tw-flex">
        <div className="tw-w-[70%] tw-flex">
          <div className="tw-w-[60px] tw-h-[60px]">
            <img
              src={pfp ? pfp : UserProfile}
              alt={`Profile of ${notification?.created_user_name}`}
              className="tw-rounded-full tw-bg-[#DDDDDD] tw-border-[1px] tw-object-cover tw-w-full tw-h-full"
            />
          </div>
          <div className="tw-w-full tw-ms-[21px] tw-h-[60px]">
            <p className="tw-text-[20px] tw-text-[#202224] tw-font-semibold common-font">
              New message from {notification?.created_user_name}
            </p>
            <p className="tw-text-[18px] tw-text-[#484848] tw-font-normal common-font">
              {notification?.notification}
            </p>
          </div>
        </div>

        <div className="tw-w-[30%] tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex-1 tw-text-center">
            <p className="tw-text-[16px] common-font tw-text-[#484848] tw-opacity-70"></p>
          </div>
          {notification?.is_read == 0 && (
            <div className="tw-flex tw-items-center tw-justify-end tw-pl-2">
              <Button aria-describedby={id} onClick={handleClick}>
                <span className="tw-cursor-pointer">
                  <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-xl tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
                </span>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  sx: {
                    elevation: 0,
                    ml: 4,
                  },
                  elevation: 1,
                }}
              >
                <Typography sx={{ p: 1 }}>
                  <button onClick={handleMarkAsRead}>Mark As Read</button>
                </Typography>
              </Popover>
            </div>
          )}
        </div>
        <span className="tw-text-gray-900 tw-flex tw-justify-center tw-items-center tw-text-nowrap">
          {new Date() === new Date(notificationDate)
            ? `Today at ${notificationDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}`
            : new Date(notificationDate).toLocaleTimeString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
        </span>
      </div>
    </div>
  );
}

export default NotificationList;
