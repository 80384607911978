import React, { useContext, useState, useEffect } from 'react';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
    Button,
    Input,
    List,
    ListItem
} from "@material-tailwind/react";
import { UserContext } from '../../../contexts/UserContext';
import FilterDropdown from './../../common/applicationTable/FilterDropdown';
import FilterInputs from './../../common/applicationTable/FilterInputs';
import {
    GetState,
    GetCity,
} from "react-country-state-city";
import { useApiService } from '../../../services/apiService';

function BorrowerHeader({ title, userStatus, handleClosed, handleSortChange, handleItemClick, setFilters, filters, showClosed }) {
    const { user } = useContext(UserContext);
    const { postRequest } = useApiService();
    const [selectedStatusItem, setSelectedStatusItem] = useState({ id: '', currentStatus: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [loanPurposeList, setLoanPurposeList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [formValues, setFormValues] = useState({
        search_text: '',
        loan_purpose_id: '',
        property_type_id: '',
        state_id: '',
        city_id: '',
        property_value_min: '',
        property_value_max: '',
    });

    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const handleCloseDropdown = () => setIsOpen(false);

    const handleTogglePopover = () => setPopoverOpen(!isPopoverOpen);
    const handleClosePopover = () => setPopoverOpen(false);
    const handleItemSelection = (id, currentStatus) => {
        setSelectedStatusItem({ id, currentStatus });
        handleItemClick(id);
        handleClosePopover();
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const FilterDropdowns = [
        {
            label: 'Loan Purpose',
            name: 'loan_purpose_id',
            options: [
                { value: '', label: '- Select -' },
                ...loanPurposeList
                    .filter(item => item.status === 1)  // Ensure active options only
                    .map(item => ({ value: item?.id, label: item?.name })),
            ],
        },
        {
            label: 'Property Type',
            name: 'property_type_id',
            options: [
                { value: '', label: '- Select -' },
                ...propertyTypeList
                    .filter(item => item.status === 1)  // Ensure active options only
                    .map(item => ({ value: item?.id, label: item?.type })),
            ],
        },
        {
            label: 'State',
            name: 'state_id',
            options: [
                { value: '', label: '- Select -' },
                ...stateList.map(item => ({
                    value: item.id,
                    label: item.name,
                })),
            ],
        },
        // {
        //     label: 'City',
        //     name: 'city_id',
        //     options: [
        //         { value: '', label: '- Select -' },
        //         ...cityList.map(item => ({
        //             value: item.id,
        //             label: item.name,
        //         })),
        //     ],
        // },
    ];



    const handleApply = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            search_text: formValues?.search_text,
            loan_purpose_id: formValues?.loan_purpose_id,
            property_type_id: formValues?.property_type_id,
            state_id: formValues?.state_id,
            city_id: formValues?.city_id,
            property_value_min: formValues?.property_value_min,
            property_value_max: formValues?.property_value_max
        }));
    };

    const handleReset = () => {
        setFormValues({
            loan_purpose_id: '',
            property_type_id: '',
            state_id: '',
            city_id: '',
            property_value_min: '',
            property_value_max: '',
        });

        setFilters((prevFilters) => ({
            ...prevFilters,
            loan_purpose_id: '',
            property_type_id: '',
            state_id: '',
            city_id: '',
            property_value_min: '',
            property_value_max: ''
        }));
    };

    // fetch property type list
    const fetchPropertyTypeList = async () => {
        try {
            const response = await postRequest(`/property-type/list`, {
                status: '1',
                sort_by: "type",
                sort_order: "ASC"
            });
            if (response?.data?.propertyTypeList) {
                setPropertyTypeList(response?.data?.propertyTypeList);
            }
        } catch (error) {
            console.error("Error fetching property types:", error);
        }
    };

    // Fetch Loan Purpose List
    const fetchLoanPurposeList = async () => {
        try {
            const response = await postRequest(`/loan-purpose/list`, {
                status: '1',
                sort_by: "name",
                sort_order: "ASC"
            });
            if (response?.data?.loadPurposeList) {
                setLoanPurposeList(response?.data?.loadPurposeList);
            }
        } catch (error) {
            console.error("Error fetching Loan Purpose types:", error);
        }
    };
    const excludedStates = ['AS', 'GU', 'MP', 'UM'];
    useEffect(() => {
        // Fetch states for the US (country ID = 233)
        GetState(233)
            .then((result) => {
                result = result.filter(
                    (i) =>
                      !(
                        excludedStates.includes(i.state_code) ||
                        i.state_code.startsWith("UM-")
                      )
                  );
                setStateList(result);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        // Fetch cities for the selected state
        if (formValues?.state_id) {

            GetCity(233, parseFloat(formValues?.state_id)).then((result) => {
                result = result.filter((r) => !r.name.endsWith(" County"))
                setCityList(result);
            });
        }
    }, [formValues?.state_id]);

    useEffect(() => {
        fetchPropertyTypeList();
        fetchLoanPurposeList();
    }, [user]);

    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            <>
                {title && title != '' ?
                    <>
                        <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'> {title}</Typography>
                    </>
                    :
                    <>
                        {!showClosed ?
                            <></>
                            :
                            <div className='tw-flex-grow-[4] tw-border-b-[1px] tw-border-[#DDDDDD] tw-items-center tw-pl-3 tw-hidden 2xl:tw-block'>
                                <i className="fas fa-search tw-text-[#4F4F4F] tw-mr-2 tw-opacity-70"></i>
                                <input
                                    type="text"
                                    name="search_text"
                                    placeholder="Search application here..."
                                    className="tw-text-[#4F4F4F] common-font tw-text-[14px] tw-font-normal tw-italic tw-bg-transparent tw-border-none focus:tw-outline-none"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,           // Spread the previous filters to preserve them
                                            search_text: value,       // Update the search_text field
                                        }));
                                    }}
                                />
                            </div>
                        }
                    </>
                }
            </>
            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>
                    <div className='tw-flex tw-items-center tw-justify-center tw-w-[200px] tw-border tw-border-[#7DDE92] tw-rounded-[5px] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                        <Button
                            type="button"
                            onClick={handleClosed}
                            className="tw-font-oswald tw-text-[#4F4F4F] tw-flex tw-items-center tw-px-[12px] "
                            id="closed_applications"
                        >
                            {!showClosed ? "Show Closed Applications" : "Show Open Applications"}
                        </Button>
                    </div>
                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#000000] tw-rounded-[5px] tw-bg-[#FFFFFF] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                        <Popover
                            placement="bottom"
                            open={isOpen}
                            handler={toggleDropdown}
                        >
                            <PopoverHandler>
                                <Button className="tw-text-[#4F4F4F]">
                                    <i className="fas fa-arrow-down tw-text-[#4F4F4F] tw-me-[3px]"></i>
                                    <i className="fas fa-arrow-up tw-text-[#4F4F4F] tw-me-[10px]"></i>
                                    Sort
                                </Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[15%] tw-mt-[2.5px] tw-border tw-border-[#DDDDDD] tw-shadow-lg">
                                <List className="p-0 tw-bg-white">
                                    {[
                                        { label: "Old First", icon: "fa-arrow-down", sortKey: "oldFirst" },
                                        { label: "New First", icon: "fa-arrow-up", sortKey: "newFirst" },
                                        { label: "Old LTV%", icon: "fa-arrow-down", sortKey: "oldLtv" },
                                        { label: "New LTV%", icon: "fa-arrow-up", sortKey: "newLtv" },
                                        { label: "Old Property Value", icon: "fa-arrow-down", sortKey: "oldPropertyValue" },
                                        { label: "New Property Value", icon: "fa-arrow-up", sortKey: "newPropertyValue" },
                                        { label: "Old Loan Amount", icon: "fa-arrow-down", sortKey: "oldLoanAmount" },
                                        { label: "New Loan Amount", icon: "fa-arrow-up", sortKey: "newLoanAmount" },
                                        { label: "Old Number of Offers", icon: "fa-arrow-down", sortKey: "oldNumberOfOffers" },
                                        { label: "New Number of Offers", icon: "fa-arrow-up", sortKey: "newNumberOfOffers" },
                                    ].map((item, index) => (
                                        <ListItem
                                            key={index}
                                            id={`sortOption-${item.sortKey}`} // Unique ID based on sortKey
                                            className={`sort-option-${item.sortKey} tw-text-[#4F4F4F] common-font tw-text-[16px] tw-my-[5px] tw-px-[10px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD] last:tw-border-none`}
                                            onClick={() => {
                                                handleSortChange(item.sortKey);
                                                handleCloseDropdown();
                                            }}
                                        >
                                            <div className="tw-flex tw-items-center tw-space-x-2 tw-truncate">
                                                {item.icon && <i className={`fas ${item.icon} tw-text-[#4F4F4F]`}></i>}
                                                <span className="tw-truncate">{item.label}</span>
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className='tw-hidden tw-relative 2xl:tw-flex tw-px-4 tw-h-full tw-border tw-border-[#DDDDDD] hover:-tw-translate-y-1 tw-transition-all tw-duration-300 tw-rounded-[5px] tw-bg-[#FFFFFF] tw-items-center'>
                        <Popover
                            placement="bottom"
                            open={isPopoverOpen}
                            handler={handleTogglePopover}
                        >
                            <PopoverHandler>
                                <Button className='tw-text-[#4F4F4F]'>
                                    <i className="fas fa-circle-notch tw-text-[#4F4F4F] tw-me-[5px]"></i>
                                    {selectedStatusItem.currentStatus
                                        ? `${selectedStatusItem.currentStatus}`
                                        : `${selectedStatusItem.currentStatus} Statuses`}
                                </Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[8.5%] tw-mt-[2.5px] tw-border tw-border-[#DDDDDD]">
                                <List className="p-0 tw-bg-white">
                                    {[
                                        { id: '', status: 'All' },
                                        ...userStatus,
                                    ].map((item) => (
                                        <ListItem
                                            key={item.id}
                                            className={`hover:tw-bg-[#f0f0f0] tw-transition-colors tw-duration-150 tw-text-[#4F4F4F] common-font tw-text-[16px] tw-py-[8px] tw-flex tw-justify-center tw-border-b-[1px] 
                                                ${item.id === selectedStatusItem.id ? 'tw-bg-[#f0f0f0]' : ''}`}
                                            onClick={() => {
                                                handleItemSelection(item?.id, item?.status);
                                            }}
                                        >
                                            {item?.status}
                                        </ListItem>
                                    ))}
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className='tw-hidden tw-h-full tw-px-2 2xl:tw-flex tw-items-center tw-rounded-[5px] tw-border tw-border-[#DDDDDD] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'>
                        <Menu placement="bottom-end" open={isFilterMenuOpen} handler={setIsFilterMenuOpen} dismiss={{ itemPress: false }}>
                            <MenuHandler>
                                <div className='tw-relative tw-cursor-pointer tw-flex tw-space-x-2 tw-mx-[8px] tw-my-[8px]'>
                                    <i className="fas fa-filter tw-text-[#4F4F4F]"></i>
                                    <h1 className='tw-text-[#4F4F4F] tw-font-semibold common-font tw-text-[14px]'>Filters</h1>
                                    <i className="fas fa-sort-alpha-down tw-text-[#4F4F4F]"></i>
                                </div>
                            </MenuHandler>

                            {isFilterMenuOpen && (
                                <MenuList className="tw-font-inter tw-w-[315px] tw-bg-white tw-z-10 tw-mt-[10px] tw-py-4 tw-border tw-border-[#DDDDDD] tw-rounded-[10px]">
                                    {FilterDropdowns.map((dropdown, index) =>
                                        dropdown.isRange ? (
                                            <FilterInputs key={index} inputs={dropdown?.inputs} />
                                        ) : (
                                            <FilterDropdown
                                                key={index}
                                                label={dropdown.label}
                                                name={dropdown.name}
                                                options={dropdown.options}
                                                formValues={formValues}
                                                onChange={handleSelectChange}
                                            />
                                        )
                                    )}
                                    <MenuItem className='tw-w-[265px] tw-h-[48px] tw-ms-[20px] tw-mt-[20px] tw-flex'>
                                        <div
                                            className='tw-w-[132.5px] tw-h-[48px] tw-flex tw-justify-center tw-items-center'
                                            onClick={handleReset}
                                        >
                                            <i className="tw-text-white fas fa-redo tw-me-[10px] tw-filter-icons-bg"></i>
                                            <span className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>
                                                Reset
                                            </span>
                                        </div>
                                        <div
                                            className='tw-w-[132.5px] tw-h-[48px] tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] hover:-tw-translate-y-1 tw-transition-all tw-duration-300'
                                            onClick={handleApply}
                                        >
                                            <i className="fas fa-chevron-right tw-me-[10px] tw-filter-icons-bg"></i>
                                            <span className='tw-text-[#2A2F70] tw-text-[15px] tw-font-semibold common-font'>
                                                Apply
                                            </span>
                                        </div>
                                    </MenuItem>
                                </MenuList>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BorrowerHeader;