// FormBox.js
import React, { useState, useContext, useReducer, useEffect } from 'react';
import { Input } from '@mui/material';
import toast from 'react-hot-toast';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import { authLocal } from '../../../utils/common';
import { useLoader } from '../../../contexts/LoaderContext';
import { useModalContext } from '../../../contexts/ModalContexts';
import { GoogleMapsContext } from '../../../contexts/GoogleMapsContext';
import GoogleAddressMarker from '../../common/GoogleAddressMarker';

const initialState = {
    loanPurpose: '',
    address: '',
    propertyValue: '',
    loanToValue: '',
    lat: null,
    lng: null
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD_VALUE':
            return { ...state, [action.field]: action.value };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
}
const FormBox = ({ callBack = () => { }, selectedLocation }) => {
    const { isLoaded, autocomplete } = useContext(GoogleMapsContext);
    const { showModal } = useModalContext();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const { showLoader, hideLoader } = useLoader();
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);

    const { loanPurpose, address, propertyValue, loanToValue, lat, lng } = state;

    const handleChange = (e) => {
        let { name, value } = e.target;

        // Check if the field is propertyValue or loanToValue and format the value
        if (name === 'propertyValue' || name === 'loanToValue') {
            // Remove non-numeric characters and add commas
            value = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            value = value.length ? parseInt(value).toLocaleString() : ''; // Add commas for thousands

            // Update the state
            dispatch({ type: 'SET_FIELD_VALUE', field: name, value });
        } else {
            dispatch({ type: 'SET_FIELD_VALUE', field: name, value: e.target.value });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!loanPurpose) newErrors.loanPurpose = "Loan Purpose is required.";
        if (!address) newErrors.address = "Property Address is required.";
        if (!propertyValue) newErrors.propertyValue = "Property Value is required.";
        if (!loanToValue) newErrors.loanToValue = "Loan Amount is required.";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const createBorrowerLoanApplication = async () => {
        showLoader();
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/create`, {
                userId: user.id,
                property_address: address,
                property_value: propertyValue,
                loan_purpose: loanPurpose,
                desired_loan_to_value: loanToValue,
                lat: lat,
                lng: lng,
                property_status: 1
            });

            if (response.status === 200) {
                // toast.success("Application Submitted Successfully.");
                dispatch({ type: 'RESET' });
                setErrors({});
                setFormSubmitted(false);
                callBack()
                showModal("Your Application Has Been Submitted!", "success");
            } else {
                toast.error("Application Submission Failed.");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error Submitting the Loan Application.';
            toast.error(errorMessage);
            console.error('Error:', errorMessage);
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        if (autocomplete) {
            const listener = autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();

                if (place) {
                    if (place?.formatted_address) {
                        dispatch({ type: 'SET_FIELD_VALUE', field: 'address', value: place.formatted_address });
                    }

                    if (place.geometry && place.geometry.location) {
                        const lat = place?.geometry?.location?.lat();
                        const lng = place?.geometry?.location?.lng();

                        dispatch({ type: 'SET_FIELD_VALUE', field: 'lat', value: lat });
                        dispatch({ type: 'SET_FIELD_VALUE', field: 'lng', value: lng });
                    }
                }
            });

            return () => {
                window.google.maps.event.removeListener(listener);
            };
        }
    }, [autocomplete]);

    const getAQuote = async () => {
        setFormSubmitted(true);
        if (!validateForm()) return;

        const loggedIn = authLocal.get();
        if (loggedIn.user_type === 'borrower') {
            await createBorrowerLoanApplication();
        } else {
            toast.error("Unauthorized user type.");
        }
    };

    return (
        <>
            <div className=' tw-border tw-border-[#DDDDDD] tw-rounded-[10px]'>
                <div className="tw-h-[166px] tw-px-[24px] tw-py-[24px] tw-gap-[10px] tw-rounded-tl-[10px] tw-rounded-tr-[10px] tw-rounded-bl-[0px] tw-rounded-br-[0px] tw-bg-[#EEEFFC66] tw-flex tw-justify-center tw-items-center">
                    <div className='tw-w-full tw-h-[118px]'>
                        {/* Content goes here */}
                        <div className=' tw-flex tw-justify-between tw-py-1'>
                            <div className='tw-font-semibold tw-text-[#202224] common-font tw-text-[16px]'>
                                Calculate Loan To Value:
                            </div>
                            <div className='tw-font-semibold tw-text-[#4853E4] common-font tw-text-[16px]'>
                                {loanToValue && propertyValue
                            ? ((parseFloat(loanToValue.replace(/,/g, '')) / parseFloat(propertyValue.replace(/,/g, ''))) * 100).toFixed(2) + '%'
                            : '0%'}
                            </div>
                        </div>
                        <div className=' tw-flex tw-justify-between tw-py-1'>
                            <div className='tw-font-semibold tw-text-[#202224] common-font tw-text-[16px]'>
                                Potential Programs Available:
                            </div>
                            <div className='tw-font-semibold tw-text-[#000000] common-font tw-text-[16px]'>
                                0
                            </div>
                        </div>
                        <div className=' tw-flex tw-justify-between tw-py-1'>
                            <div className='tw-font-semibold tw-text-[#202224] common-font tw-text-[16px]'>
                                Estimated Rate:
                            </div>
                            <div className='tw-font-semibold tw-text-[#000000] common-font tw-text-[16px]'>
                                0%
                            </div>
                        </div>
                        <div className=' tw-flex tw-justify-between tw-py-1'>
                            <div className='tw-font-semibold tw-text-[#202224] common-font tw-text-[16px]'>
                                Potential Lenders:
                            </div>
                            <div className='tw-font-semibold tw-text-[#000000] common-font tw-text-[16px]'>
                                0
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tw-w-full tw-px-[24px] tw-py-[24px]'>
                    <div className='tw-w-full'>
                        <div className='tw-h-[77px]'>
                            <div className='tw-h-[19px] tw-text-[14px] tw-font-semibold common-font tw-text-[#4F4F4F]'>
                                Loan Purpose
                            </div>
                            <div className='tw-w-full tw-h-[57px]'>
                                <select className={` ${errors.loanPurpose ? 'tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-p-2 tw-text-[#202224] tw-border-red-500' : 'tw-select'}`} name="loanPurpose" value={loanPurpose} onChange={handleChange}>
                                    <option value="" disabled>- Select -</option>
                                    <option value="PURCHASE">PURCHASE</option>
                                    <option value="REFINANCE">REFINANCE</option>
                                    <option value="CASH-OUT REFINANCE">CASH-OUT REFINANCE</option>
                                    <option value="REHAB">REHAB</option>
                                    <option value="CONSTRUCTION">CONSTRUCTION</option>
                                </select>
                                {errors.loanPurpose && <p className="tw-text-red-500 tw-text-xs">{errors.loanPurpose}</p>} {/* Show error */}

                            </div>
                        </div>
                        <div className='tw-h-[77px] tw-my-5'>
                            <div className='tw-h-[19px] tw-text-[14px] tw-font-semibold common-font tw-text-[#4F4F4F]'>
                                Property Address
                            </div>
                            <div className='tw-h-[57px]'>
                                {/* <input
                                    type="text"
                                    placeholder="Please add your Property address here"
                                    className='tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[20px] tw-p-2 tw-text-[#202224]'
                                /> */}

                                <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    value={address}
                                    onChange={handleChange}
                                    className={`tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-p-2 tw-text-[#202224] ${errors.address ? 'tw-border-red-500' : ''}`}
                                    disabled={!isLoaded}
                                />

                                {errors.address && <p className="tw-text-red-500 tw-text-xs">{errors.address}</p>} {/* Show error */}

                            </div>
                        </div>
                        <div className='tw-h-[77px] tw-my-5'>
                            <div className='tw-h-[19px] tw-text-[14px] tw-font-semibold common-font tw-text-[#4F4F4F]'>
                                Property Value
                            </div>
                            <div className='tw-h-[57px]'>
                                <div class="tw-relative tw-h-full">
                                    <div class="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[10px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-5 tw-pointer-events-none">
                                        $
                                    </div>
                                    <input
                                        type="text"
                                        id="propertyValue"
                                        name="propertyValue"
                                        value={propertyValue}
                                        onChange={handleChange}
                                        className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[10px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-14 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.propertyValue ? 'tw-border-red-500' : ''}`} />
                                </div>
                                {errors.propertyValue && <p className="tw-text-red-500 tw-text-xs">{errors.propertyValue}</p>} {/* Show error */}

                            </div>
                        </div>
                        <div className='tw-h-[77px]'>
                            <div className='tw-h-[19px] tw-text-[14px] tw-font-semibold common-font tw-text-[#4F4F4F]'>
                                Loan Amount
                            </div>
                            <div className='tw-h-[57px]'>
                                <div class="tw-relative tw-h-full">
                                    <div class="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[10px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-5 tw-pointer-events-none">
                                        $
                                    </div>
                                    <input
                                        type="text"
                                        id="loanToValue"
                                        name="loanToValue"
                                        value={loanToValue}
                                        onChange={handleChange}
                                        className={`tw-w-full tw-h-full tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[10px] tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-14 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.loanToValue ? 'tw-border-red-500' : ''}`} />
                                </div>
                                {errors.loanToValue && <p className="tw-text-red-500 tw-text-xs">{errors.loanToValue}</p>} {/* Show error */}

                            </div>
                        </div>
                        {/* {
                            selectedLocation
                                ?
                                <div className='tw-my-5'>
                                    <GoogleAddressMarker selectedLocation={selectedLocation} />
                                </div>
                                : null
                        } */}
                        <div className='tw-h-[49px] tw-my-5 '>
                            <button className='tw-w-full tw-h-[49px] tw-border tw-text-[#2A2F70] tw-font-semibold tw-border-[#DDDDDD] tw-rounded-[20px] tw-bg-[#7DDE92] text-center'
                                onClick={getAQuote}>
                                Submit An Application
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormBox;
