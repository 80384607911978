import React, { createContext, useCallback, useMemo } from 'react';
// import { Amplify, Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
// import { getCurrentUser } from '@aws-amplify/auth';
import awsconfig from './../aws-exports'; 
// const AWS = require('aws-sdk');


Amplify.configure(awsconfig);

// Create a context
export const AWSAmplifyConfigContext = createContext({
  data:{},
  endpoint:"",
  apiName:""
});

// Create a provider component
export const AWSAmplifyConfigProvider = ({value, children }) => {
    // Assuming your API is part of `aws_cloud_logic_custom`
    const toBePassedData = useMemo(()=>{
        const cloudLogicCustomConfig = awsconfig.aws_cloud_logic_custom;
        let endpoint  =''
        let apiName  =''
        if (cloudLogicCustomConfig && cloudLogicCustomConfig.length > 0) 
          endpoint = cloudLogicCustomConfig[0].endpoint;
        if(cloudLogicCustomConfig[0].name)  
          apiName = cloudLogicCustomConfig[0].name;

        return {
          data:cloudLogicCustomConfig,
          endpoint:endpoint,
          apiName: apiName,
          s3BucketName: awsconfig.aws_user_files_s3_bucket,
          region: awsconfig.aws_user_files_s3_bucket_region,
        }
    },[])
    
   
      return (
        <AWSAmplifyConfigContext.Provider value={toBePassedData}>
          {children}
        </AWSAmplifyConfigContext.Provider>
      );
};
