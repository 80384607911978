import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function OfferLimitReached({ showPopup, setShowPopup }) {

const handleClickOpen = () => {
        setShowPopup(true);
    };

    const handleClose = () => {
        setShowPopup(false);
    };
    return (
        <React.Fragment>
            <Dialog
                open={showPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='tw-p-[25px]'>
                    <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">

                        <Typography id="modal-title" className=" tw-modal-title">
                            <div className='tw-ms-[20px] tw-text-[30px] common-font tw-font-semibold'>
                                Offer Limit Reached
                            </div>
                        </Typography>
                        <Button className="tw-text-gray-800" onClick={handleClose}>X</Button>
                    </div>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            You've reached the maximum number of offers allowed for your current plan. To submit more offers,   please upgrade your account.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className='tw-border tw-border-gray-600 tw-w-[170px] tw-h-[40px] common-font tw-text-[17px] tw-rounded-[10px] tw-bg-gray-30' onClick={handleClose}>No, cancel</button>
                        <button className='tw-border  tw-w-[170px] tw-h-[40px] common-font tw-text-[17px] tw-text-white tw-rounded-[10px] tw-bg-blue-900' onClick={handleClose}>Upgrade</button>
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default OfferLimitReached;
