import React, { memo } from 'react';

function FilterDropdown({ label, name, options, formValues, onChange }) {
    return (
        <div className='tw-w-[265px] tw-ms-[20px]'>
            <div className='borrower-menu-items-container'>
                <label className='borrower-app-table-dropdown-label'>{label}</label>
                <select
                    className='borrower-app-table-select'
                    name={name}
                    value={formValues[name]}
                    onChange={onChange}
                >
                    {options.map((option) => (
                        <option key={option.value} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default memo(FilterDropdown);
