import { useState } from "react";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from "../../assets/icons/editIcon.svg";

const ChangePasswordForm = () => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownCurrentPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpCurrentPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpNewPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="tw-flex tw-flex-col tw-border-[1px] tw-border-[#0A263F]/14 tw-rounded-lg tw-p-5 tw-my-10 tw-space-y-8">
            <div className="tw-flex tw-justify-between tw-border-b-[1px] tw-pb-4">
                <h2 className="tw-text-xl tw-text-[#202224] tw-font-semibold tw-font-nunito">Change Password</h2>

                <button className="tw-flex tw-space-x-2 tw-items-center">
                    <img src={EditIcon} alt="change icon" />
                    <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Change Password</span>
                </button>
            </div>

            <form className="tw-flex tw-space-x-4">
                <div className="tw-flex tw-flex-col tw-w-1/3">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Current Password</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-currentpassword"
                            type={showCurrentPassword ? 'text' : 'password'}
                            placeholder="Current Password"
                            autoComplete="false"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={
                                            showCurrentPassword ? 'hide the password' : 'display the password'
                                        }
                                        onClick={handleClickShowCurrentPassword}
                                        onMouseDown={handleMouseDownCurrentPassword}
                                        onMouseUp={handleMouseUpCurrentPassword}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>

                <div className="tw-flex tw-flex-col tw-w-1/3">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">New Password</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-newpassword"
                            type={showNewPassword ? 'text' : 'password'}
                            autoComplete="false"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={
                                            showNewPassword ? 'hide the password' : 'display the password'
                                        }
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownNewPassword}
                                        onMouseUp={handleMouseUpNewPassword}
                                        edge="end"
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="New Password"
                        />
                    </FormControl>
                </div>

                <div className="tw-flex tw-flex-col tw-w-1/3">
                    <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Confirm Password</p>
                    <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-confirmpassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete="false"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={
                                            showConfirmPassword ? 'hide the password' : 'display the password'
                                        }
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        onMouseUp={handleMouseUpConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="Confirm Password"
                        />
                    </FormControl>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordForm;